import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import LegalAgreementContainer from 'presentation/features/legal/agreement/container';
import CheckSignsContainer from 'presentation/features/legal/checkSigns/container';
import { FCC, ReactNode, useMemo } from 'react';

const AppDecorators: FCC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const decorators = useMemo<ReactNode[]>(() => {
    return [
      isAuthenticated && <LegalAgreementContainer key='legalAgreement' />,
      isAuthenticated && <CheckSignsContainer key='checkSigns' />,
    ].filter(item => !!item);
  }, [isAuthenticated]);

  return <RenderDecoratorContainer decorators={decorators}>{children}</RenderDecoratorContainer>;
};

export default AppDecorators;
