import { MPButton } from 'theme/ui-kit/button';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useCreateSocialPackageApplicationMutation } from 'api/user';
import { useSelector } from 'react-redux';
import { cspApplicationFormSelector, cspApplicationHasApplicationSelector } from '../store/selectors';
import { SocialPackageApplicationRequest } from 'domain/model';
import { useEffect } from 'react';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'components/common/loader';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';

export const CspApplicationActionsAdapter = () => {
  const handlers = useContextHandlers();

  const [createApplication, { error, isLoading }] = useCreateSocialPackageApplicationMutation();

  const form = useSelector(cspApplicationFormSelector);
  const hasApplication = useSelector(cspApplicationHasApplicationSelector);

  const onCreateApplication = () => {
    const isValid = handlers.onValidate();
    if (isValid) {
      createApplication(form as SocialPackageApplicationRequest);
    }
  };

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  if (hasApplication) {
    return null;
  }

  return (
    <ConfirmGroupButtons>
      <MPButton
        fullWidth
        color='brand'
        disabled={isLoading}
        onClick={onCreateApplication}
      >
        Подать заявку
        {isLoading && <ContentLoader />}
      </MPButton>
    </ConfirmGroupButtons>
  );
};
