import Splitter from 'components/common/splitter';
import CompensationRulesDoc from '../../components/rulesDoc';
import PrivilegeListText from '../../components/text/privilegeListText';
import SummaryText from '../../components/text/summaryText';
import CompensationRest from '../compensatonRest';

type SocialPackageDisagreedContentProps = {
  readonly limitToSelfRest: Nullable<number>;
  readonly limitToFamilyRest: Nullable<number>;
  readonly endDate: string;
};

const SocialPackageDisagreedContent = (props: SocialPackageDisagreedContentProps) => {
  const { limitToSelfRest, limitToFamilyRest, endDate } = props;

  return (
    <>
      <SummaryText endDate={endDate} />
      <Splitter size={2} />
      <CompensationRest
        limitToFamilyRest={limitToFamilyRest}
        limitToSelfRest={limitToSelfRest}
      />
      <Splitter size={2.5} />
      <PrivilegeListText endDate={endDate} />
      <Splitter size={2.5} />
      <CompensationRulesDoc />
    </>
  );
};

export default SocialPackageDisagreedContent;
