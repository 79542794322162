import LoginScreen from 'presentation/screen/login';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import LoginRzdScreen from 'screen/loginRzd';

const root = rootRouting.login;

const routing = {
  root,
  rzd: `${root}/rzd`,
};

const AuthEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.rzd}
        component={LoginRzdScreen}
      />
      <Route component={LoginScreen} />
    </Switch>
  );
};

export default AuthEntry;
