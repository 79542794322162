/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainCollectionId,
  GenMainCustomerCorpOfferShort,
  GenMainCustomerProductOfferShort,
  GenMainCustomerTradeOfferShort,
  GenMainOfferCategory,
  GenMainPartnerDesk,
} from './data-contracts';
import { createRequest } from './http-client';

export const CollectionsApi = {
  /**
   * No description
   * * @tags Collections
   * @name GetCustomerTradeOfferCollection
   * @summary Получение коллекции торговых предложений(ТП), актуальных для пользователя
   * @request GET:/customers/current/trade-offers/collections/{collectionId}
   * @secure
   */
  getCustomerTradeOfferCollection: (baseURL: string, collectionId: GenMainCollectionId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerTradeOfferShort[], void>({
      baseURL,
      path: `/customers/current/trade-offers/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Collections
   * @name GetCustomerOfferCategoriesCollection
   * @summary Получение коллекции категорий предложений, актуальных для пользователя
   * @request GET:/customers/current/offer-categories/collections/{collectionId}
   * @secure
   */
  getCustomerOfferCategoriesCollection: (baseURL: string, collectionId: GenMainCollectionId, signal?: AbortSignal) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/customers/current/offer-categories/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Collections
   * @name GetCustomerCorpOfferCollection
   * @summary Получение КП, актуальных для пользователя
   * @request GET:/customers/current/corp-offers/collections/{collectionId}
   * @secure
   */
  getCustomerCorpOfferCollection: (baseURL: string, collectionId: GenMainCollectionId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerCorpOfferShort[], void>({
      baseURL,
      path: `/customers/current/corp-offers/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Collections
   * @name GetCustomerProductOfferCollection
   * @summary Получение коллекции ПП, актуальных для пользователя
   * @request GET:/customers/current/product-offers/collections/{collectionId}
   * @secure
   */
  getCustomerProductOfferCollection: (baseURL: string, collectionId: GenMainCollectionId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerProductOfferShort[], void>({
      baseURL,
      path: `/customers/current/product-offers/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Collections
   * @name GetCustomerPartnerDeskCollection
   * @summary Получение коллекции торговых карточек партнеров, актуальных для пользователя
   * @request GET:/customers/current/partner-desks/collections/{collectionId}
   * @secure
   */
  getCustomerPartnerDeskCollection: (baseURL: string, collectionId: GenMainCollectionId, signal?: AbortSignal) =>
    createRequest<GenMainPartnerDesk[], void>({
      baseURL,
      path: `/customers/current/partner-desks/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),
};
