import { BookingOfferListDataOfferListRequest, BookingOfferListFilter } from './list/store/types';
import {
  BookingPriceUnit,
  EBookingOfferSortType,
  EOfferClientTagType,
  OfferTag,
  paginationSizeVariant,
} from 'domain/model';
import { GetOffersCountQueryParamsProps } from '../types';
import { MPChip } from 'theme/ui-kit/chip';

export const cspOfferTag: OfferTag = {
  type: EOfferClientTagType.CSP,
  name: 'КСП: Отдых',
};

export const bookingOffersDefaultParams: BookingOfferListDataOfferListRequest & BookingOfferListFilter = {
  search: {
    name: null,
    categories: null,
  },
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: [EBookingOfferSortType.Default],
  services: null,
  rzdSocialPackage: false,
  priceRange: {
    minPrice: null,
    maxPrice: null,
  },
};

export const getBookingOffersCountQueryParams = (props: GetOffersCountQueryParamsProps) => {
  const { name, categories } = props;

  return {
    search: {
      name,
      categories,
    },
    minPrice: null,
    maxPrice: null,
  };
};

export const getBookingOfferServiceInformer = (service: BookingPriceUnit, hasRzdSocialPackage?: Nullable<boolean>) => {
  if (hasRzdSocialPackage && service.rzdSocialPackage && service.rzdSocialPackageInfo) {
    return (
      <MPChip
        variant='filled'
        color='warning'
        size='small'
        label={service.rzdSocialPackageInfo}
      />
    );
  }
};
