import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(3)};

    position: relative;
    z-index: 1;

    border-radius: ${(theme.shape.borderRadius as number) * 1.6}px;
    background-color: ${theme.palette.background.paper};

    cursor: pointer;
  `
);
