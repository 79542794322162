import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { EOfferType } from 'domain/model';

export type CollectionItemStruct = {
  readonly id: UUID;
  readonly visibleCount: number;
};

export type CollectionStateStruct = {
  readonly guid: Nullable<UUID>;
  readonly [EOfferType.TradeOffer]: CollectionItemStruct[];
  readonly [EOfferType.CorpOffer]: CollectionItemStruct[];
};

export type CollectionState = {
  readonly collections: CollectionStateStruct[];
};

type CollectionPayload = CollectionItemStruct & {
  readonly guid: UUID;
  readonly offerType: EOfferType.TradeOffer | EOfferType.CorpOffer;
};

type Reducer<T> = CaseReducer<CollectionState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CollectionState> & {
  collectionSetState: Reducer<CollectionPayload>;
};

const setupCollection = (
  collection: CollectionStateStruct,
  offerType: EOfferType.TradeOffer | EOfferType.CorpOffer,
  payload: CollectionItemStruct
) => {
  const newCollection = { ...collection };

  const collectionTyped = [...collection[offerType]];

  const existedIndex = collectionTyped.findIndex(c => c.id === payload.id);

  if (existedIndex < 0) {
    collectionTyped.push({ ...payload });
  } else {
    collectionTyped.splice(existedIndex, 1, {
      ...collectionTyped[existedIndex],
      ...payload,
    });
  }

  newCollection[offerType] = [...collectionTyped];
  return newCollection;
};

const slice = createSlice<CollectionState, Reducers, 'collection'>({
  name: 'collection',
  initialState: { collections: [] },
  reducers: {
    collectionSetState: (state, { payload }) => {
      const { guid, ...offerPayload } = payload;
      const collectionIndex = state.collections.findIndex(item => item.guid === guid);

      if (collectionIndex < 0) {
        const newCollection: CollectionStateStruct = {
          guid,
          [EOfferType.CorpOffer]: [],
          [EOfferType.TradeOffer]: [],
        };

        newCollection[payload.offerType].push(offerPayload);
        state.collections.push(newCollection);
      } else {
        const collections = [...state.collections];
        const newCollection = setupCollection(collections[collectionIndex], payload.offerType, offerPayload);
        collections.splice(collectionIndex, 1, newCollection);

        state.collections = collections;
      }
    },
  },
});

export const { collectionSetState } = slice.actions;

export default slice.reducer;
