import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EOfferStatus } from 'domain/model';
import { useMemo } from 'react';
import { pluralize } from 'utils/pluralize';
import { getOfferNotUsedCountText } from '../../../../utils';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';

type CorpOfferClaimLeftCodesProps = {
  readonly codesCount: number;
  readonly promotionType: ECorpOfferPromotionType;
};

const CorpOfferClaimLeftCodes = ({ codesCount, promotionType }: CorpOfferClaimLeftCodesProps) => {
  return (
    <Typography variant='body2'>
      {codesCount > 0 && (
        <>
          {pluralize(codesCount, ['Остался', 'Осталось', 'Осталось'])}{' '}
          {getOfferNotUsedCountText(codesCount, promotionType)}
        </>
      )}
    </Typography>
  );
};

export const CorpOfferClaimLeftCodesAdapter = () => {
  const { corpOffer } = useCorpOfferClaimContext();

  const notUsedOfferCount: number = corpOffer?.notUsedOfferCount ?? 0;

  return useMemo(
    () =>
      corpOffer?.status === EOfferStatus.Active && corpOffer.promotionType ? (
        <Wrapper>
          <CorpOfferClaimLeftCodes
            codesCount={notUsedOfferCount}
            promotionType={corpOffer.promotionType}
          />
        </Wrapper>
      ) : null,
    [corpOffer?.status, corpOffer?.promotionType, notUsedOfferCount]
  );
};
