import styled from '@emotion/styled';
import { MPGrid } from 'theme/ui-kit/grid';

export const LogoWrapper = styled(MPGrid)`
  max-width: 80px;

  img {
    max-width: 100%;
    width: auto;
    max-height: 80px;
  }
`;
