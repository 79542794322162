import { FCC } from 'react';
import { ChangeEmailEventProps } from './types';
import { ChangeEmailContainer as ChangeFeatureEmailContainer } from 'presentation/features/auth/changeEmail/container';

export const ChangeEmailContainer: FCC<ChangeEmailEventProps> = ({
  event: {
    payload: { withSuccessStep },
  },
  onNext,
  onClose,
}) => {
  const onComplete = () => {
    onNext(true);
  };

  return (
    <ChangeFeatureEmailContainer
      withSuccessStep={withSuccessStep}
      onSuccess={onComplete}
      onClose={onClose}
    />
  );
};
