import {
  cartApi,
  cartApiEndpointNames,
  EUpdateBehaviour,
  useAddItemToCartMutation,
  useDeleteCartItemMutation,
  useGetCartQuery,
  useUpdateCartItemMutation,
} from 'data/api/cart';
import ErrorHandler from 'data/network/errorHandler';
import { Cart, CartItem, ProductOfferShort } from 'domain/model';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isCartItemStockOutOfQuantity } from '../utils';

type CartProviderHelpers = {
  readonly cart: Cart;
  readonly cartItem?: CartItem;
  readonly totalCount: number;
  readonly isItemAdding: boolean;
  readonly isOutOfQuantity: boolean;
  readonly isItemUpdating: boolean;
  readonly isItemDeleting: boolean;
  readonly isCartFetching: boolean;
  readonly isItemFetching: boolean;
  readonly quantitySum: number;
  readonly deleteItem: () => void;
  readonly addToCart: (count: number) => void;
  readonly updateItem: (count: number) => void;
  readonly increment: () => void;
  readonly decrement: () => void;
};

type CartProviderContainerProps = {
  readonly children: (props: CartProviderHelpers) => ReactElement;
  readonly product: ProductOfferShort;
};

const CartProviderContainer = (props: CartProviderContainerProps) => {
  const { product } = props;
  const { id: productId } = product;

  const dispatch = useDispatch();
  const { data: cart, isFetching: isCartFetching } = useGetCartQuery();

  const [triggerAddItemToCart, { isLoading: isItemAdding, error: itemAddingError }] = useAddItemToCartMutation();
  const [triggerDeleteCartItem, { isLoading: isItemDeleting, error: itemDeletingError }] = useDeleteCartItemMutation();
  const [triggerUpdateCartItem, { isLoading: isItemUpdating, error: itemUpdatingError }] = useUpdateCartItemMutation();
  const cartItem = cart?.items?.find(i => i.offer?.id === productId);

  const [stockLimitsHandled, setStockLimitsHandled] = useState(false);

  useEffect(() => {
    if (itemAddingError) {
      ErrorHandler.handleHttpError(itemAddingError);
    }

    if (itemDeletingError) {
      ErrorHandler.handleHttpError(itemDeletingError);
    }

    if (itemUpdatingError) {
      ErrorHandler.handleHttpError(itemUpdatingError);
    }
  }, [itemAddingError, itemDeletingError, itemUpdatingError]);

  const addToCart = useCallback(
    (quantity: number) => {
      triggerAddItemToCart({ product, quantity })
        .unwrap()
        .then(result => {
          // меняем id в драфте, созданном при оптимистическом апдейте на реальный, чтобы не перезапрашивать корзину, иначе настпует пиздос когда добавляем 5 элементов подряд
          dispatch(
            cartApi.util.updateQueryData(cartApiEndpointNames.getCart, undefined, draft => {
              const draftCartItem = draft?.items?.find(i => i.offer.id === result.offer.id);
              if (draftCartItem) {
                Object.assign(draftCartItem, {
                  ...draftCartItem,
                  id: result.id,
                });
              }
            })
          );
        });
    },
    [dispatch, product, triggerAddItemToCart]
  );

  const deleteItem = useCallback(() => {
    if (!cartItem || !cartItem.id) {
      return;
    }

    triggerDeleteCartItem({ id: cartItem.id });
  }, [cartItem, triggerDeleteCartItem]);

  const updateItem = useCallback(
    (quantity: number) => {
      if (!productId) {
        return;
      }

      if (!cartItem || !cartItem.id) {
        return;
      }

      if (quantity === 0) {
        deleteItem();
        return;
      }

      triggerUpdateCartItem({
        cartItemId: cartItem.id,
        productId,
        quantity,
        behaviour: EUpdateBehaviour.Optimistic,
      });
    },
    [cartItem, deleteItem, productId, triggerUpdateCartItem]
  );

  useEffect(() => {
    if (stockLimitsHandled) {
      return;
    }
    const isMoreThanStock = cartItem?.offer?.stock ? cartItem.quantity > cartItem?.offer?.stock : false;
    const isOutOfStock = cartItem?.offer?.stock === 0;

    if (isMoreThanStock && isOutOfStock) {
      deleteItem();
    } else if (isMoreThanStock && cartItem?.offer?.stock) {
      updateItem(cartItem.offer.stock);
    }

    setStockLimitsHandled(true);
  }, [cartItem?.offer?.stock, cartItem?.quantity, deleteItem, stockLimitsHandled, updateItem]);

  // TODO (@Ruslan Protopopov): to add loader
  if (!cart) {
    return null;
  }

  const increment = () => {
    if (!cartItem) {
      addToCart(1);
      return;
    }

    updateItem(cartItem.quantity + 1);
  };

  const decrement = () => {
    if (!cartItem) {
      return;
    }

    if (cartItem.quantity === 1) {
      deleteItem();
      return;
    }

    updateItem(cartItem.quantity - 1);
  };

  const cartItemStubReplacing = !!cartItem && !cartItem?.id;

  const isItemFetching = isItemAdding || cartItemStubReplacing || isItemUpdating || isItemDeleting;

  const quantitySum = cart?.items?.reduce((acc, item) => acc + item.quantity, 0) ?? 0;

  const isOutOfQuantity = cartItem ? isCartItemStockOutOfQuantity(cartItem) : false;

  const { children } = props;
  return children({
    cart,
    cartItem,
    totalCount: cart?.items?.length ?? 0,
    quantitySum,
    isItemAdding: isItemAdding || cartItemStubReplacing,
    isItemUpdating,
    isOutOfQuantity,
    isItemDeleting,
    isCartFetching,
    isItemFetching,
    addToCart,
    deleteItem,
    updateItem,
    increment,
    decrement,
  });
};

export default CartProviderContainer;
