import { getBookingOffersSearchRoute } from 'features/offer/booking/routes';
import { BookingOffersSearchListLocationState } from 'features/offer/booking/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

const useApplyFiltersToSearchParams = (props: BookingOffersSearchListLocationState) => {
  const history = useHistory();
  const { guid, categoryId, rzdSocialPackage, minPrice, maxPrice, services, sort, lastChangedFilterAttribute } = props;
  return useCallback(
    (props: Omit<BookingOffersSearchListLocationState, 'name'>) => {
      history.push(
        getBookingOffersSearchRoute({
          categoryId,
          minPrice,
          rzdSocialPackage,
          maxPrice,
          services,
          sort,
          guid,
          lastChangedFilterAttribute,
          ...props,
        })
      );
    },
    [history, categoryId, minPrice, rzdSocialPackage, maxPrice, services, sort, guid, lastChangedFilterAttribute]
  );
};

export default useApplyFiltersToSearchParams;
