import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const confirmPhoneEventType = 'ConfirmPhone';

export type ConfirmPhoneEventPayload = {};

//номер телефона в ответе
export type ConfirmPhoneEventResult = string;

export type ConfirmPhoneEvent = BusinessEvent<ConfirmPhoneEventPayload, ConfirmPhoneEventResult>;

export type ConfirmPhoneEventProps = DisplayEventComponentProps<ConfirmPhoneEventPayload, ConfirmPhoneEventResult>;
