import styled from '@emotion/styled';
import { css } from '@mui/material';

export const ContentWrapper = styled.div(
  () => css`
    height: 100%;

    display: flex;
    overflow-y: auto;

    > * {
      width: 100%;
    }
  `
);
