import { DialogueEventsActiveFlowAdapter } from './adapters';
import { DialogueEventsProvider } from './provider';

const DialogueEventsContainer = () => {
  return (
    <DialogueEventsProvider>
      <DialogueEventsActiveFlowAdapter />
    </DialogueEventsProvider>
  );
};

export default DialogueEventsContainer;
