import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { bookingOffersDefaultParams } from '../../utils';
import { BookingOfferListState } from './types';

type Reducer<T> = CaseReducer<BookingOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<BookingOfferListState> & {
  bookingOffersStartSession: Reducer<{ guid: UUID }>;
  bookingOffersSetPageSize: Reducer<number>;
  bookingOffersSetPage: Reducer<number>;
};

const { ...defaultData } = bookingOffersDefaultParams;

const slice = createSlice<BookingOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    data: defaultData,
  },
  reducers: {
    bookingOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
    bookingOffersSetPageSize: (state, { payload }) => {
      state.data.pageSize = payload;
    },
    bookingOffersSetPage: (state, { payload }) => {
      state.data.page = payload;
    },
  },
});

export const { bookingOffersSetPageSize, bookingOffersSetPage, bookingOffersStartSession } = slice.actions;

export default slice.reducer;
