import styled from '@emotion/styled';
import { Avatar, AvatarProps, css, avatarClasses } from '@mui/material';
import React from 'react';
import { MPAvatarSize } from './types';

type StyledAvatarProps = AvatarProps & {
  readonly autosize: boolean;
  readonly size: MPAvatarSize;
  readonly selected: boolean;
  readonly children: React.ReactNode | false;
};

export const StyledAvatar = styled(({ size, autosize, selected, ...others }: StyledAvatarProps) => (
  <Avatar {...others} />
))(
  ({ theme, selected, autosize, size }) => css`
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.075em;

    ${selected &&
    `
    border: solid 3px ${theme.palette.primary.main};
  `};

    ${!autosize &&
    `
    & .${avatarClasses.img} {
      width: auto;
      height: auto;
      max-width: 70%;
      max-height: 70%;
    }
  `};

    ${size === 'micro' &&
    `
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
    font-size: .6em;
  `};

    ${size === 'small' &&
    `
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    font-size: .8em;
  `};

    ${size === 'default' &&
    `
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    font-size: .8125rem;
  `};

    ${size === 'large' &&
    `
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    font-size: 1rem;
  `};

    ${size === 'giant' &&
    `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    font-size: 1.5em;  
  `};

    ${size === 'largest' &&
    `
    width: ${theme.spacing(10)};
    height: ${theme.spacing(10)};
    font-size: 1.8em;
  `};

    ${size === 'inherit' &&
    `
    width: 100%;
    height: 100%;
    font-size: .8125rem;
  `};
  `
);
