import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    p {
      font-size: 1rem;
      margin-block-start: 0;
      margin-block-end: 0;

      line-height: 24px;
    }
  `
);
