import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { SocialPackageHowToUseEventContainer } from './container';
import { SocialPackageHowToUseEvent, SocialPackageHowToUseEventPayload, socialPackageHowToUseEventType } from './types';

export type { SocialPackageHowToUseEventPayload } from './types';

export const createEvent = (payload: SocialPackageHowToUseEventPayload): SocialPackageHowToUseEvent =>
  EventCreatorFactory.business().getSimple(
    socialPackageHowToUseEventType,
    SocialPackageHowToUseEventContainer,
    payload
  );
