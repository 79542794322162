import { CorpOfferShort } from 'domain/model';
import { useHistory } from 'react-router';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import CorpOfferClaim from './component';
import CorpOfferClaimProviders from './providers';

type CorpOfferClaimContainerProps = {
  readonly corpOffer: CorpOfferShort;
};

const CorpOfferClaimContainer = (props: CorpOfferClaimContainerProps) => {
  const { corpOffer } = props;
  const history = useHistory();

  const onShowAllOffers = () => {
    if (corpOffer?.partner) {
      history.push(
        getPartnerDeskDetailsRoute({
          id: corpOffer.partner.id,
          tab: EPartnerDeskDetailsTab.CorpOffers,
        })
      );
    }
  };

  return (
    <CorpOfferClaimProviders
      corpOffer={corpOffer}
      onShowAllOffers={onShowAllOffers}
    >
      <CorpOfferClaim />
    </CorpOfferClaimProviders>
  );
};

export default CorpOfferClaimContainer;
