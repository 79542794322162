import styled from '@emotion/styled';
import { Container, css, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { MPGrid } from 'theme/ui-kit/grid';
import { EParentGridAreas } from '../bookingDetail/types';

type ParentLayoutGridAreaProps = {
  readonly area: EParentGridAreas;
};

export const FitContainer = styled(Container)`
  [area=${EParentGridAreas.Inner}] {
    ${p => p.theme.breakpoints.up('md')} {
      padding-top: ${p => p.theme.spacing(2)};
      padding-bottom: ${p => p.theme.spacing(12.5)};
    }
  }
`;

export const ParentLayoutGridArea = styled(MPGrid)<ParentLayoutGridAreaProps>`
  grid-area: ${p => p.area};
`;

export const Text = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing()};
`;

export const Wrapper = styled.div(
  p => css`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${p.theme.spacing(1.5)};
    align-items: start;

    ${p.theme.breakpoints.up('md')} {
      grid-template-columns: 1fr min-content;
      column-gap: ${p.theme.spacing(2)};
    }

    ${p.theme.breakpoints.up('lg')} {
      column-gap: ${p.theme.spacing(3)};
    }

    ${p.theme.breakpoints.up('xl')} {
      column-gap: ${p.theme.spacing(5)};
    }
  `
);

export const Content = styled.div``;

type SidebarProps = {
  readonly top?: number | string;
  readonly children?: ReactNode;
};

export const Sidebar = styled(({ top, ...props }: SidebarProps) => <div {...props} />)(
  p => css`
    min-width: 288px;

    position: sticky;
    top: ${p.top ? (typeof p.top === 'string' ? p.top : `${p.top}px`) : 0};

    ${p.theme.breakpoints.up('lg')} {
      min-width: 346px;
    }
  `
);

type StickyTitleProps = {
  readonly top: number;
};

export const StickyTitle = styled(Typography)<StickyTitleProps>(
  p => css`
    position: sticky;
    top: ${p.top}px;

    background: ${p.theme.palette.white.main};
    z-index: 1;
  `
);
