import { ENoticeStatus, Notice } from 'domain/model';
import SentryClient from 'integration/sentry/client';
import Notifier from 'system/notifier';
import { isSecurityBusinessError } from '../../presentation/utils/auth';
import { businessErrorCode, businessErrorCode2 } from './constants';
import { ServerErrorResponse } from './types';
import { ScopeContext } from '@sentry/types/types/scope';
import { AppConfigurator } from 'system/appConfigurator';

export default class ErrorHandler {
  static handleHttpErrorLegacy(
    error: Error,
    response: any,
    parser: (response: any) => Nullable<Notice> = parseHttpErrorResponse
  ) {
    if (response) {
      const notice = parser(response);
      if (notice) {
        Notifier.getInstance().addNotice(notice.status, notice.text);

        if (AppConfigurator.getInstance().getOptions().sentry.xhrTracking) {
          const noticeForLogger = buildServerErrorResponseTextForLogger(notice.text, response);
          ErrorHandler.captureException(new Error(noticeForLogger));
        }
      }
    } else {
      console.error(error);
      ErrorHandler.captureException(error);
    }
  }

  static handleHttpError(response: any, parser: (response: any) => Nullable<Notice> = parseHttpErrorResponse) {
    if ((response as any)?.__CANCEL__) {
      // Axios Cancel
      return;
    }

    const notice = parser(response);
    if (notice) {
      Notifier.getInstance().addNotice(notice.status, notice.text);

      if (AppConfigurator.getInstance().getOptions().sentry.xhrTracking) {
        const noticeForLogger = buildServerErrorResponseTextForLogger(notice.text, response);
        ErrorHandler.captureException(new Error(noticeForLogger));
      }
    }
  }

  static handleBusinessError(
    response: ServerErrorResponse,
    parser: (response: ServerErrorResponse) => Nullable<Notice> = processServerResponseData
  ) {
    const notice = parser(response);
    if (notice) {
      Notifier.getInstance().addNotice(notice.status, notice.text);

      if (AppConfigurator.getInstance().getOptions().sentry.xhrTracking) {
        const noticeForLogger = buildServerErrorResponseTextForLogger(notice.text, response);
        ErrorHandler.captureException(new Error(noticeForLogger));
      }
    }
  }

  static captureException(exception: string | Error, context?: Partial<ScopeContext>) {
    SentryClient.getInstance().captureException(exception, context);
  }
}

const parseHttpErrorResponse = (response: any): Nullable<Notice> => {
  try {
    switch (response.status) {
      case 401:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Отсутствуют полномочия на запрашиваемый ресурс', response),
        };
      case 403:
        if (isSecurityBusinessError(response.data)) {
          return null;
        } else if (isServerResponseData(response.data)) {
          return {
            status: ENoticeStatus.Error,
            text: buildServerErrorResponseTextForClient(
              'У пользователя не хватает прав доступа к запрашиваемому ресурсу',
              response
            ),
          };
        } else {
          return null;
        }
      case 404:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Данные не найдены', response),
        };
      case businessErrorCode:
      case businessErrorCode2:
        return processServerResponseData(response.data);
      case 500:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Внутренняя ошибка сервера', response),
        };
      case 502:
      case 503:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Сервер недоступен', response),
        };
      default:
        return {
          status: ENoticeStatus.Error,
          text: buildServerErrorResponseTextForClient('Неизвестная ошибка сервера', response),
        };
    }
  } catch (e: any) {
    console.error({ ...e, source: response });
    return { status: ENoticeStatus.Error, text: 'Ошибка обработки ответа от сервера' };
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildServerErrorResponseTextForClient = (errorText: string, response: any): string => {
  return errorText;
};

const buildServerErrorResponseTextForLogger = (errorText: string, response: any): string => {
  const text = response?.data?.message?.slice(0, 500) || errorText;
  return `[${response.status || 'None'}] ${text}`;
};

const processServerResponseData = (data: ServerErrorResponse): Notice => {
  const { message } = data;
  if (!message) return { status: ENoticeStatus.Warning, text: 'Не получен ожидаемый ответ от сервера' };
  return { status: ENoticeStatus.Error, text: message };
};

const isServerResponseData = (data: ServerErrorResponse): boolean => {
  const { message } = data;
  return !!message;
};
