import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const confirmEmailEventType = 'ConfirmEmail';

export type ConfirmEmailEventPayload = {
  readonly withSuccessStep?: boolean;
};

//факт подтверждения почты в ответе
export type ConfirmEmailEventResult = boolean;

export type ConfirmEmailEvent = BusinessEvent<ConfirmEmailEventPayload, ConfirmEmailEventResult>;

export type ConfirmEmailEventProps = DisplayEventComponentProps<ConfirmEmailEventPayload, ConfirmEmailEventResult>;
