import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/deleteProfile';
import { MPLink } from 'theme/ui-kit/link';

export const UserProfileDeleteProfileAdapter = () => {
  const { publish } = useBusinessEventBus();

  const onDeleteProfile = () => {
    publish(createEvent({}));
  };

  return (
    <MPLink
      color='brand'
      onClick={onDeleteProfile}
    >
      Удалить аккаунт
    </MPLink>
  );
};
