import LoginRzdContainer from 'features/auth/loginRzd/container';
import ScreenLayout from 'presentation/layouts/screen';

const LoginRzdScreen = () => {
  return (
    <ScreenLayout>
      <LoginRzdContainer />
    </ScreenLayout>
  );
};

export default LoginRzdScreen;
