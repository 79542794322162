import { EBalanceType } from 'domain/model';
import {
  BalanceTabsAdapter,
  UserFinanceBalancesAdapter,
  UserFinanceBannersAdapter,
  UserFinancesLoaderAdapter,
} from './adapters';
import { UserFinanceHistoryAdapter } from './adapters/history';
import { UserFinancesLayout } from './layout';

const Layout = UserFinancesLayout;

type UserFinancesContainerProps = {
  readonly guid: UUID;
  readonly tab: EBalanceType;
};

const UserFinancesEntryContainer = ({ guid, tab }: UserFinancesContainerProps) => {
  return (
    <Layout
      tabs={<BalanceTabsAdapter tab={tab} />}
      loader={<UserFinancesLoaderAdapter />}
      balances={<UserFinanceBalancesAdapter />}
      banners={<UserFinanceBannersAdapter />}
      history={
        <UserFinanceHistoryAdapter
          guid={guid}
          tab={tab}
        />
      }
    />
  );
};

export default UserFinancesEntryContainer;
