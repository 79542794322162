import { Fade, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CloseWrapper, EOrderGridAreas, OrderLayoutGrid, OrderLayoutGridArea, Wrapper } from './controls';
import { BackgroundContainer } from './controls';
import BannerBadge from 'presentation/components/common/badges/banner';
import { useOrderPrepare } from '../hooks/useOrderPrepare';

type OrderLayoutProps = {
  readonly options?: ReactNode;
  readonly details?: ReactNode;
  readonly close?: ReactNode;
  readonly banner?: ReactNode;
  readonly smartphoneFixedBar?: ReactNode;
  readonly icon: typeof SvgIcon;
};

const OrderLayout = (props: OrderLayoutProps) => {
  const { smartphoneFixedBar, options, close, icon, details, banner } = props;

  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const theme = useTheme();
  useOrderPrepare();

  return (
    <Fade in>
      <Wrapper>
        {isLgDown && smartphoneFixedBar}
        {!isLgDown && <CloseWrapper>{close}</CloseWrapper>}
        <BannerBadge
          icon={icon}
          backgroundColor={theme.palette.background.default}
        >
          <BackgroundContainer>
            <OrderLayoutGrid>
              {options && <OrderLayoutGridArea area={EOrderGridAreas.Options}>{options}</OrderLayoutGridArea>}
              {banner && <OrderLayoutGridArea area={EOrderGridAreas.Banner}>{banner}</OrderLayoutGridArea>}
              <OrderLayoutGridArea area={EOrderGridAreas.Details}>{details}</OrderLayoutGridArea>
            </OrderLayoutGrid>
          </BackgroundContainer>
        </BannerBadge>
      </Wrapper>
    </Fade>
  );
};

export default OrderLayout;
