import { User } from 'domain/model';
import { FCC, ReactNode } from 'react';
import { useAuthUser } from './useAuthUser';

type LoggedUserAdapterProps = {
  readonly component?: FCC<{ user: User }>;
  readonly fallback?: ReactNode;
};

export const LoggedUserAdapter: FCC<LoggedUserAdapterProps> = props => {
  const { component: Component, fallback, children } = props;

  const { user } = useAuthUser();

  if (user) {
    return Component ? <Component user={user}>{children}</Component> : children;
  } else {
    return fallback ?? null;
  }
};
