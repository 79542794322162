import { FC } from 'react';
import { DialogueEventAsComponentType, useDialogueEvents } from '../';
import { DialogueEventDialogLayout } from '../layouts/dialog';

type DialogueEventsComponentFlowAdapterProps = {
  readonly event: Nullable<DialogueEventAsComponentType>;
};

export const DialogueEventsComponentFlowAdapter: FC<DialogueEventsComponentFlowAdapterProps> = ({ event }) => {
  const { onNext, onClose } = useDialogueEvents();

  const { component: Component } = event ?? {};

  return (
    <DialogueEventDialogLayout
      open={true}
      onClose={onClose}
      disableEscapeKeyDown
    >
      {Component && event && (
        <Component
          event={event}
          onNext={onNext}
          onClose={onClose}
        />
      )}
    </DialogueEventDialogLayout>
  );
};
