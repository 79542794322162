import { UserFeedBackForm } from 'domain/model';
import validator from 'validator';
import { EValidationMessages, EValidationType, ValidationRules } from 'presentationUtils/validation';

enum EFeedBackValidationMessages {
  EmptyName = 'Укажите имя и фамилию',
  EmptyEmail = 'Укажите электронную почту',
  EmptyMessage = 'Напишите сообщение',
}

export const userFeedBackValidation: ValidationRules<UserFeedBackForm> = {
  name: {
    required: true,
    requiredMessage: EFeedBackValidationMessages.EmptyName,
  },
  email: {
    required: true,
    requiredMessage: EFeedBackValidationMessages.EmptyEmail,
    validator: (object, value) => {
      if (!value) return null;

      const isURL = validator.isEmail(value);
      if (!isURL) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.EmailInvalid,
        };
      } else {
        return null;
      }
    },
  },
  message: {
    required: true,
    requiredMessage: EFeedBackValidationMessages.EmptyMessage,
    validator: (object, value) => {
      if (!value.trim()) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EFeedBackValidationMessages.EmptyMessage,
        };
      }

      return null;
    },
  },
};
