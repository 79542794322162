import { Text } from '../../components/controls';
const AboutCspHowIsFamilyInfo = () => {
  return (
    <>
      <Text>
        К членам семьи работника относятся супруги, дети (в возрасте до 18 лет и дети, обучающиеся по очной форме в
        высших и средних специальных учебных заведениях до достижения ими возраста 24 лет).
      </Text>
    </>
  );
};

export default AboutCspHowIsFamilyInfo;
