import { FC, useEffect, useState } from 'react';
import { UserFeedBackForm } from 'domain/model';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import { useUserFeedbackMutation } from 'api/user';
import useValidation from 'hooks/validation/useValidation';
import { userFeedBackValidation } from 'features/user/events/feedBack/validation';
import ErrorHandler from 'data/network/errorHandler';
import { getPersonFullName } from 'presentation/utils';
import { FormAdapter } from './form';
import { OnChangeObjectAttribute } from 'presentation/types';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPButton } from 'theme/ui-kit/button';
import ContentLoader from 'components/common/loader';

type CommonAdapterProps = {
  readonly onCancel: () => void;
  readonly onSuccess: () => void;
};

const emptyData: UserFeedBackForm = { name: '', email: '', message: '', attachment: null };

export const CommonAdapter: FC<CommonAdapterProps> = ({ onCancel, onSuccess }) => {
  const { user } = useAuthUser();

  const [feedBackData, setFeedBackData] = useState<UserFeedBackForm>(emptyData);
  const [validateOnChangeFeedBack, setValidateOnChangeFeedBack] = useState<boolean>(false);

  const [userFeedback, { error: userFeedbackError, isSuccess, isLoading }] = useUserFeedbackMutation();

  const { validationResult, validate } = useValidation<UserFeedBackForm>({
    object: feedBackData,
    validateOnChange: validateOnChangeFeedBack,
    rules: userFeedBackValidation,
  });

  const onSend = () => {
    if (!validate()) {
      setValidateOnChangeFeedBack(true);
    } else {
      userFeedback(feedBackData);
    }
  };

  const onChangeAttribute: OnChangeObjectAttribute<UserFeedBackForm> = (name, value) => {
    setFeedBackData(prevFeedBackData => {
      return prevFeedBackData ? { ...prevFeedBackData, [name]: value } : prevFeedBackData;
    });
  };

  useEffect(() => {
    if (userFeedbackError) {
      ErrorHandler.handleHttpError(userFeedbackError);
    }
  }, [userFeedbackError]);

  useEffect(() => {
    if (user) {
      setFeedBackData({ ...emptyData, name: getPersonFullName(user), email: user.email });
    } else {
      setFeedBackData({ ...emptyData, name: '', email: '' });
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      setValidateOnChangeFeedBack(false);
      setFeedBackData(emptyData);
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <DialogueEventContentBuilderLayout
      title='Форма обратной связи'
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isLoading}
            onClick={onSend}
          >
            Отправить
            {isLoading && <ContentLoader />}
          </MPButton>
          <MPButton
            color='brand'
            fullWidth
            size='large'
            variant='text'
            disabled={isLoading}
            onClick={onCancel}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <FormAdapter
        data={feedBackData}
        validation={validationResult}
        isFetching={isLoading}
        onChangeAttribute={onChangeAttribute}
      />
    </DialogueEventContentBuilderLayout>
  );
};
