import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { SidebarWrapper } from '../controls';
import ActivationListItemLabel from '../label/title';
import ActivationWidgetButton from '../widgetButton';
import { useRef } from 'react';
import { OfferActivation } from 'domain/model';

type ActivationListItemWidgetSidebarProps = {
  readonly appointmentDate: Nullable<string>;
  readonly expireDate: Nullable<string>;
  readonly codeTypeName: string;
  readonly activation: OfferActivation;
  readonly srcDoc: string;
};

const ActivationListItemWidgetSidebar = (props: ActivationListItemWidgetSidebarProps) => {
  const { appointmentDate, expireDate, codeTypeName, activation, srcDoc } = props;

  const wrapperRef = useRef(null);

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={1}
        direction='column'
      >
        <MPGrid item>
          <ActivationListItemLabel
            codeTypeName={codeTypeName}
            appointmentDate={appointmentDate}
          />
          {expireDate && <ActivationListItemExpireDate expireDate={expireDate} />}
        </MPGrid>
        <MPGrid item>
          <ActivationWidgetButton
            wrapperRef={wrapperRef}
            activation={activation}
            srcDoc={srcDoc}
          />
        </MPGrid>
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemWidgetSidebar;
