import { FC, useEffect } from 'react';
import ErrorHandler from 'data/network/errorHandler';
import { Category, CategoryTree } from 'domain/model';
import CategorySelectRootContainer from 'presentation/features/category/select';
import CategorySelectCommonContainer from 'presentation/features/category/select/common';
import { ViewGridIcon } from 'presentation/media/icons';

type CatalogOffersContainerProps = {
  readonly selected: Nullable<UUID>;
  readonly isFetching: boolean;
  readonly data?: CategoryTree;
  readonly error?: unknown;
  readonly onSelect: (category: Category) => void;
};

export const CatalogOffersContainer: FC<CatalogOffersContainerProps> = props => {
  const { selected, data, isFetching, error, onSelect } = props;

  const treeOut: CategoryTree = [
    {
      id: '',
      name: 'Все предложения',
      parentId: null,
      image: <ViewGridIcon />,
      children: [],
    },
    ...(data ?? []),
  ];

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  return (
    <CategorySelectRootContainer
      showSelected
      tree={treeOut}
      isFetching={isFetching}
      selected={selected}
      onSelect={onSelect}
    >
      {({ childTree }) =>
        childTree && (
          <CategorySelectCommonContainer
            categories={childTree}
            selected={selected}
            onSelect={onSelect}
          />
        )
      }
    </CategorySelectRootContainer>
  );
};
