import { DialogActions, DialogContent } from '@mui/material';
import { FCC, ReactNode } from 'react';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { DialogTitle } from './controls';
import ContentLoader from 'components/common/loader';

export type DialogueEventContentBuilderLayoutProps = {
  readonly wrapper?: FCC;
  readonly title?: ReactNode;
  readonly actions: ReactNode;
  readonly titleAsContent?: boolean;
  readonly isFetching?: boolean;
};

export const DialogueEventContentBuilderLayout: FCC<DialogueEventContentBuilderLayoutProps> = ({
  wrapper,
  title,
  titleAsContent,
  actions,
  isFetching,
  children: content,
}) => {
  return (
    <MPDialogContentBuilder
      wrapper={wrapper}
      title={!titleAsContent && title}
    >
      {(content || (title && titleAsContent)) && (
        <DialogContent>
          {titleAsContent && title && <DialogTitle>{title}</DialogTitle>}
          {content}
        </DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
      {isFetching && <ContentLoader alpha />}
    </MPDialogContentBuilder>
  );
};
