import { Typography } from '@mui/material';
import { EDateFormat, EOfferStatus } from 'domain/model';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { getLeftSeconds } from 'presentationUtils/date';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';

const TradeOfferClaimLeftPeriod = ({ endDate }: { endDate: string }) => {
  return <Typography variant='body2'>Действует до {moment(endDate).locale('ru').format(EDateFormat.Human)}</Typography>;
};

export const TradeOfferClaimLeftPeriodAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();

  const endDate = tradeOffer ? moment(tradeOffer.endDate).endOf('day') : null;
  const leftSeconds = endDate ? getLeftSeconds(endDate.endOf('day')) : null;

  const isActiveOfferByTime = leftSeconds && leftSeconds > 0;

  return useMemo(
    () =>
      tradeOffer?.endDate && tradeOffer?.status === EOfferStatus.Active && isActiveOfferByTime ? (
        <TradeOfferClaimLeftPeriod endDate={tradeOffer.endDate} />
      ) : null,
    [tradeOffer?.endDate, tradeOffer?.status, isActiveOfferByTime]
  );
};
