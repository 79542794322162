import PartnerLink from 'presentation/components/common/partnerLink';
import Splitter from 'presentation/components/common/splitter';
import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import OfferPromotionCopy from 'presentation/features/offer/components/promotionCopy';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
// import OfferPromotionCopy from '../../../../offer/components/promotionCopy';
import { SidebarWrapper } from '../controls';
import ActivationListItemLabel from '../label/title';

type ActivationListItemCodeSidebarProps = {
  readonly appointmentDate: Nullable<string>;
  readonly expireDate: Nullable<string>;
  readonly code: string;
  readonly codeTypeName: string;
  readonly partnerLink: Nullable<string>;
  readonly onOpenHelpDialog: () => void;
  readonly onPartnerLinkClick: () => void;
  readonly onCopy?: () => void;
  readonly copied: boolean;
  readonly needCopy: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

const ActivationListItemCodeSidebar = (props: ActivationListItemCodeSidebarProps) => {
  const {
    appointmentDate,
    code,
    expireDate,
    codeTypeName,
    partnerLink,
    onPartnerLinkClick,
    onCopy,
    copied,
    needCopy,
    onNeedCopy,
  } = props;

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={1}
        direction='column'
      >
        <MPGrid item>
          <ActivationListItemLabel
            codeTypeName={codeTypeName}
            appointmentDate={appointmentDate}
          />
          {expireDate && <ActivationListItemExpireDate expireDate={expireDate} />}
        </MPGrid>
        <MPGrid item>
          <OfferPromotionCopy
            label={code}
            onCopy={onCopy}
            onNeedCopy={onNeedCopy}
            needCopy={needCopy}
            copied={copied}
          />
        </MPGrid>
        <MPGrid item>
          <Splitter
            variant='horizontal'
            size={1}
          />
          <PartnerLink
            link={partnerLink}
            onClick={onPartnerLinkClick}
          />
        </MPGrid>
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemCodeSidebar;
