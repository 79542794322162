import styled from '@emotion/styled';
import { css, Paper, PaperProps } from '@mui/material';
import { forwardRef } from 'react';

export const Wrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)(
  ({ theme }) => css`
    width: 100%;
    min-height: 10rem;

    display: inline-flex;
    position: relative;

    padding: ${theme.spacing(4)};
    border: 1px solid ${theme.palette.secondary.A600};

    ${theme.breakpoints.down('lg')} {
      width: 100%;
    }

    ${theme.breakpoints.down('md')} {
      width: 100%;
      padding: 0;

      border-radius: 0;
      border: 0;
      box-shadow: none;
    }
  `
);

export const BottomCartSection = styled.div<{ offset?: Nullable<number> }>(
  ({ theme, offset }) => css`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;

    background: ${theme.palette.background.default};
    padding-bottom: ${offset ? offset : 0}px;

    && > * {
      width: 100%;

      padding: ${theme.spacing(2)};
      gap: ${theme.spacing(2)};

      ${theme.breakpoints.up('md')} {
        padding: 0;
      }

      ${theme.breakpoints.up('sm')} {
        width: 50%;
      }
    }
  `
);
