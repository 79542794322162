import CardFavoriteButton from '../../../components/common/buttons/cardFavorite/button';
import { useOfferFavoriteSimple, useOfferFavoriteStored } from '../hooks/useOfferFavorite';
import { OfferFavoriteContainerProps } from './types';

export const OfferFavoriteCommonContainerSimple = (props: OfferFavoriteContainerProps) => {
  const { guid, offerId, offerType, partnerId } = props;

  const { isFavorite, isFetching, onToggle } = useOfferFavoriteSimple({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId,
    },
  });

  return (
    <CardFavoriteButton
      active={isFavorite}
      inProgress={isFetching}
      onClick={onToggle}
    />
  );
};

export const OfferFavoriteCommonContainerStored = (props: OfferFavoriteContainerProps) => {
  const { guid, offerId, offerType, partnerId } = props;

  const { isFavorite, isFetching, onToggle } = useOfferFavoriteStored({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId,
    },
  });

  return (
    <CardFavoriteButton
      active={isFavorite}
      inProgress={isFetching}
      onClick={onToggle}
    />
  );
};

export const OfferFavoriteCommonContainer = (props: OfferFavoriteContainerProps) => {
  const { stored } = props;

  return stored ? <OfferFavoriteCommonContainerStored {...props} /> : <OfferFavoriteCommonContainerSimple {...props} />;
};
