import styled from '@emotion/styled';
import { Collapse, CollapseProps, css, Typography, TypographyProps } from '@mui/material';
import { forwardRef, HTMLAttributes } from 'react';

type ContentProps = TypographyProps & {
  readonly expanded: boolean;
  readonly lines: number;
};

export const Content = styled(
  forwardRef(({ expanded, ...props }: ContentProps, ref: any) => (
    <Typography
      ref={ref}
      {...props}
    />
  ))
)(
  ({ lines }) => css`
    display: block;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const CollapseText = styled(
  forwardRef((props: CollapseProps, ref: any) => (
    <Collapse
      ref={ref}
      {...props}
    />
  ))
)(() => css``);

export const Toggle = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};

    cursor: pointer;
  `
);

type IconWrapperProps = HTMLAttributes<HTMLSpanElement> & {
  readonly expanded: boolean;
};

export const IconWrapper = styled(({ expanded, ...props }: IconWrapperProps) => <span {...props} />)(
  ({ expanded }) => css`
    display: inline-flex;

    transition: all 0.3s ease-in-out;
    transform: ${expanded ? 'rotateZ(180deg)' : 'rotateX(0deg)'};
  `
);
