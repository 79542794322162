import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Notice } from 'domain/model';
import { ENoticeStatus } from 'domain/model';

const maxLifetime = 10000;
const maxCount = 5;

const removeOldNotices = (notices: Notice[]) =>
  notices.filter(notice => notice.timestamp && notice.timestamp > new Date().getTime() - maxLifetime);
const sortPredicate = (n1: Notice, n2: Notice) => {
  if (n1.timestamp && n2.timestamp) {
    return n1.timestamp - n2.timestamp;
  }

  return 0;
};

export type NotifierState = Notice[];

type Reducers = SliceCaseReducers<NotifierState> & {
  addNotice: CaseReducer<NotifierState, PayloadAction<{ status: ENoticeStatus; text: string }>>;
  closeNotice: CaseReducer<NotifierState, PayloadAction<Notice>>;
};

const slice = createSlice<NotifierState, Reducers, 'notifier'>({
  name: 'notifier',
  initialState: [],
  reducers: {
    addNotice(state, { payload }) {
      const notices = removeOldNotices(state);
      const { status, text } = payload;
      if (!notices.some(n => n.status === status && n.text === text)) {
        if (notices.length >= maxCount) notices.splice(0, 1);
        notices.push({
          status,
          text,
          timestamp: new Date().getTime(),
        });
      }
      state.length = 0;
      state.push(...notices.sort(sortPredicate));
    },
    closeNotice(state, { payload }) {
      const { status, text, timestamp } = payload;
      const index = state.findIndex(n => n.status === status && n.text === text && n.timestamp === timestamp);
      if (index >= 0) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addNotice, closeNotice } = slice.actions;

export default slice.reducer;
