import { EventsFlowEngine } from 'features/eventsFlow';
import { getEventsFlowEngineCommon } from '../common';
import { ArrayQueue } from './types';

export * from './types';

type EngineType = EventsFlowEngine<ArrayQueue>;

/**
 * обработка очереди событий, реализованная в рамках концепции FIFO
 * @see [about](https://ru.wikipedia.org/wiki/FIFO)
 * */
export const eventsFlowEngineFifo: EngineType = getEventsFlowEngineCommon<ArrayQueue>({
  onCreateInitial: () => ({
    events: new ArrayQueue(),
    activeFlow: null,
  }),
});
