import { useBonusesBalance } from 'features/user/current/hooks/useBonusesBalance';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/welcomeBenefits';
import { useCallback } from 'react';
import { AppNotificationsReceiverCallbackProps, ENotificationMessageType } from '../../notification/types';
import useNotificationSocket from '../../notification/useNotificationSocket';

const UserNotificationsReceiver = () => {
  const { publish } = useBusinessEventBus();
  const { refreshBalance } = useBonusesBalance();

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>(
    msg => {
      switch (msg.type) {
        case ENotificationMessageType.UserBalanceChange:
          refreshBalance();
          return;
        case ENotificationMessageType.BenefitWellcomeCredit:
          publish(createEvent({ count: msg.amount }));
          return;
        default:
          return;
      }
    },
    [publish, refreshBalance]
  );

  useNotificationSocket({ onMessageReceive });

  return null;
};

export default UserNotificationsReceiver;
