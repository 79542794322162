import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';

export const TextList = styled.ul(
  ({ theme: { spacing } }) => css`
    margin-block: 0;
    padding-inline-start: ${spacing(3.5)};
  `
);

export const TextListItem = styled.li(() => css``);

export const ContentWrapper = styled(MPGrid)(
  () => css`
    & a {
      display: inline;
    }
  `
);
