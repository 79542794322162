import { InputAdornment } from '@mui/material';
import { SearchIcon } from 'presentation/media/icons';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import { useInputDebounce } from '../../../../hooks/useInputDebounce';

type PartnerDeskFilterPatternSearchProps = {
  readonly value: Nullable<string>;
  readonly onChange: (name: Nullable<string>) => void;
};

const PartnerDeskFilterPatternSearch = ({ value, onChange }: PartnerDeskFilterPatternSearchProps) => {
  const [localSearchValue, setLocalSearchValue] = useInputDebounce({
    initialValue: value ?? null,
    nullIsValidValue: true,
    delay: 500,
    onChange,
  });

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value ?? null);
  };

  return (
    <MPFormInput
      label='Поиск партнёра'
      size='small'
      value={localSearchValue}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon color='secondary' />
            </InputAdornment>
          ),
        },
      }}
      onChange={onChangeSearch}
    />
  );
};

export default PartnerDeskFilterPatternSearch;
