import { Typography } from '@mui/material';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { EOfferActivateError, TradePersonalPromotionType } from 'domain/model';
import { TradeOffer } from 'domain/model';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FCC, useCallback, useEffect, useState } from 'react';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import { getPersonalPromotionInstrumentalLabel, getTradeOfferPromotionLabel } from '../../../utils';
import TradeOfferHelpDialog from '../../components/dialogs/help';
import useTradeOfferClaimContext from '../provider/useTradeOfferClaimContext';
import { ETradeOfferClaimDialogTag } from '../types';
import TradeOfferClaimDialogsContext, { TradeOfferClaimDialogsContextType } from './context';

type ConfirmDialogButtonsProps = {
  readonly onAccept: () => void;
  readonly onReject: () => void;
};

const ConfirmDialogButtons = ({ onAccept, onReject }: ConfirmDialogButtonsProps) => (
  <MPGrid
    container
    spacing={2}
  >
    <MPGrid
      item
      xs
    >
      <MPButton
        fullWidth
        color='brand'
        size='large'
        onClick={onAccept}
      >
        Да
      </MPButton>
    </MPGrid>
    <MPGrid item>
      <MPButton
        color='brand'
        size='large'
        onClick={onReject}
        variant='outlined'
      >
        Нет
      </MPButton>
    </MPGrid>
  </MPGrid>
);

type TradeOfferClaimDialogsProviderProps = {
  readonly tradeOffer: TradeOffer;
  readonly onShowAllOffers: () => void;
};

const TradeOfferClaimDialogsProvider: FCC<TradeOfferClaimDialogsProviderProps> = props => {
  const { children, tradeOffer, onShowAllOffers } = props;
  const { activation, stopTimer, onActivate } = useTradeOfferClaimContext();
  const { publish } = useBusinessEventBus();

  const [reactivationDialogVisible, setReactivationDialogVisible] = useState<boolean>(false);

  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog,
  } = useDialogInHistory({ tag: ETradeOfferClaimDialogTag.Help });

  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog,
  } = useDialogInHistory({ tag: ETradeOfferClaimDialogTag.Unavailable });

  const label = tradeOffer.promotionType ? getTradeOfferPromotionLabel(tradeOffer.promotionType) : null;
  const instrumentalLabel = getPersonalPromotionInstrumentalLabel(
    tradeOffer.promotionType as TradePersonalPromotionType
  );

  const openReactivationDialog = useCallback(() => setReactivationDialogVisible(true), [setReactivationDialogVisible]);

  const onActivateInternal = useCallback(() => {
    setReactivationDialogVisible(false);
    stopTimer();
    onActivate();
  }, [stopTimer, onActivate]);

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      publish(createEventNeedFillProfile({ message: activation.activationError.message }));
    }
  }, [activation.activationError, publish]);

  const value: TradeOfferClaimDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openReactivationDialog,
    openHelpDialog: onOpenHelpDialog,
    onActivate,
    onShowAllOffers,
  };

  return (
    <TradeOfferClaimDialogsContext.Provider value={value}>
      {children}
      <OfferUnavailableDialog
        open={isUnavailableDialogOpened}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      {tradeOffer.promotionType && (
        <TradeOfferHelpDialog
          promotionType={tradeOffer.promotionType}
          open={isHelpDialogOpened}
          onClose={onCloseHelpDialog}
        />
      )}
      <MPConfirmDialog
        maxWidth='xs'
        title={`Получение нового ${instrumentalLabel.toLowerCase()}`}
        open={reactivationDialogVisible}
        onClose={() => setReactivationDialogVisible(false)}
        buttons={
          <ConfirmDialogButtons
            onAccept={onActivateInternal}
            onReject={() => setReactivationDialogVisible(false)}
          />
        }
      >
        <Typography
          color='textPrimary'
          component='span'
        >
          У вас есть {label?.toLowerCase()}. Хотите получить новый?
        </Typography>
      </MPConfirmDialog>
    </TradeOfferClaimDialogsContext.Provider>
  );
};

export default TradeOfferClaimDialogsProvider;
