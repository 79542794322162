import styled from '@emotion/styled';
import { css, tabsClasses } from '@mui/material';
import { MPTabs } from 'presentation/theme/ui-kit/tab';

export const LinksWrapper = styled(MPTabs)(
  ({ theme }) => css`
    .${tabsClasses.flexContainer} {
      gap: ${theme.spacing()};
    }
  `
);

export const SeasonBg = styled.div(
  () => css`
    overflow: hidden;
    flex: 1;

    padding-left: 60px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  `
);
