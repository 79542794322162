import ContentLoader from 'components/common/loader';
import { EUserRole } from 'domain/model';
import AuthEntry from 'features/auth/entry';
import { useAuth } from 'features/auth/provider/useAuth';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import { SecurityFallbackCommon } from 'features/security/fallback';
import { FCC, ReactNode } from 'react';

type SecurityProtectProps = {
  readonly ignore?: boolean;
  /**
   * @description только для авторизованного пользователя, для других - редирект на авторизацию
   * @description если указано, то перекрывает все остальные check-проверки
   * */
  readonly checkUser?: boolean;
  /**
   * @description набор ролей, для которых разрешено
   * @description не пересекается с {@link checkUser}, чтобы можно было использовать без редиректа на авторизацию
   * @description условие - inner join
   * */
  readonly checkRoles?: EUserRole[];
  /**
   * @description только для корп юзера РЖД
   * @description не пересекается с {@link checkUser}, чтобы можно было использовать без редиректа на авторизацию
   * @description не пересекается с {@link checkRoles}, является его частным случаем для упрощения когнтракта
   * */
  readonly checkCorpUser?: boolean;
  readonly fallback?: ReactNode;
};

export const SecurityProtect: FCC<SecurityProtectProps> = ({
  ignore,
  fallback = <SecurityFallbackCommon />,
  children,
  checkUser,
  checkCorpUser,
  checkRoles,
}) => {
  const { isLoading, isAuthenticated } = useAuth();
  const { roles: userRoles } = useAuthUser();

  if (ignore) {
    return children;
  }

  if (isLoading) {
    return <ContentLoader />;
  }

  if (checkUser && !isAuthenticated) {
    return <AuthEntry />;
  }

  if (checkCorpUser && !userRoles.find(userRole => userRole === EUserRole.Corp)) {
    return fallback;
  }

  if (checkRoles?.length) {
    const hasAnyRole = checkRoles.some(role => userRoles.find(userRole => userRole === role));
    if (!hasAnyRole) {
      return fallback;
    }
  }

  return children;
};
