import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import ClaimMessage from 'presentation/features/offer/components/claim/message';

type CorpOfferClaimUpcomingMessageProps = {
  readonly startDate: Nullable<string>;
};

export const CorpOfferClaimUpcomingMessage = (props: CorpOfferClaimUpcomingMessageProps) => {
  const { startDate } = props;

  return (
    <ClaimMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение будет доступно {startDate && <>с {moment(startDate).format(EDateFormat.Human)} года</>}
      </Typography>
    </ClaimMessage>
  );
};
