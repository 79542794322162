import styled from '@emotion/styled';
import { buttonClasses } from '@mui/material';

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  .${buttonClasses.root} {
    padding: 0.75rem 1.25rem;

    ${p => p.theme.breakpoints.down('sm')} {
      padding: 0.75rem 1.25rem;
    }
  }
`;
