import styled from '@emotion/styled';
import {
  Container,
  css,
  FormControl,
  IconButton,
  paperClasses,
  RadioGroup,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from '@mui/material';

type DrawerProps = SwipeableDrawerProps & {
  readonly offsetTop?: number;
};

export const ContentWrapper = styled(FormControl)(
  ({ theme }) => css`
    padding: 3.857rem;

    ${theme.breakpoints.down('sm')} {
      padding: 1.5rem;
    }
  `
);

export const RadioHeader = styled(Typography)(
  () => css`
    margin-top: 0.5rem;
    margin-bottom: 1.4rem;
  `
);

export const RadioList = styled(RadioGroup)(() => css``);

export const Drawer = styled(({ offsetTop, ...others }: DrawerProps) => <SwipeableDrawer {...others} />)(
  ({ theme, offsetTop }) => css`
    z-index: ${theme.zIndex.appBar - 2} !important;

    .${paperClasses.root} {
      width: 100%;
      max-height: 100%;
      height: 100%;
      padding-top: ${offsetTop ?? 0}px;
    }
  `
);

export const DrawerContainer = styled(Container)(
  ({ theme }) => css`
    height: 100%;
    max-height: 100%;

    padding-bottom: ${theme.spacing(7)};

    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `
);

export const CloseButton = styled(IconButton)(
  () => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
  `
);
