import { CartItem } from 'domain/model';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback } from 'react';
import CartListItem from '../cartItem';
import { ItemWrapper, StyledCheckbox, Wrapper } from './controls';

type CartItemsListProps = {
  readonly items: CartItem[];
  readonly selected: CartItem[];
  readonly onToggleSelected: (items: CartItem[]) => void;
  readonly isCartItemEnabled: (item: CartItem) => boolean;
  readonly onOpenProductDetails: (offer: CartItem['offer']) => void;
  readonly onAddOrRemoveFavorite: (offer: CartItem['offer']) => void;
  readonly isFavoriteFetching: (id: UUID) => boolean;
};

const CartItemsList = (props: CartItemsListProps) => {
  const {
    items,
    selected,
    onToggleSelected,
    isCartItemEnabled,
    onOpenProductDetails,
    onAddOrRemoveFavorite,
    isFavoriteFetching,
  } = props;

  const { x3 } = useGridSpacing();

  const isSelected = useCallback((item: CartItem) => selected.some(s => s.id === item.id), [selected]);

  return (
    <Wrapper>
      {items.map(item => (
        <ItemWrapper key={item.id}>
          <MPGrid
            container
            spacing={x3}
          >
            <MPGrid item>
              <StyledCheckbox
                color='primary'
                disabled={!isCartItemEnabled(item)}
                checked={isCartItemEnabled(item) && isSelected(item)}
                onChange={() => onToggleSelected([item])}
              />
            </MPGrid>
            <MPGrid item>
              <CartListItem
                data={item}
                enabled={isCartItemEnabled(item)}
                onOpenProductDetails={() => onOpenProductDetails(item.offer)}
                onAddOrRemoveFavorite={() => onAddOrRemoveFavorite(item.offer)}
                isFavoriteFetching={isFavoriteFetching}
              />
            </MPGrid>
          </MPGrid>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

export default CartItemsList;
