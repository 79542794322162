import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0;
  top: 0;
`;
