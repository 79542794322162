import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { EDateFormat } from 'domain/model';
import { cspOfferTag } from 'features/offer/booking/utils';
import OfferTag from 'features/offer/components/tag';
import moment from 'moment-timezone';

type PrivilegeListTextProps = {
  readonly endDate: string;
};

const PrivilegeListText = ({ endDate }: PrivilegeListTextProps) => {
  const formattedEndDate = moment(endDate).format(EDateFormat.Human);

  return (
    <>
      <Typography variant='subtitle1'>Выбор из&nbsp;проверенных предложений</Typography>
      <Splitter size={0.5} />
      <Typography
        component='div'
        variant='body2'
      >
        Компенсировать возможно услуги объектов отдыха, отмеченных специальным знаком{' '}
        <OfferTag
          offerTag={cspOfferTag}
          size='micro'
        />{' '}
        на Витрине.
      </Typography>
      <Splitter size={2.5} />
      <Typography variant='subtitle1'>Компенсация&nbsp;— после отдыха</Typography>
      <Splitter size={0.5} />
      <Typography variant='body2'>
        Вы&nbsp;выбираете место и&nbsp;даты и&nbsp;оплачиваете путёвку самостоятельно. После отдыха подаете документы
        на&nbsp;компенсацию ответственному за&nbsp;предоставление КСП специалисту по&nbsp;управлению персоналом
        в&nbsp;вашем подразделении.{' '}
      </Typography>
      <Splitter size={2.5} />
      <Typography variant='body2'>
        Компенсацию вы&nbsp;можете получить{' '}
        <Typography
          component='span'
          variant='subtitle2'
        >
          в&nbsp;течение месяца
        </Typography>{' '}
        после получения услуги, но&nbsp;
        <Typography
          component='span'
          variant='subtitle2'
        >
          не&nbsp;позднее {formattedEndDate}
        </Typography>
        .
      </Typography>
      <Splitter size={2.5} />
      <Typography variant='subtitle1'>Другие льготы</Typography>
      <Splitter size={0.5} />
      <Typography variant='body2'>
        Если у&nbsp;вас есть право на&nbsp;господдержку в&nbsp;связи с&nbsp;материальным положением, получение
        компенсации отдыха может привести к&nbsp;утрате госльготы.
      </Typography>
      <Splitter size={2.5} />
      <Typography variant='body2'>
        Если вы&nbsp;оформляете более одной путёвки в&nbsp;год, то&nbsp;за&nbsp;вторую и&nbsp;последующие будет удержан
        налог на&nbsp;доходы физлиц от&nbsp;суммы компенсации.
      </Typography>
    </>
  );
};

export default PrivilegeListText;
