import styled from '@emotion/styled';
import { css, Link, LinkProps, Typography } from '@mui/material';

const WrapperLink = styled(Link)(
  ({ theme }) => css`
    &:hover,
    &:active,
    &:focus {
      color: ${theme.palette.primary.main};
      cursor: pointer;
    }
  `
);

export const Wrapper = styled((props: LinkProps) =>
  props.onClick ? <WrapperLink {...props} /> : <Typography {...props} />
)(
  ({ theme }) => css`
    min-height: 2.5rem;

    display: flex;
    align-items: center;

    color: ${theme.palette.text.primary};

    padding: 0 ${theme.spacing(2)};

    ${theme.breakpoints.down('sm')} {
      padding: 0 ${theme.spacing(1.5)};
    }
  `
);
