import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  width: 100%;

  margin-bottom: ${p => p.theme.spacing(3)};

  ${p => p.theme.breakpoints.down('md')} {
    margin-bottom: ${p => p.theme.spacing(2)};
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;

  width: 100%;
  min-height: 15rem;
`;
