import { BookingOfferListStateData } from 'features/offer/booking/list/store/types';
import { BookingOfferListSearchParams, ELastChangedFilterAttribute } from 'features/offer/booking/types';
import { bookingOffersDefaultParams } from 'features/offer/booking/utils';
import { BookingOffersListPriceRangeProps } from 'openApi/booking';
import { BookingOffersRequestProps } from 'openApi/user/bookingOffer';

export const getBookingOffersListArgs = (
  searchParams: BookingOfferListSearchParams,
  data: BookingOfferListStateData
): BookingOffersRequestProps => {
  const { name, rzdSocialPackage, maxPrice, services, minPrice, categoryId, sort } = searchParams;

  const priceRange = {
    minPrice,
    maxPrice,
  };

  return {
    ...data,
    ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
    services: categoryId ? services : [],
    rzdSocialPackage,
    sort,
    search: {
      name: name ?? null,
      categories: categoryId ? [categoryId] : null,
    },
  };
};

export const getBookingPriceRangeArgs = (
  searchParams: BookingOfferListSearchParams
): BookingOffersListPriceRangeProps => {
  const { categoryId, services, name, rzdSocialPackage } = searchParams;

  return {
    categoryId: categoryId ?? '',
    services: services ?? [],
    query: name,
    isRzdSocialPackage: rzdSocialPackage,
  };
};

export const getAvailableBookingServicesArgs = (searchParams: BookingOfferListSearchParams) => {
  const { rzdSocialPackage, maxPrice, minPrice, categoryId, name, lastChangedFilterAttribute } = searchParams;

  const needToGetClearServices = lastChangedFilterAttribute === ELastChangedFilterAttribute.System;

  return {
    categoryId: categoryId ?? '',
    query: name,
    isRzdSocialPackage: rzdSocialPackage,
    maxPrice: needToGetClearServices ? null : maxPrice ?? null,
    minPrice: needToGetClearServices ? null : minPrice ?? null,
  };
};

export const getAllBookingServicesArgs = (searchParams: BookingOfferListSearchParams) => {
  const { name, categoryId } = searchParams;
  return { name: name, categoryId: categoryId ?? '' };
};
