import { ReactNode } from 'react';
import { AppOptionTyped } from 'domain/model';
import {
  EBookingOfferSortType,
  ECorpOfferSortType,
  EProductOfferSortType,
  ETradeOfferSortType,
} from 'domain/model';
import SortSelect from 'presentation/components/common/selects/sort';
import { sortCollectionSplitter } from '../../../../utils/constants';

type OffersSortSelectProps<T> = {
  readonly value?: Nullable<T[]>;
  readonly onChange: (newValue: T[]) => void;
  readonly defaultValue: T;
  readonly items: AppOptionTyped<T>[];
  readonly renderValue?: (value: string) => ReactNode | undefined;
};

const OffersSortSelect = <
  T extends ETradeOfferSortType | ECorpOfferSortType | EProductOfferSortType | EBookingOfferSortType
>({
  value,
  onChange,
  defaultValue,
  items,
  renderValue,
}: OffersSortSelectProps<T>) => {
  return (
    <SortSelect
      items={items}
      value={value?.join(sortCollectionSplitter) ?? defaultValue}
      renderValue={renderValue ? renderValue : value => items.find(item => item.id === value)?.name}
      onChange={newValue => onChange(newValue.split(sortCollectionSplitter) as T[])}
    />
  );
};

export default OffersSortSelect;
