import { isValidPhoneFormat } from 'presentationUtils/phone';
import { FC } from 'react';
import { ConfirmFlowAdapter, InvalidFlowAdapter } from './adapters';
import { useUpdateUserPhone } from './hooks/useUpdateUserPhone';
import { ConfirmPhoneEventProps } from './types';

export const ConfirmPhoneEventContainer: FC<ConfirmPhoneEventProps> = ({ onClose, onNext }) => {
  const { phone, isFetching, onUpdate } = useUpdateUserPhone({ onSuccess: onNext, onClose });
  const isValidPhone = isValidPhoneFormat(phone);

  return isValidPhone ? (
    <ConfirmFlowAdapter
      phone={phone}
      isFetching={isFetching}
      onSubmit={onUpdate}
      onCancel={onNext}
    />
  ) : (
    <InvalidFlowAdapter
      phone={phone}
      isFetching={isFetching}
      onSubmit={onUpdate}
    />
  );
};
