import { EOfferListType } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCorpOffersContextHandlers } from '../../../hooks/useCorpOffersContextHandlers';
import {
  partnerDeskDetailsCorpOffersUpcomingArgsSelector,
  partnerDeskDetailsGuidSelector,
} from '../../../store/selectors';
import PartnerDeskTabTextHeader from '../../header';
import PartnerDeskCorpOffersDataContainer from '../dataContainer';

type PartnerDeskCorpOffersUpcomingAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Upcoming;

const PartnerDeskCorpOffersUpcomingAdapter = ({ guid }: PartnerDeskCorpOffersUpcomingAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsCorpOffersUpcomingArgsSelector);

  const handlers = useCorpOffersContextHandlers();

  const header = useMemo(
    () => (
      <PartnerDeskTabTextHeader>
        Скоро будут доступны
        <Splitter size={3} />
      </PartnerDeskTabTextHeader>
    ),
    []
  );

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskCorpOffersDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          header={header}
          onChangePage={handlers.onChangeCorpOffersPage}
          onShowCard={handlers.onShowCorpOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeCorpOffersPage, handlers.onShowCorpOffersCard, header]
  );
};

export default PartnerDeskCorpOffersUpcomingAdapter;
