import styled from '@emotion/styled';
import { css, linkClasses } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    & .${linkClasses.root} {
      display: flex;
    }

    img {
      height: 1.5rem;

      ${theme.breakpoints.down('md')} {
        height: 1.3125rem;
      }
    }
  `
);
