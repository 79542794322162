import { ReactNode } from 'react';
import { DetailsWrapper, FooterWrapper, HeaderWrapper, Wrapper } from './controls';

export type OrderCreatedLayoutProps = {
  readonly header?: ReactNode;
  readonly subHeader?: ReactNode;
  readonly details?: ReactNode;
  readonly footer?: ReactNode;
  readonly loader?: ReactNode;
};

export const OrderCreatedLayout = (props: OrderCreatedLayoutProps) => {
  const { header, subHeader, details, footer, loader } = props;

  return (
    <Wrapper>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}
      {subHeader && <div>{subHeader}</div>}
      {loader && <div>{loader}</div>}
      {details && <DetailsWrapper>{details}</DetailsWrapper>}
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </Wrapper>
  );
};
