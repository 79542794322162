import { createSelector } from '@reduxjs/toolkit';
import { aspOfferApi } from 'data/api/aspOffer';
import { RootState } from 'data/store/store';
import { ETradeOfferSortType } from 'domain/model';

export const aspOfferListGuidSelector = (store: RootState) => store.aspOffer.list.guid;

export const aspOfferListSelector = (store: RootState) => store.aspOffer.list;

export const aspOfferListCommonArgsSelector = (store: RootState) => store.aspOffer.list.common;

export const aspOfferListUpcomingArgsSelector = (store: RootState) => store.aspOffer.list.upcoming;

export const aspOfferListNameSelector = (store: RootState) => store.aspOffer.list.common?.search?.name ?? null;
export const aspOfferListSortSelector = (store: RootState) =>
  (store.aspOffer.list.common?.sort ?? null) as Nullable<ETradeOfferSortType[]>;
export const aspOfferListCategoryIdSelector = (store: RootState) =>
  store.aspOffer.list.common?.search?.categories?.[0] ?? null;

export const aspOfferListSearchIsEmptySelector = (store: RootState) =>
  !store.aspOffer.list.common?.search?.name && !store.aspOffer.list.common?.search?.categories?.length;

export const aspOfferListSearchByNameIsEmptySelector = (store: RootState) => !store.aspOffer.list.common?.search?.name;

export const aspOfferListCommonCountSelector = (store: RootState) => {
  const args = store.aspOffer.list.common;

  if (!args) {
    return 0;
  }

  const { data } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const aspOfferListUpcomingCountSelector = (store: RootState) => {
  const args = store.aspOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const aspOfferListCountSelector = createSelector(
  aspOfferListCommonCountSelector,
  aspOfferListUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const aspOfferListCommonIsErrorSelector = (store: RootState) => {
  const args = store.aspOffer.list.common;

  if (!args) {
    return 0;
  }

  const { isError } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return isError;
};

export const aspOfferListUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.aspOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return isError;
};

export const aspOfferListIsErrorSelector = createSelector(
  aspOfferListCommonIsErrorSelector,
  aspOfferListUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const aspOfferListCommonIsFetchingSelector = (store: RootState) => {
  const args = store.aspOffer.list.common;
  return args ? aspOfferApi.endpoints.getAspOfferList.select(args)(store).isLoading : false;
};

export const aspOfferListUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.aspOffer.list.upcoming;
  return args ? aspOfferApi.endpoints.getAspOfferList.select(args)(store).isLoading : false;
};

export const aspOfferListIsFetchingSelector = createSelector(
  aspOfferListCommonIsFetchingSelector,
  aspOfferListUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const aspOfferListIsNewFetchingSelector = createSelector(
  aspOfferListIsFetchingSelector,
  (store: RootState) => store.aspOffer.list.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

export const aspOfferListIsNewSearchFetchingSelector = createSelector(
  aspOfferListIsFetchingSelector,
  (store: RootState) => store.aspOffer.list.isNewSearchFetching,
  (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching
);

export const aspOfferListNoDataSelector = createSelector(
  aspOfferListCountSelector,
  aspOfferListIsFetchingSelector,
  aspOfferListIsErrorSelector,
  (count, isFetching, isError) => count === 0 && !isFetching && !isError
);
