import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { EOfferListType, ETradeOfferSortType } from 'domain/model';
import { aspOffersDefaultParams } from '../../utils';

export type AspOfferListState = {
  readonly guid: Nullable<UUID>;
  readonly isNewFetching: boolean;
  readonly isNewSearchFetching: boolean;
  readonly [EOfferListType.Common]: OfferListRequest;
  readonly [EOfferListType.Upcoming]: OfferListRequest;
};

type Reducer<T> = CaseReducer<AspOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AspOfferListState> & {
  aspOffersStartSession: Reducer<{ guid: UUID }>;
  aspOffersSetPageSize: Reducer<{ type: EOfferListType; pageSize: number }>;
  aspOffersSetPage: Reducer<{ type: EOfferListType; page: number }>;
  aspOffersSetSort: Reducer<{ type: EOfferListType; sort: ETradeOfferSortType[] }>;
  aspOffersSetArgs: Reducer<{ type: EOfferListType; args: OfferListRequest }>;
  aspOffersSetIsNewFetching: Reducer<boolean>;
  aspOffersSetIsNewSearchFetching: Reducer<boolean>;
};

const emptyParams = aspOffersDefaultParams;

const slice = createSlice<AspOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    common: emptyParams,
    upcoming: emptyParams,
  },
  reducers: {
    aspOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
    aspOffersSetArgs: (state, { payload }) => {
      const { type, args } = payload;

      state[type] = args;
    },
    aspOffersSetPageSize: (state, { payload }) => {
      const { type, pageSize } = payload;

      state[type].pageSize = pageSize;
    },
    aspOffersSetSort: (state, { payload }) => {
      const { type, sort } = payload;

      state[type].sort = sort;
    },
    aspOffersSetPage: (state, { payload }) => {
      const { type, page } = payload;

      state[type].page = page;
    },
    aspOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
    aspOffersSetIsNewSearchFetching: (state, { payload }) => {
      state.isNewSearchFetching = payload;
    },
  },
});

export const {
  aspOffersSetPageSize,
  aspOffersSetPage,
  aspOffersStartSession,
  aspOffersSetSort,
  aspOffersSetIsNewFetching,
  aspOffersSetIsNewSearchFetching,
  aspOffersSetArgs,
} = slice.actions;

export default slice.reducer;
