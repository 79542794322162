import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';
import { forwardRef } from 'react';

export const Wrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)`
  width: 22rem;
  min-width: 20rem;
  min-height: 10rem;
  position: fixed;

  display: inline-flex;

  padding: ${p => p.theme.spacing(4)};
  border: 1px solid ${p => p.theme.palette.secondary.A600};

  ${p => p.theme.breakpoints.down('md')} {
    width: 100%;
    padding: 0;

    border-radius: 0;
    border: 0;
    box-shadow: none;
  }
`;

export const BottomSection = styled.div`
  min-height: 9rem;

  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;

  background: ${p => p.theme.palette.background.default};
  padding: ${p => p.theme.spacing(1.5)} ${p => p.theme.spacing(2.5)};
`;
