import { Theme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import createSpacing from '@mui/system/createTheme/createSpacing';
import { ExternalTheme } from 'presentation/theme/types';
import accordionOverrides from './accordion';
import appBarOverrides from './appBar';
import badgeOverrides from './badge';
import baselineOverrides from './baseline';
import breadcrumbsOverrides from './breadcrumbs';
import buttonsOverrides from './buttons';
import cardsOverrides from './cards';
import chipOverrides from './chip';
import containerOverrides from './container';
import dialogsOverrides from './dialogs';
import dividerOverrides from './divider';
import drawersOverrides from './drawers';
import gridsOverrides from './grid';
import linkOverrides from './link';
import listsOverrides from './list';
import menusOverrides from './menus';
import paletteOverrides from './palette';
import selectOverrides from './select';
import shapeOverrides from './shape';
import sliderOverrides from './slider';
import snackbarsOverrides from './snackbars';
import spacingOverrides from './spacing';
import svgIconsOverrides from './svgIcons';
import switchOverrides from './switch';
import tabsOverrides from './tabs';
import textFieldsOverrides from './textFields';
import typographyOverrides from './typography';
import backdropsOverrides from './backdrop';
import alertsOverrides from './alert';

const overrideTheme = (baseTheme: Theme, externalTheme?: Nullable<ExternalTheme>): Theme => {
  const palette = createPalette(paletteOverrides(baseTheme, externalTheme));
  const typography = createTypography(palette, typographyOverrides(baseTheme));
  const spacing = createSpacing(spacingOverrides(baseTheme));
  const shape = shapeOverrides(baseTheme);

  const logo = externalTheme?.logo || '/logo.svg';
  const favicon = externalTheme?.favicon || '/favicon.ico';

  let theme: Theme = {
    ...baseTheme,
    spacing,
    palette: palette as Theme['palette'],
    shape,
    typography,
  };

  theme = {
    ...theme,
    logo,
    favicon,
    components: {
      ...theme.components,
      ...baselineOverrides(theme),
      ...containerOverrides(theme),
      ...snackbarsOverrides(theme),
      ...buttonsOverrides(theme),
      ...textFieldsOverrides(theme),
      ...dialogsOverrides(theme),
      ...svgIconsOverrides(theme),
      ...cardsOverrides(theme),
      ...tabsOverrides(theme),
      ...selectOverrides(theme),
      ...chipOverrides(theme),
      ...linkOverrides(theme),
      ...breadcrumbsOverrides(theme),
      ...gridsOverrides(theme),
      ...listsOverrides(theme),
      ...badgeOverrides(theme),
      ...drawersOverrides(theme),
      ...menusOverrides(theme),
      ...dividerOverrides(theme),
      ...sliderOverrides(theme),
      ...switchOverrides(theme),
      ...accordionOverrides(theme),
      ...appBarOverrides(theme),
      ...backdropsOverrides(theme),
      ...alertsOverrides(theme),
    },
  };

  return theme;
};

export default overrideTheme;
