import styled from '@emotion/styled';
import { GridSpacing } from '@mui/material/Grid/Grid';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    height: 100%;
    width: 100%;
    margin: auto;
    position: relative;
  `
);

type DetailSwiperWrapperProps = {
  readonly gap?: number;
  readonly gapSpacing?: GridSpacing;
};

export const DetailSwiperWrapper = styled.div<DetailSwiperWrapperProps>`
  height: 100%;

  .swiper-slide {
    width: auto !important;

    :not(:first-of-type) {
      margin-left: ${p =>
        p.gapSpacing && typeof p.gapSpacing === 'number' ? p.theme.spacing(p.gapSpacing) : `${p.gap ?? 0}px`};
    }
  }
`;
