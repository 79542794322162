import { BusinessEvent } from './businessEvent';
import { UserEvent } from './userEvent';

export * from './displayEvent';
export * from './businessEvent';
export * from './userEvent';

/** приоритет события */
export enum EEventPriority {
  /** по умолчанию */
  Default = 9,

  /** максимальный */
  Top = 1,

  /** реального времени */
  Realtime = 0,
}

/** тип события */
export enum EEventType {
  /** бизнес событие (конечный инициатор - система) */
  BusinessEvent = 'business-event',
  /** пользовательское событие (конечный инициатор - пользователь) */
  UserEvent = 'user-event',
}

export type EventOwn<Payload = any, Result = any> = {
  /** ключ для определения уникальности события (чтобы пользовательский код не мог опубликовать кучу дублей) */
  readonly uniqueKey: string;

  /** любая нагрузка */
  readonly payload: Payload;

  /**
   * идентификатор flow
   * @see useEventBus.publishFlow
   */
  readonly flowId?: string;

  /** приоритет */
  readonly priority?: EEventPriority;

  /** возможность объединения со следующими событиями */
  readonly mergeWithNext?: boolean;
  /** возможность объединения с предыдущими событиями */
  readonly mergeWithPrev?: boolean;

  /** калбэк для мониторинга успешного выполнения */
  readonly onSuccess?: (result: Result) => void;
};

export type AnyDisplayEvent = BusinessEvent | UserEvent;
