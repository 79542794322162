import { EOfferType, ETradeOfferPromotionType } from 'domain/model';
import { paginationSizeVariant } from 'domain/model';
import moment from 'moment-timezone';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getOffersListQueryParams } from 'presentation/features/offer/utils';
import { utcToLocalTimeZone } from '../../../utils/date';

export enum ETradeOfferUrlParam {
  Tab = 'tab',
  Category = ECatalogUrlParam.Category,
  PartnerId = 'partnerId',
}

const tradeOfferCodeAssignIntervalInSeconds = 300;

export const getTradeOfferCodeAssignDurationInSeconds = (appointmentDate: string): number => {
  const now = moment();
  const end = utcToLocalTimeZone(appointmentDate).add(tradeOfferCodeAssignIntervalInSeconds, 'seconds');
  return Math.trunc(moment.duration(end.diff(now)).asSeconds());
};

export const getIsTradeOfferPersonalPromotionType = (promotionType: ETradeOfferPromotionType): boolean => {
  switch (promotionType) {
    case ETradeOfferPromotionType.AccessCode:
    case ETradeOfferPromotionType.Promocode:
    case ETradeOfferPromotionType.Voucher:
      return true;
    case ETradeOfferPromotionType.PublicPromocode:
    case ETradeOfferPromotionType.ReferralLink:
    case ETradeOfferPromotionType.Widget:
    case ETradeOfferPromotionType.CorpCertificate:
    case ETradeOfferPromotionType.ExternalCorpCertificate:
    case ETradeOfferPromotionType.Asp:
    case ETradeOfferPromotionType.Digift:
      return false;
  }
};

export const tradeOffersDefaultParams = getOffersListQueryParams(
  {
    name: null,
    categories: null,
    partnerId: null,
    page: 1,
    pageSize: paginationSizeVariant[0],
    sort: null,
  },
  EOfferType.TradeOffer
);
