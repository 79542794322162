import styled from '@emotion/styled';
import { css, dialogClasses } from '@mui/material';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';

export const StyledConfirmDialog = styled(MPConfirmDialog)`
  .${dialogClasses.paper} {
    width: 428px;
  }
`;

export const PriceWrapper = styled.div(
  ({ theme: { typography } }) =>
    css`
      display: flex;
      align-items: baseline;

      font-size: ${typography.body1.fontSize};
    `
);

export const PeriodRestrictionWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    :not(:empty) {
      margin: ${spacing(4)} 0;
    }
  `
);
