import styled from '@emotion/styled';
import { css, ListItemSecondaryAction, ListItemText, typographyClasses } from '@mui/material';

export const CounterWrapper = styled(ListItemSecondaryAction)(
  ({ theme }) => css`
    position: relative;
    right: unset;
    top: unset;
    transform: none;
    margin-left: ${theme.spacing()};
  `
);

export const TextWrapper = styled(ListItemText)(
  () => css`
    .${typographyClasses.root} {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `
);
