import { AxiosResponse } from 'axios';
import { SignProps } from 'data/openApi/user/agreement';
import { User, UserAgreement } from 'domain/model';
import apiDefinition from '../openApi';

import { api, EAgreementServicesTag } from './index';

export const agreementsApi = api.injectEndpoints({
  endpoints: builder => ({
    checkUserCurrentFetch: builder.query<User, {}>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse) => response.data,
      query: () => apiDefinition.user.current({}),
      providesTags: [EAgreementServicesTag.Check],
    }),
    getUserAgreements: builder.query<UserAgreement[], {}>({
      keepUnusedDataFor: 0,
      query: () => apiDefinition.user.agreement.all({}),
      transformResponse: (response: AxiosResponse<UserAgreement[]>) => {
        const agreements = response.data;
        const isAllSigned = agreements.every(a => a.agreementDate);
        if (isAllSigned) {
          return agreements;
        }

        return agreements.filter(a => !a.agreementDate);
      },
    }),
    signUserAgreements: builder.mutation<void, SignProps>({
      query: ({ data }) => apiDefinition.user.agreement.sign({ data }),
      transformResponse: (response: AxiosResponse) => response.data,
      invalidatesTags: [EAgreementServicesTag.Check],
      // eslint-disable-next-line no-empty-pattern
      onQueryStarted: async ({}, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(agreementsApi.util.upsertQueryData('getUserAgreements', {}, []));
        } catch {
          console.error('Pessimistic update of agreements is failed');
        }
      },
    }),
  }),
});

export const {
  useCheckUserCurrentFetchQuery,
  useSignUserAgreementsMutation,
  useGetUserAgreementsQuery,
  useLazyGetUserAgreementsQuery,
} = agreementsApi;
