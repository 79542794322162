import { EBookingOfferSortType } from 'domain/model';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { TradeOffersListLocationState } from 'presentation/features/offer/trade/routes';
import ScreenLayout from 'presentation/layouts/screen';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { ECatalogUrlParam } from '../../features/catalog/types';
import { EGlobalUrlParam } from '../../features/header/utils';
import BookingOfferListContainer from '../../features/offer/booking/list/container';
import { EBookingUrlParam, ELastChangedFilterAttribute } from '../../features/offer/booking/types';

const BookingOffersScreen = () => {
  const location = useLocation<Nullable<TradeOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ECatalogUrlParam.Category) ?? null;
  const minPrice = searchParams.get(EBookingUrlParam.MinPrice) ?? null;
  const maxPrice = searchParams.get(EBookingUrlParam.MaxPrice) ?? null;
  const rzdSocialPackage = searchParams.get(EBookingUrlParam.Compensation) ?? null;
  const lastChangedFilterAttribute = searchParams.get(EBookingUrlParam.LastChangedFilterAttribute) ?? null;

  const searchSort = searchParams.get(EBookingUrlParam.Sort);
  const searchServices = searchParams.get(EBookingUrlParam.Services);

  const [sort, services] = useMemo(() => {
    let foundServices = searchServices !== null ? searchServices.split(',') : undefined;

    if (searchServices === '') {
      foundServices = [];
    }

    return [searchSort ? (searchSort.split('+') as EBookingOfferSortType[]) : undefined, foundServices];
  }, [searchServices, searchSort]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <BookingOfferListContainer
            guid={guid}
            name={name}
            categoryId={categoryId}
            lastChangedFilterAttribute={(lastChangedFilterAttribute as ELastChangedFilterAttribute) ?? null}
            minPrice={minPrice ? Number(minPrice) : null}
            maxPrice={maxPrice ? Number(maxPrice) : null}
            sort={sort}
            rzdSocialPackage={rzdSocialPackage === 'true'}
            services={services}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default BookingOffersScreen;
