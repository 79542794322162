import { useState } from 'react';
import { useCopyToClipboard } from './useCopyToClipboard';

const useCopied = () => {
  const [, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState<boolean>(false);
  const [needCopy, setNeedCopy] = useState<boolean>(false);

  const onNeedCopy = (value: boolean) => setNeedCopy(value);

  const onCopy = (resetTimeout?: number) => {
    setCopied(true);
    setNeedCopy(false);

    if (resetTimeout) {
      setTimeout(() => {
        setCopied(false);
      }, resetTimeout);
    }
  };

  return {
    copied,
    needCopy,
    onNeedCopy,
    onCopy,
    copy,
  };
};

export default useCopied;
