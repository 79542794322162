import { Typography } from '@mui/material';
import { AccountBalance } from 'domain/model';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { getUserBalanceExpireMessage } from '../../utils';

type UserBalanceExpireProps = {
  readonly value: number;
  readonly expireDate: string;
  readonly disabled?: boolean;
  readonly balance: AccountBalance;
};

const UserBalanceExpire = ({ value, expireDate, disabled, balance }: UserBalanceExpireProps) => {
  return (
    <MPChip
      size='micro'
      color={disabled ? 'secondary' : 'warning'}
      label={
        <Typography
          variant='subtitle2'
          whiteSpace='normal'
        >
          {getUserBalanceExpireMessage(value, expireDate, balance)}
        </Typography>
      }
    />
  );
};

export default UserBalanceExpire;
