import styled from '@emotion/styled';
import { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import { PropsWithChildren } from 'react';

export const Wrapper = styled.div`
  height: 100%;

  background: ${p => p.theme.palette.background.paper};
  border-radius: ${p => p.theme.shape.borderRadius * 1.2}px;

  > * {
    height: 100%;
  }
`;

type OfferWrapperProps = PropsWithChildren & {
  readonly direction?: OfferListItemProps['direction'];
};

export const OfferWrapper = styled(({ direction, ...props }: OfferWrapperProps) => <div {...props} />)`
  height: 100%;

  box-shadow: ${p =>
    p.direction === 'row-reverse'
      ? `0px 20px 30px -20px rgba(109, 121, 143, 0.35)`
      : `20px 0px 30px -20px rgba(109, 121, 143, 0.35)`};
  border-radius: ${p => p.theme.shape.borderRadius * 1.2}px;
`;
