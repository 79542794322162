import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';
import { EAspOfferClaimSlotNameArea } from '../types';

type SlotsGridAreaProps = {
  readonly area: EAspOfferClaimSlotNameArea;
};

export const SlotsGridArea = styled(MPGrid)<SlotsGridAreaProps>`
  width: 100%;
  grid-area: ${p => p.area};
`;

export const SlotsGrid = styled.div`
  width: 100%;
  z-index: 1;
  display: block;
  background-color: ${p => p.theme.palette.white.main};
  border-radius: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(3)};
  border: 1px solid ${p => p.theme.palette.secondary.A600};
  box-shadow: 0 20px 30px -20px hsla(220, 13%, 50%, 0.35);

  > [area=${EAspOfferClaimSlotNameArea.promotionType}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${EAspOfferClaimSlotNameArea.price}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${EAspOfferClaimSlotNameArea.activation}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${EAspOfferClaimSlotNameArea.infoMessages}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${EAspOfferClaimSlotNameArea.linkToActivations}]:not(:empty) {
    margin-top: ${p => p.theme.spacing(3)};
  }

  > [area=${EAspOfferClaimSlotNameArea.warnMessages}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(1.5)};

    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(1.5)};
  }

  > [area=${EAspOfferClaimSlotNameArea.subscriptions}]:not(:empty) {
    margin-bottom: ${p => p.theme.spacing(2)};

    height: ${p => p.theme.spacing(9)};
  }
`;

export const HelpWrapper = styled.div(
  ({ theme: { spacing, palette } }) => css`
    :not(:empty) {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};

      padding-bottom: ${spacing(2)};
      margin-bottom: ${spacing(2)};

      border-bottom: 1px solid ${palette.secondary.A900};
    }
  `
);
