import { AccountBalance } from 'domain/model';
import { FC } from 'react';
import { UserBalanceValue } from '../../../components/balanceValue';

type UserFinanceBalanceBonusesAdapterProps = {
  readonly balance: AccountBalance;
  readonly disabled?: boolean;
};

export const UserFinanceBalanceValueAdapter: FC<UserFinanceBalanceBonusesAdapterProps> = props => {
  const {
    balance: { type, value },
    disabled,
  } = props;

  return (
    <UserBalanceValue
      variant='h2'
      type={type}
      value={value}
      disabled={disabled}
    />
  );
};
