import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';
import { SocialPackage } from 'domain/model';

export const socialPackageHowToUseEventType = 'SocialPackageHowToUse';

export type SocialPackageHowToUseEventPayload = {
  readonly socialPackage: SocialPackage;
};

export type SocialPackageHowToUseEvent = BusinessEvent<SocialPackageHowToUseEventPayload>;

export type SocialPackageHowToUseEventProps = DisplayEventComponentProps<SocialPackageHowToUseEventPayload>;
