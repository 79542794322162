import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;

  padding: ${p => p.theme.spacing(0, 0, 2, 0)};

  ${p => p.theme.breakpoints.down('sm')} {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    padding-bottom: 0;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  padding: ${p => p.theme.spacing(2, 0, 2, 0)};

  > * {
    flex: 1;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;

    padding-bottom: 0;

    > * {
      width: 100%;
      height: 100%;
    }
  }
`;
