import { ContentWrapper, IconWrapper, InfoWrapper, TextWrapper, Wrapper } from './controls';
import { UserAttributeLayoutType } from './types';

const UserAttributeLayout: UserAttributeLayoutType = props => {
  const { icon, label, action, info, children } = props;

  return (
    <Wrapper>
      <ContentWrapper secondaryAction={action}>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper
          secondaryTypographyProps={{ color: 'text.primary', variant: 'body1' }}
          primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
          secondary={
            <>
              {children}
              {info && <InfoWrapper>{info}</InfoWrapper>}
            </>
          }
        >
          {label}
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default UserAttributeLayout;
