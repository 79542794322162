import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';

export const SelectWrapper = styled(MPGrid)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 1;
    }
  `
);
