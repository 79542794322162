import styled from '@emotion/styled';
import { Container, ContainerProps } from '@mui/material';
import { forwardRef } from 'react';

export const Wrapper = styled(
  forwardRef<any, ContainerProps>((props, ref) => (
    <Container
      ref={ref}
      {...props}
    />
  ))
)`
  padding-top: ${p => p.theme.spacing(1)};
  padding-bottom: ${p => p.theme.spacing(12.5)};

  flex: 1;
  position: relative;
`;
