import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { ChangeEmailContainer } from './container';
import { ChangeEmailEvent, ChangeEmailEventPayload, ChangeEmailEventResult, changeEmailEventType } from './types';

export type { ChangeEmailEventPayload } from './types';

export const createEvent = (
  payload: ChangeEmailEventPayload,
  options?: {
    flowId?: string;
    onSuccess?: (result: ChangeEmailEventResult) => void;
  }
): ChangeEmailEvent =>
  EventCreatorFactory.business()
    .getCustom(changeEmailEventType, ChangeEmailContainer)
    .withPayload(payload)
    .withFlowId(options?.flowId)
    .withCallback(options?.onSuccess)
    .makeAsFeature()
    .get();
