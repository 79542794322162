/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenCmsContainer, GenCmsPartnerId } from './data-contracts';
import { createRequest } from './http-client';

export const CmsContainersApi = {
  /**
   * No description
   * * @tags CMS-CONTAINERS
   * @name ListCustomerContainers
   * @request GET:/customers/current/containers
   * @secure
   */
  listCustomerContainers: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** id партнёра */
      partnerId?: GenCmsPartnerId | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenCmsContainer[], void>({
      baseURL,
      path: `/customers/current/containers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),
};
