import { useMediaQuery } from '@mui/material';
import BookingOfferListFilterAdapter from 'features/offer/booking/list/adapters/filter';
import BookingOfferListCategoriesAdapter from 'features/offer/booking/list/adapters/subCategories';
import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import { FC } from 'react';
import useCategoriesData from '../hooks/useCategoriesData';
import { BookingOfferListFilterCount } from '../types';

type BookingOfferListAsideAdapterProps = BookingOfferListFilterCount;

export const BookingOfferListAsideAdapter: FC<BookingOfferListAsideAdapterProps> = ({ filterCount }) => {
  const searchParams = useContextSearchParams();
  const { categoryId } = searchParams;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { subCategories, isCategoriesFetching } = useCategoriesData({ categoryId: categoryId ?? null });

  if (isMdUp && categoryId) {
    return <BookingOfferListFilterAdapter filterCount={filterCount} />;
  }

  if (subCategories?.length && !isCategoriesFetching) {
    return <BookingOfferListCategoriesAdapter subCategories={subCategories} />;
  }
};
