import { Typography } from '@mui/material';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import { FC } from 'react';

export const TextAdapter: FC = () => {
  const { user } = useAuthUser();

  const email = user?.email ?? '';

  return (
    <>
      На почту{' '}
      <Typography
        variant='subtitle1'
        component='span'
      >
        {email}
      </Typography>{' '}
      отправлен код подтверждения. Если письмо не пришло, проверьте папку «Спам»
    </>
  );
};
