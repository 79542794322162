import { AspOffer, PartnerDesk } from 'domain/model';
import { useHistory } from 'react-router';
import { useAuth } from '../../../auth/provider/useAuth';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import { useWebAnalytics } from '../../../webAnalytics';
import AspOfferClaim from './component';
import AspOfferClaimProviders from './providers';

export type AspOfferClaimContainerProps = {
  readonly guid: UUID;
  readonly aspOffer: AspOffer;
  readonly partnerDesk?: PartnerDesk;
};

const AspOfferClaimContainer = (props: AspOfferClaimContainerProps) => {
  const { aspOffer, partnerDesk, guid } = props;
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { webAnalytics } = useWebAnalytics();

  if (!aspOffer) {
    return null;
  }

  const onPartnerButtonClick = () => {
    if (aspOffer?.partnerId) {
      webAnalytics.openPartnerUrl(aspOffer?.partnerId);
      webAnalytics.offerJumpToPartnerSite(aspOffer.id);
    }
  };

  const onShowAllOffers = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id, tab: EPartnerDeskDetailsTab.AspOffers }));
    }
  };

  return (
    <AspOfferClaimProviders
      aspOffer={aspOffer}
      onShowAllOffers={onShowAllOffers}
      isActivationAllowed={isAuthenticated}
      onPartnerButtonClick={onPartnerButtonClick}
      guid={guid}
    >
      <AspOfferClaim />
    </AspOfferClaimProviders>
  );
};

export default AspOfferClaimContainer;
