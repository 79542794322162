import { useState } from 'react';
import { useAuthUser } from '../../provider/useAuthUser';
import { ChangeEmailDialog } from '../changeEmailDialog';
import { useUserProfileEmailEdit } from '../hooks/useEmailEdit';

type ChangeEmailAdapterProps = {
  readonly onNext: () => void;
  readonly onClose?: () => void;
};

const changeEmailLabel = 'Новая электронная почта';

export const ChangeEmailAdapter = ({ onClose, onNext }: ChangeEmailAdapterProps) => {
  const { validation, isFetching, onUpdate, setValidation } = useUserProfileEmailEdit();

  const { user } = useAuthUser();
  const email = user?.email ?? '';

  const [value, setValue] = useState<string>(() => email);

  const setValuelInternal = (newValue: string) => {
    setValue(newValue);
    setValidation(null);
  };

  const updateEmailInternal = () => {
    onUpdate(value).then(result => {
      if (result) {
        onNext();
      }
    });
  };

  return (
    <ChangeEmailDialog
      value={value}
      validation={validation?.email}
      label={changeEmailLabel}
      primaryText='Продолжить'
      isFetching={isFetching}
      onChange={setValuelInternal}
      onClose={onClose}
      onSave={updateEmailInternal}
    />
  );
};
