import { FC } from 'react';
import ContentLoader from 'presentation/components/common/loader';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import BookingOfferOrderDetailsAdapter from '../../../user/details/order/booking/adapters/details';
import useBookingOfferOrderData from './hooks/useBookingOfferOrderData';
import BookingOfferOrderHeader from '../components/order/header';
import BookingOfferOrderButtons from '../components/order/buttons';
import { OrderDetailsWrapper } from '../components/order/controls';
import { OrderCreatedLayout } from 'layouts/orderCreated';
import BookingOrderBannerCspAdapter from './adapters/cspBanner';

type BookingOfferContainerProps = {
  id: UUID;
};

const BookingOfferOrderContainer: FC<BookingOfferContainerProps> = ({ id }) => {
  const { order, isFetching } = useBookingOfferOrderData(id);

  return (
    <MPGrid
      container
      justifyContent='center'
    >
      <OrderCreatedLayout
        header={BookingOfferOrderHeader}
        subHeader={order && <BookingOrderBannerCspAdapter order={order} />}
        footer={<BookingOfferOrderButtons />}
        loader={isFetching && <ContentLoader position='fixed' />}
        details={
          order && (
            <OrderDetailsWrapper>
              <BookingOfferOrderDetailsAdapter order={order} />
            </OrderDetailsWrapper>
          )
        }
      />
    </MPGrid>
  );
};

export default BookingOfferOrderContainer;
