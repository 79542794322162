/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Представление транзакции пользователя */
export interface GenBTUserTransaction {
  /**
   * ИД траназакции в АИС Льготах
   * @format uuid
   */
  transactionId: string;
  /**
   * ИД заявления в АИС Льготах
   * @format uuid
   */
  requestId: string;
  /**
   * Дата транзакции
   * @format date-time
   */
  date: string;
  /** Тип транзакции * `CREDIT` - начисления * `DEBIT` - списания */
  type: GenBTTransactionType;
  /**
   * Количество баллов
   * @format int32
   */
  amount: number;
  /**
   * Наименование достижения/КП
   * @format string
   */
  name: string | null;
  /**
   * Описание транзакции (комментарий при наличии)
   * @format string
   */
  description: string | null;
  /**
   * Признак о выполненной транзакции по автосгоранию баллов
   * @default false
   */
  isExpiration: boolean | null;
}

/**
 * Pageable
 * структура для пагинации и сортировки(сортировка вида firstName,asc)
 */
export interface GenBTPageable {
  /**
   * необходимая страница
   * @format int32
   * @min 0
   */
  page: number | null;
  /**
   * Количество элементов на страницу
   * если указать больше maximum, то будет size=maximum
   * независимо от size в запросе
   * @format int32
   * @min 1
   * @max 1000
   * @default 100
   */
  size: number | null;
  /** сортировка вида firstName,asc */
  sort: string[] | null;
}

/** Тип транзакции * `CREDIT` - начисления * `DEBIT` - списания */
export enum GenBTTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

/** Стандартный ответ при негативном сценарии */
export interface GenBTError {
  /** Код ошибки */
  code: string;
  /** Сообщение */
  message: string;
  /** Доп информация */
  description: string | null;
  /** Атомарные ошибки(например валидация полей) */
  errors: GenBTErrorObject[] | null;
}

/** Ошибка валидации */
export interface GenBTErrorObject {
  /** Код ошибки */
  code: string;
  /** Поле объекта -  для ошибок валидации полей */
  field: string | null;
  /** Сообщение */
  message: string;
}
