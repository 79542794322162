import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const changeEmailEventType = 'ChangeEmail';

export type ChangeEmailEventPayload = {
  readonly withSuccessStep?: boolean;
};

//факт изменения почты в ответе
export type ChangeEmailEventResult = boolean;

export type ChangeEmailEvent = BusinessEvent<ChangeEmailEventPayload, ChangeEmailEventResult>;

export type ChangeEmailEventProps = DisplayEventComponentProps<ChangeEmailEventPayload, ChangeEmailEventResult>;
