import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { NeedFillProfileEventContainer } from './container';
import { NeedFillProfileEvent, NeedFillProfileEventPayload, needFillProfileEventType } from './types';

export const createEvent = (payload: NeedFillProfileEventPayload): NeedFillProfileEvent =>
  EventCreatorFactory.business()
    .getCustom(needFillProfileEventType, NeedFillProfileEventContainer)
    .withPayload(payload)
    .withOutMergeNext()
    .get();
