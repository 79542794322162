import { useConfirmCodeMutation } from 'data/api/user';
import { DeleteProfileResponse, UpdateEmailResponse } from 'domain/model';
import validateObject from 'hooks/validation/utils';
import { EValidationType, ValidationResult, ValidationRules } from 'presentation/utils/validation';
import { useEffect, useState } from 'react';

type CodeData = {
  code: string;
};

export const rules: ValidationRules<CodeData> = {
  code: {
    required: true,
    requiredMessage: 'Необходимо указать корректный код',
  },
};

type CodeValue = { code: string };

type UseCodeEdit = {
  readonly codeTtl: Nullable<number>;
  readonly codeRequestId: Nullable<string>;
  readonly codeNextAttemptDate: Nullable<string>;
  readonly isFetching?: boolean;
  readonly validation: Nullable<ValidationResult<CodeData>>;
  readonly onSendCode: (code: string) => Promise<boolean>;
};

type UseCodeEditProps = {
  readonly source: Nullable<UpdateEmailResponse | DeleteProfileResponse>;
  readonly onSuccess: () => void;
  readonly onGetNewCode: () => Promise<void>;
};

export const useCodeEdit = (props: UseCodeEditProps): UseCodeEdit => {
  const { source, onSuccess, onGetNewCode } = props;

  const { otpId = null, otpTtl = null, nextAttempt = null } = source ?? {};

  const [validation, setValidation] = useState<Nullable<ValidationResult<CodeValue>>>(null);

  const [confirmCode, { isLoading: isConfirming }] = useConfirmCodeMutation();

  const [isFetchingNewCode, setIsFetchingNewCode] = useState<boolean>(false);

  const onSendCode = (code: string) => {
    const validation = validateObject<CodeValue>({ code }, rules);
    const isValid = validation.isValid;
    setValidation(validation.results);

    if (isValid && otpId) {
      return confirmCode({ code, otpId })
        .unwrap()
        .then(() => {
          onSuccess();
          return true;
        })
        .catch(error => {
          setValidation({
            code: {
              hasError: true,
              type: EValidationType.Error,
              message: error.data?.message || 'Произошла неизвестная ошибка',
            },
          });

          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  };

  useEffect(() => {
    if (!source) {
      setIsFetchingNewCode(true);
      onGetNewCode().finally(() => {
        setIsFetchingNewCode(false);
      });
    }
  }, [source, onGetNewCode]);

  const isFetching = isFetchingNewCode || isConfirming;

  return {
    codeTtl: otpTtl,
    codeRequestId: otpId,
    codeNextAttemptDate: nextAttempt,
    isFetching,
    validation,
    onSendCode,
  };
};
