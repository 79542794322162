import { Typography } from '@mui/material';
import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import Splitter from 'presentation/components/common/splitter';
import { useSelector } from 'react-redux';
import { bookingOfferListNoDataSelector } from '../store/selectors';

export const BookingOfferListStubAdapter = () => {
  const searchParams = useContextSearchParams();
  const noData = useSelector(state => bookingOfferListNoDataSelector(state, searchParams));

  if (noData) {
    return (
      <div>
        <Typography variant='body2'>Ничего не найдено.</Typography>
        <Splitter size={1} />
        <Typography variant='body2'>Попробуйте изменить параметры фильтра</Typography>
      </div>
    );
  }

  return null;
};
