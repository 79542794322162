import { FC } from 'react';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { useSelector } from 'react-redux';
import { socialPackagesFirstActiveSelector } from '../../../../socialPackage/store/selectors';
import { ESocialPackageStatus } from 'domain/model';
import { EAppFeature } from '../../../../../types';
import useTechConfig from 'hooks/useTechConfig';
import { BookingOfferCspBannerAboutAdapter } from './bannerAboutCsp';
import { BookingOfferCspBannerHowUseAdapter } from './bannerHowUse';
import { BookingOfferCspBannerAgreementAdapter } from './bannerAgreement';

type BookingOfferBannerCspAdapterProps = {
  readonly id: UUID;
};

const BookingOfferBannerCspAdapter: FC<BookingOfferBannerCspAdapterProps> = props => {
  const { id } = props;
  const { isCorpUser } = useAuthUser();
  const { bookingOffer } = useBookingOfferDetailsData(id);
  const { hasFeature } = useTechConfig();
  const socialPackage = useSelector(socialPackagesFirstActiveSelector);

  // Если флаг ксп отключен или пользователь не сотрудник ржд или в букинге отсутствует КСП признак, ничего не рендерим
  if (!hasFeature(EAppFeature.Csp) || !isCorpUser || !bookingOffer?.rzdSocialPackage) {
    return null;
  }

  // если пакеты отсутствуют или отозваны
  if (!socialPackage) {
    return <BookingOfferCspBannerAboutAdapter />;
  }

  // Активирован и согласился (ожидает даты)
  if ([ESocialPackageStatus.Active, ESocialPackageStatus.Upcoming].includes(socialPackage.status)) {
    return <BookingOfferCspBannerHowUseAdapter socialPackage={socialPackage} />;
  }

  // Готовы и ожидают согласия
  if (socialPackage.status === ESocialPackageStatus.Ready) {
    return <BookingOfferCspBannerAgreementAdapter socialPackage={socialPackage} />;
  }
};

export default BookingOfferBannerCspAdapter;
