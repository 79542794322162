import styled from '@emotion/styled';
import { css, Paper } from '@mui/material';

export const SideMenuWrapper = styled(Paper)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};

    border: 1px solid ${theme.palette.secondary.light};
  `
);
