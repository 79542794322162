import { dialogActionsClasses, dialogClasses, dialogContentClasses, paperClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const dialogs: OverrideThemeFunc<Components> = baseTheme => ({
  MuiDialogTitle: {
    defaultProps: {
      variant: 'h2',
      component: 'div',
    },
    styleOverrides: {
      root: {
        whiteSpace: 'pre-wrap',

        padding: baseTheme.spacing(5, 3, 2, 3),

        [`&& + .${dialogContentClasses.root}`]: {
          paddingTop: baseTheme.spacing(0.5),
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      container: {
        position: 'relative',

        [`& .${paperClasses.outlined}`]: {
          borderColor: baseTheme.palette.secondary.main,
        },

        [baseTheme.breakpoints.down('sm')]: {
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
        },
      },
      paper: {
        margin: baseTheme.spacing(3, 2),

        [`&.${paperClasses.rounded}`]: {
          borderRadius: (baseTheme.shape.borderRadius as number) * 1.2,
        },

        [`&.${dialogClasses.paperFullScreen}`]: {
          borderRadius: 0,
          border: 'none',
        },
      },
      paperFullScreen: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      paperWidthMd: {
        maxWidth: '980px',
      },
      paperWidthSm: {
        maxWidth: '618px',
      },
      paperWidthXs: {
        maxWidth: '480px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: baseTheme.spacing(2, 3, 4, 3),

        [`+ .${dialogActionsClasses.root}`]: {
          paddingTop: 0,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: baseTheme.spacing(2, 3, 3, 3),

        '&>:not(style)~:not(style)': {
          marginLeft: baseTheme.spacing(2),
        },
      },
    },
  },
});

export default dialogs;
