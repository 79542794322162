import { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@mui/material';
import { CardGridProps } from './constants';

type GridProps = CardGridProps & {
  readonly children: ReactNode;
};

export const Grid = styled(
  forwardRef<any, GridProps>(({ span, smSpan, mdSpan, lgSpan, ...others }, ref) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)(
  ({ theme, span, lgSpan, mdSpan, smSpan }) => css`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${theme.spacing(3)};

    ${theme.breakpoints.down('lg')} {
      grid-gap: ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('sm')} {
      grid-gap: ${theme.spacing(1.5)};
    }

    > * {
      grid-column: span ${span};

      ${theme.breakpoints.down('lg')} {
        grid-column: span ${span === 3 ? 4 : span === 4 ? 3 : span === 6 ? 2 : 1};
        grid-column: span ${lgSpan ? lgSpan : span === 3 ? 4 : 6};
      }

      ${theme.breakpoints.down('md')} {
        grid-column: span ${mdSpan ? mdSpan : 6};
      }

      ${theme.breakpoints.down('sm')} {
        grid-column: span ${smSpan ? smSpan : 12};
      }
    }
  `
);
