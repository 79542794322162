import { PriceRangeFilter } from 'domain/model/offer';
import { bookingOffersDefaultParams } from 'features/offer/booking/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getNewArrayExcludingValue } from 'utils/array';
import { useWebAnalytics } from '../../../../webAnalytics';
import { getBookingOffersSearchRoute } from '../../routes';
import { BookingOffersSearchListLocationState, ELastChangedFilterAttribute, UseBookingOfferList } from '../../types';
import { bookingOffersSetPage, bookingOffersSetPageSize } from '../store/slice';
import useApplyFiltersToSearchParams from './useApplyFiltersToSearchParams';

const useBookingOfferListHandlers = (props: BookingOffersSearchListLocationState): UseBookingOfferList => {
  const { guid, name, categoryId, services } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { webAnalytics } = useWebAnalytics();

  const applyFiltersToSearchParams = useApplyFiltersToSearchParams(props);

  const onResetFilters: UseBookingOfferList['onResetFilters'] = useCallback(() => {
    if (!categoryId) return;

    applyFiltersToSearchParams({
      services: [],
      rzdSocialPackage: false,
      ...bookingOffersDefaultParams.priceRange,
      lastChangedFilterAttribute: null,
    });
  }, [applyFiltersToSearchParams, categoryId]);

  const onChangeSort: UseBookingOfferList['onChangeSort'] = useCallback(
    sort => {
      applyFiltersToSearchParams({ sort, lastChangedFilterAttribute: null });
    },
    [applyFiltersToSearchParams]
  );

  const onChangePageSize: UseBookingOfferList['onChangePageSize'] = useCallback(
    pageSize => {
      dispatch(bookingOffersSetPageSize(pageSize));
    },
    [dispatch]
  );

  const onChangePage: UseBookingOfferList['onChangePage'] = useCallback(
    page => {
      dispatch(bookingOffersSetPage(page));
    },
    [dispatch]
  );

  const onChangePriceRange: UseBookingOfferList['onChangePriceRange'] = useCallback(
    (priceRange: PriceRangeFilter) => {
      if (!categoryId) return;

      applyFiltersToSearchParams({ ...priceRange, lastChangedFilterAttribute: ELastChangedFilterAttribute.Price });
    },
    [applyFiltersToSearchParams, categoryId]
  );

  const onChangeCompensation: UseBookingOfferList['onChangeCompensation'] = useCallback(
    (rzdSocialPackageValue: boolean) => {
      if (!categoryId) return;

      applyFiltersToSearchParams({
        rzdSocialPackage: rzdSocialPackageValue,
        lastChangedFilterAttribute: ELastChangedFilterAttribute.Compensation,
      });
    },
    [applyFiltersToSearchParams, categoryId]
  );

  const onSelectService: UseBookingOfferList['onSelectService'] = useCallback(
    id => {
      if (!categoryId) return;
      let actualServices = services ? [...services] : [];

      if (actualServices.includes(id)) {
        actualServices = getNewArrayExcludingValue(actualServices, id);
      } else {
        actualServices.push(id);
      }

      applyFiltersToSearchParams({
        services: actualServices,
        lastChangedFilterAttribute: ELastChangedFilterAttribute.Services,
      });
    },
    [applyFiltersToSearchParams, categoryId, services]
  );

  const onChangeCategory = useCallback<UseBookingOfferList['onChangeCategory']>(
    category => {
      history.push(
        getBookingOffersSearchRoute({
          name,
          categoryId: category.id,
          guid,
          lastChangedFilterAttribute: null,
        })
      );
    },
    [history, guid, name]
  );

  const onReturnToTopCategory = useCallback<UseBookingOfferList['onReturnToTopCategory']>(
    id => {
      history.push(getBookingOffersSearchRoute({ name, categoryId: id, guid, lastChangedFilterAttribute: null }));
    },
    [history, guid, name]
  );

  const onClearSelectedServices: UseBookingOfferList['onClearSelectedServices'] = useCallback(() => {
    if (!categoryId) return;

    applyFiltersToSearchParams({ services: [], lastChangedFilterAttribute: ELastChangedFilterAttribute.Services });
  }, [applyFiltersToSearchParams, categoryId]);

  const onShowCard = useCallback<UseBookingOfferList['onShowCard']>(
    bookingOffer => {
      webAnalytics.offerShowInList(bookingOffer.id);
    },
    [webAnalytics]
  );

  return {
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onChangePriceRange,
    onSelectService,
    onResetFilters,
    onClearSelectedServices,
    onReturnToTopCategory,
    onChangeCategory,
    onShowCard,
    onChangeCompensation,
  };
};

export default useBookingOfferListHandlers;
