import { combineReducers } from '@reduxjs/toolkit';
import application, { CspApplicationState } from './application/store/slice';

type CspState = {
  readonly application: CspApplicationState;
};

export default combineReducers<CspState>({
  application,
});
