import { useWindowMessage } from '@mp-npm/mp-auth-client';
import { EUserServicesTag } from 'data/api';
import { useBindCurrentUserCorpRoleMutation, userApi } from 'data/api/user';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Notifier from 'system/notifier';
import { AuthAction, EAuthAction, EAuthError } from '../utils/auth';
import ErrorHandler from 'data/network/errorHandler';

type UseUserBindCorpRoleProps = {
  readonly disabled?: boolean;
  readonly refreshToken: () => Promise<void>;
  readonly bindRzhdProfile: () => void;
};

type UseUserBindCorpRole = {
  readonly isFetching: boolean;
  readonly isFetched: boolean;
  readonly error: Nullable<any>;
  readonly onBindCorpRole: () => void;
};

//переменная для синхронизации нескольких вызовов onReceiveMessage, так как средствами реакта их не синхронизируешь
let successTimeout: Nullable<NodeJS.Timeout> = null;

const useUserBindCorpRole: (props: UseUserBindCorpRoleProps) => UseUserBindCorpRole = props => {
  const { disabled, refreshToken, bindRzhdProfile } = props;

  const dispatch = useDispatch();

  const [assignCorpRole, { data: resultAssignCorpRole, error, isLoading: isFetching, reset: resetAssignCorpRole }] =
    useBindCurrentUserCorpRoleMutation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isSuccessMessageReceived, setIsSuccessMessageReceived] = useState<boolean>(false);

  const isCorpRoleAssigned = resultAssignCorpRole === 200;

  const onReceiveMessage = useCallback(
    (receiveData: AuthAction) => {
      if (disabled) {
        return;
      }

      if (receiveData.error) {
        let errorText = '';
        switch (receiveData.error) {
          case EAuthError.NotAllowed:
            errorText = 'Отсутствует разрешение на привязку стороннего провайдера. Обратитесь к разработчикам.';
            break;
          case EAuthError.UnknownIdentityProvider:
            errorText = 'При привязке стороннего провайдера произошла неизвестная ошибка. Обратитесь к разработчикам.';
            break;
          case EAuthError.NotLoggedIn:
            errorText = 'Для привязки стороннего провайдера необходима авторизация. Обратитесь к разработчикам.';
            break;
        }
        if (errorText) {
          Notifier.getInstance().addError(errorText);
          ErrorHandler.captureException(errorText);
        }
      } else {
        if (receiveData.message === EAuthAction.RzhdPortalBindSuccess) {
          if (!successTimeout) {
            successTimeout = setTimeout(() => setIsSuccessMessageReceived(true), 100);
          }
        }
      }
    },
    [disabled]
  );

  useWindowMessage<AuthAction>({
    messages: [EAuthAction.RzhdPortalBindSuccess],
    onReceiveMessage,
  });

  useEffect(() => {
    if (isSuccessMessageReceived) {
      assignCorpRole();
    }
  }, [assignCorpRole, isSuccessMessageReceived]);

  useEffect(() => {
    if (!disabled && isCorpRoleAssigned) {
      resetAssignCorpRole();
      successTimeout = null;
      refreshToken().then(() => {
        setIsSuccess(true);
        dispatch(userApi.util.invalidateTags([EUserServicesTag.Current]));
      });
    }
  }, [isCorpRoleAssigned, disabled, refreshToken, resetAssignCorpRole, dispatch]);

  return {
    isFetching,
    isFetched: isSuccess,
    error,
    onBindCorpRole: bindRzhdProfile,
  };
};

export default useUserBindCorpRole;
