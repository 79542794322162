import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { getCorpOffersListRoute } from 'features/offer/corp/routes';
import welcomeBenefitBg from 'media/images/welcomeBenefitBg.png';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import { BenefitsCount, ImageWrapper } from './controls';
import { WelcomeBenefitsEventProps } from './types';

export const WelcomeBenefitsEventContainer: FC<WelcomeBenefitsEventProps> = ({
  event: {
    payload: { count },
  },
  onNext,
}) => {
  const history = useHistory();

  const countSymbols = [];
  const countStr = count.toString(10);

  const onNextInternal = () => {
    history.push(getCorpOffersListRoute({}));
    onNext();
  };

  for (let i = 0; i < countStr.length; i++) {
    countSymbols.push(countStr[i]);
  }

  return (
    <DialogueEventContentBuilderLayout
      actions={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          onClick={onNextInternal}
        >
          Перейти к предложениям
        </MPButton>
      }
    >
      <ImageWrapper>
        <BenefitsCount>
          <div>
            {countSymbols.map(s => (
              <span key={s}>{s}</span>
            ))}
          </div>
        </BenefitsCount>
        <img
          src={welcomeBenefitBg}
          alt={'баллы за регистрацию'}
        />
      </ImageWrapper>
      <Splitter size={2} />

      <Typography
        color='textPrimary'
        variant='h2'
      >
        Добро пожаловать на Витрину!
      </Typography>
      <Splitter size={2} />

      <Typography
        color='textPrimary'
        variant='body2'
      >
        За регистрацию и подтверждение статуса работника ОАО&nbsp;«РЖД» Вам начислено {count} баллов Бонусного пакета
      </Typography>
    </DialogueEventContentBuilderLayout>
  );
};
