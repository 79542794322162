import { CustomersApi } from '@privilege-frontend/api';
import { getBaseEndpoint, getPageableFromResponseHeaders } from 'data/openApi/utils';
import { EClientOfferType, EOfferType, EPaymentType, OfferSearchSuggestion, Pageable } from 'domain/model';
import { getOfferTypeByClientType } from 'features/offer/utils';
import { api, ESearchServicesTag } from './index';

export type SearchOffersRequest = {
  readonly query?: Nullable<string>;
  readonly offerType?: Nullable<EClientOfferType[]>;
  readonly page: number;
  readonly pageSize: number;
  readonly sort?: Nullable<string[]>;
};

export const searchApi = api.injectEndpoints({
  endpoints: builder => ({
    searchOffers: builder.query<Pageable<OfferSearchSuggestion>, SearchOffersRequest>({
      queryFn: async ({ query, page, pageSize, sort, offerType }, { signal }) => {
        /**
         * дальше логика по подготовке offerType и paymentType
         * offerType типа Asp только клиентский, для него реальный фильтр нужен Trade + продаваемый за рубли АСП
         * offerType типа Trade предполагает фильтр по paymentType бесплатный, иначе сюда АСП тоже войдут
         */
        const finalOfferTypes: EOfferType[] = [];
        const finalPaymentTypes: EPaymentType[] = [];
        if (offerType?.length === 1) {
          switch (offerType[0]) {
            case EClientOfferType.TradeOffer:
              finalOfferTypes.push(getOfferTypeByClientType(offerType[0]));
              finalPaymentTypes.push(EPaymentType.Free);
              break;
            case EClientOfferType.AspOffer:
              finalOfferTypes.push(EOfferType.TradeOffer);
              finalPaymentTypes.push(EPaymentType.AspRzdRub);
              break;
            case EClientOfferType.BookingOffer:
            case EClientOfferType.CorpOffer:
            case EClientOfferType.ProductOffer:
              finalOfferTypes.push(getOfferTypeByClientType(offerType[0]));
              break;
          }
        } else {
          finalOfferTypes.push(...(offerType ?? []).map(getOfferTypeByClientType));
        }

        const response = await CustomersApi.listCustomerOffers(
          getBaseEndpoint(),
          {
            page: 0,
            size: page * pageSize,
            q: query,
            offerType: finalOfferTypes,
            paymentType: finalPaymentTypes,
            sort,
          },
          signal
        );
        const pageable = getPageableFromResponseHeaders(response);
        const data: Pageable<OfferSearchSuggestion> = {
          data: response.data as OfferSearchSuggestion[],
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
        return {
          data,
        };
      },
      providesTags: [ESearchServicesTag.Offers],
    }),
  }),
});

export const { useSearchOffersQuery } = searchApi;
