import { FCC, useEffect, useState } from 'react';
import { CommonAdapter, SuccessAdapter } from './adapters';
import { FeedbackEventProps } from './types';

export const FeedbackEventContainer: FCC<FeedbackEventProps> = props => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      {!isSuccess && (
        <CommonAdapter
          onCancel={props.onNext}
          onSuccess={() => setIsSuccess(true)}
        />
      )}
      {isSuccess && <SuccessAdapter {...props} />}
    </>
  );
};
