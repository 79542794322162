import { EOfferType } from 'domain/model';
import { useAuth } from 'features/auth/provider/useAuth';
import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { OfferFavoriteCommonContainer } from '../../../../favorite';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useCorpOfferDetailsData from '../../hooks/useCorpOfferDetailsData';

type SmartPhoneFixedBarAdapterProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const SmartPhoneFixedBarAdapter = ({ id, guid }: SmartPhoneFixedBarAdapterProps) => {
  const { isAuthenticated } = useAuth();
  const { onBack } = useContextHandlers();

  const { corpOffer } = useCorpOfferDetailsData(id);

  if (!corpOffer) {
    return null;
  }

  return (
    <OfferSmartPhoneFixedBar
      onBack={onBack}
      favorite={
        isAuthenticated && (
          <OfferFavoriteCommonContainer
            guid={guid}
            offerId={id}
            offerType={EOfferType.CorpOffer}
          />
        )
      }
      text={corpOffer.name}
    />
  );
};

export default SmartPhoneFixedBarAdapter;
