import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { NeedLoginEventContainer } from './container';
import { NeedLoginEvent, NeedLoginEventPayload, needLoginEventType } from './types';

export type { NeedLoginEventPayload } from './types';

export const createEvent = (payload: NeedLoginEventPayload): NeedLoginEvent =>
  EventCreatorFactory.business()
    .getCustom(needLoginEventType, NeedLoginEventContainer)
    .withPayload(payload)
    .withOutMerge()
    .makeAsFeature()
    .get();
