import { CardPageableList } from 'components/common/cardPageableList';
import { BenefitTransactionItem, BenefitTransactionsStub } from '../../components/benefitTransaction';
import { UserBTTransaction } from 'domain/model';
import { getEqualDates } from '../../utils';

type UserFinancesContainerProps = {
  readonly data: Nullable<UserBTTransaction[]>;
  readonly isEmpty: boolean;
  readonly isFetching: boolean;
  readonly totalCount: number;
  readonly onLoadMore: () => void;
};

const UserFinancesBonusesContainer = (props: UserFinancesContainerProps) => {
  const { isEmpty, data, onLoadMore, totalCount, isFetching } = props;

  if (isEmpty) {
    return <BenefitTransactionsStub />;
  }

  return (
    <CardPageableList
      onLoadMore={onLoadMore}
      totalCount={totalCount}
      isFetching={isFetching}
      span={12}
      smSpan={12}
      mdSpan={12}
      lgSpan={12}
    >
      {data?.map((item, index) => (
        <BenefitTransactionItem
          key={item.transactionId}
          isGroup={index !== 0 && getEqualDates(item.date, data[index - 1].date)}
          data={item}
        />
      ))}
    </CardPageableList>
  );
};

export default UserFinancesBonusesContainer;
