import { AppOption, EOrderShortDiscriminator } from 'domain/model';
import { OrderShort } from 'domain/model/order';
import BookingOfferOrderAdapter from './adapters/bookingOfferOrder';
import ProductOfferOrderAdapter from './adapters/productOfferOrder';

export type OrderListItemProps = {
  readonly order: OrderShort;
  readonly generalOrderStatuses: AppOption[];
  readonly bookingOrderStatuses: AppOption[];
  readonly onClick: () => void;
};

const OrderListItem = (props: OrderListItemProps) => {
  const { order, bookingOrderStatuses, generalOrderStatuses } = props;

  return (
    <>
      {order.discriminator === EOrderShortDiscriminator.BookingOrderShort && (
        <BookingOfferOrderAdapter
          {...props}
          orderStatuses={bookingOrderStatuses}
          order={order}
        />
      )}
      {order.discriminator === EOrderShortDiscriminator.ProductOrderShort && (
        <ProductOfferOrderAdapter
          {...props}
          orderStatuses={generalOrderStatuses}
          order={order}
        />
      )}
    </>
  );
};

export default OrderListItem;
