import { Typography } from '@mui/material';
import ContentLoader from 'components/common/loader';
import Splitter from 'components/common/splitter';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { isValidPhoneFormat } from 'presentationUtils/phone';
import { FC, useState } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPPhoneInput } from 'theme/ui-kit/input';
import { InfoText } from './controls';

type InvalidStepAdapterProps = {
  readonly phone: string;
  readonly isFetching?: boolean;
  readonly onSubmit: (phone: string) => void;
};

type TextProps = {
  readonly phone: string;
};

export const InvalidPhoneText: FC<TextProps> = ({ phone }) => {
  return (
    <>
      Ваш номер{' '}
      <Typography
        display='inline'
        variant='subtitle2'
      >
        {phone}
      </Typography>{' '}
      введен в неверном формате.
      <Typography variant='body2'>Пожалуйста, введите номер телефона заново.</Typography>
    </>
  );
};

export const NoPhoneText: FC = () => {
  return 'Нужен для связи с Вами, если понадобится уточнить детали по заказу';
};

export const InvalidStepAdapter: FC<InvalidStepAdapterProps> = ({ phone, isFetching, onSubmit }) => {
  const [localPhone, setLocalPhone] = useState<string>('');
  const [error, setError] = useState<Nullable<string>>(null);

  const onChangeInternal = (value: string) => {
    setError(null);
    setLocalPhone(value);
  };

  const onSubmitInternal = () => {
    const isValidPhone = localPhone && isValidPhoneFormat(localPhone);
    if (isValidPhone) {
      onSubmit(localPhone);
      setError(null);
    } else {
      setError('Введите корректный номер телефона');
    }
  };

  return (
    <DialogueEventContentBuilderLayout
      title='Укажите свой номер телефона'
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isFetching}
            onClick={onSubmitInternal}
          >
            Продолжить
            {isFetching && <ContentLoader />}
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <InfoText variant='body2'>{!phone ? <NoPhoneText /> : <InvalidPhoneText phone={phone} />}</InfoText>
      <Splitter size={2} />
      <MPPhoneInput
        label={'Номер телефона'}
        value={localPhone ?? ''}
        helperText={error}
        error={!!error}
        disabled={isFetching}
        onChange={onChangeInternal}
      />
    </DialogueEventContentBuilderLayout>
  );
};
