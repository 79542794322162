import { Location } from 'history';
import ProductOfferScreen from 'presentation/screen/productOffer';
import ProductOfferListScreen from 'presentation/screen/productOffers';
import { Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PriceRangeFilter } from 'domain/model';
import { EGlobalUrlParam } from '../../header/utils';
import { EProductOfferDetailsTab } from './details/utils';
import { getNonFilterSearch } from './filter/utils';
import { EProductOfferUrlParam } from './utils';
import { routing } from './routes';

export type ProductOffersListLocationState = {
  readonly guid: UUID;
};

type GetProductOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly tab?: EProductOfferDetailsTab;
};

type GetProductOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
};

type GetProductOffersFilterRouteProps = {
  readonly location: Location;
  readonly filter: PriceRangeFilter;
};

type GetProductOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getProductOfferListRoute = (
  props?: GetProductOffersListRouteProps
): Location<ProductOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.root,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getProductListRoute = () => routing.root;

export const getProductOfferDetailsRoute = ({ id, tab }: GetProductOfferDetailsRouteProps) => {
  const params = new URLSearchParams();

  if (tab) {
    params.append(EProductOfferUrlParam.Tab, tab);
  }

  return `${routing.details.replace(':id', id)}?${params.toString()}`;
};

export const getProductOffersSearchRoute = (props: GetProductOffersSearchRouteProps) => {
  const { name, categoryId, guid } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(EProductOfferUrlParam.Category, categoryId);
  }
  return {
    pathname: routing.root,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getProductOffersFilterRoute = (props: GetProductOffersFilterRouteProps): Location => {
  const { location, filter } = props;

  const params = new URLSearchParams(getNonFilterSearch(location.search));
  Object.entries(filter).forEach(([filterId, value]) => {
    if (value !== null) params.append(filterId, value.toString());
  });

  return {
    ...location,
    search: params.toString(),
  };
};

const ProductOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.root}
        component={ProductOfferListScreen}
      />
      <Route
        exact
        path={routing.details}
        component={ProductOfferScreen}
      />
    </Switch>
  );
};

export default ProductOfferEntry;
