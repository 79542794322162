import { useGetProductOfferCategoriesUsedTreeQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CategoryTree } from 'domain/model';
import CategorySelectRootContainer from 'presentation/features/category/select';
import CategorySelectDoubleContainer from 'presentation/features/category/select/double';
import { ViewGridIcon } from 'presentation/media/icons';
import { FC, useEffect } from 'react';
import useProductOffersCatalog from './useProductOffersCatalog';

type CatalogProductOffersContainerProps = {
  readonly selected: Nullable<UUID>;
};

export const CatalogProductOffersContainer: FC<CatalogProductOffersContainerProps> = props => {
  const { selected } = props;

  const { city, onChangeCategory } = useProductOffersCatalog();

  const { data: tree, isFetching, error } = useGetProductOfferCategoriesUsedTreeQuery({ city });

  const treeOut: CategoryTree = [
    {
      id: '',
      name: 'Все товары',
      parentId: null,
      image: <ViewGridIcon />,
      children: [],
    },
    ...(tree ?? []),
  ];

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  return (
    <CategorySelectRootContainer
      tree={treeOut}
      isFetching={isFetching}
      selected={selected}
      onSelect={onChangeCategory}
    >
      {({ childTree, selectedCategory, expanded, isExpanded }) =>
        childTree && (
          <CategorySelectDoubleContainer
            categories={childTree}
            selectedCategory={selectedCategory}
            expanded={expanded}
            onSelect={onChangeCategory}
            isSelectedOrExpanded={isExpanded}
          />
        )
      }
    </CategorySelectRootContainer>
  );
};
