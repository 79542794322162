import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

type WrapperProps = {
  readonly mobileColumnsCount?: number;
  readonly children: ReactNode;
};

export const Wrapper = styled(({ mobileColumnsCount, ...others }: WrapperProps) => <div {...others} />)`
  --spacing: ${p => p.theme.spacing(3)};

  width: 100%;

  display: grid;
  grid-template-columns: repeat(${p => React.Children.count(p.children)}, 1fr);
  column-gap: var(--spacing);
  row-gap: var(--spacing);

  > * {
    width: inherit;
    max-height: 600px;
  }

  ${p => p.theme.breakpoints.down('md')} {
    --spacing: ${p => p.theme.spacing(2)};
  }

  ${p => p.theme.breakpoints.down('sm')} {
    --spacing: ${p => p.theme.spacing(1.5)};
    ${({ mobileColumnsCount }) => {
      return mobileColumnsCount ? `grid-template-columns: repeat(${mobileColumnsCount}, 1fr);` : '1fr';
    }}
  }
`;
