import { paginationSizeVariant } from 'domain/model';

export type PaginationSize = (typeof paginationSizeVariant)[number];

export type OnChangeObjectAttribute<T> = <A extends keyof T>(name: A, value: T[A]) => void;

export enum EAppFeature {
  PartnerWindow = 'cmsPartner',
  LandingWindow = 'cmsLanding',
  Product = 'product',
  Trade = 'trade',
  PersonalDataPolicy = 'pdp',
  TermsOfUse = 'terms',
  Cookies = 'cookies',
  PersonalDataAgreement = 'pda',
  WebAnalytics = 'webAnalytics',
  Booking = 'booking',
  Asp = 'asp',
  Csp = 'csp',
  GuestMode = 'guestMode',
  Assistant = 'assistant',
}

export enum ELocaleShort {
  Ru = 'ru',
  En = 'en',
}

export type Alphabet = {
  readonly locale: ELocaleShort;
  readonly value: string[];
};

/** Перечень названий существующих категорий */
export enum EAppCategories {
  TradeOffers = 'Скидки и акции',
  ProductOffers = 'Товары',
  CorpOffers = 'Бонусный пакет',
  BookingOffers = 'Досуг и отдых',
  AspOffers = 'Сертификаты',
  TradeAndAspOffers = 'Скидки и сертификаты',
}

export enum ESearchUrlParam {
  Query = 'text',
}

/** Точки входа в фичу, может использоваться для понимания того, какая именно функциональность А пользуется функиональностью Б*/
export enum ERenderSource {
  /** разделы офферов */
  OfferList = 'offer-list',
  /** поиск офферов */
  OfferSearch = 'offer-search',
  /** не важно */
  Any = 'any',
}
