import { useOfferFavoriteSimple } from '../hooks/useOfferFavorite';
import { OfferFavoriteContainerProps } from './types';
import AppSvgIcon from '../../../components/common/icon';
import { IconFavoriteFilled } from '../../../media/icons';
import { Typography } from '@mui/material';
import ContentLoader from '../../../components/common/loader';
import { ButtonLink } from '../../../components/common/buttons/link';

export const OfferFavoriteLinkContainer = (props: OfferFavoriteContainerProps) => {
  const { guid, offerId, offerType, partnerId } = props;

  const { isFavorite, isFetching, onToggle } = useOfferFavoriteSimple({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId,
    },
  });

  return (
    <ButtonLink
      startIcon={
        <AppSvgIcon
          color={isFavorite ? 'brand' : 'secondary'}
          icon={IconFavoriteFilled}
        />
      }
      onClick={onToggle}
      disabled={isFetching}
    >
      <Typography>{isFavorite ? 'В избранном' : 'В избранное'}</Typography>
      {isFetching && <ContentLoader size={20} />}
    </ButtonLink>
  );
};
