import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';

export const TextFieldLimited = styled.div`
  width: 100%;

  position: relative;
`;

export const Counter = styled(Typography)(
  ({ theme }) => css`
    position: absolute;
    top: calc(1rem / 2 * -1);
    right: ${theme.spacing(2)};
    z-index: 2;

    padding-left: ${theme.spacing(0.5)};
    padding-right: ${theme.spacing(0.5)};

    background: ${theme.palette.background.paper};
  `
);
