import { useGetPartnerCorpOfferCategoriesUsedQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferStatus } from 'domain/model';
import PartnerDeskOffersCategories from 'presentation/features/partnerDesk/details/tabs/categories';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCorpOffersContextHandlers } from '../../../hooks/useCorpOffersContextHandlers';
import { partnerDeskDetailsCorpOffersCommonArgsSelector } from '../../../store/selectors';

const statuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

const PartnerDeskCorpOffersCategoriesAdapter = () => {
  const { onChangeCorpOffersCategory } = useCorpOffersContextHandlers();

  const { partnerId, categoryId } = useSelector(partnerDeskDetailsCorpOffersCommonArgsSelector);

  const { data, error } = useGetPartnerCorpOfferCategoriesUsedQuery({ partnerId, statuses });

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  return data ? (
    <PartnerDeskOffersCategories
      categoryId={categoryId ?? null}
      categories={data}
      onChange={onChangeCorpOffersCategory}
    />
  ) : null;
};

export default PartnerDeskCorpOffersCategoriesAdapter;
