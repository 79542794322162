import { Typography } from '@mui/material';
import { EDateTimeFormat } from 'domain/model';
import { utcToLocalTimeZone } from 'presentation/utils/date';

type ActivationListItemLabelProps = {
  readonly codeTypeName: string;
  readonly appointmentDate: Nullable<string>;
};

const ActivationListItemLabel = ({ codeTypeName, appointmentDate }: ActivationListItemLabelProps) => {
  return (
    <Typography variant='body2'>
      {codeTypeName}{' '}
      {appointmentDate && `от ${utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}`}
    </Typography>
  );
};

export default ActivationListItemLabel;
