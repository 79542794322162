import { StompClientProvider } from '@mp-npm/mp-stomp-client';
import HttpClient from 'data/network/http';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { FCC } from 'react';
import { User } from 'domain/model';
import useWsClient from './useWsClient';

type WsClientProviderProps = {
  readonly user: Nullable<User>;
  readonly isCorpUser: Nullable<boolean>;
};

export const WsClientProvider: FCC<WsClientProviderProps> = ({ children, user, isCorpUser }) => {
  const { token } = useAuthToken();

  const { apiWs, debug, isWsEnabled, wsEndpoint } = useWsClient();

  return (
    <StompClientProvider
      stop={!isWsEnabled || !user || !isCorpUser || !token}
      endpoint={wsEndpoint}
      wsPath={apiWs?.path}
      sockjsPath={apiWs?.sockjsPath}
      debug={debug}
      getToken={() => HttpClient.getInstance().getAuthService()?.token || token || ''}
    >
      {children}
    </StompClientProvider>
  );
};
