import { AppOptionTyped } from 'domain/model';
import { EDeliveryType, EProductOrderPaymentType } from 'domain/model';
import { ProductOfferShort } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { InfoLink } from './controls';

type SidebarInfoProps = {
  readonly product: ProductOfferShort;
  readonly paymentTypes: AppOptionTyped<EProductOrderPaymentType>[];
  readonly deliveryTypes: AppOptionTyped<EDeliveryType>[];
  readonly onClickPayment: () => void;
  readonly onClickDelivery: () => void;
};

const SidebarInfo = ({ product, deliveryTypes, paymentTypes, onClickPayment, onClickDelivery }: SidebarInfoProps) => {
  const deliveryType = deliveryTypes.find(dt => dt.id === product.deliveryType);
  const paymentType = paymentTypes.find(dt => dt.id === product.paymentType);

  let deliveryInfo = deliveryType ? `Есть ${deliveryType.name.toLowerCase()}` : null;
  if (deliveryType?.id === EDeliveryType.Other) {
    deliveryInfo = 'Информация о доставке';
  }

  let paymentInfo = paymentType ? `Оплата ${paymentType.name.toLowerCase()}` : null;
  if (paymentType?.id === EProductOrderPaymentType.Other) {
    paymentInfo = 'Информация об оплате';
  }

  return (
    <MPGrid
      item
      container
      spacing={1.5}
      flexDirection='column'
    >
      {deliveryInfo && (
        <MPGrid item>
          <InfoLink onClick={onClickDelivery}>{deliveryInfo}</InfoLink>
        </MPGrid>
      )}
      {paymentInfo && (
        <MPGrid item>
          <InfoLink onClick={onClickPayment}>{paymentInfo}</InfoLink>
        </MPGrid>
      )}
    </MPGrid>
  );
};

export default SidebarInfo;
