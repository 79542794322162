import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { SocialPackageToAgreementEventContainer } from './container';
import {
  SocialPackageToAgreementEvent,
  SocialPackageToAgreementEventPayload,
  socialPackageToAgreementEventType,
} from './types';

export type { SocialPackageToAgreementEventPayload } from './types';

export const createEvent = (payload: SocialPackageToAgreementEventPayload): SocialPackageToAgreementEvent =>
  EventCreatorFactory.business().getSimple(
    `${socialPackageToAgreementEventType}-${payload.socialPackage.id}`,
    SocialPackageToAgreementEventContainer,
    payload
  );
