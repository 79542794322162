import { getFileDownloadEndpoint } from 'data/openApi/utils';
import { User } from 'domain/model';
import profileStub from 'presentation/media/images/profileStub.png';
import { MPAvatar } from 'presentation/theme/ui-kit/avatar';
import { MPAvatarSize, MPAvatarVariant } from 'theme/ui-kit/avatar/types';
import { getAvatarNameAbbreviation } from './utils';

type UserAvatarProps = {
  readonly user: User;
  readonly size?: MPAvatarSize;
  readonly variant?: MPAvatarVariant;
};

export const UserAvatar = ({ user, size, variant }: UserAvatarProps) => {
  const fullName = [user.lastName, user.firstName].filter(item => !!item).join(' ');
  const url = user.photo?.path ? getFileDownloadEndpoint({ id: user.photo.path, private: true }) : profileStub;
  const content = !url && (getAvatarNameAbbreviation(fullName) ?? 'NA');

  return (
    <MPAvatar
      size={size}
      variant={variant}
      description={fullName}
      url={url}
    >
      {content}
    </MPAvatar>
  );
};
