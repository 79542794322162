import { Text } from '../../components/controls';
import { LinkCSPObjects } from './link';

const AboutCspWhatObjectsInfo = () => {
  return (
    <Text>
      Вы можете выбрать один из объектов перейдя <LinkCSPObjects>по ссылке</LinkCSPObjects>.
    </Text>
  );
};

export default AboutCspWhatObjectsInfo;
