import { PartnerWindow } from 'domain/model';
import CmsContainerContainer from '../../../../cms/container/container';
import { LazyContentWrapper } from '../../../../general/lazyContentWrapper';
import { LazyContentItem } from '../../../../general/lazyContentWrapper/item';
import PartnerDeskDetailsWindowLayout from './layout';

type PartnerDeskDetailsTabPartnerWindowProps = {
  readonly guid: UUID;
  readonly partnerId: UUID;
  readonly partnerWindow: PartnerWindow;
};

const Layout = PartnerDeskDetailsWindowLayout;

export const PartnerDeskDetailsTabPartnerWindow = ({
  partnerWindow,
  guid,
  partnerId,
}: PartnerDeskDetailsTabPartnerWindowProps) => {
  // delete when back-end make filter
  const partnerWindowList = partnerWindow.filter(item => item.status === 'ACTIVE');

  return (
    <Layout>
      <LazyContentWrapper guid={guid}>
        {listSize => (
          <>
            {partnerWindowList?.slice(0, listSize + 1).map((container, i) => (
              <div key={container.id}>
                <LazyContentItem
                  elementSize={33}
                  guid={guid}
                  containerId={container.id}
                  isLoaded={i !== listSize}
                >
                  {onContainerDataLoaded => (
                    <CmsContainerContainer
                      context={{ partner: { id: partnerId } }}
                      guid={guid}
                      container={container}
                      onContainerDataLoaded={onContainerDataLoaded}
                    />
                  )}
                </LazyContentItem>
              </div>
            ))}
          </>
        )}
      </LazyContentWrapper>
    </Layout>
  );
};
