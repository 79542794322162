import { Location } from 'history';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';

const root = rootRouting.cspOffer;
export const routing = {
  list: root,
};

export type CspOffersListLocationState = {
  readonly guid: UUID;
};

type GetCspOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getCspOffersListRoute = (props?: GetCspOffersListRouteProps): Location<CspOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};
