import { CorpOfferShort } from 'domain/model';
import { FCC } from 'react';
import CorpOfferClaimDialogsProvider from './dialogProvider/provider';
import CorpOfferClaimProvider from './provider/provider';

type CorpOfferClaimProvidersProps = {
  readonly corpOffer: CorpOfferShort;
  readonly onShowAllOffers: () => void;
};

const CorpOfferClaimProviders: FCC<CorpOfferClaimProvidersProps> = props => {
  const { children, corpOffer, onShowAllOffers } = props;

  return (
    corpOffer.promotionType && (
      <CorpOfferClaimProvider
        id={corpOffer.id}
        promotionType={corpOffer.promotionType}
      >
        <CorpOfferClaimDialogsProvider
          corpOffer={corpOffer}
          onShowAllOffers={onShowAllOffers}
        >
          {children}
        </CorpOfferClaimDialogsProvider>
      </CorpOfferClaimProvider>
    )
  );
};

export default CorpOfferClaimProviders;
