import { EOfferStatus } from 'domain/model';
import OfferClaimHelp from '../../../../components/claim/help';
import useAspOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';

const NdflAdapter = () => {
  const { aspOffer } = useAspOfferClaimContext();
  const { openNdflDialog } = useAspOfferClaimDialogs();

  const isVisible = aspOffer?.status === EOfferStatus.Active;

  return isVisible ? (
    <OfferClaimHelp
      text={'Возможно, оплата НДФЛ'}
      onClick={openNdflDialog}
    />
  ) : null;
};

export default NdflAdapter;
