import { useSelector } from 'react-redux';
import { cspApplicationHasApplicationSelector } from '../store/selectors';

export const CspApplicationPreviewAdapter = () => {
  const hasApplication = useSelector(cspApplicationHasApplicationSelector);

  if (hasApplication) {
    return null;
  }

  return null;
};
