import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { css, FormControl, IconButton, Link as MuiLink, Typography, TypographyProps } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1.25)};
`;

export const Link = styled(MuiLink)`
  word-break: break-all;
`;

type ContentProps = TypographyProps & {
  readonly rows: number;
};

export const ContentWrapper = styled(FormControl)(
  ({ theme }) => css`
    padding: 3.857rem;

    ${theme.breakpoints.down('sm')} {
      padding: 1.5rem;
    }
  `
);

export const CloseButton = styled(IconButton)(
  () => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
  `
);

export const Content = styled(
  forwardRef(({ ...props }: ContentProps, ref: any) => (
    <Typography
      ref={ref}
      {...props}
    />
  ))
)(
  ({ rows }) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${rows};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);
