import styled from '@emotion/styled';
import { css } from '@mui/material';

export const ImageWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: 100%;

    margin-top: ${theme.spacing(3)};

    position: relative;

    img {
      width: 100%;
      max-width: 100%;

      border-radius: ${theme.shape.borderRadius}px;
    }
  `
);

export const BenefitsCount = styled.div(
  () => css`
    width: 100%;
    height: 100%;
    position: absolute;

    display: grid;
    align-content: center;
    justify-content: center;

    font-size: 85px;
    font-weight: bold;

    span {
      background: linear-gradient(224.37deg, #ff4040 14.05%, #ffaa2b 85.15%);
      background-clip: text;
      color: transparent;
    }
  `
);
