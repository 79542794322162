import Splitter from 'components/common/splitter';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC, useState } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { ConfirmCodeEventInputAdapter, ConfirmCodeEventTextAdapter, ConfirmCodeEventTimerAdapter } from './adapters';
import { useCodeEdit } from './hooks/useCodeEdit';
import { ConfirmCodeEventPayload } from './types';

export type ConfirmCodeInnerContainerProps = ConfirmCodeEventPayload & {
  readonly onSuccess: () => void;
};

export const ConfirmCodeInnerContainer: FC<ConfirmCodeInnerContainerProps> = ({
  title,
  text,
  back,
  source,
  onGetNewCode,
  onSuccess,
}) => {
  const { validation, codeTtl, codeRequestId, codeNextAttemptDate, isFetching, onSendCode } = useCodeEdit({
    source,
    onSuccess,
    onGetNewCode,
  });

  const [value, setValue] = useState<string>('');

  return (
    <DialogueEventContentBuilderLayout
      title={title}
      isFetching={isFetching}
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => onSendCode(value)}
          >
            Продолжить
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <ConfirmCodeEventTextAdapter>{text}</ConfirmCodeEventTextAdapter>
      <Splitter size={2} />

      <ConfirmCodeEventInputAdapter
        value={value}
        validation={validation?.code}
        disabled={isFetching}
        onChange={setValue}
      />
      <Splitter size={2} />

      {codeTtl !== null && codeRequestId !== null && (
        <ConfirmCodeEventTimerAdapter
          codeTtl={codeTtl}
          codeRequestId={codeRequestId}
          codeNextAttemptDate={codeNextAttemptDate}
          onGetNewCode={onGetNewCode}
        />
      )}

      {back && (
        <>
          <Splitter size={2} />
          {back}
        </>
      )}
    </DialogueEventContentBuilderLayout>
  );
};
