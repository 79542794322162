import styled from '@emotion/styled';
import { css } from '@mui/material';

export const PageSizeSelectWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding-top: ${theme.spacing(2.5)};
    padding-bottom: ${theme.spacing(2.5)};
  `
);

export const LoaderWrapper = styled.div(
  () => css`
    position: relative;

    width: 100%;
    min-height: 15rem;
  `
);
