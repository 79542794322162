import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div(
  () => css`
    position: relative;
  `
);
