import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import {
  SocialPackageAgreedActionsAdapter,
  SocialPackageAgreedContentAdapter,
  SocialPackageAgreedTitleAdapter,
} from './adapters';
import { SocialPackageAgreedEventProps } from './types';

/**
 * успешное согласие с КСП
 */

export const SocialPackageAgreedEventContainer: FC<SocialPackageAgreedEventProps> = props => {
  return (
    <DialogueEventContentBuilderLayout
      title={<SocialPackageAgreedTitleAdapter />}
      actions={<SocialPackageAgreedActionsAdapter {...props} />}
    >
      <SocialPackageAgreedContentAdapter />
    </DialogueEventContentBuilderLayout>
  );
};
