import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ellipsisCss } from '../../../../../components/common/ellipsis';
import { TypographyEllipsis } from '../typographyEllipsis';

export const Price = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    ${ellipsisCss};

    text-align: right;
  `
);

export const Content = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 110px;
    grid-gap: ${theme.spacing(2)};
  `
);

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: ${theme.spacing(0.5)};

    border-radius: ${theme.shape.borderRadius * 0.8}px;
    background: ${theme.palette.background.paper};

    padding: ${theme.spacing(1.5)};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(2)};
      grid-template-columns: 1fr max-content;
      grid-gap: ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: 1fr;
    }

    ${TypographyEllipsis} {
      flex-grow: 1;
    }
  `
);
