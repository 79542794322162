import { useUpdateCurrentUserMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { User } from 'domain/model';
import { userProfileValidation } from 'presentation/features/user/validation';
import useValidation from 'presentation/hooks/validation/useValidation';
import { ValidationResult } from 'presentation/utils/validation';
import { useEffect, useState } from 'react';

type UseUserProfileAttributeEditProps<A extends keyof User> = {
  readonly user: User;
  readonly attribute: A;
  readonly initialValue?: User[A];
};

type UseUserProfileAttributeEdit<A extends keyof User> = {
  readonly value: User[A];
  readonly isUpdating: boolean;
  readonly validation: Nullable<ValidationResult<User>>;
  readonly update: () => Promise<boolean>;
  readonly reset: () => void;
  readonly onChange: (newValue: User[A]) => void;
};

const getValue = <A extends keyof User>(attribute: A, user: User, initialValue?: User[A]): User[A] =>
  initialValue !== undefined ? initialValue : user[attribute];

export const useUserProfileAttributeEdit = <A extends keyof User>(
  props: UseUserProfileAttributeEditProps<A>
): UseUserProfileAttributeEdit<A> => {
  const { user, attribute, initialValue } = props;

  const [value, setValue] = useState<User[A]>(() => getValue(attribute, user, initialValue));

  const [
    updateCurrentUser,
    { error: updateCurrentUserError, isSuccess: isUpdated, isLoading: isUpdating, reset: updateCurrentUserReset },
  ] = useUpdateCurrentUserMutation();

  const data: User = { ...user, [attribute]: value };

  const {
    validate,
    validationResult: validation,
    resetValidationResult,
  } = useValidation({
    object: data,
    rules: { [attribute]: userProfileValidation[attribute] },
  });

  const update = () => {
    const isValid = validate();
    if (isValid) {
      return updateCurrentUser({ id: user.id, data })
        .unwrap()
        .then(result => result === 200);
    }
    return Promise.resolve(false);
  };

  const reset = () => {
    resetValidationResult();
    setValue(getValue(attribute, user, initialValue));
  };

  useEffect(() => {
    setValue(getValue(attribute, user, initialValue));
  }, [attribute, user, initialValue]);

  useEffect(() => {
    if (isUpdated) {
      updateCurrentUserReset();
    }
  }, [isUpdated, updateCurrentUserReset]);

  useEffect(() => {
    if (updateCurrentUserError) {
      ErrorHandler.handleHttpError(updateCurrentUserError);
    }
  }, [updateCurrentUserError]);

  return {
    value,
    isUpdating,
    validation,
    update,
    reset,
    onChange: setValue,
  };
};
