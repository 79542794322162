import { FC } from 'react';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPButton } from 'presentation/theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';

type UserPromptProps = {
  readonly isVisible: boolean;
  readonly title: string;
  readonly text: string;
  readonly onCancel: VoidFunction;
  readonly onProceed: VoidFunction;
};

const UserPrompt: FC<UserPromptProps> = ({ isVisible, onCancel, onProceed, title, text }) => {
  return (
    <MPConfirmDialog
      open={isVisible}
      title={title}
      maxWidth='xs'
      onClose={onCancel}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            size='large'
            color='brand'
            onClick={onProceed}
          >
            Продолжить
          </MPButton>
          <MPButton
            fullWidth
            size='large'
            variant='text'
            color='brand'
            onClick={onCancel}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      {text}
    </MPConfirmDialog>
  );
};

export default UserPrompt;
