import { FC } from 'react';
import { EOrderDiscriminator } from 'domain/model';
import SmartPhoneFixedBar from 'presentation/components/common/smartPhoneFixedBar';
import { useContextHandlers } from '../../../hooks/useContextHandlers';
import { BookingOrderProps } from '../../types';

const BookingOfferOrderSmartPhoneFixedBarAdapter: FC<BookingOrderProps> = ({ order }) => {
  const { goBack } = useContextHandlers();

  if (!order || order?.discriminator !== EOrderDiscriminator.BookingOrder) {
    return null;
  }

  return (
    <SmartPhoneFixedBar
      onBack={goBack}
      text={order?.offer?.name ? `${order.offer.name} – Заказ №: ${order.number}` : ''}
    />
  );
};

export default BookingOfferOrderSmartPhoneFixedBarAdapter;
