import styled from '@emotion/styled';
import {
  css,
  ListItem,
  listItemClasses,
  ListItemIcon,
  listItemSecondaryActionClasses,
  ListItemText,
} from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    .${listItemClasses.container} {
      list-style-type: none;
    }
  `
);

export const ContentWrapper = styled(ListItem)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    background: ${theme.palette.background.paper};
    border-radius: ${theme.shape.borderRadius * 1.2}px;

    .${listItemSecondaryActionClasses.root} {
      align-self: flex-start;
      position: static;
      margin-left: ${theme.spacing(0.5)};
      margin-top: 4px;

      transform: none;
    }
  `
);

export const IconWrapper = styled(ListItemIcon)(
  ({ theme }) => css`
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: ${theme.spacing(1.5)};
  `
);

export const TextWrapper = styled(ListItemText)(
  () => css`
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-word;
  `
);

export const InfoWrapper = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing()};
  `
);
