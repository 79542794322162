import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledCatalogButton = styled(MPButton)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 1.5)} !important;
    min-width: auto;
    display: flex;
    gap: ${theme.spacing(1)};
  `
);
