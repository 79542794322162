import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { PartnerDesk } from 'domain/model';
import PartnerDeskCard from '../../components/card';
import { PartnerGrid } from '../../components/grid';
import { getPartnerDeskDetailsRoute } from '../../entry';
import usePartnerDeskList from '../provider/usePartnerDeskList';

const PartnerDeskListDataAdapter = () => {
  const history = useHistory();
  const { data } = usePartnerDeskList();

  const onOpenPartnerDeskDetails = useCallback(
    (partnerDesk: PartnerDesk) => {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id }));
    },
    [history]
  );

  return useMemo(
    () =>
      data && data.length > 0 ? (
        <PartnerGrid>
          {data.map(item =>
            item.partner ? (
              <PartnerDeskCard
                key={item.partnerId}
                title={item.partner.name}
                image={item.partner.logo}
                onClick={() => onOpenPartnerDeskDetails(item)}
              />
            ) : null
          )}
        </PartnerGrid>
      ) : null,
    [data, onOpenPartnerDeskDetails]
  );
};

export default PartnerDeskListDataAdapter;
