import { EUserFavoritesTab } from 'features/user/details/favorites/utils';
import { useCallback, useState } from 'react';

const useMountCandidates = () => {
  /* on mount candidates management  */
  const [mountCandidates, setMountCandidate] = useState([
    EUserFavoritesTab.BookingOffers,
    EUserFavoritesTab.TradeOffers,
    EUserFavoritesTab.CorpOffers,
    EUserFavoritesTab.ProductOffers,
  ]);

  const hasCandidate = useCallback(
    (candidate: EUserFavoritesTab) => {
      return !!mountCandidates.find(mc => mc === candidate);
    },
    [mountCandidates]
  );

  const excludeCandidate = useCallback(
    (candidate: EUserFavoritesTab) => {
      if (!hasCandidate(candidate)) {
        return;
      }

      const newCandidates = mountCandidates.filter(mc => mc !== candidate);
      setMountCandidate(newCandidates);
    },
    [hasCandidate, mountCandidates]
  );

  return {
    excludeCandidate,
    hasCandidate,
  };
};

export default useMountCandidates;
