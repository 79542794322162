import { Fade } from '@mui/material';
import { Category } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButtonsGroup } from 'presentation/theme/ui-kit/button/group';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import OffersTextHeader from '../textHeader';

type OffersSearchHeaderProps = {
  readonly category: Nullable<Category>;
  readonly count: number;
  readonly categories: Category[];
  readonly onChangeCategory: (category: Category) => void;
};

const OffersSearchHeader = (props: OffersSearchHeaderProps) => {
  const { category, count, categories, onChangeCategory } = props;

  const { x3 } = useGridSpacing();

  return (
    <Fade in>
      <MPGrid
        container
        spacing={x3}
        justifyContent='space-between'
        alignItems='center'
      >
        <MPGrid item>
          <OffersTextHeader>
            {count === 1 ? 'Найден ' : 'Найдено '} {count}{' '}
            {pluralize(count, ['результат', 'результата', 'результатов'])}
            {categories.length > 0 &&
              ` в ${categories.length} ${pluralize(categories.length, ['категории', 'категориях', 'категориях'])}`}
            <Splitter size={1} />
          </OffersTextHeader>
        </MPGrid>
        <MPGrid
          item
          zero={12}
        >
          <MPButtonsGroup<Category>
            color='secondary'
            exclusive
            items={categories}
            selected={category ? [category] : categories}
            onChange={([value]) => onChangeCategory(value)}
          />
        </MPGrid>
      </MPGrid>
    </Fade>
  );
};

export default OffersSearchHeader;
