import { aspOfferApi } from 'data/api/aspOffer';
import { RootState } from 'data/store/store';

export const aspOfferRelatedListArgsSelector = (store: RootState) => store.aspOffer.relatedList.args;

export const aspOfferRelatedListIsEmptySelector = (store: RootState) => {
  const args = store.aspOffer.relatedList.args;

  if (!args) {
    return true;
  }

  const { data } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return (data?.data?.length ?? 0) === 0;
};
