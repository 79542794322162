import { Link as MUILink } from '@mui/material';
import { AspOfferShort } from 'domain/model';
import { OfferTags } from 'domain/model';
import { ERenderSource } from 'presentation/types';
import { forwardRef, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OfferListItem, { OfferListItemProps } from '../../../components/listItem';
import { aspOfferTag } from '../../../utils';
import { getAspOfferDetailsRoute } from '../../routes';
import AspOfferListItemInfo from './info';
import AspOfferListItemPrice from './price';

export type AspOfferListItemProps = {
  readonly offer: Pick<
    AspOfferShort,
    'id' | 'name' | 'title' | 'images' | 'partner' | 'tags' | 'price' | 'promotionType'
  >;
  readonly direction?: OfferListItemProps['direction'];
  readonly favorite?: ReactNode;
  readonly renderFrom?: ERenderSource;
};

const AspOfferListItem = forwardRef<any, AspOfferListItemProps>((props, ref) => {
  const { offer, direction, favorite, renderFrom } = props;
  const { id, images, name, tags, price } = offer;

  const aspOfferTags = useMemo<OfferTags>(() => [...(tags ?? []), aspOfferTag], [tags]);

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getAspOfferDetailsRoute({ id, from: renderFrom })}
    >
      <OfferListItem
        images={images}
        description={name}
        header={<AspOfferListItemPrice price={price} />}
        favorite={favorite}
        info={<AspOfferListItemInfo {...offer} />}
        direction={direction}
        tags={aspOfferTags}
      />
    </MUILink>
  );
});

AspOfferListItem.displayName = 'AspOfferListItem';

export default AspOfferListItem;
