import { FC } from 'react';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { Typography } from '@mui/material';
import { MPButton } from 'theme/ui-kit/button';
import { FeedbackEventProps } from '../types';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { IconWrapper, SuccessFeedBackIcon } from '../controls';

export const SuccessAdapter: FC<FeedbackEventProps> = ({ onNext }) => {
  return (
    <DialogueEventContentBuilderLayout
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => onNext()}
          >
            Вернуться на витрину
          </MPButton>
        </ConfirmGroupButtons>
      }
      title={
        <Typography
          variant='inherit'
          align='center'
        >
          Спасибо!{'\u000a'}Сообщение отправлено
        </Typography>
      }
    >
      <IconWrapper>
        <SuccessFeedBackIcon fontSize='huge' />
      </IconWrapper>
    </DialogueEventContentBuilderLayout>
  );
};
