import { Redirect, Switch } from 'react-router-dom';
import { AppConfigurator } from 'system/appConfigurator';
import { routing } from './routes';

const CspOfferEntry = () => {
  return (
    <Switch>
      <Redirect
        path={routing.list}
        to={AppConfigurator.getInstance().getCorpCspOffersUrl()}
      />
    </Switch>
  );
};

export default CspOfferEntry;
