import { FC } from 'react';
import useTechConfig from 'hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { createEvent as createEventHowToUse } from '../../../../socialPackage/events/howToUse';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { BookingOrder } from 'domain/model/order';

type BookingOrderBannerCspAdapterProps = {
  readonly order: BookingOrder;
};

const BookingOrderBannerCspAdapter: FC<BookingOrderBannerCspAdapterProps> = ({ order }) => {
  const { hasFeature } = useTechConfig();
  const socialPackage = order.customerRzdSocialPackage;
  const { publish } = useBusinessEventBus();

  // Если флаг ксп отключен  или в заказе создан без ксп, или сейчас нет соц пакета в Active
  if (!hasFeature(EAppFeature.Csp) || !order?.hasRzdSocialPackage || !socialPackage) {
    return null;
  }

  return (
    <SocialPackageBanner
      variant={'tertiary'}
      actionText={'Как получить компенсацию'}
      onClick={() => {
        publish(createEventHowToUse({ socialPackage }));
      }}
    >
      Компенсируйте часть своих затрат на оздоровление и отдых
    </SocialPackageBanner>
  );
};

export default BookingOrderBannerCspAdapter;
