import { EBookingOfferSortType } from 'domain/model';
import { BookingOffersSearchListLocationState } from 'features/offer/booking/types';

export const makeSearchParams = (rawSearchParams: BookingOffersSearchListLocationState) => {
  const { name, rzdSocialPackage, minPrice, maxPrice, services, categoryId, sort, lastChangedFilterAttribute } =
    rawSearchParams;
  return {
    services: services ?? [],
    categoryId: categoryId ?? null,
    maxPrice: maxPrice ?? null,
    minPrice: minPrice ?? null,
    name: name ?? null,
    sort: sort ?? [EBookingOfferSortType.Default],
    rzdSocialPackage: rzdSocialPackage ?? null,
    lastChangedFilterAttribute: lastChangedFilterAttribute ?? null,
  };
};
