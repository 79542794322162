import { Text } from '../../components/controls';

const AboutCspWhatCategoryInfo = () => {
  return (
    <>
      <Text>
        Работникам ОАО «РЖД», которые получили положительное решение Комиссии по распределению путевок, по заявлению
        работника.
      </Text>
    </>
  );
};

export default AboutCspWhatCategoryInfo;
