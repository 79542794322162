import { BonusAccountBalance } from 'domain/model';
import { FC } from 'react';
import { UserFinancesBalanceLayout } from '../layout';
import { BalanceSlotMainBar } from '../layout/controls';
import {
  UserFinanceBalanceSlotActionsAdapter,
  UserFinanceBalanceSlotExpireAdapter,
  UserFinanceBalanceSlotTitleAdapter,
  UserFinanceBalanceSlotValueAdapter,
} from './balanceSlot';

type UserFinanceBalanceBonusesAdapterProps = {
  readonly balance: BonusAccountBalance;
};

export const UserFinanceBalanceBonusesAdapter: FC<UserFinanceBalanceBonusesAdapterProps> = props => {
  const { balance } = props;

  return (
    <UserFinancesBalanceLayout
      slots={[
        <UserFinanceBalanceSlotExpireAdapter
          key={'expire'}
          balance={balance}
        />,
        <BalanceSlotMainBar key='mainBar'>
          <UserFinanceBalanceSlotTitleAdapter
            key={'title'}
            balance={balance}
          />
          <UserFinanceBalanceSlotValueAdapter
            key={'value'}
            balance={balance}
          />
          <UserFinanceBalanceSlotActionsAdapter
            key={'actions'}
            balance={balance}
          />
        </BalanceSlotMainBar>,
      ]}
    />
  );
};
