import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';

export const SidebarWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: inline-flex;

    padding: ${theme.spacing(2)};
  `
);

export const CommentWrapper = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1.8)} ${theme.spacing(3.2)};

    background-color: ${theme.palette.secondary.A600};
    border-radius: ${theme.shape.borderRadius}px;
  `
);

export const CommentTitle = styled(Typography)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
  `
);
