import styled from '@emotion/styled';
import { css } from '@mui/material';

type WrapperProps = {
  readonly top: number | string;
};

export const Wrapper = styled.div<WrapperProps>(
  ({ top, theme }) => css`
    position: sticky;
    top: ${typeof top === 'string' ? top : `${top}px`};

    background-color: ${theme.palette.background.default};

    z-index: 2;
  `
);
