import { Category } from 'domain/model';
import { BreadcrumbObject } from 'presentation/components/common/breadcrumb/expanded';
import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getCorpOffersListRoute, getCorpOffersSearchRoute } from '../../routes';

type CorpOfferBreadcrumbsProps = {
  readonly offerCategories: Nullable<Category[]>;
  readonly dictionary: Nullable<Category[]>;
};

const CorpOfferBreadcrumbs = (props: CorpOfferBreadcrumbsProps) => {
  const { offerCategories, dictionary } = props;

  const getBreadcrumbLink = (category: Category) => getCorpOffersSearchRoute({ categoryId: category.id });

  const startCategory: BreadcrumbObject = {
    id: '',
    name: EAppCategories.CorpOffers,
    parentId: null,
    link: getCorpOffersListRoute(),
  };

  return (
    <OfferBreadcrumbs
      startCategory={startCategory}
      offerCategories={offerCategories ?? null}
      dictionary={dictionary}
      linkFactory={getBreadcrumbLink}
    />
  );
};

export default CorpOfferBreadcrumbs;
