import PartnerDeskDetailsTabCorpOffersToolbar from '../tabs/corpOffers/toolbar';
import PartnerDeskDetailsTabTradeOffersToolbar from '../tabs/tradeOffers/toolbar';
import PartnerDeskDetailsTabAspOffersToolbar from '../tabs/aspOffers/toolbar';
import PartnerDeskDetailsTabProductOffersToolbar from '../tabs/productOffers/toolbar';
import PartnerDeskDetailsTabBookingOffersToolbar from '../tabs/bookingOffers/toolbar';
import PartnerDeskDetailsTabAboutCompanyToolbar from '../tabs/aboutCompany/toolbar';
import PartnerDeskDetailsTabContactsToolbar from '../tabs/contacts/toolbar';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName, PartnerDeskDetailsTabInfo } from './index';
import { PartnerDesk } from 'domain/model';

type GetPartnerDeskDetailsTabList = {
  readonly partnerDesk: PartnerDesk;
  readonly isFetching: boolean;
  readonly tradeOffersCount: number;
  readonly aspOffersCount: number;
  readonly corpOffersCount: number;
  readonly productOffersCount: number;
  readonly bookingOffersCount: number;
  readonly partnerWindowContainersCount: number;
  readonly isCorpUser: boolean;
};

export const getPartnerDeskDetailsTabList = (props: GetPartnerDeskDetailsTabList): PartnerDeskDetailsTabInfo[] => {
  const {
    partnerDesk,
    isFetching,
    corpOffersCount,
    productOffersCount,
    tradeOffersCount,
    aspOffersCount,
    bookingOffersCount,
    partnerWindowContainersCount,
    isCorpUser,
  } = props;

  if (isFetching) {
    return [];
  }

  const tabs: PartnerDeskDetailsTabInfo[] = [];

  if (partnerWindowContainersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.PartnerWindow),
      value: EPartnerDeskDetailsTab.PartnerWindow,
    });
  }

  if (isCorpUser && corpOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.CorpOffers),
      count: corpOffersCount,
      value: EPartnerDeskDetailsTab.CorpOffers,
      toolbar: PartnerDeskDetailsTabCorpOffersToolbar,
    });
  }

  if (tradeOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.TradeOffers),
      count: tradeOffersCount,
      value: EPartnerDeskDetailsTab.TradeOffers,
      toolbar: PartnerDeskDetailsTabTradeOffersToolbar,
    });
  }

  if (isCorpUser && aspOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AspOffers),
      count: aspOffersCount,
      value: EPartnerDeskDetailsTab.AspOffers,
      toolbar: PartnerDeskDetailsTabAspOffersToolbar,
    });
  }

  if (productOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.ProductOffers),
      count: productOffersCount,
      value: EPartnerDeskDetailsTab.ProductOffers,
      toolbar: PartnerDeskDetailsTabProductOffersToolbar,
    });
  }

  if (bookingOffersCount) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.BookingOffers),
      count: bookingOffersCount,
      value: EPartnerDeskDetailsTab.BookingOffers,
      toolbar: PartnerDeskDetailsTabBookingOffersToolbar,
    });
  }

  if (partnerDesk.aboutCompany) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AboutCompany),
      value: EPartnerDeskDetailsTab.AboutCompany,
      toolbar: PartnerDeskDetailsTabAboutCompanyToolbar,
    });
  }

  if (partnerDesk.contacts) {
    tabs.push({
      label: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.Contacts),
      value: EPartnerDeskDetailsTab.Contacts,
      toolbar: PartnerDeskDetailsTabContactsToolbar,
    });
  }

  return tabs;
};
