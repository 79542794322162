import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';

type UserResetPasswordConfirmDialogProps = {
  readonly open: boolean;
  readonly isFetching: boolean;
  readonly onConfirm: () => void;
  readonly onClose: () => void;
};

const UserResetPasswordConfirmDialog = (props: UserResetPasswordConfirmDialogProps) => {
  const { open, isFetching, onConfirm, onClose } = props;

  return (
    <MPConfirmDialog
      title='Сменить пароль?'
      open={open}
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isFetching}
            onClick={onConfirm}
          >
            Продолжить
            {isFetching && <ContentLoader />}
          </MPButton>
          <MPButton
            variant='text'
            color='brand'
            size='large'
            fullWidth
            disabled={isFetching}
            onClick={onClose}
          >
            Отменить
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      Для изменения пароля необходимо выполнить вход с текущим паролем, после чего вы сможете ввести новый.
    </MPConfirmDialog>
  );
};

export default UserResetPasswordConfirmDialog;
