import styled from '@emotion/styled';
import { AppBar, AppBarProps, css } from '@mui/material';
import React from 'react';

type WrapperProps = AppBarProps & {
  readonly offsetTop: number;
  readonly children: React.ReactNode;
};

export const Wrapper = styled(({ offsetTop, ...others }: WrapperProps) => <AppBar {...others} />)(
  ({ theme, offsetTop }) => css`
    top: ${offsetTop}px;
    z-index: ${theme.zIndex.appBar - 1};

    background-color: ${theme.palette.background.default};
  `
);
