import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent as confirmEmailEvent } from 'features/user/events/confirmEmail';
import { createEvent as changeEmailEvent } from 'features/user/events/changeEmail';
import { MailIcon } from 'media/icons';
import { MPChip } from 'theme/ui-kit/chip';
import UserProfileViewAttribute from '../../../components/attributeView';
import { UserProfileDataAdapterProps } from './types';

const label = 'Электронная почта';

export const UserProfileEmailAdapter = ({ user }: UserProfileDataAdapterProps) => {
  const { publish } = useBusinessEventBus();

  const onConfirm = () => {
    publish(confirmEmailEvent({}));
  };

  const onChange = () => {
    publish(changeEmailEvent({}));
  };

  return (
    <UserProfileViewAttribute
      label={label}
      value={user.email}
      icon={<MailIcon color='secondary' />}
      info={
        !user.emailVerified && (
          <MPChip
            variant='filled'
            color='warning'
            label='Почта не подтверждена'
            size='micro'
          />
        )
      }
      actionText={user.emailVerified ? 'Изменить' : 'Подтвердить'}
      onAction={user.emailVerified ? onChange : onConfirm}
    />
  );
};
