import { AutocompleteProps, InputBaseProps } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { useState } from 'react';
import { MPFormInput } from '../input';
import { Autocomplete, LoaderWrapper } from './controls';

type MPAutocompleteSingleSelectItem<IDT extends string | number = string> = {
  readonly id: IDT;
  readonly name: Nullable<string>;
};

type MPAutocompleteSingleSelectProps<T> = Omit<
  AutocompleteProps<T, false, false, false>,
  'inputValue' | 'renderInput' | 'onChange'
> & {
  readonly value: Nullable<T>;
  readonly options: T[];
  readonly label: string;
  readonly error?: boolean;
  readonly helperText?: Nullable<string>;
  readonly disabled?: boolean;
  readonly noOptionsText?: string;
  readonly isLoading?: boolean;
  readonly inputValue?: Nullable<string>;
  readonly inputProps?: InputBaseProps['inputProps'];
  readonly onSearchValue: (value: string) => void;
  readonly onChangeValue: (value: Nullable<T>) => void;
};

export function MPAutocompleteSingleSelect<
  T extends MPAutocompleteSingleSelectItem<IDT>,
  IDT extends string | number = string
>(props: MPAutocompleteSingleSelectProps<T>): JSX.Element {
  const {
    options,
    value,
    onChangeValue,
    onSearchValue,
    getOptionLabel,
    label,
    error,
    helperText,
    isLoading,
    disabled,
    noOptionsText,
    inputValue,
    inputProps,
  } = props;

  const [internalInputValue, setInputValue] = useState<Nullable<string>>(inputValue ?? null);

  return (
    <Autocomplete
      clearOnBlur
      disabled={disabled}
      value={value}
      inputValue={internalInputValue || ''}
      options={options}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel ?? (option => (option as T).name ?? '')}
      onChange={(event, newValue, reason) => {
        if (typeof newValue !== 'string') {
          onChangeValue(newValue);
        } else {
          if (reason === 'createOption') {
            if (options.length > 0) {
              onChangeValue(options[0]);
            } else {
              setInputValue(null);
              onChangeValue(null);
            }
          }
        }
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
        onSearchValue(newValue);
      }}
      renderInput={params => (
        <MPFormInput
          {...params}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: isLoading ? (
                <LoaderWrapper>
                  <ContentLoader size={23} />
                </LoaderWrapper>
              ) : (
                <>{params.InputProps.endAdornment}</>
              ),
            },
            htmlInput: {
              ...params.inputProps,
              ...inputProps,
              autoComplete: 'none',
              'aria-autocomplete': 'none',
            },
          }}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
