import { Link, Typography } from '@mui/material';
import AppImage from 'presentation/components/common/images/common';
import { toRubCurrency } from '../../../../utils/currency';
import {
  ContentGrid,
  ContentGridArea,
  ECartItemGridAreas,
  PreviewWrapper,
  ShortFinanceBlock,
  ShortPrice,
  Wrapper,
} from './controls';
import { ProductOrderItem } from 'domain/model/order';

type CartListItemProps = {
  readonly data: ProductOrderItem;
  readonly onOpenProductDetails: () => void;
};

const CartListItemShort = (props: CartListItemProps) => {
  const { data, onOpenProductDetails } = props;

  const { image, name, cost, qty } = data;

  return (
    <Wrapper>
      <ContentGrid>
        <ContentGridArea area={ECartItemGridAreas.Preview}>
          {image && (
            <Link
              color={'textPrimary'}
              underline='hover'
              onClick={onOpenProductDetails}
            >
              <PreviewWrapper enabled>
                <AppImage src={image} />
              </PreviewWrapper>
            </Link>
          )}
        </ContentGridArea>
        <ContentGridArea area={ECartItemGridAreas.Name}>
          <Link
            color={'textPrimary'}
            underline='hover'
            onClick={onOpenProductDetails}
          >
            {name}
          </Link>
        </ContentGridArea>

        <ContentGridArea area={ECartItemGridAreas.Finance}>
          <ShortFinanceBlock>
            <Typography> {qty}шт.</Typography>
            <ShortPrice>{toRubCurrency(cost)}</ShortPrice>
          </ShortFinanceBlock>
        </ContentGridArea>
      </ContentGrid>
    </Wrapper>
  );
};

export default CartListItemShort;
