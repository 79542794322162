import { FC } from 'react';
import SocialPackageToAgreementContent from '../../../components/content/disagreed';
import { SocialPackageToAgreementEventProps } from '../types';

export const SocialPackageToAgreementContentAdapter: FC<SocialPackageToAgreementEventProps> = ({
  event: { payload },
}) => {
  const {
    socialPackage: { limitToSelfRest, limitToFamilyRest, endDate },
  } = payload;

  return (
    <SocialPackageToAgreementContent
      endDate={endDate}
      limitToSelfRest={limitToSelfRest}
      limitToFamilyRest={limitToFamilyRest}
    />
  );
};
