import { getAboutCspRoute } from 'features/about/entry';
import { DocIcon2 } from 'media/icons';
import { MPLink } from 'theme/ui-kit/link';

export const CspApplicationRulesLinkAdapter = () => {
  return (
    <MPLink
      target='_blank'
      href={getAboutCspRoute()}
      startIcon={DocIcon2}
      iconSize='small'
    >
      Подробные правила программы
    </MPLink>
  );
};
