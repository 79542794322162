import styled from '@emotion/styled';
import { css } from '@mui/material';
import { Ellipsis } from '../../../../../components/common/ellipsis';

export const InfoWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PriceWrapper = styled(Ellipsis)(
  ({ theme: { typography, breakpoints, spacing } }) =>
    css`
      display: flex;
      align-items: baseline;
      margin-right: ${spacing(0.5)};

      font-size: ${typography.body1.fontSize};

      ${breakpoints.down('md')} {
        font-size: ${typography.caption.fontSize};
      }
    `
);

export const Price = styled.div(
  ({ theme: { typography, spacing, breakpoints } }) =>
    css`
      display: flex;

      font-size: ${typography.h3.fontSize};
      font-weight: ${typography.h2.fontWeight};

      margin-left: ${spacing(0.5)};
      margin-right: ${spacing(0.5)};

      ${breakpoints.down('sm')} {
        font-size: ${typography.body1.fontSize};
      }
    `
);
