import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const backdrops: OverrideThemeFunc<Components> = () => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'hsla(0, 0%, 0%, 0.6)',
        backdropFilter: 'blur(6px)',
      },
      invisible: {
        background: 'inherit',
        backdropFilter: 'none',
      },
    },
  },
});

export default backdrops;
