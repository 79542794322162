import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { CspAccountBalance } from 'domain/model';
import { createEvent } from 'features/socialPackage/events/howToUse';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import { BalanceSlotActions } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

type UserFinanceBalanceSlotActionsCspAdapterProps = UserFinanceBalanceSlotAdapterProps<CspAccountBalance>;

export const UserFinanceBalanceSlotActionsCspAdapter: FC<UserFinanceBalanceSlotActionsCspAdapterProps> = ({
  balance,
}) => {
  const { publish } = useBusinessEventBus();
  const onGetInfo = () => {
    publish(createEvent({ socialPackage: balance.source }));
  };

  return (
    <BalanceSlotActions>
      <MPButton
        variant='outlined'
        color='brand'
        onClick={onGetInfo}
        fullWidth
      >
        Как получить компенсацию
      </MPButton>
    </BalanceSlotActions>
  );
};
