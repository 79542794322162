import { getBookingOffersDetailsRoute } from 'presentation/features/offer/booking/routes';
import { UserIcon } from 'presentation/media/icons';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { EBookingOfferDetailsTab } from '../../../../../offer/booking/details/types';
import Option from './option';

const Icon = <UserIcon />;

type ContactsOptionProps = {
  offerId: string;
};

const ContactsOption: FC<ContactsOptionProps> = ({ offerId }) => {
  const history = useHistory();

  return (
    <>
      <Option
        icon={Icon}
        text='Контакты объекта'
        onClick={() =>
          history.push(getBookingOffersDetailsRoute({ id: offerId, tab: EBookingOfferDetailsTab.Contacts }))
        }
      />
    </>
  );
};

export default ContactsOption;
