import { Typography } from '@mui/material';
import { useAuth } from 'features/auth/provider/useAuth';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';
import { ProfileDeletedEventProps } from './types';

export const ProfileDeletedEventContainer: FC<ProfileDeletedEventProps> = () => {
  const { logOut } = useAuth();

  return (
    <MPConfirmDialog
      open
      title='Аккаунт удален'
      maxWidth='xs'
      disableEscapeKeyDown
      onClose={logOut}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => logOut()}
          >
            Закрыть
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <Typography>Ваш аккаунт полностью удален из Витрины</Typography>
    </MPConfirmDialog>
  );
};
