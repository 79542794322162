import { Typography } from '@mui/material';
import { InlineLink } from 'components/common/links/inline';
import Splitter from 'components/common/splitter';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import { FC, Fragment } from 'react';
import ListNumber from '../../listNumber';
import { TextWrapper, Wrapper } from './controls';

const getList = ({ endDate }: PrivilegeListHowToGeTextProps) => [
  <Typography
    variant='body2'
    key='1'
  >
    Забронируйте и&nbsp;оплатите путёвку не&nbsp;менее чем на&nbsp;7&nbsp;дней отдыха.
  </Typography>,
  <Typography
    variant='body2'
    key='2'
  >
    Перед заселением предупредите администратора, что вы&nbsp;работник РЖД, и&nbsp;вам понадобятся{' '}
    <InlineLink
      target='_blank'
      href={'/support/Памятка КСП.pdf'}
    >
      подтверждающие документы (PDF)
    </InlineLink>
    .
  </Typography>,
  <Typography
    variant='body2'
    key='3'
  >
    <Typography
      component='span'
      variant='subtitle2'
    >
      В&nbsp;течение месяца, но&nbsp;не&nbsp;позднее {moment(endDate).format(EDateFormat.Human)}
    </Typography>{' '}
    после отдыха подаете документы на&nbsp;компенсацию ответственному за&nbsp;предоставление КСП специалисту
    по&nbsp;управлению персоналом в&nbsp;вашем подразделении.
  </Typography>,
  <Typography
    variant='body2'
    key='4'
  >
    Ожидайте поступления выплаты вместе с&nbsp;очередной зарплатой.
  </Typography>,
];

type PrivilegeListHowToGeTextProps = {
  readonly endDate: string;
};

const PrivilegeListHowToGetText: FC<PrivilegeListHowToGeTextProps> = ({ endDate }) => {
  return (
    <>
      {getList({ endDate }).map((item, index) => (
        <Fragment key={index}>
          {index > 0 && <Splitter size={2} />}
          <Wrapper>
            <ListNumber number={index + 1} />
            <TextWrapper>{item}</TextWrapper>
          </Wrapper>
        </Fragment>
      ))}
    </>
  );
};

export default PrivilegeListHowToGetText;
