import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const welcomeBenefitsEventType = 'WelcomeBenefits';

export type WelcomeBenefitsEventPayload = {
  readonly count: number;
};

export type WelcomeBenefitsEvent = BusinessEvent<WelcomeBenefitsEventPayload>;

export type WelcomeBenefitsEventProps = DisplayEventComponentProps<WelcomeBenefitsEventPayload>;
