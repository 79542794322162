import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 736px;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    &:not(:empty) {
      margin-top: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(5)};
    }

    ${theme.breakpoints.up('md')} {
      &:not(:empty) {
        margin-top: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(3)};
      }
    }
  `
);

export const HeaderWrapper = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      padding-left: 0;
      padding-right: 0;
    }
  `
);

export const DetailsWrapper = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const FooterWrapper = HeaderWrapper;
