import { useGetUserFavoritesCorpOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { FC, useEffect } from 'react';
import OfferListItemContainer from '../../../../offer/listItem/container';
import { TabProps } from './types';

export const UserFavoritesCorpOffersContainer: FC<TabProps> = ({ guid, updateOnMount, onMount }) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  const { data, error: corpOffersError } = useGetUserFavoritesCorpOffersQuery(
    { guid },
    {
      refetchOnMountOrArgChange: updateOnMount,
    }
  );
  const corpOffers = data?.data;

  useEffect(() => {
    if (corpOffersError) {
      ErrorHandler.handleHttpError(corpOffersError);
    }
  }, [corpOffersError]);

  if (!corpOffers) {
    return null;
  }

  return (
    <CardList>
      {corpOffers.map(offer => (
        <OfferListItemContainer
          key={offer.id}
          offer={offer}
          favorite={{
            guid,
            stored: true /*чтобы не скрывался таб после убирания всех сердечек*/,
          }}
        />
      ))}
    </CardList>
  );
};
