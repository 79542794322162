import { Typography } from '@mui/material';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { SocialPackageBanner } from 'features/socialPackage/components/banners';
import { createEvent as createEventToAgreement } from 'features/socialPackage/events/toAgreement';
import { socialPackagesFirstReadySelector } from 'features/socialPackage/store/selectors';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { useSelector } from 'react-redux';

export const UserFinanceBannersAdapter = () => {
  const readySocialPackage = useSelector(socialPackagesFirstReadySelector);
  const { publish } = useBusinessEventBus();

  if (!readySocialPackage) {
    return null;
  }

  const onClick = () => publish(createEventToAgreement({ socialPackage: readySocialPackage }));

  return (
    <SocialPackageBanner
      onClick={onClick}
      actionText='Подробнее'
      variant='primary'
    >
      <Typography variant='subtitle1'>
        Компенсируем до {toCspRubCurrency(readySocialPackage.maxLimit)} от ваших затрат на оздоровление и отдых
      </Typography>
    </SocialPackageBanner>
  );
};
