import Splitter from 'components/common/splitter';
import PrivilegeListHowToGetText from '../../components/text/howToGetListText';
import CompensationRest from '../compensatonRest';
import CompensationRulesDoc from '../rulesDoc';
import SummaryText from '../../components/text/summaryText';

type SocialPackageHowToUseContentProps = {
  readonly limitToSelfRest: Nullable<number>;
  readonly limitToFamilyRest: Nullable<number>;
  readonly endDate: string;
};

const SocialPackageHowToUseContent = (props: SocialPackageHowToUseContentProps) => {
  const { limitToSelfRest, limitToFamilyRest, endDate } = props;

  return (
    <>
      <SummaryText endDate={endDate} />
      <Splitter size={2} />
      <CompensationRest
        limitToFamilyRest={limitToFamilyRest}
        limitToSelfRest={limitToSelfRest}
      />
      <Splitter size={2.5} />
      <PrivilegeListHowToGetText endDate={endDate} />
      <Splitter size={2.5} />
      <CompensationRulesDoc />
    </>
  );
};

export default SocialPackageHowToUseContent;
