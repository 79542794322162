import { User } from 'domain/model';
import { ValidationResult } from 'presentation/utils/validation';
import { useEffect, useState } from 'react';
import { AddressHelper } from '../../../../../../utils/address';
import useUserLocalSettings from '../../../../../hooks/useUserLocalSettings';

type Attribute = 'locality';

type UseUserProfileLocalityEditProps = {
  readonly user: User;
};

type UseUserProfileLocalityEdit = {
  readonly value: User[Attribute];
  readonly validation: Nullable<ValidationResult<User>>;
  readonly update: () => void;
  readonly reset: () => void;
  readonly onChange: (newValue: User[Attribute]) => void;
};

export const useUserProfileLocalityEdit = (props: UseUserProfileLocalityEditProps): UseUserProfileLocalityEdit => {
  const { user } = props;

  const [value, setValue] = useState<User[Attribute]>(() => user.locality);
  const { setCity } = useUserLocalSettings();

  const data: User = { ...user, locality: value };

  const update = () => {
    if (data.locality) {
      return setCity(data.locality.id, new AddressHelper(data.locality).getLocalitySimpleName());
    }
  };

  const reset = () => {
    /*resetValidationResult();*/
    setValue(user.locality);
  };

  useEffect(() => {
    setValue(user.locality);
  }, [user.locality]);

  return {
    value,
    validation: null,
    update,
    reset,
    onChange: setValue,
  };
};
