import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { UserAgreement, UserAgreementDocLink } from 'domain/model';
import { ApiCancellable } from '../types';
import { createCancelToken } from '../utils';

type AllProps = ApiCancellable;

export type SignRequest = {
  readonly docs: UserAgreementDocLink[];
};

export type SignProps = {
  readonly data: SignRequest;
};

export type UserAgreementApi = {
  readonly all: (props: AllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, UserAgreement[]>>;
  readonly sign: (props: SignProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, void>>;
};

const agreement: UserAgreementApi = {
  all: ({ signal }) => ({
    url: `/users/current/agreements`,
    method: 'GET',
    cancelToken: signal && createCancelToken(axios, signal),
  }),
  sign: ({ data }) => {
    return {
      url: `/users/current/agreements`,
      method: 'POST',
      data,
    };
  },
};

export default agreement;
