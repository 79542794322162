import { FC, useEffect } from 'react';
import { eventBusDomLabel } from './utils';

export const EventBusContainer: FC = () => {
  useEffect(() => {
    const element = document.createComment(eventBusDomLabel);

    document.body.appendChild(element);
  }, []);

  return null;
};
