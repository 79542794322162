import styled from '@emotion/styled';
import { css, IconButton as MuiIconButton } from '@mui/material';

export const IconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};

    &,
    &:focus,
    &:active,
    &:hover {
      padding: ${theme.spacing(0.8)};
    }
  `
);
