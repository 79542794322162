import { FC } from 'react';
import SocialPackageHowToUseContent from '../../../components/content/howToUse';
import { SocialPackageHowToUseEventProps } from '../types';

export const SocialPackageHowToUseContentAdapter: FC<SocialPackageHowToUseEventProps> = props => {
  const {
    event: {
      payload: {
        socialPackage: { endDate, limitToSelfRest, limitToFamilyRest },
      },
    },
  } = props;

  return (
    <SocialPackageHowToUseContent
      endDate={endDate}
      limitToSelfRest={limitToSelfRest}
      limitToFamilyRest={limitToFamilyRest}
    />
  );
};
