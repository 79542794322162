import styled from '@emotion/styled';
import { css, Paper } from '@mui/material';

export const Wrapper = styled(Paper)(
  ({ theme: { palette, spacing, shape } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing()};

    padding: ${spacing(1.5, 2)};

    background-color: ${palette.primary.A400};
    border-radius: ${shape.borderRadius * 1.2}px;
  `
);
