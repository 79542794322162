import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { createEvent } from '../changeCity';
import { ConfirmCityEventProps } from './types';

export const ConfirmCityEventContainer: FC<ConfirmCityEventProps> = ({
  event: {
    payload: { city },
  },
  onNext,
}) => {
  const { publishTop } = useBusinessEventBus();

  const onChangeCity = () => {
    publishTop(createEvent({}));
    onNext();
  };

  return (
    <DialogueEventContentBuilderLayout
      title={`Ваше местоположение ${city}?`}
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onNext}
          >
            Да
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onChangeCity}
          >
            Нет
          </MPButton>
        </ConfirmGroupButtons>
      }
      titleAsContent
    />
  );
};
