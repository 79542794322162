import { SecurityProtect } from 'features/security';
import { FC } from 'react';
import {
  CspApplicationActionsAdapter,
  CspApplicationContentAdapter,
  CspApplicationFormAdapter,
  CspApplicationLastApplicationAdapter,
  CspApplicationLoaderAdapter,
  CspApplicationPreviewAdapter,
  CspApplicationRulesLinkAdapter,
  CspApplicationTitleAdapter,
} from './adapters';
import { CspApplicationContext } from './context';
import useCspApplication from './hooks/useCspApplication';
import CspApplicationLayout from './layout';

type CspApplicationContainerProps = {
  readonly guid: UUID;
};

const Layout = CspApplicationLayout;

const CspApplicationContainer: FC<CspApplicationContainerProps> = ({ guid }) => {
  const handlers = useCspApplication({ guid });

  return (
    <SecurityProtect
      checkUser
      checkCorpUser
    >
      <CspApplicationContext.Provider value={handlers}>
        <Layout
          preview={<CspApplicationPreviewAdapter />}
          title={<CspApplicationTitleAdapter />}
          actions={<CspApplicationActionsAdapter />}
          loader={CspApplicationLoaderAdapter}
        >
          <CspApplicationLastApplicationAdapter />
          <CspApplicationContentAdapter />
          <CspApplicationRulesLinkAdapter />
          <CspApplicationFormAdapter />
        </Layout>
      </CspApplicationContext.Provider>
    </SecurityProtect>
  );
};

export default CspApplicationContainer;
