import { makeSearchParams } from 'features/offer/booking/list/utils';
import OfferListLayout from 'presentation/layouts/offerList';
import { memo } from 'react';
import { BookingOffersSearchListLocationState } from '../types';
import {
  BookingOfferListAsideAdapter,
  BookingOfferListControlAdapter,
  BookingOfferListDataAdapter,
  BookingOfferListLoaderAdapter,
  BookingOfferListStubAdapter,
  BookingOffersHeaderAdapter,
} from './adapters';
import { BookingOfferListContext, BookingOfferListSearchParamsContext } from './context';
import useBookingFilter from './hooks/useBookingFilter';
import useBookingOfferListHandlers from './hooks/useBookingOfferListHandlers';

const BookingOfferListContainer = memo((props: BookingOffersSearchListLocationState) => {
  const { services, rzdSocialPackage, minPrice, maxPrice, guid } = props;

  useBookingFilter(props);

  const handlers = useBookingOfferListHandlers(props);

  const filterCount = (services?.length ?? 0) + (minPrice || maxPrice ? 1 : 0) + (rzdSocialPackage ? 1 : 0);

  const searchParams = makeSearchParams(props);

  return (
    <BookingOfferListSearchParamsContext.Provider value={searchParams}>
      <BookingOfferListContext.Provider value={handlers}>
        <OfferListLayout
          header={<BookingOffersHeaderAdapter />}
          aside={<BookingOfferListAsideAdapter filterCount={filterCount} />}
          controls={
            <BookingOfferListControlAdapter
              {...props}
              filterCount={filterCount}
            />
          }
          stub={<BookingOfferListStubAdapter />}
          loader={<BookingOfferListLoaderAdapter />}
        >
          <BookingOfferListDataAdapter guid={guid} />
        </OfferListLayout>
      </BookingOfferListContext.Provider>
    </BookingOfferListSearchParamsContext.Provider>
  );
});

export default BookingOfferListContainer;
