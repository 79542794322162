import styled from '@emotion/styled';
import { css } from '@mui/material';

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-left: -${theme.spacing(1.5)};
      margin-right: -${theme.spacing(1.5)};
    }
  `
);
