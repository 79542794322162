import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { FCC } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { UserProfileEditAttributeCommonProps } from './types';

export const UserProfileEditAttribute: FCC<UserProfileEditAttributeCommonProps> = props => {
  const { label, isFetching, onClose, onSave, children, primaryText = 'Сохранить' } = props;

  return (
    <MPConfirmDialog
      open
      title={label}
      maxWidth='xs'
      fullScreenBreakpoint='sm'
      scroll='body'
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isFetching}
            onClick={onSave}
          >
            {primaryText}
            {isFetching && <ContentLoader />}
          </MPButton>
          <MPButton
            color='brand'
            size='large'
            variant='text'
            fullWidth
            disabled={isFetching}
            onClick={onClose}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
      onClose={onClose}
    >
      {children}
    </MPConfirmDialog>
  );
};
