import { FCC, Fragment, useContext } from 'react';
import { MPDialogProps } from 'theme/ui-kit/dialog';
import { MPCommonDialogContext } from 'theme/ui-kit/dialog/common/context';
import { InnerWrapper, StyledDialogTitle } from './controls';

export type MPDialogContentBuilderProps = Pick<MPDialogProps, 'wrapper' | 'title'>;

export const MPDialogContentBuilder: FCC<MPDialogContentBuilderProps> = ({
  wrapper: Wrapper = Fragment,
  title,
  children,
}) => {
  const { isBackButtonVisible } = useContext(MPCommonDialogContext);

  return (
    <Wrapper>
      <InnerWrapper>
        {title && <StyledDialogTitle compact={!!isBackButtonVisible}>{title}</StyledDialogTitle>}
        {children}
      </InnerWrapper>
    </Wrapper>
  );
};
