/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainAddress,
  GenMainCustomerOrderCancellationReasonType,
  GenMainDictionary,
  GenMainDictionaryId,
  GenMainDictionaryValue,
  GenMainDictionaryValueId,
  GenMainFeedBackForm,
  GenMainOfferPausedReasonType,
  GenMainOfferRejectionReasonType,
  GenMainOfferType,
  GenMainOrderCancellationReasonType,
  GenMainOrgUnit,
  GenMainOrgUnitId,
  GenMainPartnerId,
  GenMainPartnerRejectionReasonType,
  GenMainPredicate,
  GenMainRoad,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const CommonsApi = {
  /**
   * No description
   * * @tags Commons
   * @name UserFeedback
   * @summary Форма обратной связи пользователя
   * @request POST:/feedback
   * @secure
   */
  userFeedback: (baseURL: string, data: GenMainFeedBackForm, signal?: AbortSignal) =>
    createRequest<void, GenMainFeedBackForm>({
      baseURL,
      path: `/feedback`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name ListOrgUnits
   * @summary Получение списка орг едениц
   * @request GET:/org-units
   * @secure
   */
  listOrgUnits: (
    baseURL: string,
    query: {
      /** поисковый запрос - строка */
      q?: string | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Отфильтровать часто используемые в предложениях любых партнеров */
      topUsed?: boolean | null;
      /** Отфильтровать часто используемые в предложениях конкретных партнеров */
      topPartnerUsed?: GenMainPartnerId[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOrgUnit[], void>({
      baseURL,
      path: `/org-units`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetOrgUnitById
   * @summary Получение подразделения по id
   * @request GET:/org-units/{orgUnitId}
   * @secure
   */
  getOrgUnitById: (baseURL: string, orgUnitId: GenMainOrgUnitId, signal?: AbortSignal) =>
    createRequest<GenMainOrgUnit, void>({
      baseURL,
      path: `/org-units/${orgUnitId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name ListTargetLocalities
   * @summary Получение списка дорог
   * @request GET:/target-localities
   * @secure
   */
  listTargetLocalities: (
    baseURL: string,
    query: {
      /** Отфильтровать часто используемые в предложениях конкретных партнеров */
      topPartnerUsed?: GenMainPartnerId[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainAddress[], void>({
      baseURL,
      path: `/target-localities`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name ListRoads
   * @summary Получение списка дорог
   * @request GET:/roads
   * @secure
   */
  listRoads: (
    baseURL: string,
    query: {
      /** поисковый запрос - строка */
      q?: string | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Отфильтровать часто используемые в предложениях любых партнеров */
      topUsed?: boolean | null;
      /** Отфильтровать часто используемые в предложениях конкретных партнеров */
      topPartnerUsed?: GenMainPartnerId[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRoad[], void>({
      baseURL,
      path: `/roads`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetRoadById
   * @summary Получение дороги по id
   * @request GET:/roads/{roadId}
   * @secure
   */
  getRoadById: (baseURL: string, roadId: string, signal?: AbortSignal) =>
    createRequest<GenMainRoad, void>({
      baseURL,
      path: `/roads/${roadId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetPartnerRejectionReasonTypes
   * @summary Получение списка типов причин отклонения заявки на партнёрство
   * @request GET:/partners/rejection-reasons
   * @secure
   */
  getPartnerRejectionReasonTypes: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainPartnerRejectionReasonType[], void>({
      baseURL,
      path: `/partners/rejection-reasons`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetOfferRejectionReasonTypes
   * @summary Получение списка типов причин отклонения предложения
   * @request GET:/offers/rejection-reasons
   * @secure
   */
  getOfferRejectionReasonTypes: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainOfferRejectionReasonType[], void>({
      baseURL,
      path: `/offers/rejection-reasons`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetOfferPausedReasonTypes
   * @summary Получение списка типов причин приостановки предложений
   * @request GET:/offers/paused-reasons
   * @secure
   */
  getOfferPausedReasonTypes: (
    baseURL: string,
    query: {
      /** Фильтр по признаку системных причин */
      system?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferPausedReasonType[], void>({
      baseURL,
      path: `/offers/paused-reasons`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetCustomerOrderCancellationReasonTypes
   * @summary Получение списка типов причин отмены заказа покупателем
   * @request GET:/customer/order/cancel-reason-types
   * @secure
   */
  getCustomerOrderCancellationReasonTypes: (
    baseURL: string,
    query: {
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerOrderCancellationReasonType[], void>({
      baseURL,
      path: `/customer/order/cancel-reason-types`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetOrderCancellationReasonTypes
   * @summary Получение списка типов причин отмены заказа
   * @request GET:/orders/cancel-reason-types
   * @secure
   */
  getOrderCancellationReasonTypes: (
    baseURL: string,
    query: {
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOrderCancellationReasonType[], void>({
      baseURL,
      path: `/orders/cancel-reason-types`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetDictionaries
   * @summary Получение списка справочников
   * @request GET:/dictionaries
   * @secure
   */
  getDictionaries: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainDictionary[], void>({
      baseURL,
      path: `/dictionaries`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryById
   * @summary Получение справочника
   * @request GET:/dictionaries/{dictionaryId}
   * @secure
   */
  getDictionaryById: (baseURL: string, dictionaryId: GenMainDictionaryId, signal?: AbortSignal) =>
    createRequest<GenMainDictionary, void>({
      baseURL,
      path: `/dictionaries/${dictionaryId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryValues
   * @summary Получение списка значений справочника
   * @request GET:/dictionaries/{dictionaryId}/values
   * @secure
   */
  getDictionaryValues: (
    baseURL: string,
    {
      dictionaryId,
      ...query
    }: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** id справочника */
      dictionaryId: GenMainDictionaryId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainDictionaryValue[], void>({
      baseURL,
      path: `/dictionaries/${dictionaryId}/values`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryValueById
   * @summary Получение значения справочника
   * @request GET:/dictionary-values/{dictionaryValueId}
   * @secure
   */
  getDictionaryValueById: (baseURL: string, dictionaryValueId: GenMainDictionaryValueId, signal?: AbortSignal) =>
    createRequest<GenMainDictionaryValue, void>({
      baseURL,
      path: `/dictionary-values/${dictionaryValueId}`,
      method: 'GET',
      signal,
    }),
};
