import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};

    border-radius: ${theme.shape.borderRadius * 1.6}px;
    background: ${theme.palette.secondary.A400};

    padding: ${theme.spacing(2)};

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(1.5)};
    }
  `
);

export const Info = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing()};
  `
);

export const Header = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const Informer = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const List = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing()};
  `
);

export const ExpandableHTML = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
