export enum EParentGridAreas {
  Bar = 'bar',
  Inner = 'inner',
}

export enum EInnerGridAreas {
  SmartphoneFixedBar = 'smartphone-fixed-bar',
  Breadcrumbs = 'Breadcrumbs',
  Topbar = 'top-bar',
  Favorite = 'favorite',
  Contentbar = 'content-bar',
  Sidebar = 'side-bar',
  Bottombar = 'bottom-bar',
}

export enum EContentbarGridAreas {
  Image = 'image',
  Breadcrumbs = 'breadcrumbs',
  Banner = 'banner',
  SmartphoneTitle = 'smartphone-title',
  Description = 'description',
  LargeDesktopTabs = 'large-desktop-tabs',
}

export enum ESidebarGridAreas {
  Actions = 'actions',
  PartnerInfo = 'partner-info',
}

export type FixedPositions = (EParentGridAreas | EContentbarGridAreas | EInnerGridAreas)[];
