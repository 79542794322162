import winterBg from 'media/images/winterBg.png';
import moment from 'moment-timezone';

const SeasonBackgroundImg = () => {
  const month = moment().month();

  switch (month) {
    case 0:
    case 11:
      return (
        <img
          src={winterBg}
          alt='winter season'
        />
      );
    default:
      return null;
  }
};

export default SeasonBackgroundImg;
