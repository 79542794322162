import { useSelector } from 'react-redux';
import { cspApplicationHasApplicationSelector } from '../store/selectors';

export const CspApplicationTitleAdapter = () => {
  const hasApplication = useSelector(cspApplicationHasApplicationSelector);

  if (hasApplication) {
    return null;
  }

  return `Подача заявления на\u00A0компенсацию стоимости путевок на\u00A0оздоровление и\u00A0отдых (КСП)`;
};
