import { AccountBalance } from 'domain/model';
import { currentUserBalancesSelector, currentUserCspBalancesSelector } from 'features/user/current/store/selectors';
import { useSelector } from 'react-redux';

const useCurrentUserBalances = (): Nullable<AccountBalance[]> => {
  const balances = useSelector(currentUserBalancesSelector);
  const cspBalances = useSelector(currentUserCspBalancesSelector);

  const finalBalances: AccountBalance[] = [];

  if (balances) {
    finalBalances.push(balances);
  }

  if (cspBalances) {
    finalBalances.push(...cspBalances);
  }

  return finalBalances.length ? finalBalances : null;
};

export default useCurrentUserBalances;
