import { useAgreeSocialPackageMutation } from 'api/user';
import ContentLoader from 'components/common/loader';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { SocialPackageToAgreementEventProps } from '../types';

type SocialPackageToAgreementActionsAdapterProps = Pick<SocialPackageToAgreementEventProps, 'event'> & {
  readonly onAgreed: () => void;
  readonly onRefuse: () => void;
};

export const SocialPackageToAgreementActionsAdapter: FC<SocialPackageToAgreementActionsAdapterProps> = props => {
  const {
    event: {
      payload: {
        socialPackage: { id },
      },
    },
    onAgreed,
    onRefuse,
  } = props;

  const [onAgree, { isLoading }] = useAgreeSocialPackageMutation();

  const onAgreeInternal = async () => {
    await onAgree({ socialPackageId: id });
    onAgreed();
  };

  return (
    <ConfirmGroupButtons>
      <MPButton
        color='brand'
        fullWidth
        size={'large'}
        onClick={onAgreeInternal}
        disabled={isLoading}
      >
        Согласиться на льготу
        {isLoading && <ContentLoader />}
      </MPButton>
      <MPButton
        color='brand'
        variant='outlined'
        size={'large'}
        fullWidth
        onClick={onRefuse}
      >
        Отказаться
      </MPButton>
    </ConfirmGroupButtons>
  );
};
