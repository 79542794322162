import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { getAboutCspRoute } from '../../../../about/entry';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';

export const BookingOfferCspBannerAboutAdapter = () => {
  const history = useHistory();

  return (
    <SocialPackageBanner
      variant={'tertiary'}
      actionText={'Узнать о льготе'}
      onClick={() => {
        history.push(getAboutCspRoute());
      }}
    >
      <Typography>
        Возвращаем часть затрат на оздоровление и отдых получателям компенсируемого социального пакета
      </Typography>
    </SocialPackageBanner>
  );
};
