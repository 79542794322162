import { Typography } from '@mui/material';
import { AppOption, EOrderShortDiscriminator, EOrderStatus } from 'domain/model';
import { OrderShort } from 'domain/model/order';

export const getRequisites = (orderNumber: number | string, date: string) => {
  return `${orderNumber} от ${date}`;
};

export const getStatusName = (orderStatuses: AppOption[], orderStatus: EOrderStatus) => {
  return orderStatuses.find(status => status.id === orderStatus)?.name;
};

export const getStatusElement = (name?: string, order?: OrderShort) => {
  if (!order) {
    return null;
  }

  const isBookingOrder = order.discriminator === EOrderShortDiscriminator.BookingOrderShort;

  if (isBookingOrder) {
    return (
      <Typography color={[EOrderStatus.Confirmed].find(i => i === order.status) ? 'success.main' : 'black'}>
        {name}
      </Typography>
    );
  }

  return (
    <Typography color={[EOrderStatus.New].find(i => i === order.status) ? 'success.main' : 'black'}>{name}</Typography>
  );
};
