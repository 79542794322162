import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FCC } from 'react';

export const BalanceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export enum EBalanceSlotNameArea {
  expire = 'expire',
  title = 'title',
  mainBar = 'mainBar',
  value = 'value',
  limits = 'limits',
  actions = 'actions',
}

type BalanceSlotsGridAreaProps = {
  readonly area: EBalanceSlotNameArea;
};

export const BalanceSlotsGridArea = styled(MPGrid)<BalanceSlotsGridAreaProps>`
  width: 100%;
  grid-area: ${p => p.area};
`;

export const BalanceSlotExpire: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.expire}
    {...props}
  />
);

export const BalanceSlotTitle: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.title}
    {...props}
  />
);

export const BalanceSlotMainBar: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.mainBar}
    {...props}
  />
);

export const BalanceSlotValue: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.value}
    {...props}
  />
);

export const BalanceSlotLimits: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.limits}
    {...props}
  />
);

export const BalanceSlotActions: FCC = props => (
  <BalanceSlotsGridArea
    area={EBalanceSlotNameArea.actions}
    {...props}
  />
);

export const SlotsGrid = styled.div(
  ({ theme: { palette, spacing } }) => css`
    width: 100%;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-flow: column;
    background-color: ${palette.white.main};
    border-radius: ${spacing(2)};
    padding: ${spacing(3)};
    border: 1px solid ${palette.secondary.A600};
    box-shadow: 0 20px 30px -20px hsla(220, 13%, 50%, 0.35);

    [area=${EBalanceSlotNameArea.expire}]:not(:empty) {
      margin-bottom: ${spacing(3)};
    }

    [area=${EBalanceSlotNameArea.mainBar}]:not(:empty) {
      margin-top: auto;
    }

    [area=${EBalanceSlotNameArea.limits}]:not(:empty) {
      margin-top: ${spacing(2)};
    }

    [area=${EBalanceSlotNameArea.actions}] {
      display: flex;
      gap: ${spacing(1)};

      :not(:empty) {
        padding-top: ${spacing(3)};
        margin-top: auto;
      }
    }
  `
);

export const ContentWrapper = styled.div(
  () => css`
    max-width: 842px;

    margin: auto;
  `
);

export const BalancesWrapper = styled.div(
  ({ theme: { spacing, breakpoints } }) => css`
    display: grid;
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    grid-template-columns: 1fr;

    &:not(:empty) {
      margin-bottom: ${spacing(3)};
    }

    ${breakpoints.up('md')} {
      grid-template-columns: 1fr 1fr;

      &:not(:empty) {
        margin-bottom: ${spacing(6.5)};
      }
    }
  `
);

export const BannersWrapper = styled.div(
  ({ theme: { breakpoints, spacing } }) => css`
    display: flex;

    &:not(:empty) {
      margin-bottom: ${spacing(5)};
    }

    ${breakpoints.up('md')} {
      &:not(:empty) {
        margin-bottom: ${spacing(6.5)};
      }
    }
  `
);
