import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { getPartnerDeskDetailsRoute } from '../../../../partnerDesk/entry';
import { EPartnerDeskDetailsTab } from '../../../../partnerDesk/details/utils';
import { useHistory } from 'react-router';

type BookingOfferPartnerInfoProps = {
  readonly id: UUID;
};

const BookingOfferPartnerInfoAdapter = ({ id }: BookingOfferPartnerInfoProps) => {
  const history = useHistory();
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const onPartnerClick = () => {
    if (bookingOffer && bookingOffer.partner) {
      history.push(
        getPartnerDeskDetailsRoute({ id: bookingOffer.partner.id, tab: EPartnerDeskDetailsTab.BookingOffers })
      );
    }
  };

  if (!bookingOffer?.partner) {
    return null;
  }

  return (
    <OfferPartnerInfo
      partner={bookingOffer.partner}
      onClick={onPartnerClick}
    />
  );
};

export default BookingOfferPartnerInfoAdapter;
