import store from 'data/store/store';
import { SocialPackageApplicationForm } from 'domain/model';
import validateObject from 'hooks/validation/utils';
import { OnChangeObjectAttribute } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  cspApplicationClearValidationResult,
  cspApplicationSetFormAttribute,
  cspApplicationSetValidationResult,
  cspApplicationStartSession,
} from '../store/slice';
import { cspApplicationValidationRules } from '../utils';

export type UseCspApplication = {
  readonly onChangeAttribute: OnChangeObjectAttribute<SocialPackageApplicationForm>;
  readonly onValidate: () => boolean;
};

export type UseCspApplicationFormProps = {
  readonly guid: UUID;
};

const getState = () => store.getState().csp.application;

const useCspApplication = ({ guid }: UseCspApplicationFormProps): UseCspApplication => {
  const dispatch = useDispatch();

  const onChangeAttribute = useCallback<UseCspApplication['onChangeAttribute']>(
    (name, value) => {
      dispatch(
        cspApplicationSetFormAttribute({
          name,
          value,
        })
      );

      switch (name) {
        case 'withFamilyMembers':
          dispatch(
            cspApplicationSetFormAttribute({
              name: 'familyMembersCount',
              value: null,
            })
          );
          break;
        default:
          break;
      }

      dispatch(cspApplicationClearValidationResult(name));
    },
    [dispatch]
  );

  const onValidate = useCallback<UseCspApplication['onValidate']>(() => {
    const form = getState().form;
    const validationResult = validateObject(form, cspApplicationValidationRules);
    dispatch(cspApplicationSetValidationResult(validationResult.results));
    return validationResult.isValid;
  }, [dispatch]);

  useEffect(() => {
    dispatch(cspApplicationStartSession({ guid }));
  }, [dispatch, guid]);

  return {
    onChangeAttribute,
    onValidate,
  };
};

export default useCspApplication;
