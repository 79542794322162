import { createSelector } from '@reduxjs/toolkit';
import { bookingOfferApi } from 'data/api/bookingOffer';
import { RootState } from 'data/store/store';
import { BookingPriceRange, BookingServicesItem } from 'domain/model';
import {
  getAllBookingServicesArgs,
  getAvailableBookingServicesArgs,
  getBookingOffersListArgs,
  getBookingPriceRangeArgs,
} from 'features/offer/booking/list/cacheKeyArgs';
import { BookingOfferListSearchParams } from 'features/offer/booking/types';
import { bookingOffersDefaultParams } from '../../utils';

export const bookingOfferListGuidSelector = (store: RootState) => store.bookingOffer.list.guid;

export const bookingOfferListArgsSelector = (store: RootState) => store.bookingOffer.list.data;

export const bookingOfferListCountSelector = (store: RootState, searchParams: BookingOfferListSearchParams) => {
  const { data: args } = store.bookingOffer.list;

  if (!args) {
    return 0;
  }

  const offersListArgs = getBookingOffersListArgs(searchParams, args);

  const { data } = bookingOfferApi.endpoints.getBookingOfferList.select(offersListArgs)(store);

  return data?.data?.length ?? 0;
};

export const bookingOfferListIsErrorSelector = (store: RootState, searchParams: BookingOfferListSearchParams) => {
  const { data: args } = store.bookingOffer.list;

  if (!args) {
    return 0;
  }

  const offersListArgs = getBookingOffersListArgs(searchParams, args);

  const { isError } = bookingOfferApi.endpoints.getBookingOfferList.select(offersListArgs)(store);

  return isError;
};

export const bookingOfferListIsFetchingSelector = (store: RootState, searchParams: BookingOfferListSearchParams) => {
  const { data: args } = store.bookingOffer.list;

  const offersListArgs = getBookingOffersListArgs(searchParams, args);

  return args ? bookingOfferApi.endpoints.getBookingOfferList.select(offersListArgs)(store).isLoading : false;
};

let extremumPriceRangeCachedData: BookingPriceRange | null = null;
export const bookingOfferExtremumPriceRangeSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): BookingPriceRange => {
  const params = getBookingPriceRangeArgs(searchParams);

  const { data, status } = bookingOfferApi.endpoints.getBookingPriceRange.select(params)(store);

  if (status === 'fulfilled') {
    extremumPriceRangeCachedData = data ?? bookingOffersDefaultParams.priceRange;
  }

  if (status === 'uninitialized' || status === 'pending') {
    return extremumPriceRangeCachedData ?? bookingOffersDefaultParams.priceRange;
  }

  return data ?? bookingOffersDefaultParams.priceRange;
};

export const bookingOfferPriceRangeIsFetchingSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): boolean => {
  const params = getBookingPriceRangeArgs(searchParams);

  const { isLoading } = bookingOfferApi.endpoints.getBookingPriceRange.select(params)(store);

  return isLoading ?? false;
};

let cachedAvailableServices: BookingServicesItem[] = [];
export const bookingOfferAvailableServicesSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): BookingServicesItem[] => {
  const params = getAvailableBookingServicesArgs(searchParams);

  const { data, status } = bookingOfferApi.endpoints.getAvailableBookingServices.select(params)(store);

  if (status === 'fulfilled') {
    cachedAvailableServices = data ?? [];
  }

  if (status === 'uninitialized' || status === 'pending') {
    return cachedAvailableServices;
  }

  return data ?? [];
};

export const bookingOfferAvailableServicesIsFetchingSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): boolean => {
  const params = getAvailableBookingServicesArgs(searchParams);

  const { isLoading } = bookingOfferApi.endpoints.getAvailableBookingServices.select(params)(store);

  return isLoading ?? false;
};

let cachedAllServices: BookingServicesItem[] = [];
export const bookingOfferAllServicesSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): BookingServicesItem[] => {
  const params = getAllBookingServicesArgs(searchParams);
  const { data, status } = bookingOfferApi.endpoints.getAllBookingServices.select(params)(store);

  if (status === 'fulfilled') {
    cachedAllServices = data ?? [];
  }

  if (status === 'uninitialized' || status === 'pending') {
    return cachedAllServices;
  }

  return data ?? [];
};

export const bookingOfferAllServicesIsFetchingSelector = (
  store: RootState,
  searchParams: BookingOfferListSearchParams
): boolean => {
  const params = getAllBookingServicesArgs(searchParams);
  const { isLoading } = bookingOfferApi.endpoints.getAllBookingServices.select(params)(store);
  return isLoading ?? false;
};

export const makeBookingOfferUnionIsFetchingSelector = (searchParams: BookingOfferListSearchParams) =>
  createSelector(
    [
      (store: RootState) => bookingOfferPriceRangeIsFetchingSelector(store, searchParams),
      (store: RootState) => bookingOfferAvailableServicesIsFetchingSelector(store, searchParams),
      (store: RootState) => bookingOfferAllServicesIsFetchingSelector(store, searchParams),
    ],
    (...isFetchings: boolean[]) => {
      return isFetchings.some(isFetching => isFetching);
    }
  );

export const bookingOfferListNoDataSelector = createSelector(
  bookingOfferListCountSelector,
  bookingOfferListIsFetchingSelector,
  bookingOfferListIsErrorSelector,
  (count, isListFetching, isError) => {
    return !isError && !isListFetching && count === 0;
  }
);

export const bookingOfferListNameSelector = (store: RootState) => store.bookingOffer.list.data?.search?.name ?? null;
