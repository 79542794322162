import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { ValidationItemResult } from '../../../../utils/validation';

export type ChangeEmailDialogProps = {
  readonly label: string;
  readonly primaryText: string;
  readonly value: string;
  readonly isFetching: boolean;
  readonly validation?: ValidationItemResult;
  readonly onChange: (value: string) => void;
  readonly onClose?: () => void;
  readonly onSave: () => void;
};

export const ChangeEmailDialog = (props: ChangeEmailDialogProps) => {
  const { label, value, validation, isFetching, primaryText, onChange, onClose, onSave } = props;

  return (
    <MPConfirmDialog
      open
      title={label}
      maxWidth='xs'
      fullScreenBreakpoint='xs'
      closeToBackButtonInFullScreen={false}
      scroll='body'
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isFetching}
            onClick={onSave}
          >
            {primaryText}
            {isFetching && <ContentLoader />}
          </MPButton>
          <MPButton
            color='brand'
            size='large'
            variant='text'
            fullWidth
            disabled={isFetching}
            onClick={onClose}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
      onClose={onClose}
    >
      <MPFormInput
        label={label}
        value={value ?? ''}
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      />
    </MPConfirmDialog>
  );
};
