import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import Splitter from 'presentation/components/common/splitter';
import PartnerDeskContact from '../../../components/contact';
import PartnerDeskDetailsContactsLayout from './layout';
import { PartnerContactData } from 'domain/model';

type TradeOfferDetailsTabContactsProps = {
  readonly contacts: readonly PartnerContactData[];
  readonly description?: Nullable<string>;
  readonly descriptionTitle?: string;
};

const Layout = PartnerDeskDetailsContactsLayout;

export const PartnerDeskDetailsTabContacts = ({
  contacts,
  description,
  descriptionTitle,
}: TradeOfferDetailsTabContactsProps) => {
  return (
    <>
      <Layout>
        {contacts.map((contact, index) => (
          <MPGrid
            key={index}
            item
            xl={4}
            lg={6}
            sm={12}
          >
            <PartnerDeskContact contact={contact} />
            <Splitter size={2} />
          </MPGrid>
        ))}
      </Layout>
      {description && (
        <Layout>
          <MPGrid item>
            <Typography
              variant='subtitle1'
              mb={1.25}
            >
              {descriptionTitle}
            </Typography>
            <DangerouslyHtmlWrapper>
              <Typography variant='body1'>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
            </DangerouslyHtmlWrapper>
          </MPGrid>
        </Layout>
      )}
    </>
  );
};
