import { EOfferStatus } from 'domain/model';
import PromotionTypeText from 'presentation/features/offer/components/claim/obtainingMethod';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';

export const AspOfferPromotionTypeAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();

  if (!aspOffer) {
    return null;
  }

  const isVisible =
    (aspOffer.status === EOfferStatus.Active || aspOffer.status === EOfferStatus.Upcoming) &&
    !activation.isLastActivationPending;

  if (!isVisible || !aspOffer.promotionType) {
    return null;
  }

  return <PromotionTypeText promotionType={aspOffer.promotionType} />;
};
