import styled from '@emotion/styled';
import { css, filledInputClasses, selectClasses } from '@mui/material';
import { MPSelect } from 'presentation/theme/ui-kit/select';

export const Select = styled(MPSelect)(
  ({ theme }) =>
    css`
      min-height: 0;
      border-radius: ${theme.shape.borderRadius}px;

      & .${filledInputClasses.root} {
        background-color: transparent;
      }

      & .${selectClasses.select} {
        padding: ${theme.spacing(0.5, 4, 0.5, 1.5)};

        border-radius: ${theme.shape.borderRadius}px;
        background-color: ${theme.palette.secondary.light};
      }
    `
) as any;
