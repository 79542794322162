import { useSelector } from 'react-redux';
import { CspApplicationForm } from '../../components/applicationForm';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  cspApplicationFormSelector,
  cspApplicationHasApplicationSelector,
  cspApplicationValidationSelector,
} from '../store/selectors';

export const CspApplicationFormAdapter = () => {
  const handlers = useContextHandlers();

  const form = useSelector(cspApplicationFormSelector);
  const validation = useSelector(cspApplicationValidationSelector);
  const hasApplication = useSelector(cspApplicationHasApplicationSelector);

  if (hasApplication) {
    return null;
  }

  return (
    <CspApplicationForm
      form={form}
      validation={validation}
      onChangeAttribute={handlers.onChangeAttribute}
    />
  );
};
