import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PriceRangeFilter } from 'domain/model';
import { getProductOffersFilterRoute } from '../../entry';
import { productOfferEmptyFilter } from '../utils';

export type UseProductOfferFilterInLocation = {
  readonly onFilterApplyValues: (filter: PriceRangeFilter) => void;
  readonly onFilterClearValues: () => void;
};

export const useProductOfferFilterInLocation = (): UseProductOfferFilterInLocation => {
  const location = useLocation();
  const history = useHistory();

  const onFilterApplyValues = useCallback(
    (filter: PriceRangeFilter) => {
      history.replace(getProductOffersFilterRoute({ location, filter }));
    },
    [location, history]
  );

  const onFilterClearValues = useCallback(() => {
    history.replace(getProductOffersFilterRoute({ location, filter: productOfferEmptyFilter }));
  }, [location, history]);

  return {
    onFilterApplyValues,
    onFilterClearValues,
  };
};
