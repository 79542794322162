import { FC, ReactNode, useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { SlideContentWrapper } from './controls';

type SwipeStepperProps = {
  readonly activeIndex: number;
  readonly steps: ReactNode[];
};

export const SwipeStepper: FC<SwipeStepperProps> = ({ activeIndex, steps }) => {
  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex, swiper]);

  return (
    <Swiper
      slidesPerView={1}
      allowTouchMove={false}
      noSwiping
      loop={false}
      freeMode
      onSwiper={setSwiper}
    >
      {steps.map((step, index) => (
        <SwiperSlide key={index}>
          {index <= activeIndex && <SlideContentWrapper active={index < activeIndex}>{step}</SlideContentWrapper>}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
