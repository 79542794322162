import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const deleteProfileEventType = 'DeleteProfile';

export type DeleteProfileEventPayload = {};

//факт удаления аккаунта в ответе
export type DeleteProfileEventResult = boolean;

export type DeleteProfileEvent = BusinessEvent<DeleteProfileEventPayload, DeleteProfileEventResult>;

export type DeleteProfileEventProps = DisplayEventComponentProps<DeleteProfileEventPayload, DeleteProfileEventResult>;
