import { EBalanceType, ESocialPackageStatus } from 'domain/model';
import useCurrentUserBalances from 'features/user/current/hooks/useBalances';
import { UserFinanceBalanceAdapter } from './balance';

const isDisplayDisabledBalance = true;

export const UserFinanceBalancesAdapter = () => {
  const balancesData = useCurrentUserBalances();

  if (!balancesData) {
    return null;
  }

  const filteredBalancesData = isDisplayDisabledBalance
    ? balancesData
    : balancesData.filter(item => {
        return !(item.type === EBalanceType.CSP && item.source.status === ESocialPackageStatus.Upcoming);
      });

  return filteredBalancesData.map((balance, index) => (
    <UserFinanceBalanceAdapter
      key={index}
      balance={balance}
    />
  ));
};
