import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';
import { gridClasses } from '@mui/material/Grid';

export const ListCounter = styled(Typography)`
  position: absolute;
  left: 0;
`;

export const NumberedList = styled(MPGrid)`
  .${gridClasses.item} {
    position: relative;
  }

  &.${gridClasses['spacing-xs-2']} {
    > .${gridClasses.item} {
      padding-left: ${p => p.theme.spacing(5)};
    }

    ${ListCounter} {
      margin-left: ${p => p.theme.spacing(2)};
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    &.${gridClasses['spacing-xs-1']} {
      > .${gridClasses.item} {
        padding-left: ${p => p.theme.spacing(3)};
      }

      ${ListCounter} {
        margin-left: ${p => p.theme.spacing(1)};
      }
    }
  }
`;
