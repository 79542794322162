import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    height: ${theme.spacing(4)};

    ${theme.breakpoints.down('md')} {
      height: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      height: ${theme.spacing(2.5)};
    }

    ${theme.breakpoints.down('xs')} {
      height: ${theme.spacing(2)};
    }
  `
);
