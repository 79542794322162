import styled from '@emotion/styled';
import {
  css,
  dialogClasses,
  DialogContent,
  DialogContentProps,
  Fab,
  Paper,
  paperClasses,
  PaperProps,
} from '@mui/material';
import { forwardRef } from 'react';

export const DialogWrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)(
  () => css`
    &.${paperClasses.root}.${dialogClasses.paper} {
      max-width: 100%;
      width: 100%;
      height: 100vh;
      max-height: 100vh;

      padding: 0;
      margin: 0;

      border-radius: 0;
      border: 0;
    }
  `
);

export const dialogVerticalSpacingOffset = 12.5;

export const StyledDialogContent = styled(
  forwardRef<any, DialogContentProps>((props, ref) => (
    <DialogContent
      {...props}
      ref={ref}
    />
  ))
)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(dialogVerticalSpacingOffset)} ${theme.spacing(4)};
    }

    ${theme.breakpoints.up('lg')} {
      padding: ${theme.spacing(dialogVerticalSpacingOffset)} ${theme.spacing(5)};
    }
  `
);

export const MediaPreviewDialogCloseButton = styled(Fab)(
  ({ theme }) => css`
    position: absolute;
    background-color: hsla(0, 0%, 100%, 0.6);
    bottom: ${theme.spacing(8)};
    box-shadow: none;
    left: 50%;
    transform: translateX(-50%);

    ${theme.breakpoints.up('md')} {
      bottom: initial;
      left: initial;
      transform: initial;
      top: ${theme.spacing(2)};
      box-shadow: 0px 4px 20px 0px hsla(220, 13%, 50%, 0.2);
      right: ${theme.spacing(2)};
    }
  `
);
