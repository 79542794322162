import { BookingSlotPeriod } from 'domain/model';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import BookingOfferModal from '../../components/modal';
import { BookingCspPeriodRestrictions } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferDetailsModalSelector } from '../store/selectors';

const BookingOfferDetailsModalsAdapter: FC = () => {
  const modalItem = useSelector(bookingOfferDetailsModalSelector);

  const { onApplyModalChanges, onCalculateCspPeriodRestrictions } = useContextHandlers();

  const priceItem = modalItem?.priceUnit?.priceItems?.find(({ id }) => id === modalItem.orderItem?.priceItem?.id);

  const onCalculateCspPeriodRestrictionsInternal = (
    slots: BookingSlotPeriod[]
  ): Nullable<BookingCspPeriodRestrictions> =>
    priceItem?.unit && modalItem?.priceUnit
      ? onCalculateCspPeriodRestrictions(modalItem.priceUnit, priceItem.unit, slots)
      : null;

  if (!modalItem) {
    return null;
  }

  return (
    <BookingOfferModal
      {...modalItem}
      isVisible={!!modalItem}
      onClose={onApplyModalChanges}
      onCalculateCspPeriodRestrictions={onCalculateCspPeriodRestrictionsInternal}
    />
  );
};

export default BookingOfferDetailsModalsAdapter;
