import { createSelector } from '@reduxjs/toolkit';
import { tradeOfferApi } from 'data/api/tradeOffer';
import { RootState } from 'data/store/store';
import { ETradeOfferSortType } from 'domain/model';

export const tradeOfferListGuidSelector = (store: RootState) => store.tradeOffer.list.guid;

export const tradeOfferListSelector = (store: RootState) => store.tradeOffer.list;

export const tradeOfferListCommonArgsSelector = (store: RootState) => store.tradeOffer.list.common;

export const tradeOfferListUpcomingArgsSelector = (store: RootState) => store.tradeOffer.list.upcoming;

export const tradeOfferListNameSelector = (store: RootState) => store.tradeOffer.list.common?.search?.name ?? null;
export const tradeOfferListSortSelector = (store: RootState) =>
  (store.tradeOffer.list.common?.sort ?? null) as Nullable<ETradeOfferSortType[]>;
export const tradeOfferListCategoryIdSelector = (store: RootState) =>
  store.tradeOffer.list.common?.search?.categories?.[0] ?? null;

export const tradeOfferListSearchIsEmptySelector = (store: RootState) =>
  !store.tradeOffer.list.common?.search?.name && !store.tradeOffer.list.common?.search?.categories?.length;

export const tradeOfferListSearchByNameIsEmptySelector = (store: RootState) =>
  !store.tradeOffer.list.common?.search?.name;

export const tradeOfferListCommonCountSelector = (store: RootState) => {
  const args = store.tradeOffer.list.common;

  if (!args) {
    return 0;
  }

  const { data } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const tradeOfferListUpcomingCountSelector = (store: RootState) => {
  const args = store.tradeOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { data } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};

export const tradeOfferListCountSelector = createSelector(
  tradeOfferListCommonCountSelector,
  tradeOfferListUpcomingCountSelector,
  (commonCount, upcomingCount) => commonCount + upcomingCount
);

export const tradeOfferListCommonIsErrorSelector = (store: RootState) => {
  const args = store.tradeOffer.list.common;

  if (!args) {
    return 0;
  }

  const { isError } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return isError;
};

export const tradeOfferListUpcomingIsErrorSelector = (store: RootState) => {
  const args = store.tradeOffer.list.upcoming;

  if (!args) {
    return 0;
  }

  const { isError } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return isError;
};

export const tradeOfferListIsErrorSelector = createSelector(
  tradeOfferListCommonIsErrorSelector,
  tradeOfferListUpcomingIsErrorSelector,
  (commonIsError, upcomingIsError) => commonIsError || upcomingIsError
);

export const tradeOfferListCommonIsFetchingSelector = (store: RootState) => {
  const args = store.tradeOffer.list.common;
  return args ? tradeOfferApi.endpoints.getTradeOfferList.select(args)(store).isLoading : false;
};

export const tradeOfferListUpcomingIsFetchingSelector = (store: RootState) => {
  const args = store.tradeOffer.list.upcoming;
  return args ? tradeOfferApi.endpoints.getTradeOfferList.select(args)(store).isLoading : false;
};

export const tradeOfferListIsFetchingSelector = createSelector(
  tradeOfferListCommonIsFetchingSelector,
  tradeOfferListUpcomingIsFetchingSelector,
  (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching
);

export const tradeOfferListIsNewFetchingSelector = createSelector(
  tradeOfferListIsFetchingSelector,
  (store: RootState) => store.tradeOffer.list.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

export const tradeOfferListIsNewSearchFetchingSelector = createSelector(
  tradeOfferListIsFetchingSelector,
  (store: RootState) => store.tradeOffer.list.isNewSearchFetching,
  (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching
);

export const tradeOfferListNoDataSelector = createSelector(
  tradeOfferListCountSelector,
  tradeOfferListIsFetchingSelector,
  tradeOfferListIsErrorSelector,
  (count, isFetching, isError) => count === 0 && !isFetching && !isError
);
