import styled from '@emotion/styled';
import { Badge as MuiBadge, badgeClasses, css } from '@mui/material';

export const Badge = styled(MuiBadge)(
  () => css`
    .${badgeClasses.badge} {
      bottom: 0;
      right: 0;
      padding: 0;

      transform: none;
      transform-origin: unset;
    }
  `
);
