import { Address } from 'domain/model';
import { EAddressLevel } from 'domain/model';
import { AddressHelper } from 'utils/address';
import AddressSingleSelector, { AddressSingleSelectorProps } from './index';

export type AddressCitySingleSelectorProps = Omit<
  AddressSingleSelectorProps,
  'onlyValidPostalCode' | 'level' | 'fromLevel' | 'toLevel'
>;

const getOptionLabelDefault = (address: Address) => new AddressHelper(address).getLocalityFullPath();

const AddressCitySingleSelector = (props: AddressCitySingleSelectorProps) => {
  return (
    <AddressSingleSelector
      {...props}
      noOptionsText='Введите название'
      getOptionLabel={props.getOptionLabel ?? getOptionLabelDefault}
      level={EAddressLevel.Value5}
    />
  );
};

export default AddressCitySingleSelector;
