import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { aspOfferRelatedListIsEmptySelector } from '../store/selectors';

export const AspOffersRelatedHeaderAdapter = () => {
  const isEmpty = useSelector(aspOfferRelatedListIsEmptySelector);

  return (
    !isEmpty && (
      <Typography
        variant='h3'
        component='h4'
      >
        Похожие предложения
      </Typography>
    )
  );
};
