import { Location } from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import CspApplicationScreen from 'screen/cspApplication';

const root = rootRouting.csp;

const routing = {
  root,
  application: `${root}/application`,
};

export const getCspApplicationRoute = (): Location => {
  const params = new URLSearchParams();
  return {
    pathname: routing.application,
    search: params.toString(),
    state: undefined,
    hash: '',
  };
};

const CspEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.application}
        component={CspApplicationScreen}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default CspEntry;
