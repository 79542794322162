import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import { css } from '@mui/material';

export const SuccessFeedBackIcon = styled(CheckIcon)(
  ({ theme }) => css`
    color: ${theme.palette.success.contrastText};
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    width: 64px;
    height: 64px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    margin-top: ${theme.spacing(1.5)};

    border-radius: 50%;
    background-color: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
  `
);
