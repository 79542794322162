import styled from '@emotion/styled';
import { css } from '@mui/material';

export const DetailsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3, 1.5, 5)};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(3, 2, 5)};
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(3, 5, 5)};
    }
  `
);
