import { BookingOfferListSearchParams } from '@/presentation/features/offer/booking/types';
import { AxiosResponse } from 'axios';
import { CorpOfferCategoriesUsedRequest } from 'data/api/corpOffer';
import {
  BookingOffer,
  BookingOfferShort,
  BookingOrder,
  BookingOrderRequest,
  BookingPriceRange,
  BookingServicesItem,
  Category,
  CategoryTree,
  EOfferStatus,
  Pageable,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { BookingOffersListPriceRangeProps, BookingOffersListServicesProps } from '../openApi/booking';
import { BookingOffersRequestProps } from '../openApi/user/bookingOffer';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import {
  api,
  EBookingOfferServicesTag,
  EOrderServicesTag,
  EUserServicesTag,
  OffersCountRequest,
  OffersCountResponse,
} from './index';

const defaultBookingOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const bookingOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getBookingOfferCategories: builder.query<CategoryTree, {}>({
      transformResponse: (response: AxiosResponse<Category[]>) => {
        // Возвращает только категории 1 уровня
        return Array.isArray(response.data)
          ? categoriesBuildTree(response.data.filter(({ parentId }) => !parentId))
          : ([] as CategoryTree);
      },
      query: () => {
        return apiDefinition.user.booking.categories({});
      },
    }),

    getBookingOfferCategoriesUsed: builder.query<Category[], CorpOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse<Category[]>) => response.data,
      query: ({ name, onlyLeafCategories }) => {
        return apiDefinition.user.booking.categoriesUsed({
          query: name,
          statuses: defaultBookingOfferValidStatuses,
          onlyLeafCategories,
        });
      },
    }),

    getBookingOfferList: builder.query<Pageable<BookingOfferShort>, BookingOffersRequestProps>({
      transformResponse: (response: AxiosResponse): Pageable<BookingOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, ...bookingProps }) => {
        const { name, categories, partnerId } = search;
        return apiDefinition.user.booking.all({
          page: 1,
          pageSize: page * pageSize,
          query: name,
          categories,
          partnerId,
          ...bookingProps,
        });
      },
      providesTags: [EBookingOfferServicesTag.List],
    }),

    getBookingOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        return apiDefinition.user.booking.count({
          query: search?.name,
          minPrice: null,
          maxPrice: null,
        });
      },
    }),

    getBookingOfferDetails: builder.query<BookingOffer, UUID>({
      query: id => apiDefinition.user.booking.one(id),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, id) => [
        { type: EBookingOfferServicesTag.Details, id },
        EBookingOfferServicesTag.Details,
      ],
    }),

    createOrder: builder.mutation<BookingOrder, BookingOrderRequest>({
      query: order => apiDefinition.user.booking.createOrder(order),
      transformResponse: (response: AxiosResponse) => response.data[0],
      invalidatesTags: [EUserServicesTag.OrdersCount, EOrderServicesTag.Orders],
    }),
    // extremum price range
    getBookingPriceRange: builder.query<BookingPriceRange, BookingOffersListPriceRangeProps>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: props => {
        return apiDefinition.booking.priceRange(props);
      },
    }),
    getAvailableBookingServices: builder.query<BookingServicesItem[], BookingOffersListServicesProps>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: props => {
        return apiDefinition.booking.serviceCategories(props);
      },
      keepUnusedDataFor: 0,
    }),
    getAllBookingServices: builder.query<
      BookingServicesItem[],
      Pick<BookingOfferListSearchParams, 'categoryId' | 'name'>
    >({
      transformResponse: (response: AxiosResponse) => response.data,
      query: props => {
        const params: BookingOffersListServicesProps = {
          categoryId: props.categoryId ?? '',
          query: props.name,
          minPrice: null,
          maxPrice: null,
        };

        return apiDefinition.booking.serviceCategories(params);
      },
    }),
  }),
});

export const {
  useGetBookingOfferListQuery,
  useGetBookingOffersCountQuery,
  useGetBookingOfferCategoriesQuery,
  useGetBookingOfferCategoriesUsedQuery,
  useGetBookingOfferDetailsQuery,
  useGetBookingPriceRangeQuery,
  useCreateOrderMutation,
  useGetAvailableBookingServicesQuery,
  useGetAllBookingServicesQuery,
} = bookingOfferApi;
