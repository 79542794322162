import { EClientOfferType } from 'domain/model';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { EAppFeature, ESearchUrlParam } from 'presentation/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { SearchResultsLocationState } from '../../features/search/entry';
import SearchResultsContainer from '../../features/search/offers/container';
import useTechConfig from '../../hooks/useTechConfig';

const SearchResultsScreen = () => {
  const location = useLocation<Nullable<SearchResultsLocationState>>();
  const { hasFeature } = useTechConfig();
  const { isCorpUser } = useAuthUser();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get(ESearchUrlParam.Query);

  const offerType = useMemo<EClientOfferType[]>(() => {
    const result: EClientOfferType[] = [];

    if (hasFeature(EAppFeature.Trade)) {
      result.push(EClientOfferType.TradeOffer);
    }
    if (hasFeature(EAppFeature.Asp)) {
      result.push(EClientOfferType.AspOffer);
    }
    if (hasFeature(EAppFeature.Booking)) {
      result.push(EClientOfferType.BookingOffer);
    }
    if (hasFeature(EAppFeature.Product)) {
      result.push(EClientOfferType.ProductOffer);
    }
    if (isCorpUser) {
      result.push(EClientOfferType.CorpOffer);
    }

    return result;
  }, [hasFeature, isCorpUser]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <SearchResultsContainer
            guid={guid}
            query={query}
            offerType={offerType}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default SearchResultsScreen;
