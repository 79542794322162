import moment from 'moment-timezone';
import { BookingSlotPeriod, EDateFormat, EOrderByDateType } from 'domain/model';
import { BookingCartItem } from '../../details/types';
import { toRubCurrency } from '../../../../../utils/currency';

export const calcPrice = (unitType: Nullable<EOrderByDateType>, price: number, slots: BookingSlotPeriod[]) => {
  if (unitType === EOrderByDateType.Period) {
    return slots.reduce((total, { startDate, endDate, qty }) => {
      const diff = moment(endDate, EDateFormat.Server).diff(moment(startDate, EDateFormat.Server), 'days');
      return total + diff * price * qty;
    }, 0);
  }

  if (unitType === EOrderByDateType.Days) {
    return slots.reduce((total, { qty }) => total + qty * price, 0);
  }

  if (unitType === EOrderByDateType.None) {
    return slots[0].qty * price;
  }

  return 0;
};

export const calcTotalPrice = (cart: Nullable<BookingCartItem[]>) =>
  cart
    ? toRubCurrency(
        cart.reduce((total, { priceUnit, orderItem }) => {
          const item = priceUnit.priceItems?.find(({ id }) => id === orderItem.priceItem.id);
          return (
            total +
            (item
              ? calcPrice(
                  priceUnit.orderByDateType,
                  item.price ?? 0,
                  priceUnit.orderByDateType === EOrderByDateType.None
                    ? [{ qty: orderItem.qty } as BookingSlotPeriod]
                    : orderItem.slots
                )
              : 0)
          );
        }, 0)
      )
    : '';
