import { ValidationItemResult } from 'presentationUtils/validation';
import { FC } from 'react';
import { CodeInput } from './controls';

type ConfirmCodeEventInputAdapterProps = {
  readonly value: string;
  readonly validation?: Nullable<ValidationItemResult>;
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
};

export const ConfirmCodeEventInputAdapter: FC<ConfirmCodeEventInputAdapterProps> = ({
  value,
  validation,
  disabled,
  onChange,
}) => {
  return (
    <CodeInput
      label='Код подтверждения'
      value={value}
      type='number'
      error={!!validation?.hasError}
      helperText={validation?.message}
      disabled={disabled}
      onChange={event => onChange(event.target.value)}
    />
  );
};
