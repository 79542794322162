import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import addressApi from 'data/openApi/address';
import { AppThunkAPIConfig } from 'data/store/store';
import { Fetchable, fetchableDefault } from 'data/store/types';
import { Address } from 'domain/model';
import { EAddressLevel } from 'domain/model';

export const locationDetectorCityFetch = createAsyncThunk<Address, undefined, AppThunkAPIConfig<Nullable<Address>>>(
  'locationDetector/city/fetch',
  async (props, { signal, rejectWithValue }) => {
    try {
      const { data } = await addressApi.addressByIpQuery({ signal });
      return data;
    } catch (e: any) {
      console.warn(e.response);
      const { data } = await addressApi.addressQuery({ query: 'Москва', level: EAddressLevel.Value5, signal });
      return rejectWithValue(data?.[0] ?? null);
    }
  }
);

export type LocationDetectorState = {
  readonly city: Fetchable & {
    readonly data: Nullable<Address>;
  };
};

type Reducer<T = undefined> = CaseReducer<LocationDetectorState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<LocationDetectorState> & {
  locationDetectorClear: Reducer;
};

const slice = createSlice<LocationDetectorState, Reducers, 'locationDetector'>({
  name: 'locationDetector',
  initialState: {
    city: {
      ...fetchableDefault,
      data: null,
    },
  },
  reducers: {
    locationDetectorClear(state) {
      state.city = {
        ...fetchableDefault,
        data: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(locationDetectorCityFetch.pending, state => {
        state.city.isFetching = true;
        state.city.isFetched = false;
        state.city.isFailed = false;
        state.city.data = null;
      })
      .addCase(locationDetectorCityFetch.fulfilled, (state, { payload }) => {
        state.city.isFetching = false;
        state.city.isFetched = true;
        state.city.isFailed = false;
        state.city.data = payload;
      })
      .addCase(locationDetectorCityFetch.rejected, (state, { meta, payload }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.city.isFetching = false;
          state.city.isFetched = false;
          state.city.isFailed = true;
        } else {
          state.city.isFetching = false;
          state.city.isFetched = false;
          state.city.isFailed = false;
        }

        state.city.data = payload ?? null;
      });
  },
});

export const { locationDetectorClear } = slice.actions;

export default slice.reducer;
