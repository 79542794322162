import { Typography, TypographyProps } from '@mui/material';
import { currencySymbols } from 'locale';
import { KeyboardEvent } from 'react';
import { toCurrency } from '../../../../utils/currency';
import { InputNumber, RangeInputsWrapper } from './controls';

const styles: TypographyProps = { variant: 'body2', color: 'secondary' };

const inputFromProps = {
  startAdornment: <Typography {...styles}>от</Typography>,
  endAdornment: <Typography {...styles}>{currencySymbols.RU}</Typography>,
};

const inputToProps = {
  startAdornment: <Typography {...styles}>до</Typography>,
  endAdornment: inputFromProps.endAdornment,
};

type PriceRangeProps = {
  readonly isDisabled?: boolean;
  //значение с
  readonly valueFrom: number;
  //значение по
  readonly valueTo: number;
  //доступный диапазон с
  readonly from?: number;
  //доступный диапазон по
  readonly to?: number;
  readonly onChange: (from: number, to: number) => void;
  readonly onBlur: VoidFunction;
};

export const PriceRange: React.FCC<PriceRangeProps> = props => {
  const { isDisabled, from, to, valueFrom, valueTo, onChange, onBlur } = props;

  const onChangeMinValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value);
    if ((from && inputValue >= from) || !from) onChange(inputValue, valueTo);
  };

  const onChangeMaxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value);
    if ((to && inputValue <= to) || !to) onChange(valueFrom, inputValue);
  };

  const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };

  return (
    <RangeInputsWrapper>
      <InputNumber
        disabled={isDisabled}
        value={toCurrency(valueFrom)}
        size='small'
        slotProps={{
          input: {
            ...inputFromProps,
          },
        }}
        placeholder={from ? `${toCurrency(from)}` : undefined}
        onChange={onChangeMinValue}
        onBlur={onBlur}
        onKeyUp={handlePressEnter}
      />
      <InputNumber
        disabled={isDisabled}
        value={toCurrency(valueTo)}
        size='small'
        slotProps={{
          input: {
            ...inputToProps,
          },
        }}
        placeholder={to ? `${toCurrency(to)}` : undefined}
        onChange={onChangeMaxValue}
        onKeyUp={handlePressEnter}
        onBlur={onBlur}
      />
    </RangeInputsWrapper>
  );
};
