import styled from '@emotion/styled';
import { css, FormControlLabel, formControlLabelClasses, typographyClasses } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';

export const SwitchControl = styled(FormControlLabel)(
  () => css`
    &.${formControlLabelClasses.labelPlacementStart} {
      margin-left: 0;
      display: flex;
    }

    .${typographyClasses.root} {
      flex: 1;
    }
  `
);

export const SwitchControlGroup = styled(MPGrid)(
  () => css`
    display: inline-flex;
  `
);
