import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { useDeleteCurrentUserProfileMutation } from 'api/user';
import { AxiosResponse } from 'axios';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import { DeleteProfileResponse, EDeleteProfileError } from 'domain/model';
import { createEvent as createEventProfileDeleted } from 'features/user/events/profileDeleted';
import { createEvent as createEventProfileDeleteForbidden } from 'features/user/events/profileDeleteForbidden';
import { FCC, useCallback, useEffect, useState } from 'react';
import { TextAdapter } from './adapters';
import { DeleteProfileEventProps } from './types';
import { ConfirmCodeInnerContainer } from 'features/user/events/confirmCode/innerContainer';
import { deleteProfileEventTitle } from 'features/user/events/deleteProfile/index';

export const DeleteProfileEventContainer: FCC<DeleteProfileEventProps> = ({ onClose, onNext }) => {
  const { publishTop } = useBusinessEventBus();

  const [source, setSource] = useState<Nullable<DeleteProfileResponse>>(null);

  const [deleteCurrentUserProfile, { error }] = useDeleteCurrentUserProfileMutation();

  const onCodeConfirmed = useCallback(() => {
    setSource(null);
    publishTop(createEventProfileDeleted({}));
    onNext();
  }, [onNext, publishTop]);

  const onGetCode = useCallback(() => {
    return deleteCurrentUserProfile()
      .unwrap()
      .then(setSource)
      .catch(error => error);
  }, [deleteCurrentUserProfile]);

  useEffect(() => {
    if (error) {
      if ((error as AxiosResponse<ServerErrorResponse>).data.code === EDeleteProfileError.UserProfileDeleteForbidden) {
        publishTop(createEventProfileDeleteForbidden({}));
        onNext();
      } else {
        ErrorHandler.handleHttpError(error);
        onClose?.();
      }
    }
  }, [error, onClose, onNext, publishTop]);

  return (
    <ConfirmCodeInnerContainer
      title={deleteProfileEventTitle}
      text={<TextAdapter />}
      source={source}
      onSuccess={onCodeConfirmed}
      onGetNewCode={onGetCode}
    />
  );
};
