import { SearchOffersRequest, useSearchOffersQuery } from 'data/api/search';
import ErrorHandler from 'data/network/errorHandler';
import { BookingOfferShort, CorpOfferShort, EOfferShortDiscriminator, TradeOfferShort } from 'domain/model';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { ERenderSource } from 'presentation/types';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../auth/provider/useAuth';
import OfferListItemContainer from '../../offer/listItem/container';
import { searchOffersIsFetchingSelector, searchOffersIsNewFetchingSelector } from './store/selectors';

type SearchOffersDataContainerProps = {
  readonly guid: UUID;
  readonly args: SearchOffersRequest;
  readonly onLoadMore: () => void;
  readonly onShowCard: (offer: TradeOfferShort | CorpOfferShort | BookingOfferShort) => void;
};

const SearchOffersDataContainer = (props: SearchOffersDataContainerProps) => {
  const { guid, args, onLoadMore, onShowCard } = props;

  const { isAuthenticated } = useAuth();
  const isFetching = useSelector(searchOffersIsFetchingSelector);
  const isNewFetching = useSelector(searchOffersIsNewFetchingSelector);

  const { data, error, isSuccess } = useSearchOffersQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!offers && offers.length > 0;
  const isMoreFetching = !!offers && isFetching;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      <CardPageableList
        span={3}
        lgSpan={4}
        totalCount={totalCount}
        disableLoadMore={isMoreFetching}
        onLoadMore={onLoadMore}
      >
        {offers.map(offer => (
          <Fragment key={offer.id}>
            {offer.discriminator === EOfferShortDiscriminator.Trade && (
              <InView
                active={isSuccess && !isFetching}
                sessionKey={sessionKey}
                data={offer}
                onShow={onShowCard}
              >
                <OfferListItemContainer
                  key={offer.id}
                  offer={offer}
                  favorite={
                    isAuthenticated && {
                      guid,
                    }
                  }
                  renderFrom={ERenderSource.OfferSearch}
                />
              </InView>
            )}
            {offer.discriminator === EOfferShortDiscriminator.Corp && (
              <InView
                active={isSuccess && !isFetching}
                sessionKey={sessionKey}
                data={offer}
                onShow={onShowCard}
              >
                <OfferListItemContainer
                  key={offer.id}
                  offer={offer}
                  favorite={
                    isAuthenticated && {
                      guid,
                    }
                  }
                  renderFrom={ERenderSource.OfferSearch}
                />
              </InView>
            )}
            {offer.discriminator === EOfferShortDiscriminator.Booking && (
              <InView
                active={isSuccess && !isFetching}
                sessionKey={sessionKey}
                data={offer}
                onShow={onShowCard}
              >
                <OfferListItemContainer
                  key={offer.id}
                  offer={offer}
                  favorite={
                    isAuthenticated && {
                      guid,
                    }
                  }
                  renderFrom={ERenderSource.OfferSearch}
                />
              </InView>
            )}
            {offer.discriminator === EOfferShortDiscriminator.Product && <div>товар, не поддерживается</div>}
          </Fragment>
        ))}
      </CardPageableList>
    </div>
  );
};

export default SearchOffersDataContainer;
