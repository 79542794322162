import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { useCallback } from 'react';
import { AppNotificationsReceiverCallbackProps, ENotificationMessageType } from '../../../notification/types';
import useNotificationSocket from '../../../notification/useNotificationSocket';
import { CorpOfferReceivedActivationEventPayload, createEvent } from '../events/receivedActivation';

const CorpOffersNotificationsReceiver = () => {
  const { publish } = useBusinessEventBus<CorpOfferReceivedActivationEventPayload>();

  const onMessageReceive = useCallback<AppNotificationsReceiverCallbackProps>(
    msg => {
      switch (msg.type) {
        case ENotificationMessageType.OfferActivationChangedMessage:
          publish(
            createEvent({
              caption: msg.title,
              content: msg.description,
            })
          );
          return;
        default:
          return;
      }
    },
    [publish]
  );

  useNotificationSocket({ onMessageReceive });

  return null;
};

export default CorpOffersNotificationsReceiver;
