import { Category } from 'domain/model';
import { ECatalogTab } from 'presentation/features/catalog/types';
import React from 'react';

type SelectCategory = (category: Category) => void;

export type CatalogContextValue = {
  readonly onClose: () => void;
  readonly onChangeTab: (tab: ECatalogTab) => void;
  readonly onSelectTradeOffersCategory: SelectCategory;
  readonly onSelectAspOffersCategory: SelectCategory;
  readonly onSelectCorpOffersCategory: SelectCategory;
  readonly onSelectProductOffersCategory: SelectCategory;
  readonly onSelectBookingOffersCategory: SelectCategory;
};

export const CatalogContext = React.createContext<CatalogContextValue>({} as CatalogContextValue);
