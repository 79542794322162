import { Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useAuth } from 'features/auth/provider/useAuth';
import useEffectAfterMount from 'hooks/useEffectAfterMount';
import useHistoryExtensions from 'hooks/useHistoryExtensions';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { getHomepageRoute } from 'routing';
import { MPButton } from 'theme/ui-kit/button';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';
import { NeedLoginEventProps } from './types';

export const NeedLoginEventContainer: FC<NeedLoginEventProps> = ({ onNext }) => {
  const { login } = useAuth();
  const { location } = useHistory();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onCloseInternal: DialogProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    gotoPrevIndependentLocation(getHomepageRoute());
  };

  useEffectAfterMount(() => {
    onNext();
    //не добавляем onNext в зависимости, иначе может криво работать
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MPConfirmDialog
      title='Войдите в профиль'
      open
      maxWidth='xs'
      disableEscapeKeyDown
      onClose={onCloseInternal}
      buttons={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          onClick={login}
        >
          Войти
        </MPButton>
      }
    >
      <Typography>Авторизируйтесь, чтобы пользоваться всеми функциями Витрины</Typography>
    </MPConfirmDialog>
  );
};
