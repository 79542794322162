import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EDateTimeFormat } from 'domain/model';
import { utcToLocalTimeZone } from 'presentationUtils/date';
import { getCorpOfferPromotionLabel } from '../../../../../utils';
import useCorpOfferClaimContext from '../../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';

type PromotionTypeTextProps = {
  readonly promotionType: ECorpOfferPromotionType;
};

const PromotionTypeText = ({ promotionType }: PromotionTypeTextProps) => {
  return (
    <Typography
      variant='body2'
      color='black'
    >
      {getCorpOfferPromotionLabel(promotionType)}
    </Typography>
  );
};

const ClaimPromotionDateActivateNow = () => {
  return (
    <Typography
      variant='body2'
      color='textSuccess'
    >
      получен только что
    </Typography>
  );
};

type TradeOfferClaimPromotionDateProps = {
  readonly appointmentDate: string;
};

const ClaimPromotionDate = (props: TradeOfferClaimPromotionDateProps) => {
  const { appointmentDate } = props;
  return (
    <Typography variant='body2'>
      от {utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}
    </Typography>
  );
};

const CorpOfferPromotionTypeAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferClaimContext();

  if (!corpOffer) {
    return null;
  }

  return (
    <Wrapper>
      {corpOffer.promotionType && <PromotionTypeText promotionType={corpOffer.promotionType} />}
      {isActivationReceivedNow && <ClaimPromotionDateActivateNow />}
      {!isActivationReceivedNow && activation.lastActivation?.appointmentDate && (
        <ClaimPromotionDate appointmentDate={activation.lastActivation.appointmentDate} />
      )}
    </Wrapper>
  );
};

export default CorpOfferPromotionTypeAdapter;
