import { skipToken } from '@reduxjs/toolkit/query';
import { useGetSocialPackagesListQuery } from 'api/user';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import useTechConfig from 'hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';

export const useSocialPackages = () => {
  const { isCorpUser, user } = useAuthUser();
  const { hasFeature } = useTechConfig();
  const skipGetCspBalanceRequest =
    !isCorpUser || !user?.pernr || !user.hasRzdSocialPackage || !hasFeature(EAppFeature.Csp);

  return useGetSocialPackagesListQuery(skipGetCspBalanceRequest ? skipToken : undefined);
};
