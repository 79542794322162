import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import { UserAvatar } from 'presentation/components/common/avatars';
import { getPersonFullName } from 'presentation/utils';
import { FC, useState } from 'react';
import { MPChip } from 'theme/ui-kit/chip';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import { UserProfileEditAttributeCommonInfo } from '../../../components/attributeEdit/commonInfo';
import UserProfileViewAttribute from '../../../components/attributeView';
import { getUserGenderName } from '../../../utils';
import { useUserProfileCommonAttributesEdit } from '../hooks/useCommonAttributesEdit';
import { UserProfileDataAdapterProps } from './types';

export const UserProfileCommonAdapter: FC<UserProfileDataAdapterProps> = ({ user }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { values, validation, isUpdating, update, reset, onChange } = useUserProfileCommonAttributesEdit({ user });

  const { isCorpUser } = useAuthUser();

  const showEdit = () => setEditMode(true);

  const fullName = getPersonFullName(user);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const onUpdateInternal = () => {
    update().then(result => result && hideEdit());
  };

  const fullNameNode = fullName ? (
    <Typography
      variant='body1'
      color='text.primary'
    >
      {fullName}
    </Typography>
  ) : (
    <Typography
      variant='body1'
      color='text.secondary'
    >
      ФИО
    </Typography>
  );

  const commonInfoNode = (
    <Typography
      component='div'
      variant='body2'
      color='text.secondary'
    >
      {user.gender ? (
        <>
          {getUserGenderName(user.gender)}
          <br />
        </>
      ) : (
        <>
          Пол
          <br />
        </>
      )}
      {user.birthDate ? `${moment(user.birthDate).format(EDateFormat.Human)} г.` : 'Дата рождения'}
    </Typography>
  );

  const avatarNode = (
    <UserAvatar
      user={user}
      variant='rounded'
      size='large'
    />
  );

  const infoNode = (!fullName || !user.gender || !user.birthDate) && (
    <MPChip
      variant='filled'
      color='warning'
      label='Добавьте информацию о себе'
      size='micro'
    />
  );

  return (
    <>
      <UserProfileViewAttribute
        label={fullNameNode}
        value={commonInfoNode}
        icon={avatarNode}
        info={infoNode}
        onAction={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributeCommonInfo
          label='Личные данные'
          user={values}
          isCorpUser={isCorpUser}
          isFetching={isUpdating}
          validation={validation}
          onChange={onChange}
          onClose={hideEdit}
          onSave={onUpdateInternal}
        />
      )}
    </>
  );
};
