import { FC, useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { DialogueEventAsComponentType, useDialogueEvents } from '../';
import { DialogueEventDialogLayout } from '../layouts/dialog';
import { SlideContentWrapper } from './controls';

type DialogueEventsComponentSwipeFlowAdapterProps = {
  readonly activeIndex: number;
  readonly events: DialogueEventAsComponentType[];
};

/**
 * @experimental
 */
export const DialogueEventsComponentSwipeFlowAdapter: FC<DialogueEventsComponentSwipeFlowAdapterProps> = ({
  activeIndex,
  events,
}) => {
  const { onNext, onClose } = useDialogueEvents();

  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex, swiper]);

  return (
    <DialogueEventDialogLayout
      open={true}
      disableEscapeKeyDown
      onClose={onClose}
    >
      <Swiper
        slidesPerView={1}
        allowTouchMove={false}
        noSwiping
        loop={false}
        freeMode
        onSwiper={setSwiper}
      >
        {events.map((event, index) => {
          const { uniqueKey, component: Component } = event;
          return (
            <SwiperSlide key={uniqueKey}>
              {index <= activeIndex && (
                <SlideContentWrapper active={index < activeIndex}>
                  {Component && ( //рисуются только слайды до текущего, чтобы не срабатывал раньше времени mount в будущих
                    <Component
                      event={event}
                      onNext={onNext}
                      onClose={onClose}
                    />
                  )}
                </SlideContentWrapper>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </DialogueEventDialogLayout>
  );
};
