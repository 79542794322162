import { InputAdornment } from '@mui/material';
import { EDateFormat } from 'domain/model';
import { isArray } from 'lodash';
import moment from 'moment-timezone';
import { CloseIcon } from 'presentation/media/icons';
import { MPIconButton } from 'presentation/theme/ui-kit/button';
import { MPFormInput, MPFormInputProps, MPMaskedInput } from 'presentation/theme/ui-kit/input';
import { CalendarProps, DateObject, DatePickerProps } from 'react-multi-date-picker';
import { permanentOptions, regularOptions, StyledPicker } from '../configuration';

export type MPSingleDatePickerProps = Omit<CalendarProps & DatePickerProps, 'onChange' | 'multiple' | 'range'> &
  Pick<MPFormInputProps, 'error' | 'helperText' | 'size' | 'color' | 'disabled' | 'label'> & {
    readonly onChange?: (date: Nullable<Date>) => false | void;
  };

const format = EDateFormat.DisplayDefault;
const mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

export const MPSingleDatePicker = (props: MPSingleDatePickerProps) => {
  const { error, helperText, size, color, disabled, label, ...pickerProps } = props;

  const onChangeInternal = (selectedDates: Nullable<DateObject | DateObject[]>): false | void => {
    if (selectedDates === null) {
      return pickerProps.onChange?.(null);
    }

    //ничего не делаем если значение не изменилось
    if (((isArray(selectedDates) && selectedDates.length === 0) || !selectedDates) && !pickerProps.value) {
      return;
    }

    const result: Nullable<Date> = isArray(selectedDates)
      ? selectedDates?.[0]?.toDate()
      : selectedDates.toDate() ?? null;

    return pickerProps.onChange?.(result);
  };

  const onChangeCustom = (value: string) => {
    return pickerProps.onChange?.(moment(value, format).toDate());
  };

  return (
    <StyledPicker
      {...regularOptions}
      {...pickerProps}
      value={pickerProps.value ?? []}
      onChange={onChangeInternal}
      multiple={false}
      range={false}
      containerStyle={{ width: '100%' }}
      format={format}
      render={(val, openCalendar) => {
        return (
          <MPFormInput
            label={label}
            fullWidth
            value={pickerProps.value ? moment(pickerProps.value.toString()).format(format) : ''}
            size={size}
            color={color}
            disabled={disabled}
            error={error}
            helperText={helperText}
            slotProps={{
              input: {
                endAdornment: pickerProps.onChange && pickerProps.value && !disabled && (
                  <InputAdornment position='end'>
                    <MPIconButton
                      size='small'
                      onClick={event => {
                        event.stopPropagation();
                        pickerProps.onChange?.(null);
                      }}
                    >
                      <CloseIcon fontSize='small' />
                    </MPIconButton>
                  </InputAdornment>
                ),
                inputComponent: props => (
                  <MPMaskedInput
                    {...props}
                    mask={mask}
                    showMask={false}
                  />
                ),
              },
            }}
            onBlur={event => onChangeCustom(event.target.value)}
            onClick={disabled ? undefined : openCalendar}
          />
        );
      }}
      shadow
      {...permanentOptions}
    />
  );
};
