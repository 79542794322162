import { MPDialog, MPDialogProps } from 'theme/ui-kit/dialog';
import { ContentWrapper } from './controls';

export type DialogueEventDialogLayoutProps = MPDialogProps;

export const DialogueEventDialogLayout = ({ children, ...others }: DialogueEventDialogLayoutProps) => {
  return (
    <MPDialog
      data-testid='dialogue-event-dialog'
      fullWidth
      keepMounted
      maxWidth='xs'
      fullScreenBreakpoint='xs'
      closeToBackButtonInFullScreen={false}
      externalBuilder
      scroll='paper'
      {...others}
    >
      <ContentWrapper>{children}</ContentWrapper>
    </MPDialog>
  );
};
