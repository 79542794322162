import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { ConfirmEmailContainer } from './container';
import { ConfirmEmailEvent, ConfirmEmailEventPayload, ConfirmEmailEventResult, confirmEmailEventType } from './types';

export type { ConfirmEmailEventPayload } from './types';

export const createEvent = (
  payload: ConfirmEmailEventPayload,
  onSuccess?: (result: ConfirmEmailEventResult) => void
): ConfirmEmailEvent =>
  EventCreatorFactory.business()
    .getCustom(confirmEmailEventType, ConfirmEmailContainer)
    .withPayload(payload)
    .withCallback(onSuccess)
    .makeAsFeature()
    .get();
