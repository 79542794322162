import styled from '@emotion/styled';
import { css } from '@mui/material';
import { dialogVerticalSpacingOffset } from '../../../../features/offer/product/details/mediaPreviewDialog/controls';

export const mediaPreviewThumbCarouselHeight = 126;
export const separatorSpacingOffset = 3;

export const MediaPreviewCarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MediaPreviewDefaultCarouselWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    ${theme.breakpoints.up('md')} {
      width: 50%;
    }

    & .swiper-slide img {
      object-fit: contain;
    }
  `
);

export const Leveler = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      height: calc(
        100vh - ${mediaPreviewThumbCarouselHeight}px - ${theme.spacing(separatorSpacingOffset)} -
          ${theme.spacing(dialogVerticalSpacingOffset)}
      );
    }
  `
);

export const MediaPreviewThumbCarouselWrapper = styled.div`
  height: ${mediaPreviewThumbCarouselHeight}px;
`;

export const MediaPreviewCarouselsSeparator = styled.div(
  ({ theme }) => css`
    min-height: ${theme.spacing(separatorSpacingOffset)};
  `
);
