import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AssistantScenarioConfig, EAssistantScenarioStatus } from '../types';

type AssistantScenarioStoreConfig = AssistantScenarioConfig & {
  readonly status: EAssistantScenarioStatus;
  readonly changeTime: Nullable<number>;
};

export type AssistantState = {
  readonly open: boolean;
  readonly scenarios: AssistantScenarioStoreConfig[];
  readonly activeScenario: Nullable<AssistantScenarioConfig>;
};

type Reducer<T = undefined> = CaseReducer<AssistantState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<AssistantState> & {
  assistantOpen: Reducer;
  assistantClose: Reducer;
  assistantToggle: Reducer;
  assistantSetScenarios: Reducer<AssistantScenarioConfig[]>;
  assistantActivateScenario: Reducer<Pick<AssistantScenarioStoreConfig, 'type' | 'changeTime'>>;
  assistantStartScenario: Reducer<AssistantScenarioConfig['type']>;
  assistantFinishScenario: Reducer<AssistantScenarioConfig['type']>;
  assistantReadyScenariosUpTime: Reducer<number>;
};

const slice = createSlice<AssistantState, Reducers, 'provide'>({
  name: 'provide',
  initialState: {
    open: false,
    scenarios: [],
    activeScenario: null,
  },
  reducers: {
    assistantOpen: state => {
      state.open = true;
    },
    assistantClose: state => {
      state.open = false;
    },
    assistantToggle: state => {
      state.open = !state.open;
    },
    assistantSetScenarios: (state, { payload }) => {
      state.scenarios = payload.map(item => ({ ...item, status: EAssistantScenarioStatus.Pending, changeTime: null }));
    },
    assistantActivateScenario: (state, { payload }) => {
      const { type, changeTime } = payload;

      const scenario = state.scenarios.find(s => s.type === type) ?? null;
      if (scenario) {
        scenario.status = EAssistantScenarioStatus.Ready;
        scenario.changeTime = changeTime;
      }
    },
    assistantStartScenario: (state, { payload }) => {
      state.activeScenario = state.scenarios.find(s => s.type === payload) ?? null;
    },
    assistantFinishScenario: (state, { payload }) => {
      state.activeScenario = null;
      const index = state.scenarios.findIndex(s => s.type === payload);
      if (index !== -1) {
        state.scenarios.splice(index, 1);
      }
      if (!state.scenarios.length) {
        state.open = false;
      }
    },
    assistantReadyScenariosUpTime: (state, { payload }) => {
      state.scenarios.forEach(s => {
        if (s.status === EAssistantScenarioStatus.Ready) {
          s.changeTime = payload;
        }
      });
    },
  },
});

export const {
  assistantClose,
  assistantOpen,
  assistantToggle,
  assistantSetScenarios,
  assistantActivateScenario,
  assistantStartScenario,
  assistantFinishScenario,
  assistantReadyScenariosUpTime,
} = slice.actions;

export default slice.reducer;
