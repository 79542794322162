import { useCheckUserCurrentFetchQuery } from 'data/api/agreements';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import { ENoticeStatus } from 'domain/model';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { isMissingUserAgreementError, isSecurityBusinessError } from 'presentation/utils/auth';
import { FCC, useEffect } from 'react';

const CheckSignsContainer: FCC = ({ children }) => {
  const { logOut } = useAuth();
  const { tokenParsed } = useAuthToken();
  const { data: user, error: userFetchError, isFetching } = useCheckUserCurrentFetchQuery({}, { skip: !tokenParsed });

  const isMissingUserAgreementsError = isMissingUserAgreementError(
    (userFetchError as any)?.data as ServerErrorResponse
  );

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (userFetchError && !isSecurityBusinessError((userFetchError as any)?.data as ServerErrorResponse)) {
      ErrorHandler.handleHttpError(userFetchError, () => ({
        status: ENoticeStatus.Error, text: 'При получении информации о текущем пользователе произошла ошибка'
      }));
      logOut({ withTimeout: true });
    }
  }, [logOut, userFetchError]);

  if (isFetching || !tokenParsed) {
    return null;
  }

  if (!user && isMissingUserAgreementsError) {
    return null;
  }

  return <>{children}</>;
};

export default CheckSignsContainer;
