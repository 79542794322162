import styled from '@emotion/styled';
import { css, menuItemClasses, svgIconClasses } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1.5)};

    .${menuItemClasses.root} {
      padding-left: ${theme.spacing(1.5)};
      border-radius: ${(theme.shape.borderRadius as number) / 2}px;

      .${svgIconClasses.root} {
        margin-right: ${theme.spacing(2)};
      }

      svg {
        font-size: 24px;
      }
    }
  `
);
