import { BookingPriceUnit, EOrderByDateType } from 'domain/model';
import { ExpandableText } from 'presentation/components/common/expandableText';
import { FC, ReactNode, useState } from 'react';
import { BookingCartItem, UseBookingOfferDetails } from '../../details/types';
import { ServiceSelect } from '../select';
import { ExpandableHTML, Header, Info, Informer, List, Wrapper } from './controls';

export type ServiceSelectWidgetProps = BookingPriceUnit & {
  readonly informer?: ReactNode;
  readonly cart: Nullable<BookingCartItem[]>;
  readonly onChange: UseBookingOfferDetails['onChangeServicesSelection'];
};

export const ServiceSelector: FC<ServiceSelectWidgetProps> = ({ cart, onChange, ...priceUnit }) => {
  const { informer, name, description, priceItems } = priceUnit;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (priceItemId: UUID) => {
    return () => {
      onChange({ priceUnit, priceItemId, isIncrement: priceUnit.orderByDateType === EOrderByDateType.None });
    };
  };

  return (
    <Wrapper>
      <Info>
        {informer && <Informer>{informer}</Informer>}
        {name && <Header variant={'h3'}>{name}</Header>}
        {description && (
          <ExpandableText
            as='div'
            expanded={isExpanded}
            onExpand={() => setIsExpanded(!isExpanded)}
          >
            <ExpandableHTML dangerouslySetInnerHTML={{ __html: description }} />
          </ExpandableText>
        )}
      </Info>
      {priceItems && (
        <List>
          {priceItems.map(item => (
            <ServiceSelect
              key={item.id}
              {...item}
              // по старой логике способен отображать +/-. Чтобы этого не было, добавлен 0 по-умолчанию
              count={0}
              onChange={item.id ? handleChange(item.id) : null}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
};
