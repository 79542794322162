import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { SocialPackageToConfirmationEventContainer } from './container';
import {
  SocialPackageToConfirmationEvent,
  SocialPackageToConfirmationEventPayload,
  socialPackageToConfirmationEventType,
} from './types';

export type { SocialPackageToConfirmationEventPayload } from './types';

export const createEvent = (payload: SocialPackageToConfirmationEventPayload): SocialPackageToConfirmationEvent =>
  EventCreatorFactory.business().getSimple(
    `${socialPackageToConfirmationEventType}-${payload.socialPackage.id}`,
    SocialPackageToConfirmationEventContainer,
    payload
  );
