import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const corpOfferReceivedActivationEventType = 'CorpOfferReceivedActivation';

export type CorpOfferReceivedActivationEventPayload = {
  readonly caption: string;
  readonly content: string;
};

export type CorpOfferReceivedActivationEvent = BusinessEvent<CorpOfferReceivedActivationEventPayload>;

export type CorpOfferReceivedActivationEventProps = DisplayEventComponentProps<CorpOfferReceivedActivationEventPayload>;
