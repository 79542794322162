import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { DeleteProfileEventContainer } from './container';
import { DeleteProfileEvent, DeleteProfileEventPayload, deleteProfileEventType } from './types';

export type { DeleteProfileEventPayload } from './types';

export const createEvent = (payload: DeleteProfileEventPayload): DeleteProfileEvent =>
  EventCreatorFactory.business()
    .getCustom(deleteProfileEventType, DeleteProfileEventContainer)
    .withPayload(payload)
    .withOutMergePrev()
    .get();

export const deleteProfileEventTitle = 'Удаление аккаунта';
