import OrderListItemLayout from '../layout';
import { ProductOrderShort } from 'domain/model/order';
import { utcToLocalTimeZone } from 'presentationUtils/date';
import { AppOption, EDateFormat } from 'domain/model';
import { toRubCurrency } from 'presentationUtils/currency';
import { getRequisites, getStatusElement, getStatusName } from './utils';
import { BagIcon } from 'media/icons';

type ProductOfferOrderAdapterProps = {
  readonly order: ProductOrderShort;
  readonly orderStatuses: AppOption[];
  readonly onClick: () => void;
};

const ProductOfferOrderAdapter = (props: ProductOfferOrderAdapterProps) => {
  const { order, orderStatuses, onClick } = props;

  const date = utcToLocalTimeZone(order.createdAt).format(EDateFormat.Human);

  const requisites = getRequisites(order.number, date);
  const statusName = getStatusName(orderStatuses, order.status);
  const name = 'product order name (in progress)';

  const cost = toRubCurrency(order.cost);

  return (
    <OrderListItemLayout
      status={getStatusElement(statusName, order)}
      name={name}
      icon={BagIcon}
      cost={cost}
      requisites={requisites}
      onClick={onClick}
    />
  );
};

export default ProductOfferOrderAdapter;
