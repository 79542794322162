/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainNotificationOption,
  GenMainNotificationSetting,
  GenMainNotificationSettingData,
  GenMainUserId,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const NotificationsApi = {
  /**
   * No description
   * * @tags Notifications
   * @name GetUserNotificationOptions
   * @summary Список доступных настроек уведомлений для пользоветеля
   * @request GET:/notifications/options
   * @secure
   */
  getUserNotificationOptions: (
    baseURL: string,
    query: {
      /** фильтр по ролям */
      role: string[];
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainNotificationOption[], void>({
      baseURL,
      path: `/notifications/options`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * @description Неуказанные типы уведомлений для данного типа пользователя не будут изменены
   * * @tags Notifications
   * @name UpdateUserNotificationSettings
   * @summary Редактирование изменившихся настроек уведомлений для пользователя
   * @request PATCH:/notifications/users/{userId}/settings
   * @secure
   */
  updateUserNotificationSettings: (
    baseURL: string,
    userId: GenMainUserId,
    data: GenMainNotificationSettingData,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainNotificationSetting, GenMainNotificationSettingData>({
      baseURL,
      path: `/notifications/users/${userId}/settings`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),
};
