import { Dispatch, useCallback, useEffect } from 'react';
import { BusinessEvent, EEventType } from './types';
import { useEventBus } from './useEventBus';

export type UseBusinessEventBus<Payload extends object = any> = {
  /** @see useEventBus.publish */
  publish: (...events: BusinessEvent<Payload>[]) => void;
  /** @see useEventBus.publishTop */
  publishTop: (...events: BusinessEvent<Payload>[]) => void;
  /** @see useEventBus.publishFlow */
  publishFlow: (events: BusinessEvent<Payload>[]) => void;
};

/**
 * @description шина бизнес событий событий (см. {@link ../README.md})
 * @description добавлен для работы именно с бизнес событиями (для публикации любого типа, для подписки конкретного)
 * @description подписка/отписка выполняется автоматически
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * const createEvent = (payload: EventPayloadType) => {
 *   return {
 *     uniqueKey: 'event_type',
 *     content: ContentComponent,
 *     mergeWithNext: true,
 *     mergeWithPrev: false,
 *     payload,
 *   };
 * }
 * ...
 * const { publish } = useBusinessEventBus<EventPayloadType>();
 * ...
 * publish(createEvent({ text: 'hello' }));
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * useBusinessEventBus<EventPayloadType>(newEvent => {
 *   ...
 * });
 */
export const useBusinessEventBus = <Payload extends object = any>(
  callback?: Dispatch<Payload>
): UseBusinessEventBus<Payload> => {
  const {
    publish: publishOwn,
    publishTop: publishTopOwn,
    publishFlow: publishFlowOwn,
    subscribe,
    unsubscribe,
  } = useEventBus();

  const publish = useCallback<UseBusinessEventBus['publish']>(
    (...events) => {
      publishOwn(EEventType.BusinessEvent, ...events);
    },
    [publishOwn]
  );

  const publishTop = useCallback<UseBusinessEventBus['publishTop']>(
    (...events) => {
      publishTopOwn(EEventType.BusinessEvent, ...events);
    },
    [publishTopOwn]
  );

  const publishFlow = useCallback<UseBusinessEventBus['publishFlow']>(
    events => {
      publishFlowOwn(EEventType.BusinessEvent, ...events);
    },
    [publishFlowOwn]
  );

  useEffect(() => {
    if (callback) {
      subscribe(EEventType.BusinessEvent, callback);
      return () => unsubscribe(EEventType.BusinessEvent);
    }
  }, [callback, subscribe, unsubscribe]);

  return {
    publish,
    publishTop,
    publishFlow,
  };
};
