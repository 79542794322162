import ClaimTitle from '../../../../components/claim/title';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';

const TradeOfferClaimTitleAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();

  if (!tradeOffer?.title) {
    return null;
  }

  return <ClaimTitle text={tradeOffer.title} />;
};

export default TradeOfferClaimTitleAdapter;
