import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  InputBaseComponentProps,
  Radio,
  RadioProps,
  Switch,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { MaskedPattern } from 'imask';
import {
  CheckboxCheckMarkIcon,
  CheckboxIcon,
  CheckboxIndeterminateMarkIcon,
  RadioCheckMarkIcon,
  RadioMarkIcon,
} from 'media/icons';
import React, { ReactNode, useEffect, useState } from 'react';
import { useIMask } from 'react-imask';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { phoneMask } from '../../../utils/phone';
import { MPNaturalNumberFormat } from '../format';
import { Counter, TextFieldLimited } from './controls';

export type MPFormInputProps = TextFieldProps;

export const MPFormInput = TextField;
export const MPSwitch = Switch;
export const MPRadio = (props: RadioProps) => {
  return (
    <Radio
      icon={<RadioMarkIcon />}
      checkedIcon={<RadioCheckMarkIcon />}
      {...props}
    />
  );
};

type MPCheckboxProps = CheckboxProps & {
  readonly label?: Nullable<ReactNode>;
};

export const MPCheckbox = ({ label, ...other }: MPCheckboxProps) => {
  const checkbox = (
    <Checkbox
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckMarkIcon />}
      indeterminateIcon={<CheckboxIndeterminateMarkIcon />}
      {...other}
    />
  );

  if (label) {
    return (
      <FormControlLabel
        control={checkbox}
        label={label}
      />
    );
  }

  return checkbox;
};

export const MPNaturalNumberInput = (props: MPFormInputProps) => {
  const { slotProps, ...others } = props;

  return (
    <MPFormInput
      slotProps={{
        ...slotProps,
        input: {
          ...slotProps?.input,
          inputComponent: MPNaturalNumberFormat,
        },
      }}
      {...others}
    />
  );
};

export const MPMaskedInput = React.forwardRef<any, InputBaseComponentProps & MaskedInputProps>(
  (props, forwardedRef: any) => {
    return (
      <MaskedInput
        showMask
        {...props}
        ref={(ref: any) => {
          forwardedRef?.(ref ? ref.inputElement : null);
        }}
      />
    );
  }
);

export const MPFormTextArea = (props: MPFormInputProps) => {
  const { slotProps, value, onChange, ...other } = props;
  const maxLength = (slotProps?.htmlInput as InputBaseComponentProps)?.maxLength;

  const [symbolsCount, setSymbolsCount] = useState<number>(((value as string) ?? '').length);

  const onChangeInternal = (event: any) => {
    setSymbolsCount(event.target.value.length ?? 0);
    onChange?.(event);
  };

  if (maxLength) {
    return (
      <TextFieldLimited>
        <MPFormInput
          {...other}
          value={value}
          onChange={onChangeInternal}
          slotProps={slotProps}
        />
        <Counter
          variant={'body2'}
          color={'secondary'}
        >
          {symbolsCount} из {maxLength}
        </Counter>
      </TextFieldLimited>
    );
  }

  return (
    <MPFormInput
      {...other}
      value={value}
      onChange={onChangeInternal}
      slotProps={slotProps}
    />
  );
};

export type MPMaskInputProps<Value extends string = string> = Omit<MPFormInputProps, 'onChange' | 'value'> & {
  readonly onChange?: (value: Value) => void;
  readonly value?: Nullable<Value>;
  readonly maskOptions: MaskedPattern<Value>;
};

const MPBaseMaskInput = (props: MPMaskInputProps) => {
  const { maskOptions, slotProps, value: controlledValue = '', onFocus, onBlur, onChange, ...other } = props;
  const [isFocused, setIsFocused] = useState(false);

  const { ref, value, setValue } = useIMask(maskOptions, {
    onAccept: (value, masked) => {
      onChange?.(masked.unmaskedValue);
    },
  });

  const shrink = isFocused || !!value;

  // Синхронизация значений
  useEffect(() => {
    setValue(controlledValue || '');
  }, [setValue, controlledValue]);

  return (
    <MPFormInput
      {...other}
      inputRef={ref}
      value={value}
      onFocus={event => {
        onFocus?.(event);
        setIsFocused(true);
      }}
      onBlur={event => {
        onBlur?.(event);
        setIsFocused(false);
      }}
      slotProps={{
        ...slotProps,
        inputLabel: {
          ...slotProps?.inputLabel,
          shrink,
        },
      }}
    />
  );
};

export const MPPhoneInput = (props: Omit<MPMaskInputProps, 'maskOptions'>) => {
  return (
    <MPBaseMaskInput
      maskOptions={phoneMask}
      {...props}
    />
  );
};
