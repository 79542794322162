import styled from '@emotion/styled';
import { css, Link } from '@mui/material';

export const Wrapper = styled(Link)(
  ({ theme, onClick }) => css`
    display: flex;
    align-items: center;

    padding: ${theme.spacing(1.5, 2)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1.5, 0)};
    }

    &:hover,
    &:active,
    &:focus {
      color: ${onClick ? theme.palette.primary.main : theme.palette.text.primary};
    }
  `
);
