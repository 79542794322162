import { Typography } from '@mui/material';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { getUserActivationsRoute } from 'features/user/entry';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import { CorpOfferReceivedActivationEventProps } from './types';

export const CorpOfferReceivedActivationEventContainer: FC<CorpOfferReceivedActivationEventProps> = ({
  event: {
    payload: { caption, content },
  },
  onNext,
}) => {
  const history = useHistory();

  const openPromotions = () => {
    history.push(getUserActivationsRoute({}));
    onNext?.();
  };

  return (
    <DialogueEventContentBuilderLayout
      title={caption}
      actions={
        <MPButton
          color='brand'
          size='large'
          fullWidth
          onClick={openPromotions}
        >
          Посмотреть в Моих привилегиях
        </MPButton>
      }
    >
      <Typography
        variant='body2'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </DialogueEventContentBuilderLayout>
  );
};
