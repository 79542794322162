import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useCallback, useMemo } from 'react';
import HeaderLinks from '../components/links';
import { useUserEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/feedBack';

const HeaderLinksAdapter = () => {
  const { publishTop } = useUserEventBus();

  const { isAuthenticated } = useAuth();

  const onOpenFeedback = useCallback(() => publishTop(createEvent({})), [publishTop]);

  return useMemo(
    () => (
      <HeaderLinks
        isAuthenticated={isAuthenticated}
        reverseDirection
        onOpenFeedback={onOpenFeedback}
      />
    ),
    [isAuthenticated, onOpenFeedback]
  );
};

export default HeaderLinksAdapter;
