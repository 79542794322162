import { Card, Content, Title, Wrapper } from './controls';
import { UserBTTransaction } from 'domain/model';
import { Typography } from '@mui/material';
import AppSvgIcon from 'components/common/icon';
import { UnitsIcon } from 'media/icons';
import { ContentLimiter } from 'components/common/contentLimit';
import { ContentLimiterTooltipLayout } from 'components/common/contentLimit/layouts';
import {
  formatDateBenefitTransactions,
  getLabelUserBTTransactionLabel,
  getAmountByTypeTransaction,
} from '../../../utils';

type BenefitTransactionItemProps = {
  readonly data: UserBTTransaction;
  readonly isGroup: boolean;
};

export const BenefitTransactionItem = (props: BenefitTransactionItemProps) => {
  const { data, isGroup } = props;
  const label = getLabelUserBTTransactionLabel(data.type, !!data.isExpiration);
  const labelDate = formatDateBenefitTransactions(data.date);
  const amount = getAmountByTypeTransaction(data.type, data.amount);

  return (
    <Wrapper>
      {!isGroup && <Typography variant={'h3'}>{labelDate}</Typography>}
      <Card>
        <AppSvgIcon
          icon={UnitsIcon}
          fontSize={'large'}
        />
        <Content>
          <Title variant={'subtitle1'}>
            <span>{label}</span> <span>{amount}</span>
          </Title>
          {data.name && <ContentLimiter layout={ContentLimiterTooltipLayout}>{data.name}</ContentLimiter>}
        </Content>
      </Card>
    </Wrapper>
  );
};
