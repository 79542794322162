import {
  AspOfferShort,
  BookingOfferShort,
  CorpOfferShort,
  ECorpOfferPromotionType,
  EOfferType,
  ETradeOfferPromotionType,
  OfferFavorite,
  TradeOfferShort,
} from 'domain/model';
import { ERenderSource } from 'presentation/types';
import { forwardRef } from 'react';
import AspOfferListItem from '../asp/components/listItem';
import BookingOfferListItem from '../booking/components/listItem';
import { OfferListItemProps } from '../components/listItem';
import CorpOfferListItem from '../corp/components/listItem';
import { OfferFavoriteCommonContainer, OfferFavoriteContainerProps } from '../favorite';
import TradeOfferListItem from '../trade/components/listItem';

type TradeOfferType = Pick<TradeOfferShort, 'id' | 'name' | 'title' | 'images' | 'partner' | 'tags' | 'promotionType'>;

type AspOfferType = Pick<
  AspOfferShort,
  'id' | 'name' | 'title' | 'images' | 'partner' | 'tags' | 'promotionType' | 'price'
>;

type CorpOfferType = Pick<
  CorpOfferShort,
  'id' | 'name' | 'images' | 'partner' | 'promotionType' | 'price' | 'originalPrice'
>;

type BookingOfferType = BookingOfferShort;

type OfferListItemContainerProps = {
  readonly offer: TradeOfferType | CorpOfferType | AspOfferType | BookingOfferType | OfferFavorite;
  readonly favorite?: Pick<OfferFavoriteContainerProps, 'partnerId' | 'guid' | 'stored'> | false;
  readonly direction?: OfferListItemProps['direction'];
  readonly renderFrom?: ERenderSource;
};

const OfferListItemContainer = forwardRef<any, OfferListItemContainerProps>((props, ref) => {
  const { offer, favorite, direction, renderFrom } = props;

  //определяем тип оффера по типу промоушена
  if ((offer as CorpOfferType | TradeOfferType | AspOfferType).promotionType) {
    const typedOffer: CorpOfferType | TradeOfferType | AspOfferType = offer as
      | CorpOfferType
      | TradeOfferType
      | AspOfferType;
    const aspOffer = typedOffer as AspOfferType;

    switch (typedOffer.promotionType) {
      case ECorpOfferPromotionType.CorpCertificate:
      case ECorpOfferPromotionType.ExternalCorpCertificate:
        return (
          <CorpOfferListItem
            ref={ref}
            offer={typedOffer}
            favorite={
              favorite && (
                <OfferFavoriteCommonContainer
                  offerId={offer.id}
                  offerType={EOfferType.CorpOffer}
                  {...favorite}
                />
              )
            }
            direction={direction}
            renderFrom={renderFrom}
          />
        );

      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.Voucher:
      case ETradeOfferPromotionType.ReferralLink:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.AccessCode:
        return (
          <TradeOfferListItem
            ref={ref}
            offer={typedOffer}
            favorite={
              favorite && (
                <OfferFavoriteCommonContainer
                  offerId={offer.id}
                  offerType={EOfferType.TradeOffer}
                  {...favorite}
                />
              )
            }
            direction={direction}
            renderFrom={renderFrom}
          />
        );

      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        return (
          <AspOfferListItem
            ref={ref}
            offer={aspOffer}
            favorite={
              favorite && (
                <OfferFavoriteCommonContainer
                  offerId={offer.id}
                  offerType={EOfferType.TradeOffer}
                  {...favorite}
                />
              )
            }
            direction={direction}
            renderFrom={renderFrom}
          />
        );
      case null:
      case ETradeOfferPromotionType.CorpCertificate:
      case ETradeOfferPromotionType.ExternalCorpCertificate:
        return null;
    }
  }

  //определяем букинг оффер по цене
  if ((offer as BookingOfferType).displayPriceItem !== undefined) {
    const typedOffer: BookingOfferType = offer as BookingOfferType;
    return (
      <BookingOfferListItem
        ref={ref}
        offer={typedOffer}
        favorite={
          favorite && (
            <OfferFavoriteCommonContainer
              offerId={offer.id}
              offerType={EOfferType.BookingOffer}
              {...favorite}
            />
          )
        }
        direction={direction}
        renderFrom={renderFrom}
      />
    );
  }
});

export default OfferListItemContainer;
