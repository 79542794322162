import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const confirmCityEventType = 'ConfirmCity';

export type ConfirmCityEventPayload = {
  readonly city: string;
};

export type ConfirmCityEvent = BusinessEvent<ConfirmCityEventPayload>;

export type ConfirmCityEventProps = DisplayEventComponentProps<ConfirmCityEventPayload>;
