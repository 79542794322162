import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { css } from '@mui/material';

export type WrapperProps = {
  readonly columnsCount?: number;
  readonly gap?: number;
  readonly mdColumnsCount?: number;
  readonly smColumnsCount?: number;
  readonly xsColumnsCount?: number;
};

export const Wrapper = styled(
  ({ columnsCount, mdColumnsCount, smColumnsCount, xsColumnsCount, ...others }: PropsWithChildren<WrapperProps>) => (
    <div {...others} />
  )
)(
  ({ theme, ...p }) => css`
    display: grid;
    grid-template-columns: repeat(${p.columnsCount}, 1fr);
    grid-gap: ${theme.spacing(p.gap || 0)};

    ${theme.breakpoints.down('md')} {
      grid-template-columns: repeat(${p.mdColumnsCount}, 1fr);
    }

    ${theme.breakpoints.down('sm')} {
      grid-template-columns: repeat(${p.smColumnsCount}, 1fr);
    }

    ${theme.breakpoints.down('xs')} {
      grid-template-columns: repeat(${p.xsColumnsCount}, 1fr);
    }
  `
);
