import { AnyDisplayEvent } from '@privilege-frontend/eventBus';
import { EventsFlowEngine, EventsFlowEngineInstance, EventsFlowEngineState, QueueOwn } from '../';

const warn = (...args: any[]) => console.warn('EventsFlow [common]', ...args);

const duplicatePredicate = (event: AnyDisplayEvent, newEvent: AnyDisplayEvent) => {
  return event.uniqueKey === newEvent.uniqueKey;
};

/**
 * общая обработка очереди событий
 * */
export const getEventsFlowEngineCommon = <QueueType extends QueueOwn>(
  instance: EventsFlowEngineInstance<QueueType>
): EventsFlowEngine<QueueType> => {
  const engine: EventsFlowEngine<QueueType> = {
    ...instance,
    onRecalculate: state => {
      const events = state.events.clone();
      const activeFlow = state.activeFlow;

      if (events.size && !activeFlow) {
        const newFlow: EventsFlowEngineState<QueueType>['activeFlow'] = {
          activeIndex: 0,
          events: [events.poll()!],
          required: true,
        };

        return { events, activeFlow: newFlow };
      }

      return state;
    },
    onNew: (state, newEvent) => {
      const events = state.events.clone();
      //контролируем уникальность - если неправильный код кинул одно и то же событие несколько раз - учитываем только первое
      let cloneInEvents = null;
      events.forEach(event => {
        if (duplicatePredicate(event, newEvent)) {
          cloneInEvents = event;
        }
      });
      const duplicate = cloneInEvents || state.activeFlow?.events?.find(event => duplicatePredicate(event, newEvent));
      if (duplicate) {
        warn(`duplicated event '${newEvent.uniqueKey}' has ignored`);
        return state;
      }

      events.add(newEvent);
      //пересчитываем состояние
      return engine.onRecalculate({ ...state, events });
    },
    onNext: (state, result) => {
      const activeFlow = state.activeFlow;
      const events = state.events.clone();

      //переключаем на следующее событие или завершает текущий флоу
      if (activeFlow) {
        //дёргаем калбэк ивента о выполнении
        const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
        //игнорируем штатный event по currentTarget (чтобы всякие клики на контролы, которые event засылают, не сломали нам onSuccess)
        if (activeEvent?.onSuccess && result !== undefined && !result.currentTarget) {
          activeEvent.onSuccess(result);
        }

        if (activeFlow.activeIndex < activeFlow.events.length - 1) {
          const newFlow: EventsFlowEngineState<QueueType>['activeFlow'] = {
            ...activeFlow,
            activeIndex: activeFlow.activeIndex + 1,
          };
          return { ...state, activeFlow: newFlow };
        } else {
          const lastActiveFlowEvent = activeFlow.events?.[activeFlow.events.length - 1];
          const firstEventInQueue = events.peek();
          if (lastActiveFlowEvent?.mergeWithNext && firstEventInQueue?.mergeWithPrev) {
            const newFlow: EventsFlowEngineState<QueueType>['activeFlow'] = {
              ...activeFlow,
              events: [...activeFlow.events, firstEventInQueue],
              activeIndex: activeFlow.activeIndex + 1,
            };
            events.poll();
            return { ...state, events, activeFlow: newFlow };
          } else {
            return { ...state, activeFlow: null };
          }
        }
      }

      return state;
    },
    onClose: state => {
      /**
       * если для события указан flowId, то при его отмене нужно отменить и все остальные
       * для этого переключаем activeIndex на индекс последнего события с нашим flowId (после чего onNext переключит еще на +1)
       * также из ажидающих ивентов тоже убраем с таким flowId
       */
      const activeFlow: EventsFlowEngineState<QueueType>['activeFlow'] = state.activeFlow;
      if (activeFlow) {
        const events = state.events.clone();
        const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
        if (activeEvent?.flowId) {
          let i;
          for (i = activeFlow.activeIndex; i < activeFlow.events.length; i++) {
            if (activeFlow.events[i].flowId !== activeEvent?.flowId) {
              break;
            }
          }
          const newFlow: EventsFlowEngineState<QueueType>['activeFlow'] = {
            ...activeFlow,
            activeIndex: i - 1,
          };

          if (activeEvent?.flowId) {
            events.removeMany(event => event.flowId === activeEvent.flowId);
          }

          return engine.onNext({ ...state, events, activeFlow: newFlow });
        }
      }

      return engine.onNext(state);
    },
  };

  return engine;
};
