import { Text } from '../../components/controls';
const AboutCspCommonInfo = () => {
  return (
    <>
      <Text>
        Компенсируемый социальный пакет или КСП, это компенсация работникам, чьи должности (жизненная ситуация) вошли в
        перечень значимых категорий профессий на год предоставления КСП и имеют право на все элементы КСП за исключением
        элемента «Компенсация оплаты услуг организации отдыха и санаторно-курортного лечения для работника, его детей,
        супруга/супруги на сторонних объектах, определенных отдельными локальными нормативными актами ОАО «РЖД» по
        вопросам кадровой и социальной политики».
      </Text>
    </>
  );
};

export default AboutCspCommonInfo;
