import { EOfferStatus } from 'domain/model';
import OfferClaimHelp from '../../../../components/claim/help';
import { dialogContent } from '../../../components/dialogs/help/constants';
import useTradeOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';

const TradeOfferClaimHelpAdapter = () => {
  const { tradeOffer } = useTradeOfferClaimContext();
  const { openHelpDialog } = useTradeOfferClaimDialogs();

  if (!tradeOffer) {
    return null;
  }

  const text = tradeOffer.promotionType ? dialogContent[tradeOffer.promotionType].title : null;

  const isVisible = tradeOffer.status === EOfferStatus.Active;

  return isVisible ? (
    <OfferClaimHelp
      text={text}
      onClick={openHelpDialog}
    />
  ) : null;
};

export default TradeOfferClaimHelpAdapter;
