import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OrderDetailsWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.white.main};

    ${theme.breakpoints.up('md')} {
      border-radius: ${theme.spacing(2)};
    }
  `
);
