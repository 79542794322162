import { Category, CategoryTree, CategoryTreeItem } from 'domain/model';

const categoryBuildTreeItem = (category: Category, categories: Category[]): CategoryTreeItem => {
  return {
    ...category,
    children: categories.filter(c => c.parentId === category.id).map(c => categoryBuildTreeItem(c, categories)),
  };
};

export const categoriesBuildTree = (categories: Category[]): CategoryTree => {
  return categories
    .sort((c1, c2) => c1.name.localeCompare(c2.name))
    .filter(item => !item.parentId)
    .map(category => categoryBuildTreeItem(category, categories));
};
