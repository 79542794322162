import { Breakpoint, Container, Fade, Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { ImageWrapper } from 'presentation/components/common/stub/common/controls';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MPButton } from 'theme/ui-kit/button';

type StubCommonProps = {
  readonly title?: ReactNode;
  readonly text?: ReactNode;
  readonly image?: ReactNode;
  readonly actions?: ReactNode;
};

const maxWidth: Breakpoint = 'md';

const defaultActions = (
  <MPButton
    component={RouterLink}
    color='brand'
    to='/'
  >
    На главную
  </MPButton>
);

const StubCommon = ({ title = 'Данные не найдены', text, image, actions = defaultActions }: StubCommonProps) => {
  const isUpMaxWidth = useMediaQuery(theme => theme.breakpoints.up(maxWidth));

  const { x4, x7 } = useGridSpacing();

  return (
    <Fade in>
      <Container
        maxWidth={maxWidth}
        fixed={isUpMaxWidth}
      >
        <Splitter size={x7} />
        <MPGrid
          container
          spacing={x4}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <MPGrid item>
            {title && (
              <>
                {typeof title !== 'string' ? (
                  <>{title}</>
                ) : (
                  <Typography
                    variant='h2'
                    align='center'
                    component='div'
                  >
                    {title}
                  </Typography>
                )}
              </>
            )}
          </MPGrid>
          {text && (
            <MPGrid item>
              <>{typeof text !== 'string' ? <>{text}</> : <Typography align='center'>{text}</Typography>}</>
            </MPGrid>
          )}
          {actions && <MPGrid item>{actions}</MPGrid>}
          {image && (
            <MPGrid item>
              <Splitter size={5} />
              {typeof image === 'string' && (
                <ImageWrapper>
                  <img
                    src={image}
                    alt='stub'
                  />
                </ImageWrapper>
              )}
            </MPGrid>
          )}
        </MPGrid>
        <Splitter size={5} />
      </Container>
    </Fade>
  );
};

export default StubCommon;
