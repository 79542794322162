import styled from '@emotion/styled';
import { PaperProps } from '@mui/material';
import { forwardRef } from 'react';

export const BreadcrumbsWrapper = styled.div`
  > :last-of-type {
    margin-bottom: ${p => p.theme.spacing()};
  }
`;

export const AsideWrapper = styled(
  forwardRef<HTMLElement, PaperProps>((props, ref) => (
    <aside
      {...props}
      ref={ref}
    />
  ))
)`
  position: relative;
`;

export const AnyContentWrapper = styled.div`
  flex: 1;

  > :not(:first-of-type) {
    margin-top: ${p => p.theme.spacing(5)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;

  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const BannersWrapper = styled.div`
  > :last-of-type {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > :last-of-type {
    margin-left: ${p => p.theme.spacing(1.5)};
  }
`;

export const ControlsWrapperDense = styled.div`
  > * {
    width: auto;
  }
`;

export const ControlsWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)};

  > * {
    width: auto;
  }
`;
