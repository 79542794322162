import { Typography } from '@mui/material';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/feedBack';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';
import { ProfileDeleteForbiddenEventProps } from './types';

export const ProfileDeleteForbiddenEventContainer: FC<ProfileDeleteForbiddenEventProps> = ({ onNext }) => {
  const { publishTop } = useBusinessEventBus();

  const onOpenFeedback = () => {
    publishTop(createEvent({}));
    onNext();
  };

  return (
    <MPConfirmDialog
      open
      title='Не удалось удалить аккаунт'
      maxWidth='xs'
      disableEscapeKeyDown
      onClose={onNext}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onOpenFeedback}
          >
            Написать в службу поддержки
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <Typography>Ваш аккаунт привязан к учетной записи партнёра или администратора.</Typography>
      <Typography>Для удаления аккаунта обратитесь в службу поддержки</Typography>
    </MPConfirmDialog>
  );
};
