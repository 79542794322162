import styled from '@emotion/styled';
import { ButtonProps, css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const Wrapper = styled.div(
  () => css`
    position: relative;
    width: 100%;
  `
);

export const LoaderWrapper = styled.div(
  () => css`
    height: 14rem;
  `
);

const AttributeInlineButton = styled(MPButton)(
  () => css`
    overflow: hidden;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline;
  `
);

type AttributeButtonProps = {
  readonly selected?: boolean;
  readonly isOutOfStock?: boolean;
};

export const AttributeButton = styled(({ isOutOfStock, selected, ...others }: ButtonProps & AttributeButtonProps) => (
  <AttributeInlineButton {...others} />
))(
  ({ theme, selected, isOutOfStock }) => css`
    line-height: 1;
    padding: ${theme.spacing(0.9, 1.5)};

    border: 0 !important;
    background-color: transparent !important;
    outline: ${selected && !isOutOfStock ? 2 : 1}px ${isOutOfStock ? 'dashed' : 'solid'}
      ${isOutOfStock
        ? selected
          ? theme.palette.text.primary
          : theme.palette.secondary.dark
        : selected
        ? theme.palette.text.primary
        : theme.palette.secondary.dark} !important;
    color: ${isOutOfStock
      ? selected
        ? theme.palette.text.primary
        : theme.palette.secondary.dark
      : theme.palette.text.primary} !important;
    outline-offset: -1px;

    font-weight: ${theme.typography.body1.fontWeight};
  `
);
