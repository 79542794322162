import { SupportIcon } from 'presentation/media/icons';
import { FC } from 'react';
import Option from './option';

const Icon = <SupportIcon />;

type SupportOptionProps = {
  readonly onOpen: () => void;
};

const SupportOption: FC<SupportOptionProps> = ({ onOpen }) => {
  return (
    <Option
      icon={Icon}
      text='Написать в поддержку'
      onClick={onOpen}
    />
  );
};

export default SupportOption;
