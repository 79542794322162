import { FC } from 'react';
import HeaderLink from './link';

type FeedbackProps = {
  readonly onOpen: () => void;
};

const Feedback: FC<FeedbackProps> = ({ onOpen }) => {
  return (
    <HeaderLink
      text='Форма обратной связи'
      component='div'
      onClick={onOpen}
    />
  );
};

export default Feedback;
