import { Typography } from '@mui/material';
import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import OffersCommonHeader from 'presentation/features/offer/components/commonHeader';
import { searchTitleByClientOfferType } from 'presentation/features/search/utils';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import { useSelector } from 'react-redux';
import { searchOffersCountSelector, searchOffersOfferTypeSelector } from '../store/selectors';

const SearchOffersHeaderAdapter = () => {
  const { x2 } = useGridSpacing();

  const count = useSelector(searchOffersCountSelector);
  const offerType = useSelector(searchOffersOfferTypeSelector);
  const clientOfferType = offerType?.[0];

  const isCommonHeaderVisible = offerType?.length === 1;

  return count ? (
    <MPGrid
      container
      spacing={x2}
      direction='column'
    >
      <MPGrid item>
        <Typography variant='body2'>
          Найдено {count} {pluralize(count, ['результат', 'результата', 'результатов'])}
        </Typography>
      </MPGrid>
      {clientOfferType && (
        <MPGrid item>
          {isCommonHeaderVisible && (
            <OffersCommonHeader icon={<PartitionIcon type={clientOfferType} />}>
              {searchTitleByClientOfferType[clientOfferType]}
            </OffersCommonHeader>
          )}
        </MPGrid>
      )}
    </MPGrid>
  ) : null;
};

export default SearchOffersHeaderAdapter;
