import { Text } from '../../components/controls';
const AboutCspWHatPeriodInfo = () => {
  return (
    <>
      <Text>
        Дата заезда в сторонние учреждения должна быть не ранее 1 января, а дата выезда не позднее 1 декабря года, в
        котором предоставлено право на КСП на оздоровление.
      </Text>
    </>
  );
};

export default AboutCspWHatPeriodInfo;
