import { Theme, useMediaQuery } from '@mui/material';
import { AccountBalance } from 'domain/model';
import { BalancesIcon } from 'presentation/media/icons';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';
import { UserBalanceValue } from '../../balanceValue';
import { UserMenuItem } from '../../menuItem';
import { BalancesWrapper } from './controls';

type UserMenuItemFinancesProps = {
  readonly balances?: Nullable<AccountBalance[]>;
  readonly onClick?: () => void;
};

export const UserMenuItemFinances: FC<UserMenuItemFinancesProps> = ({ balances, onClick }) => {
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <div>
      <UserMenuItem
        label='Мой баланс'
        icon={<BalancesIcon color='black' />}
        onClick={onClick}
      />
      {isLgDown && (
        <BalancesWrapper>
          {balances && balances.length > 0 && (
            <MPGrid
              container
              spacing={0.5}
              direction='column'
            >
              {balances.map(balance => {
                return (
                  <MPGrid
                    key={balance.type}
                    item
                  >
                    <UserBalanceValue
                      type={balance.type}
                      value={balance.value}
                    />
                  </MPGrid>
                );
              })}
            </MPGrid>
          )}
        </BalancesWrapper>
      )}
    </div>
  );
};
