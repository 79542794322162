export enum EDateFormat {
  Server = 'YYYY-MM-DD',
  DisplayDefault = 'DD.MM.YYYY',
  DisplayDefaultShort = 'DD.MM.YY',
  Human = 'D\u00A0MMMM YYYY',
  HumanShort = 'D\u00A0MMMM',
}

export enum EDateTimeFormat {
  Server = 'YYYY-MM-DDTHH:mm:ss',
  Human = 'D\u00A0MMMM YYYY HH:mm',
  HumanShort = 'D\u00A0MMMM HH:mm',
  HumanPoint = 'D\u00A0MMMM YYYY в HH:mm',
  HumanPointShort = 'D\u00A0MMMM в HH:mm',
  DisplayDefault = 'DD.MM.YY - HH:mm',
}

export enum ETimeFormat {
  Default = 'HH:mm',
}
