import { EOfferType } from 'domain/model';
import { getNewArrayExcludingValue } from 'utils/array';
import { AppDispatch, RootState } from '../../store/store';
import { api, EUserServicesTag } from '../index';
import { favoritesCountMap, userApi, UserFavoritesResponse } from '../user';
import { CreatePatchesResult } from './types';

type CreateOfferFavoritePatchesProps = {
  readonly id: UUID;
  readonly type: EOfferType;
  readonly favorite: boolean;
  readonly state: RootState;
  readonly dispatch: AppDispatch;
};

// выполнение апдейтов признака favorite в офферах
export const createOfferFavoritePatches = ({
  id,
  type,
  favorite,
  state,
  dispatch,
}: CreateOfferFavoritePatchesProps): CreatePatchesResult => {
  // создаём набор патчей
  return api.util.selectInvalidatedBy(state, [EUserServicesTag.FavoritesCount]).map(cache => {
    const { originalArgs, endpointName } = cache;
    return dispatch(
      userApi.util.updateQueryData(endpointName as any, originalArgs, draft => {
        const dataObject = draft as UserFavoritesResponse;
        const typeCount = dataObject[favoritesCountMap[type]] as number;

        if (favorite) {
          Object.assign(dataObject, {
            ...dataObject,
            [type]: [...dataObject[type], id],
            [favoritesCountMap[type]]: typeCount + 1,
            totalCount: dataObject.totalCount + 1,
          });
        } else {
          const removed = getNewArrayExcludingValue(dataObject[type], id);

          if (removed === dataObject[type]) {
            return;
          }

          Object.assign(dataObject, {
            ...dataObject,
            [type]: removed,
            [favoritesCountMap[type]]: typeCount - 1,
            totalCount: dataObject.totalCount - 1,
          });
        }
      })
    );
  });
};
