import { RootState } from 'data/store/store';
import { userApi } from 'api/user';

export const cspApplicationFormSelector = (store: RootState) => store.csp.application.form;

export const cspApplicationValidationSelector = (store: RootState) => store.csp.application.validation;

export const cspApplicationIsFetchingSelector = (store: RootState) =>
  userApi.endpoints.getSocialPackageApplicationsList.select()(store).isLoading;

export const cspApplicationHasApplicationSelector = (store: RootState) => {
  const data = userApi.endpoints.getSocialPackageApplicationsList.select()(store).data;
  return data && data.length > 0;
};
