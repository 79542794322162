import AboutTextSectionsContainer from '../textSections/container';
import AboutCspCommonInfo from './info/common';
import AboutCspWhatIsItInfo from './info/whatIsIt';
import AboutCspHowIsFamilyInfo from './info/howIsFamily';
import AboutCspWhatCategoryInfo from './info/whatCategory';
import AboutCspWhatNeedDoInfo from './info/whatNeedDo';
import AboutCspWhatSizeCostInfo from './info/whatSizeCost';
import AboutCspCanIGetInfo from './info/canIGet';
import AboutCspWhatObjectsInfo from './info/whatObjects';
import AboutCspWhenSubmitInfo from './info/whenSubmit';
import AboutCspIsThereTaxInfo from './info/IsThereTax';
import { NumberedText } from '../components/textNumber';
import AboutCspWhatDocsInfo from './info/whatDocs';
import AboutCspWhenBuyInfo from './info/whenBuy';
import AboutCspWHatPeriodInfo from './info/whatPeriod';

enum ESection {
  Common = 'common',
  WhatIsIt = 'WhatIsIt',
  HowIsFamily = 'HowIsFamily',
  WhatCategory = 'WhatCategory',
  WhatNeedDo = 'WhatNeedDo',
  WhatSizeCost = 'WhatSizeCost',
  CanIGet = 'CanIGet',
  WhatObjects = 'whatObjects',
  WhenSubmit = 'WhenSubmit',
  IsThereTax = 'IsThereTax',
  WhatDocs = 'WhatDocs',
  WhatPeriod = 'WhatPeriod',
  HowOftenUpdate = 'HowOftenUpdate',
}

const AboutCspContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Компенсируемый социальный пакет'
      sections={[
        {
          id: ESection.Common,
          name: <NumberedText number={1}>Что такое классический компенсируемый социальный пакет?</NumberedText>,
          component: AboutCspCommonInfo,
        },
        {
          id: ESection.WhatIsIt,
          name: <NumberedText number={2}>Что такое КСП на оздоровление и отдых?</NumberedText>,
          component: AboutCspWhatIsItInfo,
        },
        {
          id: ESection.HowIsFamily,
          name: (
            <NumberedText number={3}>
              Кто относится к членам семьи работника при предоставлении КСП на оздоровление?
            </NumberedText>
          ),
          component: AboutCspHowIsFamilyInfo,
        },
        {
          id: ESection.WhatCategory,
          name: (
            <NumberedText number={4}>
              Какой категории работников предоставляется КСП на оздоровление и отдых?
            </NumberedText>
          ),
          component: AboutCspWhatCategoryInfo,
        },
        {
          id: ESection.WhatNeedDo,
          name: (
            <NumberedText number={5}>
              Что нужно сделать, чтобы получить возможность компенсировать путевку на оздоровление по КСП?
            </NumberedText>
          ),
          component: AboutCspWhatNeedDoInfo,
        },
        {
          id: ESection.WhatSizeCost,
          name: <NumberedText number={6}>Какой размер стоимости путевки мне могут компенсировать?</NumberedText>,
          component: AboutCspWhatSizeCostInfo,
        },
        {
          id: ESection.CanIGet,
          name: (
            <NumberedText number={7}>
              Если я получаю КСП на оздоровление и отдых, могу ли я получить КСП по другим категориям?
            </NumberedText>
          ),
          component: AboutCspCanIGetInfo,
        },
        {
          id: ESection.WhatObjects,
          name: <NumberedText number={8}>На какие объекты я могу поехать отдыхать с семьей?</NumberedText>,
          component: AboutCspWhatObjectsInfo,
        },
        {
          id: ESection.WhenSubmit,
          name: (
            <NumberedText number={9}>
              Когда надо подавать заявление на путевку работнику и члену его семьи?
            </NumberedText>
          ),
          component: AboutCspWhenSubmitInfo,
        },
        {
          id: ESection.IsThereTax,
          name: <NumberedText number={10}>Взимается ли 13% налога с путевки?</NumberedText>,
          component: AboutCspIsThereTaxInfo,
        },

        {
          id: ESection.WhatDocs,
          name: <NumberedText number={11}>Какие документы нужно представить для получения компенсации?</NumberedText>,
          component: AboutCspWhatDocsInfo,
        },
        {
          id: ESection.HowOftenUpdate,
          name: <NumberedText number={12}>Когда можно приобрести путевку в сторонние объекты?</NumberedText>,
          component: AboutCspWhenBuyInfo,
        },
        {
          id: ESection.WhatPeriod,
          name: (
            <NumberedText number={13}>
              В какой период можно получить оздоровление по путевке в сторонних учреждениях?
            </NumberedText>
          ),
          component: AboutCspWHatPeriodInfo,
        },
      ]}
    />
  );
};

export default AboutCspContainer;
