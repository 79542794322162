import styled from '@emotion/styled';
import { buttonClasses } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledDottedButton = styled(MPButton)`
  &.${buttonClasses.root} {
    border: 1px dashed ${p => p.theme.palette.primary.A600};

    background-color: ${p => p.theme.palette.primary.A500};
  }
`;
