import { Link } from '@mui/material';
import UserAttributeLayout from 'presentation/layouts/userAttribute';
import { ReactNode } from 'react';

type UserProfileViewAttributeProps = {
  readonly label?: ReactNode;
  readonly icon?: ReactNode;
  readonly info?: ReactNode;
  readonly value: ReactNode;
  readonly actionText?: ReactNode;
  readonly onAction?: () => void;
};

const Layout = UserAttributeLayout;

const UserProfileViewAttribute = (props: UserProfileViewAttributeProps) => {
  const { label, icon, info, value, actionText = 'Изменить', onAction } = props;

  const action = onAction && (
    <Link
      component='div'
      color='primary'
      variant='caption'
      onClick={onAction}
    >
      {actionText}
    </Link>
  );

  return (
    <Layout
      label={label}
      icon={icon}
      action={action}
      info={info}
    >
      {value}
    </Layout>
  );
};

export default UserProfileViewAttribute;
