import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { BalancesWrapper, BannersWrapper, ContentWrapper } from './controls';
import { UserFinancesLayoutType } from './types';

export const UserFinancesLayout: UserFinancesLayoutType = props => {
  const { balances, history, actions, loader, children, tabs, banners } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        <ContentWrapper>
          <Typography variant='h1'>Мой баланс</Typography>
          <Splitter size={3} />

          {balances && <BalancesWrapper>{balances}</BalancesWrapper>}

          {banners && <BannersWrapper>{banners}</BannersWrapper>}

          {history && (
            <>
              <Typography variant='h1'>История операций</Typography>
              <Splitter size={3} />
              {tabs}
              <Splitter size={3} />
              {history}
              <Splitter size={5} />
            </>
          )}

          {actions}

          {children}
          {loader}
        </ContentWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};
