import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';
import { EContentbarGridAreas, EInnerGridAreas, EParentGridAreas, ESidebarGridAreas, FixedPositions } from './types';

type ParentLayoutGridAreaProps = {
  readonly area: EParentGridAreas;
};

export const ParentLayoutGridArea = styled(MPGrid)<ParentLayoutGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const ParentLayoutGrid = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing()};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas: '${`${EParentGridAreas.Inner} `.repeat(12)}';

    ${theme.breakpoints.up('md')} {
      grid-row-gap: ${theme.spacing(1)};
      grid-template-areas:
        '${`${EParentGridAreas.Bar} `.repeat(12)}'
        '${`${EParentGridAreas.Inner} `.repeat(12)}';
    }

    [area=${EParentGridAreas.Bar}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        display: flex;
      }
    }
  `
);

type InnerLayoutGridAreaProps = {
  readonly area: EInnerGridAreas;
};

export const InnerLayoutGridArea = styled(MPGrid)<InnerLayoutGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const InnerLayoutGrid = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: grid;
    padding-top: ${theme.spacing(3.5)};

    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing(3)};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
    grid-template-areas:
      '${`${EInnerGridAreas.Contentbar} `.repeat(12)}'
      '${`${EInnerGridAreas.Sidebar} `.repeat(12)}'
      '${`${EInnerGridAreas.Bottombar} `.repeat(12)}'
      '${`${EInnerGridAreas.SmartphoneFixedBar} `.repeat(12)}';

    ${theme.breakpoints.up('sm')} {
      grid-row-gap: ${theme.spacing(4)};
    }

    ${theme.breakpoints.up('md')} {
      padding-top: 0;
      border-radius: ${theme.spacing(3)};

      grid-column-gap: ${theme.spacing(2)};
      grid-row-gap: ${theme.spacing(1.5)};
      grid-template-columns: 1fr 288px;
      grid-template-areas:
        '${EInnerGridAreas.Breadcrumbs} ${EInnerGridAreas.Breadcrumbs}'
        '${EInnerGridAreas.Topbar} ${EInnerGridAreas.Topbar}'
        '${EInnerGridAreas.Favorite} ${EInnerGridAreas.Favorite}'
        '${EInnerGridAreas.Contentbar} ${EInnerGridAreas.Sidebar}'
        '${EInnerGridAreas.Bottombar} ${EInnerGridAreas.Bottombar}';
    }

    ${theme.breakpoints.up('lg')} {
      grid-template-columns: 1fr minmax(200px, 348px);
      grid-column-gap: ${theme.spacing(3)};
    }

    ${theme.breakpoints.up('xl')} {
      grid-template-areas:
        '${EInnerGridAreas.Breadcrumbs} ${EInnerGridAreas.Breadcrumbs}'
        '${EInnerGridAreas.Topbar} ${EInnerGridAreas.Topbar}'
        '${EInnerGridAreas.Favorite} ${EInnerGridAreas.Favorite}'
        '${EInnerGridAreas.Contentbar} ${EInnerGridAreas.Sidebar}';
    }

    [area=${EInnerGridAreas.Favorite}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        display: flex;
      }
    }

    [area=${EInnerGridAreas.SmartphoneFixedBar}] {
      display: flex;

      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }

    [area=${EInnerGridAreas.Breadcrumbs}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        display: flex;
        padding: ${theme.spacing(0.5)} 0;
      }
    }

    [area=${EInnerGridAreas.Topbar}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        padding-right: ${theme.spacing(6)};
        display: flex;
      }
    }

    [area=${EInnerGridAreas.Bottombar}] {
      ${theme.breakpoints.up('sm')} {
        margin-top: ${theme.spacing()};
      }

      ${theme.breakpoints.up('md')} {
        margin-top: ${theme.spacing(3.5)};
      }

      ${theme.breakpoints.up('lg')} {
        margin-top: ${theme.spacing(1)};
      }

      ${theme.breakpoints.up('xl')} {
        display: none;
      }
    }

    [area=${EInnerGridAreas.Contentbar}] {
      ${theme.breakpoints.up('xl')} {
        padding-right: ${theme.spacing(2)};
      }
    }
  `
);

type ContentBarGridAreaProps = {
  readonly area: EContentbarGridAreas;
};

export const ContentBarGridArea = styled(MPGrid)<ContentBarGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

type ContentBarGridProps = {
  fixedPositionsAreas?: FixedPositions;
  descriptionFixedWidth?: string;
};

export const ContentBarGrid = styled.div<ContentBarGridProps>(
  ({ theme, fixedPositionsAreas }) => css`
    width: 100%;

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing(1.5)};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas:
      '${`${EContentbarGridAreas.Image} `.repeat(12)}'
      '${`${EContentbarGridAreas.Banner} `.repeat(12)}'
      '${`${EContentbarGridAreas.Breadcrumbs} `.repeat(12)}'
      '${`${EContentbarGridAreas.SmartphoneTitle} `.repeat(12)}'
      '${`${EContentbarGridAreas.Description} `.repeat(12)}'
      '${`${EContentbarGridAreas.LargeDesktopTabs} `.repeat(12)}';

    ${theme.breakpoints.up('sm')} {
      grid-row-gap: ${theme.spacing(2)};
    }

    ${theme.breakpoints.up('md')} {
      grid-row-gap: ${theme.spacing(4)};

      grid-template-areas:
        '${`${EContentbarGridAreas.Image} `.repeat(12)}'
        '${`${EContentbarGridAreas.Banner} `.repeat(12)}'
        '${`${EContentbarGridAreas.Description} `.repeat(12)}'
        '${`${EContentbarGridAreas.LargeDesktopTabs} `.repeat(12)}';
    }

    ${theme.breakpoints.up('lg')} {
      grid-column-gap: ${theme.spacing(3)};
      grid-template-columns: ${fixedPositionsAreas?.includes(EContentbarGridAreas.Description)
        ? `minmax(300px, 480px) minmax(150px, auto)`
        : 'minmax(0, auto) 326px'};

      grid-template-areas:
        '${EContentbarGridAreas.Image} ${EContentbarGridAreas.Description}'
        '${EContentbarGridAreas.Banner} ${EContentbarGridAreas.Banner}'
        '${EContentbarGridAreas.LargeDesktopTabs} ${EContentbarGridAreas.LargeDesktopTabs}';
    }

    [area=${EContentbarGridAreas.Image}] {
      margin: 0 -${theme.spacing(1.5)};

      ${theme.breakpoints.up('sm')} {
        margin: 0 -${theme.spacing(2)};
      }

      ${theme.breakpoints.up('md')} {
        margin: 0;
      }
    }

    [area=${EContentbarGridAreas.Breadcrumbs}] {
      display: flex;

      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }

    [area=${EContentbarGridAreas.SmartphoneTitle}] {
      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }

    [area=${EContentbarGridAreas.LargeDesktopTabs}] {
      display: none;

      ${theme.breakpoints.up(fixedPositionsAreas?.includes(EContentbarGridAreas.LargeDesktopTabs) ? 'zero' : 'xl')} {
        display: flex;
        flex-direction: column;
      }
    }
  `
);

type SidebarGridAreaProps = {
  readonly area: ESidebarGridAreas;
};

export const SidebarGridArea = styled(MPGrid)<SidebarGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const SidebarGrid = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing(3)};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas:
      '${`${ESidebarGridAreas.Actions} `.repeat(12)}'
      '${`${ESidebarGridAreas.PartnerInfo} `.repeat(12)}';
  `
);
