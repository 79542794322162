import { Dispatch, useCallback, useEffect } from 'react';
import { BusinessEvent, EEventType, UserEvent } from './types';
import { useEventBus } from './useEventBus';

export type UseUserEventBus<Payload extends object = any> = {
  /** @see useEventBus.publish */
  publish: (...events: UserEvent<Payload>[]) => void;
  /** @see useEventBus.publishTop */
  publishTop: (...events: BusinessEvent<Payload>[]) => void;
  /** @see useEventBus.publishFlow */
  publishFlow: (events: UserEvent<Payload>[]) => void;
};

/**
 * @description шина системных пользовательских событий (см. {@link ../README.md})
 * @description добавлен для работы именно с пользовательскими событиями (для публикации любого типа, для подписки конкретного)
 * @description подписка/отписка выполняется автоматически
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * const createEvent = (payload: EventPayloadType) => {
 *   return {
 *     uniqueKey: 'event_type',
 *     content: ContentComponent,
 *     mergeWithNext: true,
 *     mergeWithPrev: false,
 *     payload,
 *   };
 * }
 * ...
 * const { publish } = useUserEventBus<EventPayloadType>();
 * ...
 * publish(createEvent({ text: 'hello' }));
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * useUserEventBus<EventPayloadType>(newEvent => {
 *   ...
 * });
 */
export const useUserEventBus = <Payload extends object = any>(
  callback?: Dispatch<Payload>
): UseUserEventBus<Payload> => {
  const {
    publish: publishOwn,
    publishTop: publishTopOwn,
    publishFlow: publishFlowOwn,
    subscribe,
    unsubscribe,
  } = useEventBus();

  const publish = useCallback<UseUserEventBus['publish']>(
    (...events) => {
      publishOwn(EEventType.UserEvent, ...events);
    },
    [publishOwn]
  );

  const publishTop = useCallback<UseUserEventBus['publishTop']>(
    (...events) => {
      publishTopOwn(EEventType.UserEvent, ...events);
    },
    [publishTopOwn]
  );

  const publishFlow = useCallback<UseUserEventBus['publishFlow']>(
    events => {
      publishFlowOwn(EEventType.UserEvent, ...events);
    },
    [publishFlowOwn]
  );

  useEffect(() => {
    if (callback) {
      subscribe(EEventType.UserEvent, callback);
      return () => unsubscribe(EEventType.UserEvent);
    }
  }, [callback, subscribe, unsubscribe]);

  return {
    publish,
    publishTop,
    publishFlow,
  };
};
