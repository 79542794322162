import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';

export const Number = styled(Typography)(
  ({ theme }) => css`
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${theme.palette.secondary.A700};
    border-radius: 50%;
  `
);
