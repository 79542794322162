import { EBalanceType } from 'domain/model';
import { BalanceTabs } from '../../../components/balanceTabs/controls';
import { MPTab } from 'theme/ui-kit/tab';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { getUserFinancesRoute } from '../../../entry';

type BalanceTabsAdapterProps = {
  readonly tab: EBalanceType;
};

export const BalanceTabsAdapter = (props: BalanceTabsAdapterProps) => {
  const { tab } = props;
  const { isCorpUser } = useAuthUser();

  const history = useHistory();

  const onChangeTab = useCallback(
    (newTab: EBalanceType) => {
      history.push(getUserFinancesRoute({ type: newTab }));
    },
    [history]
  );

  return (
    <BalanceTabs
      value={tab}
      onChange={(event, tab) => onChangeTab(tab)}
    >
      {isCorpUser && (
        <MPTab
          label='Бонусный пакет'
          value={EBalanceType.Bonuses}
        />
      )}
    </BalanceTabs>
  );
};
