import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { getUserProfileRoute } from 'features/user/entry';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { NeedFillProfileEventProps } from './types';

export const NeedFillProfileEventContainer: FC<NeedFillProfileEventProps> = ({
  event: {
    payload: { message },
  },
  onNext,
}) => {
  const history = useHistory();

  const onFill = () => {
    history.push(getUserProfileRoute());
    onNext();
  };

  return (
    <DialogueEventContentBuilderLayout
      title='Необходимо заполнить профиль'
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onFill}
          >
            Заполнить профиль
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      {message || 'Для получения предложения необходимо заполнить профиль'}
    </DialogueEventContentBuilderLayout>
  );
};
