import styled from '@emotion/styled';
import { svgIconClasses } from '@mui/material';
import { FavoriteOutlineIcon, IconFavoriteFilled } from 'presentation/media/icons';

export const IconOn = styled(IconFavoriteFilled)`
  &.${svgIconClasses.root} {
    color: ${p => p.theme.palette.brand.main};
  }
`;

export const IconOff = styled(IconFavoriteFilled)`
  color: ${p => p.theme.palette.secondary.A900};
`;

export const IconOffOutlined = styled(FavoriteOutlineIcon)``;
