import { EOfferType } from 'domain/model';
import { useAuth } from 'features/auth/provider/useAuth';
import { OfferFavoriteLinkContainer } from '../../../favorite';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';

type TradeOfferDetailsFavoriteAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

const TradeOfferDetailsFavoriteAdapter = ({ guid, id }: TradeOfferDetailsFavoriteAdapterProps) => {
  const { isAuthenticated } = useAuth();
  const { tradeOffer } = useTradeOfferDetailsData(id);

  if (!tradeOffer || !isAuthenticated) {
    return null;
  }

  return (
    <OfferFavoriteLinkContainer
      guid={guid}
      offerId={id}
      offerType={EOfferType.TradeOffer}
    />
  );
};

export default TradeOfferDetailsFavoriteAdapter;
