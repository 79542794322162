import styled from '@emotion/styled';

export const ItemWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.light};
  padding: ${p => p.theme.spacing(2)} 0;

  :last-child {
    border-bottom: 1px solid ${p => p.theme.palette.secondary.light};
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(0.5)};

  margin-top: 2.25rem;
  margin-bottom: 1.5rem;
`;
