import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import BookingOfferListFilter from 'features/offer/booking/filter';
import DataFilterView from '../../../../../../components/common/dataFilter/view';
import BackButton from '../../../../components/back';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import {
  bookingOfferAllServicesSelector,
  bookingOfferAvailableServicesSelector,
  bookingOfferExtremumPriceRangeSelector,
  makeBookingOfferUnionIsFetchingSelector,
} from '../../store/selectors';
import { BookingOfferListFilterCount } from '../../types';
import { BookingOfferListAsideAdapter } from '../aside';
import { ChipsContainer, SmartPhoneFixedBarWrapper } from './controls';

type BookingOfferListMobileFilterAdapterProps = BookingOfferListFilterCount & {
  onBack: VoidFunction;
};

export const BookingOfferListMobileFilterAdapter: FC<BookingOfferListMobileFilterAdapterProps> = ({
  filterCount,
  onBack,
}) => {
  const searchParams = useContextSearchParams();
  const { services } = searchParams;
  const isFilterFetching = useSelector(state => makeBookingOfferUnionIsFetchingSelector(searchParams)(state));

  const extremumPriceRange = useSelector(state => bookingOfferExtremumPriceRangeSelector(state, searchParams));

  const allServices = useSelector(state => bookingOfferAllServicesSelector(state, searchParams));
  const availableServices = useSelector(state => bookingOfferAvailableServicesSelector(state, searchParams));
  const { minPrice, maxPrice, rzdSocialPackage } = useContextSearchParams();
  const priceFilter = {
    minPrice,
    maxPrice,
  };

  const { onSelectService, onClearSelectedServices, onChangePriceRange, onResetFilters, onChangeCompensation } =
    useContextHandlers();

  const selectedChips = allServices?.filter(({ id }) => id && (!services ? false : services.includes(id)));

  return (
    <SmartPhoneFixedBarWrapper>
      <MPGrid
        spacing={2}
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        width='100%'
      >
        <MPGrid
          item
          mb={1}
        >
          <BackButton
            label='Фильтр'
            onClick={onBack}
          />
        </MPGrid>
        <MPGrid
          item
          px={2}
        >
          {!!selectedChips?.length && (
            <ChipsContainer>
              <DataFilterView
                values={selectedChips}
                onDelete={onSelectService}
                onClear={onClearSelectedServices}
              />
            </ChipsContainer>
          )}
          <BookingOfferListAsideAdapter filterCount={filterCount} />

          <BookingOfferListFilter
            isFilterFetching={isFilterFetching}
            allServices={allServices}
            services={services}
            availableServices={availableServices}
            extremumPriceRange={extremumPriceRange}
            priceFilter={priceFilter}
            rzdSocialPackage={rzdSocialPackage}
            filterCount={filterCount}
            onChangePriceRange={onChangePriceRange}
            onChangeCompensation={onChangeCompensation}
            onSelectService={onSelectService}
            onResetFilters={onResetFilters}
          />
        </MPGrid>
      </MPGrid>
    </SmartPhoneFixedBarWrapper>
  );
};
