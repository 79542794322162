import styled from '@emotion/styled';
import { css, linearProgressClasses } from '@mui/material';
import { HTMLAttributes } from 'react';

const progressHeight = '1px';

type WrapperProps = HTMLAttributes<HTMLDivElement> & {
  readonly top: number;
};

export const Wrapper = styled(({ top, ...props }: WrapperProps) => <div {...props} />)(
  ({ theme, top }) => css`
    position: fixed;
    z-index: ${theme.zIndex.appBar + 1};
    top: calc(${top}px - ${progressHeight});
    left: 0;
    right: 0;

    .${linearProgressClasses.root} {
      height: ${progressHeight};
    }
  `
);
