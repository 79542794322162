import styled from '@emotion/styled';
import { css } from '@mui/material';

export const SmartPhoneFixedBarWrapper = styled.div(
  ({ theme }) => css`
    height: 100vh;
    position: fixed;
    z-index: ${theme.zIndex.drawer};
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    overflow: scroll;
    background: ${theme.palette.secondary.A400};
  `
);

export const ChipsContainer = styled.div(
  ({ theme: { spacing } }) => css`
    margin: ${spacing(2)} 0;
  `
);
