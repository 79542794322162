import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { EDateFormat } from 'domain/model';
import moment from 'moment';
import { pluralize } from 'presentation/utils';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';

type CorpOfferGetCertProps = {
  readonly open: boolean;
  readonly price: Nullable<number>;
  readonly offerName: Nullable<string>;
  readonly offerEndDate: Nullable<string>;
  readonly activationExpiryDays: number;
  readonly onGetCertificate: () => void;
  readonly onClose: () => void;
};

const CorpOfferGetCertDialog = ({
  open,
  price,
  offerName,
  offerEndDate,
  activationExpiryDays,
  onGetCertificate,
  onClose,
}: CorpOfferGetCertProps) => {
  const activationExpiryDate = moment(offerEndDate).endOf('day').add(activationExpiryDays, 'day');

  return (
    <MPConfirmDialog
      open={open}
      title='Обменять баллы на сертификат?'
      closeButtonVisible
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            onClick={onGetCertificate}
          >
            Да, обменять
          </MPButton>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            variant='text'
            onClick={onClose}
          >
            Нет
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <Typography variant='body2'>
        Обменять {price} {price !== null && pluralize(price, ['балл', 'балла', 'баллов'])} на сертификат предложения «
        {offerName}
        »?
      </Typography>
      <Splitter size={3} />
      <Typography
        variant='body2'
        fontWeight='bold'
      >
        Внимание!
      </Typography>
      <Typography variant='body2'>
        В случае не использования сертификата или отказа от корпоративного предложения, бонусные баллы на счет не
        возвращаются.
      </Typography>
      <Splitter size={3} />
      <Typography variant='body2'>
        Сертификат действителен до {moment(activationExpiryDate).format(EDateFormat.Human)} года.
      </Typography>
    </MPConfirmDialog>
  );
};

export default CorpOfferGetCertDialog;
