import * as H from 'history';
import { Location } from 'history';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { SearchResultsLocationState } from 'presentation/features/search/entry';
import { ERenderSource, ESearchUrlParam } from 'presentation/types';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
import { GetSearchResultsRouteProps } from '../types';
import { ECorpOfferDetailsTab } from './details/utils';
import { ECorpOfferUrlParam } from './utils';

const root = rootRouting.corpOffer;
export const routing = {
  list: root,
  search: `${root}/search`,
  details: `${root}/:id`,
};

export type CorpOffersListLocationState = {
  readonly guid: UUID;
};

export type CorpOfferDetailsLocationState = {
  readonly guid: UUID;
  readonly from?: ERenderSource;
};

type GetCorpOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

type GetCorpOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: ECorpOfferDetailsTab;
  readonly from?: ERenderSource;
};

type GetCorpOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly partnerId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
};

export const getCorpOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getCorpOffersSearchRoute = (props: GetCorpOffersSearchRouteProps) => {
  const { name, categoryId, guid, partnerId } = props;
  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ECorpOfferUrlParam.Category, categoryId);
  }

  if (partnerId) {
    params.append(ECorpOfferUrlParam.PartnerId, partnerId);
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getCorpOffersListRoute = (props?: GetCorpOffersListRouteProps): Location<CorpOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getCorpOfferDetailsRoute = ({
  id,
  tab,
  guid,
  from,
}: GetCorpOfferDetailsRouteProps): H.Location<CorpOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(ECorpOfferUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
      from,
    },
    hash: '',
  };
};
