import styled from '@emotion/styled';
import { css } from '@mui/material';

export const ImageWrapper = styled.div(
  () => css`
    width: 250px;
    height: auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  `
);
