import { AnyDisplayEvent, EEventPriority } from '@privilege-frontend/eventBus';
import FastPriorityQueue from 'fastpriorityqueue';
import { EventsFlowEngine } from '../../types';
import { getEventsFlowEngineCommon } from '../common';

export * from './types';

const comparator = (e1: AnyDisplayEvent, e2: AnyDisplayEvent) =>
  (e2.priority ?? EEventPriority.Default) > (e1.priority ?? EEventPriority.Default);

type EngineType = EventsFlowEngine<FastPriorityQueue<AnyDisplayEvent>>;

/**
 * обработка очереди событий, реализованная в рамках концепции PriorityQueue
 * @see [about](https://ru.wikipedia.org/wiki/%D0%9E%D1%87%D0%B5%D1%80%D0%B5%D0%B4%D1%8C_%D1%81_%D0%BF%D1%80%D0%B8%D0%BE%D1%80%D0%B8%D1%82%D0%B5%D1%82%D0%BE%D0%BC_(%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5))
 * */
export const eventsFlowEnginePriorityQueue: EngineType = getEventsFlowEngineCommon<FastPriorityQueue<AnyDisplayEvent>>({
  onCreateInitial: () => ({
    events: new FastPriorityQueue(comparator),
    activeFlow: null,
  }),
});
