import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { ProfileDeletedEventContainer } from './container';
import { ProfileDeletedEvent, ProfileDeletedEventPayload, profileDeletedEventType } from './types';

export type { ProfileDeletedEventPayload } from './types';

export const createEvent = (payload: ProfileDeletedEventPayload): ProfileDeletedEvent =>
  EventCreatorFactory.business()
    .getCustom(profileDeletedEventType, ProfileDeletedEventContainer)
    .withPayload(payload)
    .makeAsFeature()
    .get();
