import { RootState } from 'data/store/store';
import { EOfferType } from 'domain/model';
import { createSelector } from '@reduxjs/toolkit';
import { UserFavoriteProcess } from '../../../../../domain/model';

const sessionsSelector = (state: RootState) => state.favorites.provide;
const sessionGuidSelector = (state: RootState, guid: UUID) => guid;
const offerTypeSelector = (state: RootState, guid: UUID, offerType: EOfferType) => offerType;
const processIdSelector = (state: RootState, guid: UUID, processId: UUID) => processId;

export const favoriteProvideByProcessIdSelector = (guid: UUID, processId: UUID) => (state: RootState) =>
  createFavoriteProvideByProcessIdSelector(state, guid, processId);

export const createFavoriteProvideByProcessIdSelector = createSelector(
  sessionsSelector,
  sessionGuidSelector,
  processIdSelector,
  (sessions, guid, processId) =>
    (sessions?.[guid]?.processes as UserFavoriteProcess[])?.find(p => p.id === processId) ?? null
);

export const favoriteProvideHasOffersChangesByTypeSelector = createSelector(
  sessionsSelector,
  sessionGuidSelector,
  offerTypeSelector,
  (sessions, guid, offerType): boolean =>
    !!sessions?.[guid]?.processes?.filter(item => item.offerType === offerType)?.length
);

export const createFavoriteProvideHasChangesSelector = (guid: UUID, offerType: EOfferType) => (state: RootState) =>
  favoriteProvideHasOffersChangesByTypeSelector(state, guid, offerType);
