import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SocialPackageApplicationForm } from 'domain/model';
import { ValidationResult } from 'presentationUtils/validation';

export type CspApplicationState = {
  readonly guid: Nullable<UUID>;
  readonly form: SocialPackageApplicationForm;
  readonly validation: Nullable<ValidationResult<SocialPackageApplicationForm>>;
};

type Reducer<T = undefined> = CaseReducer<CspApplicationState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CspApplicationState> & {
  cspApplicationStartSession: Reducer<{ guid: UUID }>;
  cspApplicationSetFormAttribute: Reducer<{ name: keyof CspApplicationState['form']; value: any }>;
  cspApplicationSetValidationResult: Reducer<Nullable<ValidationResult<SocialPackageApplicationForm>>>;
  cspApplicationClearValidationResult: Reducer<keyof CspApplicationState['form'] | undefined>;
};

const initialState: CspApplicationState = {
  guid: null,
  form: {
    year: null,
    month: null,
    familyMembersCount: null,
    withFamilyMembers: false,
  },
  validation: null,
};

const slice = createSlice<CspApplicationState, Reducers, 'details'>({
  name: 'details',
  initialState,
  reducers: {
    cspApplicationStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (state.guid !== guid) {
        state.form = initialState.form;
      }

      state.guid = guid;
    },
    cspApplicationSetFormAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.form = {
        ...state.form,
        [name]: value,
      };
    },
    cspApplicationSetValidationResult: (state, { payload }) => {
      state.validation = payload;
    },
    cspApplicationClearValidationResult: (state, { payload }) => {
      if (payload && state.validation) {
        delete state.validation[payload];
      } else {
        state.validation = null;
      }
    },
  },
});

export const {
  cspApplicationStartSession,
  cspApplicationSetFormAttribute,
  cspApplicationSetValidationResult,
  cspApplicationClearValidationResult,
} = slice.actions;

export default slice.reducer;
