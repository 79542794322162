import { SocialPackageApplicationForm } from 'domain/model';
import { ValidationRules } from 'presentationUtils/validation';

export const cspApplicationValidationRules: ValidationRules<SocialPackageApplicationForm> = {
  year: {
    required: true,
    requiredMessage: 'Укажите месяц заезда',
  },
  month: {
    required: true,
    requiredMessage: 'Укажите месяц заезда',
  },
  familyMembersCount: {
    required: ({ withFamilyMembers }) => withFamilyMembers,
    requiredMessage: 'Укажите количество членов семьи',
  },
};
