import StubCommon from 'presentation/components/common/stub/common';
import { offersStub } from 'presentation/media/images';

type BenefitTransactionsProps = {
  readonly text?: string;
};

export const BenefitTransactionsStub = ({
  text = 'Здесь будут записи обо всех операциях баланса бонусного пакета',
}: BenefitTransactionsProps) => {
  return (
    <StubCommon
      title={text}
      image={offersStub}
    />
  );
};
