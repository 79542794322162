import { Typography, useMediaQuery } from '@mui/material';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import React from 'react';
import CodeTitle from '../../../../../components/common/codeTitle/title';
import { Bold } from '../../../../user/details/activations/controls';
import { SidebarWrapper } from '../controls';

type ActivationListItemUpcomingSidebarProps = {
  readonly offerName: Nullable<string>;
  readonly offerCode: Nullable<string>;
  readonly startDate: Nullable<string>;
  readonly subscription?: React.ReactNode;
  readonly onOfferClick: () => void;
};

const ActivationListItemUpcomingSidebar = (props: ActivationListItemUpcomingSidebarProps) => {
  const { offerName, offerCode, startDate, subscription, onOfferClick } = props;

  const isSmDown = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { x2 } = useGridSpacing();

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={x2}
        direction='column'
      >
        {isSmDown && (
          <MPGrid item>
            <Bold onClick={onOfferClick}>{offerName}</Bold>
          </MPGrid>
        )}
        <MPGrid item>
          <CodeTitle code={offerCode} />
          <Typography color='error'>
            Предложение будет доступно{' '}
            {startDate && (
              <Typography
                component='span'
                noWrap
              >
                с {moment(startDate).format(EDateFormat.Human)} года
              </Typography>
            )}
          </Typography>
        </MPGrid>
        {subscription && <MPGrid item>{subscription}</MPGrid>}
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemUpcomingSidebar;
