import { Text } from '../../components/controls';
const AboutCspWhenBuyInfo = () => {
  return (
    <>
      <Text>
        Договор на приобретение путевки (и дата оплаты) должен быть заключен работником не ранее даты принятия решения
        Комиссией по распределению путевок на предоставление КСП на оздоровление и отдых
      </Text>
    </>
  );
};

export default AboutCspWhenBuyInfo;
