import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import {
  SocialPackageRefuseActionsAdapter,
  SocialPackageRefuseContentAdapter,
  SocialPackageRefuseTitleAdapter,
} from './adapters';
import { SocialPackageRefuseEventProps } from './types';

/**
 * отказ от КСП
 */

export const SocialPackageRefuseEventContainer: FC<SocialPackageRefuseEventProps> = props => {
  return (
    <DialogueEventContentBuilderLayout
      title={<SocialPackageRefuseTitleAdapter />}
      actions={<SocialPackageRefuseActionsAdapter {...props} />}
    >
      <SocialPackageRefuseContentAdapter />
    </DialogueEventContentBuilderLayout>
  );
};
