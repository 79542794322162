import ContentLoader from 'components/common/loader';
import { FCC } from 'react';
import { useSelector } from 'react-redux';
import { cspApplicationIsFetchingSelector } from '../store/selectors';

export const CspApplicationLoaderAdapter: FCC = ({ children }) => {
  const isFetching = useSelector(cspApplicationIsFetchingSelector);

  return isFetching ? <ContentLoader /> : children;
};
