import { useGetCorpOfferDetailsQuery } from 'data/api/corpOffer';
import { EBalanceType, EOfferType, OfferPromotionType } from 'domain/model';
import { useBonusesBalance } from 'features/user/current/hooks/useBonusesBalance';
import moment from 'moment-timezone';
import { FCC, useCallback, useEffect } from 'react';
import { AppConfigurator } from 'system/appConfigurator';
import useOfferActivations from '../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../hooks/useOfferSubscription';
import useTakeOfferActivations from '../../../hooks/useTakeOfferActivations';
import CorpOfferClaimContext, { CorpOfferClaimContextType } from './context';

type CorpOfferClaimProviderProps = {
  readonly id: UUID;
  readonly promotionType: OfferPromotionType;
};

const CorpOfferClaimProvider: FCC<CorpOfferClaimProviderProps> = props => {
  const { children, id, promotionType } = props;

  const { data: corpOffer = null, refetch: corpOfferRefetch } = useGetCorpOfferDetailsQuery(
    { id },
    { refetchOnMountOrArgChange: true }
  );

  const { onTakeActivation, isTaking, onCopy, isTaked, onNeedCopy, needCopy } = useTakeOfferActivations();
  const activation = useOfferActivations({
    offerId: id,
    offerPrice: corpOffer?.price,
    offerRefetch: corpOfferRefetch,
    balanceType: EBalanceType.Bonuses,
    onTakeActivation,
  });

  const { isUserBalanceNotEnough, isLoadActivationsFetching, lastReceivedActivation } = activation;

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    offer: corpOffer,
    offerType: EOfferType.CorpOffer,
    promotionType,
  });

  const { value: userBalance, isFetching: userBalanceIsFetching, refreshBalance } = useBonusesBalance();
  const earnPointsUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl() ?? '';

  const onActivate = useCallback(async () => {
    if (!corpOffer) {
      return null;
    }
    activation.userActivationAttempt(corpOffer);
    return await activation.activateOffer(corpOffer);
  }, [corpOffer, activation]);

  useEffect(() => {
    if (isUserBalanceNotEnough) {
      refreshBalance();
    }
  }, [isUserBalanceNotEnough, refreshBalance]);

  const isFetching = isLoadActivationsFetching || (isUserBalanceNotEnough && userBalanceIsFetching);
  const endDate = corpOffer ? moment(corpOffer.endDate).endOf('day') : null;

  const isActivationReceivedNow = !!lastReceivedActivation;

  const value: CorpOfferClaimContextType = {
    corpOffer,
    endDate,
    isFetching,
    earnPointsUrl,
    userBalance,
    activation,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isActivationReceivedNow,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onTakeActivation,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking,
  };

  return <CorpOfferClaimContext.Provider value={value}>{children}</CorpOfferClaimContext.Provider>;
};

export default CorpOfferClaimProvider;
