import { EBannerPartition } from 'domain/model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BannersMainTopContainer from '../../../../banners/mainTop/container';
import { productOfferListSearchIsEmptySelector } from '../store/selectors';

type ProductOfferListBannersAdapterProps = {
  readonly guid: UUID;
}

const ProductOfferListBannersAdapter = ({ guid }: ProductOfferListBannersAdapterProps) => {
  const isEmptySearch = useSelector(productOfferListSearchIsEmptySelector);

  return useMemo(
    () =>
      isEmptySearch ? (
        <BannersMainTopContainer
          guid={guid}
          partition={EBannerPartition.ProductOffers}
        />
      ) : null,
    [guid, isEmptySearch]
  );
};

export default ProductOfferListBannersAdapter;
