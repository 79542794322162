import { useGetCmsPartnerDesksCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import PartnerDeskCard from 'presentation/features/partnerDesk/components/card';
import { PartnerGrid } from 'presentation/features/partnerDesk/components/grid';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { CmsCollectionLayoutType } from '../layouts';
import { CmsContainerTypedCommonProps } from '../types';
import { CmsPartnersCollectionShowAllAdapter } from './adapters/partnersShowAll';

type CmsPartnerCollectionProps = CmsContainerTypedCommonProps<CmsCollectionLayoutType> & {
  readonly id: UUID;
};

export const CmsPartnerCollection: React.FCC<CmsPartnerCollectionProps> = ({
  guid,
  id,
  header,
  footer,
  layout: Layout,
  onDataLoaded,
}) => {
  const history = useHistory();

  const { data, error, isFetching } = useGetCmsPartnerDesksCollectionQuery({ guid, id });

  const onPartnerClick = (id: UUID) => {
    history.push(getPartnerDeskDetailsRoute({ id }));
  };

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.length && !isFetching) onDataLoaded?.();
  }, [data, isFetching, onDataLoaded]);

  const Footer = footer;
  const hasContent = data && data.length > 0;

  if (!data?.length) return null;

  const dataOut = data;

  return (
    <Layout
      header={hasContent && header}
      showAll={hasContent && <CmsPartnersCollectionShowAllAdapter />}
      footer={Footer && <Footer hasContent={hasContent} />}
    >
      <PartnerGrid>
        {dataOut?.map(partnerDesk =>
          partnerDesk.partner ? (
            <PartnerDeskCard
              key={partnerDesk.id}
              title={partnerDesk.partner.name}
              image={partnerDesk.partner.logo}
              onClick={() => onPartnerClick(partnerDesk.id)}
            />
          ) : null
        )}
      </PartnerGrid>
    </Layout>
  );
};
