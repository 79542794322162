import { ELocaleShort } from './presentation/types';

export const locale = {
  short: ELocaleShort.Ru,
  full: 'ru-RU',
  isoCode: 'Rub',
};

export enum currencySymbols {
  RU = '₽',
}
