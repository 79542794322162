import { Wrapper } from './controls';

type NumberedTextProps = {
  readonly number: number;
  readonly children: any;
};

export const NumberedText = (props: NumberedTextProps) => {
  const { number, children } = props;
  return (
    <Wrapper>
      <span>{number}.</span>
      {children}
    </Wrapper>
  );
};
