import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const needFillProfileEventType = 'NeedFillProfile';

export type NeedFillProfileEventPayload = {
  readonly message?: Nullable<string>;
};

export type NeedFillProfileEvent = BusinessEvent<NeedFillProfileEventPayload>;

export type NeedFillProfileEventProps = DisplayEventComponentProps<NeedFillProfileEventPayload>;
