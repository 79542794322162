/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { Override } from '../../types.ts';

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 * Тело запроса jsonpatch
 * @minItems 1
 */
export type GenCmsJsonPatchRequest = GenCmsJsonPatchOperation[];

/**
 * A JSONPatch document as defined by RFC 6902
 * DTO ресурса из метода PUT
 * @example "{
 *  "op": "move",
 *  "from": "/containers/1",
 *  "path": "/containers/0",
 * }
 * "
 */
export interface GenCmsJsonPatchOperation {
  /** The operation to be performed */
  op: 'add' | 'remove' | 'replace' | 'move' | 'copy' | 'test';
  /** A string containing a JSON Pointer value. */
  from: string | null;
  /** A JSON-Pointer */
  path: string;
  /** The value to be used within the operations. */
  value: object | null;
}

/**
 * ID партнёра
 * @format uuid
 */
export type GenCmsPartnerId = string;

/**
 * ID категории
 * @format uuid
 */
export type GenCmsCategoryId = string;

/**
 * ID предложения
 * @format uuid
 */
export type GenCmsOfferId = string;

/** Структура подразделения РЖД, содержащиая только ИД */
export interface GenCmsOrgUnitLink {
  /** Идентификатор подразделения */
  id: GenCmsOrgUnitId;
}

/**
 * Идентификатор подразделения
 * @format uuid
 */
export type GenCmsOrgUnitId = string;

/** Подразделение */
export type GenCmsOrgUnit = Override<
  UtilRequiredKeys<GenCmsOrgUnitLink, 'id'>,
  {
    /** Структура подразделения РЖД, содержащиая только ИД */
    parent: GenCmsOrgUnitLink | null;
    /** Наименование подразделения */
    name: string;
    /** Полное наименование подразделения */
    fullName: string | null;
    /** Код ЕКАСУТР */
    ekasutrId: string | null;
    /** Тип организации */
    type: string | null;
  }
>;

/** Объект аудита */
export interface GenCmsAuditEntity {
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * ИД автора
   * @format uuid
   */
  createdBy: string | null;
  /**
   * Дата обновления
   * @format date-time
   */
  updatedAt: string | null;
  /**
   * ИД автора
   * @format uuid
   */
  updatedBy: string | null;
}

/** Стандартный ответ при негативном сценарии */
export interface GenCmsError {
  /** Код ошибки(например PRODUCT_NOT_FOUND) */
  code: string;
  /** Сообщение */
  message: string;
  /** Доп информация */
  description: string | null;
}

/**
 * Pageable
 * структура для пагинации и сортировки(сортировка вида firstName,asc)
 */
export interface GenCmsPageable {
  /**
   * необходимая страница
   * @format int32
   * @min 0
   */
  page: number | null;
  /**
   * Количество элементов на страницу
   * если указать больше maximum, то будет size=maximum
   * независимо от size в запросе
   * @format int32
   * @min 1
   * @max 1000
   * @default 100
   */
  size: number | null;
  /** сортировка вида firstName,asc */
  sort: string[] | null;
}

/** Структура для выдачи элемента справочника */
export interface GenCmsOption {
  /** @format uuid */
  id: string;
  /** код справочной сущности сущности для показа в ui(может отсутствовать) */
  code: string | null;
  /** название справочной сущности для показа в ui */
  name: string;
}

/**
 * Структура для фильтра с dsl
 * @example "{
 *   "field": "name",
 *   "operator": "=",
 *   "value": ["example"]
 * }
 * "
 */
export interface GenCmsPredicate {
  /** название поля сущности */
  field: string;
  /**
   * оператор: like - регистронезависимый like
   *        [string]% - поиск строки по началу
   *        %[string]% - поиск строки по вхождению
   */
  operator: '=' | '<' | '>' | '>=' | '<=' | 'in' | 'like';
  /**
   * значение параметра фильтра
   * @minItems 1
   */
  value: string[];
}

/** Блокировка пользователем */
export interface GenCmsExclusiveLock {
  /**
   * Дата создания
   * @format date-time
   */
  lockedAt: string | null;
  /** Структура для выдачи элемента справочника */
  lockedBy: GenCmsOption | null;
}

/**
 * Тип таргетирования
 * * GEO - географическое
 * * CORP - корпоративное
 */
export enum GenCmsTargetType {
  Geo = 'GEO',
  Corp = 'CORP',
}

/** Опции таргета на сайте */
export type GenCmsTarget = UtilRequiredKeys<GenCmsTargetRequest, 'targetType'> & {
  /** Мультиселектор - Населённые пункты для таргетирования */
  targetLocalities: GenCmsLocalityMultiSelectorPredicate | null;
  targetRoads: GenCmsRoadMultiSelectorPredicate | null;
  targetOrgUnits: GenCmsOrgUnitMultiSelectorPredicate | null;
  targetClientOrgs: GenCmsClientOrgMultiSelectorPredicate | null;
};

/** Опции таргета на сайте */
export interface GenCmsTargetRequest {
  /**
   * Тип таргетирования
   * * GEO - географическое
   * * CORP - корпоративное
   */
  targetType: GenCmsTargetType | null;
  targetLocalities: GenCmsLocalityMultiSelectorPredicateRequest | null;
  targetRoads: GenCmsRoadMultiSelectorPredicateRequest | null;
  targetOrgUnits: GenCmsOrgUnitMultiSelectorPredicateRequest | null;
  targetClientOrgs: GenCmsClientOrgMultiSelectorPredicateRequest | null;
  /** Внешние пользователи */
  targetExternalUsers: boolean | null;
  targetGender: GenCmsGenderMultiSelectorPredicate | null;
  /** Только для членов профсоюза */
  targetTradeUnionMembersOnly: boolean | null;
  /** Только если есть ребенок до 18 лет */
  targetHavingChildFamilyMemberOnly: boolean | null;
  /** Только для членов семьи */
  targetFamilyMemberOnly: boolean | null;
}

/** Оператор утверждения(all - все значения перечисления, in - подмножество)) */
export enum GenCmsMultiSelectorValueType {
  All = 'all',
  In = 'in',
}

/**
 * Утверждение о том, что выбраны все или  некоторые значения из множества
 * При type=all значения из поля in игнорируются
 */
export interface GenCmsMultiSelectorPredicate {
  /** Оператор утверждения(all - все значения перечисления, in - подмножество)) */
  select: GenCmsMultiSelectorValueType;
}

/** Мультиселектор - Населённые пункты для таргетирования */
export type GenCmsLocalityMultiSelectorPredicate = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsAddress[] | null;
};

export type GenCmsLocalityMultiSelectorPredicateRequest = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsAddressObjectLink[] | null;
};

export type GenCmsRoadMultiSelectorPredicateRequest = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsRoadLink[] | null;
};

export type GenCmsRoadMultiSelectorPredicate = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsRoad[] | null;
};

export type GenCmsOrgUnitMultiSelectorPredicateRequest = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsOrgUnitLink[] | null;
};

export type GenCmsOrgUnitMultiSelectorPredicate = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsOrgUnit[] | null;
};

export type GenCmsClientOrgMultiSelectorPredicate = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsClientOrgShort[] | null;
};

/** Организация клиент */
export type GenCmsClientOrgShort = Override<
  UtilRequiredKeys<GenCmsClientOrgLink, 'id'>,
  {
    /** Название организации */
    name: string;
    /**
     * Код организации(== коду группы в кс)
     * После создания изменению не подлежит
     * @max 10
     */
    code: string;
    /** Домен для SaaS */
    domain: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    logo: GenCmsFileLink | null;
    /** структура для ссылки и описания ресурса(файла) */
    favicon: GenCmsFileLink | null;
    colorBrand: string | null;
    colorPrimary: string | null;
  }
>;

/** Ссылка на организацию клиент */
export interface GenCmsClientOrgLink {
  /** ID организации */
  id: GenCmsClientOrgId;
}

export type GenCmsGenderMultiSelectorPredicate = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsGender[] | null;
};

export type GenCmsClientOrgMultiSelectorPredicateRequest = GenCmsMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenCmsClientOrgLink[] | null;
};

/**
 * ID организации
 * @format uuid
 */
export type GenCmsClientOrgId = string;

/**
 * Значение для таргета предпросмотра.
 * JSON закодированный в [BASE64URL](https://www.rfc-editor.org/rfc/rfc4648#section-5)!
 * Пример: eyJ0YXJnZXRUeXBlIjoiR0VPIiwidGFyZ2V0TG9jYWxpdGllcyI6eyJzZWxlY3QiOiJJTiIsImluIjpbeyJpZCI6IjBjNWIyNDQ0LTcwYTAtNDkzMi05ODBjLWI0ZGMwZDNmMDJiNSJ9XX0sInRhcmdldFJvYWRzIjp7InNlbGVjdCI6IklOIiwgImluIjpbeyJpZCI6IjdjOGU1YjYxLWUwNGMtNDNmMS05ZTE1LTkyYTc0M2QxMTY1OSJ9XX0sICJ0YXJnZXRPcmdVbml0cyI6eyJzZWxlY3QiOiJJTiIsICJpbiI6W3siaWQiOiI3M2M4YTU3ZS02ZWE0LTQyOGQtOTliZS1mMzZlMGFkYzU0MWUifV19LCAidGFyZ2V0QXVkaWVuY2UiOnsic2VsZWN0IjoiSU4iLCJpbiI6WyJDT1JQX1VTRVIiXX0sInRhcmdldEdlbmRlciI6eyJzZWxlY3QiOiJBTEwifSwidGFyZ2V0VHJhZGVVbmlvbk1lbWJlcnNPbmx5IjpmYWxzZSwidGFyZ2V0SGF2aW5nQ2hpbGRGYW1pbHlNZW1iZXJPbmx5IjpmYWxzZSwgInRhcmdldEZhbWlseU1lbWJlck9ubHkiOmZhbHNlfQ
 * Для Json: {"targetType":"GEO","targetLocalities":{"select":"IN","in":[{"id":"0c5b2444-70a0-4932-980c-b4dc0d3f02b5"}]},"targetRoads":{"select":"IN", "in":[{"id":"7c8e5b61-e04c-43f1-9e15-92a743d11659"}]}, "targetOrgUnits":{"select":"IN", "in":[{"id":"73c8a57e-6ea4-428d-99be-f36e0adc541e"}]}, "targetClientOrgs":{"select":"IN","in":["73c8a57e-6ea4-428d-99be-f36e0adc541e"]}, "targetExternalUsers": true,"targetGender":{"select":"ALL"},"targetTradeUnionMembersOnly":false,"targetHavingChildFamilyMemberOnly":false, "targetFamilyMemberOnly":false}
 * @example ""
 */
export type GenCmsTargetBase64 = GenCmsTargetRequest;

/** Пол */
export enum GenCmsGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

/** Структура дороги, содержащая только ИД */
export interface GenCmsRoadLink {
  /**
   * Идентификатор дороги
   * @format uuid
   */
  id: string;
}

/** Дорога */
export type GenCmsRoad = Override<
  UtilRequiredKeys<GenCmsRoadLink, 'id'>,
  {
    /** Наименование дороги */
    name: string;
  }
>;

/**
 * ID страницы
 * @format uuid
 */
export type GenCmsSitePageId = string;

/** Данные страницы сайта Бизнес правило: partner - для одного партнера можно создать только одну страницу! При указании containers будут клонированы указанные имеющиеся контейнеры Страница создается с флагом enabled=false */
export interface GenCmsSitePageCreateRequest {
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
}

/** Данные страницы сайта Бизнес правило: partner - для одного партнера можно создать только одну страницу! При указании containers будут клонированы указанные имеющиеся контейнеры */
export interface GenCmsSitePageUpdateRequest {
  /**
   * Страница опубликована
   * @default false
   */
  enabled: boolean | null;
  /** @minItems 1 */
  containers: GenCmsContainerLink[] | null;
}

/** Страница сайта */
export interface GenCmsSitePage {
  /** ID страницы */
  id: GenCmsSitePageId;
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
  /**
   * Страница опубликована
   * @default false
   */
  enabled: boolean | null;
  /** @minItems 1 */
  containers: GenCmsContainer[] | null;
}

/**
 * ID визуального контейнера
 * @format uuid
 */
export type GenCmsContainerId = string;

/** Контейнер для копирования визуальных компонентов страницы - link */
export interface GenCmsContainerCopyLink {
  /** ID визуального контейнера */
  id: GenCmsContainerId;
}

/** Контейнер визуальных компонентов страницы - link */
export interface GenCmsContainerLink {
  /** ID визуального контейнера */
  id: GenCmsContainerId;
}

/** Тип контейнера */
export interface GenCmsContainerType {
  /** Код типа контейнера */
  code: string;
  /** Название типа контейнера */
  name: string;
}

/** Ссылка на тип контейнера (по коду) */
export interface GenCmsContainerTypeLink {
  /** Код типа контейнера */
  code: string;
}

/**
 * Статус визуального контейнера
 * * DRAFT - черновик
 * * UPCOMING - предстоящий (дата размещения еще не наступила)
 * * ACTIVE - активный (дата размещения наступила и срок размещения еще не истек)
 * * PAUSED - черновик (приостановлен админом)
 * * ARCHIVED - черновик (срок размещения истек или архивирован админом)
 */
export enum GenCmsContainerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

/** Основные данные для контейнера визуальных компонентов страницы */
export interface GenCmsContainerData {
  /** Название */
  name: string | null;
  /**
   * Иконка рядом с заголовком(например иконка предложения)
   * @default false
   */
  showIcon: boolean | null;
  /** Заголовок блока */
  title: string | null;
  /**
   * Показывать заголовок
   * @default false
   */
  showTitle: boolean | null;
  /**
   * Дата начала срока размещения
   * @format date
   */
  startDate: string | null;
  /**
   * Дата окончания срока размещения
   * @format date
   */
  endDate: string | null;
  /**
   * Опции таргета на сайте
   * @deprecated
   */
  target: GenCmsTargetRequest | null;
  /**
   * Количество видимых элементов внутри контейнера
   * @format int32
   */
  pageSize: number | null;
  /** Настройки дополнительного фильтра контенера */
  filterSettings: GenCmsCmsContainerFilterSettings | null;
}

/** Настройки дополнительного фильтра контенера */
export interface GenCmsCmsContainerFilterSettings {
  /** Допустим только один тип предложений внутри контейнера иначе любой */
  offerType: GenCmsOfferType | null;
  /** Пользовательский фильтр по настройкам таргета контейнера */
  target: GenCmsTargetRequest | null;
}

/** Основные данные для контейнера визуальных компонентов страницы в статусе draft */
export type GenCmsContainerDataDraft = Override<
  GenCmsContainerData,
  {
    /** Тип контейнера */
    type: GenCmsContainerType | null;
    /**
     * Опции таргета на сайте
     * @deprecated
     */
    target: GenCmsTarget | null;
  }
>;

/** Контейнер визуальных компонентов страницы */
export type GenCmsContainer = (GenCmsContainerLink & GenCmsContainerDataDraft & GenCmsAuditEntity) & {
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
  /**
   * Статус визуального контейнера
   * * DRAFT - черновик
   * * UPCOMING - предстоящий (дата размещения еще не наступила)
   * * ACTIVE - активный (дата размещения наступила и срок размещения еще не истек)
   * * PAUSED - черновик (приостановлен админом)
   * * ARCHIVED - черновик (срок размещения истек или архивирован админом)
   */
  status: GenCmsContainerStatus;
  /** Комментарий к текущему статусу. Например, причина перехода контейнера в статус пауза */
  statusComment: string | null;
  /** Блокировка пользователем */
  lock: GenCmsExclusiveLock | null;
  components: GenCmsComponent[] | null;
  /** Опции таргета на сайте */
  target: GenCmsTarget | null;
};

/** Данные для создания контейнера визуальных компонентов страницы */
export type GenCmsContainerCreateRequestData = Override<
  GenCmsContainerData,
  {
    /** Ссылка на тип контейнера (по коду) */
    type: GenCmsContainerTypeLink;
  }
>;

/** Команда на создание контейнера При указании containers будут клонированы указанные имеющиеся контейнеры */
export type GenCmsContainerCreateRequest = GenCmsBaseContainerCreateRequest &
  (
    | GenCmsBaseContainerCreateRequestDiscriminatorMapping<
        'ContainerCreateRequestData',
        GenCmsContainerCreateRequestData
      >
    | GenCmsBaseContainerCreateRequestDiscriminatorMapping<'ContainerCopyLink', GenCmsContainerCopyLink>
  );

/** Данные для обновления контейнера визуальных компонентов страницы */
export type GenCmsContainerDraftUpdateRequest = Override<
  GenCmsContainerData,
  {
    /** Ссылка на тип контейнера (по коду) */
    type: GenCmsContainerTypeLink | null;
    components: GenCmsComponentLink[] | null;
  }
>;

/** Данные для обновления контейнера - не в статусе черновик */
export type GenCmsValidContainerUpdateRequest = GenCmsContainerDraftUpdateRequest;

/** Команда на обновление контейнера */
export type GenCmsContainerUpdateRequest = GenCmsBaseContainerUpdateRequest &
  (
    | GenCmsBaseContainerUpdateRequestDiscriminatorMapping<
        'ContainerDraftUpdateRequest',
        GenCmsContainerDraftUpdateRequest
      >
    | GenCmsBaseContainerUpdateRequestDiscriminatorMapping<
        'ValidContainerUpdateRequest',
        GenCmsValidContainerUpdateRequest
      >
  );

/** Публикация визуального контейнера, в результате контейнер либо переводится в ACTIVE и публикуется на сайте, если тек. дата попадает в сроки размещения, либо в UPCOMING и становится предстоящим, если не попадает */
export interface GenCmsContainerPublishCommand {
  discriminator: string | null;
}

/** Взятие на редактирование пользователь вызвавший метод прописывается в объект lock */
export interface GenCmsContainerToEditCommand {
  discriminator: string | null;
}

/** Приостановка визуального контейнера (перевод в статус PAUSED) */
export interface GenCmsContainerPauseCommand {
  discriminator: string | null;
}

/** Возобновление визуального контейнера, в результате контейнер либо переводится в ACTIVE и публикуется на сайте, если тек. дата попадает в сроки размещения, либо в UPCOMING и становится предстоящим, если не попадает */
export interface GenCmsContainerResumeCommand {
  discriminator: string | null;
}

/** Архивация визуального контейнера (перевод в статус ARCHIVED) */
export interface GenCmsContainerArchiveCommand {
  discriminator: string | null;
}

/** Допустимые команды к ресурсу - контейнер */
export type GenCmsContainerCommand = GenCmsBaseContainerCommand &
  (
    | GenCmsBaseContainerCommandDiscriminatorMapping<'ContainerPublishCommand', GenCmsContainerPublishCommand>
    | GenCmsBaseContainerCommandDiscriminatorMapping<'ContainerToEditCommand', GenCmsContainerToEditCommand>
    | GenCmsBaseContainerCommandDiscriminatorMapping<'ContainerPauseCommand', GenCmsContainerPauseCommand>
    | GenCmsBaseContainerCommandDiscriminatorMapping<'ContainerResumeCommand', GenCmsContainerResumeCommand>
    | GenCmsBaseContainerCommandDiscriminatorMapping<'ContainerArchiveCommand', GenCmsContainerArchiveCommand>
  );

/**
 * ID визуального компонента
 * @format uuid
 */
export type GenCmsComponentId = string;

/** Визуальный компонент - ссылка */
export interface GenCmsComponentLink {
  /** ID визуального компонента */
  id: GenCmsComponentId;
}

/** Данные для создания визуального компонента */
export interface GenCmsComponentData {
  /** Зона в контейнере */
  zone: string | null;
  /** Высота */
  height: string | null;
  /** Ширина */
  width: string | null;
  /**
   * Количество элементов страницы компонента
   * @format int32
   */
  pageSize: number | null;
  /**
   * Тип объекта на который ссылается визуальный компонент
   * * BANNER - баннер
   * * PARTNER - партнер
   * * COLLECTION - подборка ресурсов
   * * VIDEO - видео-ресурсов
   * * TRADE_OFFER - ТП
   * * CORP_OFFER - КП
   * * PRODUCT_OFFER - ПП
   * * BOOKING_OFFER - Объект ДиО
   * * CORP_OFFER_CATEGORY - категория КП
   * * TRADE_OFFER_CATEGORY - категория ТП
   * * PRODUCT_OFFER_CATEGORY - категория ПП
   * * LINK - внешняя ссылка
   */
  linkObjectType: GenCmsLinkObjectType | null;
  /**
   * ID объекта для отображения
   * @format uuid
   */
  linkObjectId: string | null;
}

/** Визуальный компонент */
export type GenCmsComponent = Override<
  GenCmsComponentLink & GenCmsComponentData & GenCmsAuditEntity,
  {
    /** Ссылка на партнера */
    partner: GenCmsPartnerLink | null;
  }
>;

/** Запрос создания компонента */
export type GenCmsComponentCreateRequest = GenCmsComponentData;

/** Запрос обновления компонента */
export type GenCmsComponentUpdateRequest = GenCmsComponentData;

/**
 * ID баннера
 * @format uuid
 */
export type GenCmsBannerId = string;

/** Основные данные баннера */
export interface GenCmsBannerData {
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
  /** Высота */
  height: string | null;
  /** Ширина */
  width: string | null;
  /**
   * ID объекта для отображения
   * @format uuid
   */
  linkObjectId: string | null;
  /**
   * Тип объекта на который ссылается банер
   * * PARTNER - партнер
   * * COLLECTION - коллекция ресурсов
   * * VIDEO - видео-ресурс
   * * TRADE_OFFER - ТП
   * * CORP_OFFER - КП
   * * PRODUCT_OFFER - ПП
   * * BOOKING_OFFER - Объект ДиО
   * * CORP_OFFER_CATEGORY - категория КП
   * * TRADE_OFFER_CATEGORY - категория ТП
   * * PRODUCT_OFFER_CATEGORY - категория ПП
   * * LINK - http ссылка на внешний ресурс из поля url объекта банера
   */
  linkObjectType: GenCmsBannerLinkObjectType | null;
  /** структура для ссылки и описания ресурса(файла) */
  desktopImage: GenCmsFileLink | null;
  /** структура для ссылки и описания ресурса(файла) */
  mobileImage: GenCmsFileLink | null;
  /**
   * Url - абсолютная ссылка для перехода(если нужна)
   * @format url
   */
  url: string | null;
  /** Опции таргета на сайте */
  target: GenCmsTargetRequest | null;
}

/** баннер */
export type GenCmsBanner = (GenCmsBannerData & GenCmsAuditEntity) & {
  /** ID баннера */
  id: GenCmsBannerId | null;
  /** Опции таргета на сайте */
  target: GenCmsTarget | null;
};

/**
 * Тип объекта на который ссылается банер
 * * PARTNER - партнер
 * * COLLECTION - коллекция ресурсов
 * * VIDEO - видео-ресурс
 * * TRADE_OFFER - ТП
 * * CORP_OFFER - КП
 * * PRODUCT_OFFER - ПП
 * * BOOKING_OFFER - Объект ДиО
 * * CORP_OFFER_CATEGORY - категория КП
 * * TRADE_OFFER_CATEGORY - категория ТП
 * * PRODUCT_OFFER_CATEGORY - категория ПП
 * * LINK - http ссылка на внешний ресурс из поля url объекта банера
 */
export enum GenCmsBannerLinkObjectType {
  Link = 'LINK',
  Video = 'VIDEO',
  Partner = 'PARTNER',
  Collection = 'COLLECTION',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  ProductOffer = 'PRODUCT_OFFER',
  BookingOffer = 'BOOKING_OFFER',
  CorpOfferCategory = 'CORP_OFFER_CATEGORY',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  ProductOfferCategory = 'PRODUCT_OFFER_CATEGORY',
}

/**
 * ID видео
 * @format uuid
 */
export type GenCmsVideoId = string;

/** Основные данные видео ресурса */
export interface GenCmsVideoResourceData {
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
  /** структура для ссылки и описания ресурса(файла) */
  video: GenCmsFileLink | null;
  /** структура для ссылки и описания ресурса(файла) */
  desktopImage: GenCmsFileLink | null;
  /** структура для ссылки и описания ресурса(файла) */
  mobileImage: GenCmsFileLink | null;
  /** Опции таргета на сайте */
  target: GenCmsTargetRequest | null;
}

/** Видео-ресурс */
export type GenCmsVideoResource = GenCmsVideoResourceData & {
  /** ID видео */
  id: GenCmsVideoId;
  /** Опции таргета на сайте */
  target: GenCmsTarget | null;
};

/**
 * ID подборки
 * @format uuid
 */
export type GenCmsCollectionId = string;

/**
 * Тип объекта на который ссылается подборка
 * * PARTNER - партнер
 * * VIDEO - видео-ресурс
 * * TRADE_OFFER - ТП
 * * CORP_OFFER - КП
 * * PRODUCT_OFFER - ПП
 * * BOOKING_OFFER - Объект ДиО
 * * CORP_OFFER_CATEGORY - категория КП
 * * TRADE_OFFER_CATEGORY - категория ТП
 * * PRODUCT_OFFER_CATEGORY - категория ПП
 */
export enum GenCmsCollectionLinkObjectType {
  Video = 'VIDEO',
  Partner = 'PARTNER',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  ProductOffer = 'PRODUCT_OFFER',
  BookingOffer = 'BOOKING_OFFER',
  CorpOfferCategory = 'CORP_OFFER_CATEGORY',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  ProductOfferCategory = 'PRODUCT_OFFER_CATEGORY',
}

/** Данные подборки */
export interface GenCmsCollectionCreateRequest {
  /** Ссылка на партнера */
  partner: GenCmsPartnerLink | null;
  /**
   * Тип объекта на который ссылается подборка
   * * PARTNER - партнер
   * * VIDEO - видео-ресурс
   * * TRADE_OFFER - ТП
   * * CORP_OFFER - КП
   * * PRODUCT_OFFER - ПП
   * * BOOKING_OFFER - Объект ДиО
   * * CORP_OFFER_CATEGORY - категория КП
   * * TRADE_OFFER_CATEGORY - категория ТП
   * * PRODUCT_OFFER_CATEGORY - категория ПП
   */
  linkObjectType: GenCmsCollectionLinkObjectType;
  linkObjectIds: string[] | null;
  /** Опции таргета на сайте */
  target: GenCmsTargetRequest | null;
}

/** Данные подборки */
export interface GenCmsCollectionUpdateRequest {
  /**
   * Тип объекта на который ссылается подборка
   * * PARTNER - партнер
   * * VIDEO - видео-ресурс
   * * TRADE_OFFER - ТП
   * * CORP_OFFER - КП
   * * PRODUCT_OFFER - ПП
   * * BOOKING_OFFER - Объект ДиО
   * * CORP_OFFER_CATEGORY - категория КП
   * * TRADE_OFFER_CATEGORY - категория ТП
   * * PRODUCT_OFFER_CATEGORY - категория ПП
   */
  linkObjectType: GenCmsCollectionLinkObjectType;
  linkObjectIds: string[] | null;
}

/** Подборка */
export type GenCmsCollection = GenCmsCollectionCreateRequest & {
  /**
   * Id подборки
   * @format uuid
   */
  id: string;
  /** Опции таргета на сайте */
  target: GenCmsTarget | null;
};

/** Ссылка на Элемент словаря */
export interface GenCmsTermLink {
  /**
   * Id
   * @format uuid
   */
  id: string;
}

/** Элемент словаря */
export interface GenCmsTermRequest {
  /** @minLength 1 */
  value: string;
  /**
   * Набор тегов для поиска и группировки словаря
   * @minItems 1
   */
  tags: GenCmsTermTag[];
}

/** Элемент словаря */
export type GenCmsTerm = UtilRequiredKeys<GenCmsTermRequest, 'value' | 'tags'>;

/**
 * Тег для группировки элементов словаря
 * @minLength 1
 */
export type GenCmsTermTag = string;

/**
 * Тип предложения
 * * TRADE_OFFER - торговое предложение
 * * CORP_OFFER - корпоративное предложение
 * * PRODUCT_OFFER - товарное предложение
 * * BOOKING_OFFER - Объект Досуга и Отдыха
 */
export enum GenCmsOfferType {
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  ProductOffer = 'PRODUCT_OFFER',
  BookingOffer = 'BOOKING_OFFER',
}

/**
 * Тип объекта на который ссылается визуальный компонент
 * * BANNER - баннер
 * * PARTNER - партнер
 * * COLLECTION - подборка ресурсов
 * * VIDEO - видео-ресурсов
 * * TRADE_OFFER - ТП
 * * CORP_OFFER - КП
 * * PRODUCT_OFFER - ПП
 * * BOOKING_OFFER - Объект ДиО
 * * CORP_OFFER_CATEGORY - категория КП
 * * TRADE_OFFER_CATEGORY - категория ТП
 * * PRODUCT_OFFER_CATEGORY - категория ПП
 * * LINK - внешняя ссылка
 */
export enum GenCmsLinkObjectType {
  Link = 'LINK',
  Video = 'VIDEO',
  Partner = 'PARTNER',
  Collection = 'COLLECTION',
  Banner = 'BANNER',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  ProductOffer = 'PRODUCT_OFFER',
  BookingOffer = 'BOOKING_OFFER',
  CorpOfferCategory = 'CORP_OFFER_CATEGORY',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  ProductOfferCategory = 'PRODUCT_OFFER_CATEGORY',
}

/** структура для ссылки и описания ресурса(файла) */
export interface GenCmsFileLink {
  /**
   * Путь к файлу в хранилище (bucket/name) Можно использовать как id файла в хранилище
   * @minLength 1
   */
  path: string | null;
}

/** Ссылка на партнера */
export interface GenCmsPartnerLink {
  /** ID партнёра */
  id: GenCmsPartnerId;
}

/** Геопозиция(lat, lon) */
export interface GenCmsPosition {
  /**
   * latitude - широта
   * @format float
   * @min -90
   * @max 90
   */
  lat: number;
  /**
   * longitude - долгота
   * @format float
   * @min -180
   * @max 180
   */
  lon: number;
}

/** Адресный объект */
export type GenCmsAddressObject = Override<
  GenCmsAddressObjectLink,
  {
    level: GenCmsAddressLevel;
    /** Значение адресного элемента без сокращений */
    name: string;
    /** Значение адресного элемента c сокращениями */
    shortName: string;
    /** Составные части значения адресного элемента */
    values: GenCmsAddressObjectValue[] | null;
    /**
     * является ли адрес - результатом ручного ввода адреса
     * @default false
     */
    manual: boolean | null;
  }
>;

/**
 * Адресный уровень элемента в виде номера(уровень в ГАР)
 * 1 -   Субъект РФ
 * 2 -   Административный район
 * 3 -   Муниципальный район
 * 4 -   Сельское/городское поселение
 * 5 -   Город
 * 6 -   Населенный пункт
 * 7 -   Элемент планировочной структуры
 * 8 -   Элемент улично-дорожной сети
 * 9 -   Земельный участок
 * 10 -  Здание
 * 11 -  Помещение
 * 12 -  Помещения в пределах помещения
 * 17 -  Машино-место
 * @format int32
 */
export enum GenCmsAddressLevelId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value17 = 17,
}

export interface GenCmsAddressLevel {
  /**
   * Адресный уровень элемента в виде номера(уровень в ГАР)
   * 1 -   Субъект РФ
   * 2 -   Административный район
   * 3 -   Муниципальный район
   * 4 -   Сельское/городское поселение
   * 5 -   Город
   * 6 -   Населенный пункт
   * 7 -   Элемент планировочной структуры
   * 8 -   Элемент улично-дорожной сети
   * 9 -   Земельный участок
   * 10 -  Здание
   * 11 -  Помещение
   * 12 -  Помещения в пределах помещения
   * 17 -  Машино-место
   */
  id: GenCmsAddressLevelId;
  /** Название уровня */
  name: string;
}

/** Часть значения адресного объекта. Для домов/зданий это Сооружение, Строение, Корпус */
export interface GenCmsAddressObjectValue {
  /** Значение */
  value: string;
  /** Название типа без сокращений */
  typeName: string;
  /** Сокращенное Название типа */
  shortTypeName: string;
}

/** Ссылка на объект адреса */
export interface GenCmsAddressObjectLink {
  /**
   * ID
   * @format uuid
   */
  id: string;
}

/** Адрес */
export type GenCmsAddress = Override<
  UtilRequiredKeys<GenCmsAddressObjectLink, 'id'>,
  {
    /** Значение адреса без сокращений */
    name: string | null;
    /** Значение адреса c сокращениями */
    shortName: string | null;
    level: GenCmsAddressLevel | null;
    /** Почтовый индекс(может быть только у зданий) */
    postalCode: string | null;
    /** Геопозиция(lat, lon) */
    position: GenCmsPosition | null;
    /** Иерархия объектов адресации в составе данного адреса */
    hierarchy: GenCmsAddressObject[] | null;
    /**
     * является ли адрес - результатом ручного ввода адреса
     * @default false
     */
    manual: boolean | null;
  }
>;

/** Команда на создание контейнера При указании containers будут клонированы указанные имеющиеся контейнеры */
interface GenCmsBaseContainerCreateRequest {
  /** дискриминатор - техническое поле */
  discriminator: string;
}

type GenCmsBaseContainerCreateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на обновление контейнера */
interface GenCmsBaseContainerUpdateRequest {
  /** дискриминатор - техническое поле */
  discriminator: string;
}

type GenCmsBaseContainerUpdateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Допустимые команды к ресурсу - контейнер */
interface GenCmsBaseContainerCommand {
  /**
   * Дискриминатор - техническое поле Значения: ContainerPublishCommand, ContainerToEditCommand, ContainerPauseCommand,
   *           ContainerResumeCommand, ContainerArchiveCommand
   */
  discriminator: string;
}

type GenCmsBaseContainerCommandDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;
