import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { ProfileDeleteForbiddenEventContainer } from './container';
import {
  ProfileDeleteForbiddenEvent,
  ProfileDeleteForbiddenEventPayload,
  profileDeleteForbiddenEventType,
} from './types';

export const createEvent = (payload: ProfileDeleteForbiddenEventPayload): ProfileDeleteForbiddenEvent =>
  EventCreatorFactory.business()
    .getCustom(profileDeleteForbiddenEventType, ProfileDeleteForbiddenEventContainer)
    .withPayload(payload)
    .makeAsFeature()
    .get();
