import { useBusinessEventBus, useUserEventBus } from '@privilege-frontend/eventBus';
import FastPriorityQueue from 'fastpriorityqueue';
import { FCC, useCallback, useEffect, useState } from 'react';
import { eventsFlowEnginePriorityQueue } from '../engine';
import { DialogueEventType } from './';
import { DialogueEventsContext, DialogueEventsContextState } from './context';

const checkInterval = 3000;
const engine = eventsFlowEnginePriorityQueue;

export const DialogueEventsProvider: FCC = ({ children }) => {
  const [state, setState] = useState<DialogueEventsContextState<FastPriorityQueue<DialogueEventType>>>(
    engine.onCreateInitial()
  );

  const onNext = useCallback((result?: any) => {
    setState(prev => engine.onNext(prev, result));
  }, []);

  const onClose = useCallback(() => {
    setState(prev => engine.onClose(prev));
  }, []);

  useBusinessEventBus<DialogueEventType>(newEvent => {
    setState(prev => engine.onNew(prev, newEvent));
  });

  useUserEventBus<DialogueEventType>(newEvent => {
    setState(prev => engine.onNew(prev, newEvent));
  });

  useEffect(() => {
    //для того, чтобы не задолбать юзера, добавляем пересчет состояния в интервал
    //если за время предыдущего пересчета накопятся события, то сформируется новый флоу
    const intervalId = setInterval(() => {
      setState(engine.onRecalculate);
    }, checkInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <DialogueEventsContext.Provider
      value={{
        ...state,
        onNext,
        onClose,
      }}
    >
      {children}
    </DialogueEventsContext.Provider>
  );
};
