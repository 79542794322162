import { EOfferStatus, EPaymentType } from 'domain/model';
import { useMemo } from 'react';
import OfferClaimPriceInfo from '../../../../components/claim/priceInfo';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';

export const CorpOfferClaimPriceAdapter = () => {
  const { corpOffer, isActivationReceivedNow } = useCorpOfferClaimContext();

  return useMemo(
    () =>
      corpOffer?.id &&
      (corpOffer.status === EOfferStatus.Active ||
        corpOffer.status === EOfferStatus.Upcoming ||
        isActivationReceivedNow) ? (
        <OfferClaimPriceInfo
          paymentType={EPaymentType.BenefitRzdPoint}
          price={corpOffer.price}
          originalPrice={corpOffer.originalPrice}
        />
      ) : null,
    [corpOffer?.id, corpOffer?.status, corpOffer?.price, corpOffer?.originalPrice, isActivationReceivedNow]
  );
};
