import styled from '@emotion/styled';
import { css, StackTypeMap, Theme } from '@mui/material';
import { bgBannerCSPPrimary, bgBannerCSPSecondary } from 'media/images';
import { SocialPackageBannerVariants } from './component';

type WrapperProps = StackTypeMap['props'] & {
  readonly variant: SocialPackageBannerVariants;
};

const getVariant = (variant: SocialPackageBannerVariants, theme: Theme) => {
  switch (variant) {
    case 'primary':
      return css`
        background-image: url(${bgBannerCSPPrimary});
      `;
    case 'secondary':
      return css`
        background-image: url(${bgBannerCSPSecondary});
      `;
    case 'tertiary':
      return css`
        background-color: ${theme.palette.background.default};
      `;
  }
};

export const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;
    justify-content: flex-start;

    align-items: center;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.down('lg')} {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  `
);

export const Actions = styled.div(
  ({ theme }) => css`
    align-items: flex-end;
    flex-shrink: 0;
    min-width: 224px;
    margin-top: ${theme.spacing(2)};
    width: 100%;

    ${theme.breakpoints.up('lg')} {
      margin-top: 0;
      width: auto;
    }
  `
);

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, variant }) => css`
    display: flex;
    width: 100%;

    border-radius: ${theme.shape.borderRadius * 1.2}px;
    padding: ${theme.spacing(2)};
    background-size: cover;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 0;
    gap: ${theme.spacing(2)};

    ${getVariant(variant, theme)}
    ${theme.breakpoints.down('lg')} {
      flex-direction: column;
    }
  `
);

export const IconWrapper = styled('div')(
  ({ theme }) => css`
    display: inline-flex;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;

      ${theme.breakpoints.down('lg')} {
        width: 64px;
        height: 64px;
      }
    }
  `
);
