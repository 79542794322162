import styled from '@emotion/styled';
import { svgIconClasses } from '@mui/material';

type ShowMoreWrapperProps = {
  readonly expanded: boolean;
};

export const FirstLeaf = styled.div`
  * {
    font-weight: ${p => p.theme.typography.fontWeightBold} !important;
  }
`;

export const ShowMoreWrapper = styled.div<ShowMoreWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .${svgIconClasses.root} {
    margin-left: ${p => p.theme.spacing()};
    transform: rotate(${p => (p.expanded ? '-90deg' : '90deg')});
  }
`;
