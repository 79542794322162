import { Category } from 'domain/model';
import { getBookingOffersSearchRoute } from 'features/offer/booking/routes';
import OfferListAside from 'features/offer/components/listAside';
import OfferSubCategories from 'features/offer/components/subCategories';

type BookingOfferListCategoriesAdapterProps = {
  readonly subCategories: Nullable<Category[]>;
};

const BookingOfferListCategoriesAdapter = (props: BookingOfferListCategoriesAdapterProps) => {
  const { subCategories } = props;
  const linkFactory = (category: Category) => getBookingOffersSearchRoute({ categoryId: category.id });

  return (
    <OfferListAside>
      <OfferSubCategories
        categories={subCategories}
        linkFactory={linkFactory}
      />
    </OfferListAside>
  );
};

export default BookingOfferListCategoriesAdapter;
