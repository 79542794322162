import { FCC } from 'react';
import { Char } from './controls';

type AlphabeticalSearchItemProps = {
  readonly disabled?: any;
  readonly selected?: any;
  readonly onClick?: () => void;
};

const AlphabeticalSearchItem: FCC<AlphabeticalSearchItemProps> = props => {
  const { selected, disabled, children, onClick } = props;

  return (
    <Char
      fullWidth={false}
      disabled={disabled}
      variant={selected ? 'contained' : 'text'}
      color={'black'}
      size='large'
      onClick={onClick}
    >
      {children}
    </Char>
  );
};

export default AlphabeticalSearchItem;
