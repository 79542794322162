import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bookingOfferSortType, EBookingOfferSortType } from 'domain/model';
import OffersSortSelect from '../../../../offer/components/sortSelect';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';
import { partnerDeskDetailsBookingOffersSortSelector } from '../../store/selectors';
import { useBookingOffersContextHandlers } from '../../hooks/useBookingOffersContextHandlers';

const items = Object.values(bookingOfferSortType);

const PartnerDeskDetailsTabBookingOffersToolbar = () => {
  const sort = useSelector(partnerDeskDetailsBookingOffersSortSelector);

  const handlers = useBookingOffersContextHandlers();

  return useMemo(
    () => (
      <PartnerDeskDetailsTabToolbarLayout
        title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.BookingOffers)}
        sort={
          <OffersSortSelect<EBookingOfferSortType>
            value={sort}
            items={items}
            defaultValue={EBookingOfferSortType.Default}
            onChange={handlers.onChangeBookingOffersSort}
          />
        }
      />
    ),
    [sort, handlers.onChangeBookingOffersSort]
  );
};

export default PartnerDeskDetailsTabBookingOffersToolbar;
