import { FC } from 'react';
import useTechConfig from 'hooks/useTechConfig';
import { BookingOfferOrderCspBannerHowUseAdapter } from './bannerHowUse';
import { BookingOrderProps } from '../../types';
import { EAppFeature } from 'presentation/types';
import { EOrderStatus } from 'domain/model';

const BookingOfferOrderBannerCspAdapter: FC<BookingOrderProps> = ({ order }) => {
  const { hasFeature } = useTechConfig();
  const socialPackage = order.customerRzdSocialPackage;

  const isCspFeatureDisabled = !hasFeature(EAppFeature.Csp);
  const isOrderWithoutCsp = !order?.hasRzdSocialPackage;
  const isSocialPackageEmpty = !socialPackage;
  const isOrderCancelled = order.status === EOrderStatus.Cancelled;

  if (isCspFeatureDisabled || isOrderWithoutCsp || isSocialPackageEmpty || isOrderCancelled) {
    return null;
  }

  return <BookingOfferOrderCspBannerHowUseAdapter socialPackage={socialPackage} />;
};

export default BookingOfferOrderBannerCspAdapter;
