import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';
import { AccountBalance, BonusAccountBalance, EBalanceType, ESocialPackageStatus, EUserStatus } from 'domain/model';
import { socialPackagesAgreedSelector } from 'features/socialPackage/store/selectors';

export const currentUserIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};

export const currentUserLocalityIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};

export const currentUserBalancesDataSelector = (store: RootState) => {
  const { data } = userApi.endpoints.getUserBalance.select()(store);
  return data;
};

export const currentUserBalancesSelector = createSelector(
  currentUserBalancesDataSelector,
  (data): Nullable<BonusAccountBalance> => {
    return data
      ? {
          source: data,
          value: data.balance,
          type: EBalanceType.Bonuses,
        }
      : null;
  }
);

export const currentUserIsEmailVerifiedSelector = (store: RootState): Nullable<boolean> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.emailVerified ?? null;
};

export const currentUserHasRzdSocialPackages = (store: RootState): Nullable<boolean> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.hasRzdSocialPackage ?? null;
};

export const currentUserIsStatusEnabledSelector = (store: RootState): Nullable<boolean> => {
  const status = userApi.endpoints.getCurrentUser.select({})(store).data?.status;
  return status ? status === EUserStatus.Enabled : null;
};

export const currentUserCspBalancesSelector = createSelector(
  socialPackagesAgreedSelector,
  (socialPackages): Nullable<AccountBalance[]> => {
    if (!socialPackages) {
      return null;
    }

    return socialPackages.map(sp => ({
      type: EBalanceType.CSP,
      value: sp.maxLimit,
      source: sp,
    }));
  }
);

export const currentUserCspActiveBalanceSelector = createSelector(
  socialPackagesAgreedSelector,
  (socialPackages): Nullable<AccountBalance> => {
    if (!socialPackages) {
      return null;
    }

    const firstActiveSocialPackage = socialPackages.find(sp => sp.status === ESocialPackageStatus.Active);

    if (!firstActiveSocialPackage) {
      return null;
    }

    return {
      type: EBalanceType.CSP,
      value: firstActiveSocialPackage.maxLimit,
      source: firstActiveSocialPackage,
    };
  }
);
