import { Typography, TypographyProps } from '@mui/material';
import { Address } from 'domain/model';
import { EAddressOption } from 'domain/model';
import { AddressHelper } from 'utils/address';

type AddressFullViewProps = TypographyProps & {
  readonly address?: Nullable<Address>;
  readonly options?: EAddressOption[];
};

const AddressFullView = ({ address, options, ...others }: AddressFullViewProps) => {
  return <Typography {...others}>{address ? new AddressHelper(address).getFullPath({ options }) : '-'}</Typography>;
};

export default AddressFullView;
