import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import AboutAspScreen from 'screen/about/asp';
import AboutCspScreen from 'screen/about/csp';
import AboutPublicOfferScreen from 'screen/about/publicOffer';
import { EAppFeature } from '../../types';
import { AppRoute } from '../general/routing';

const root = rootRouting.about;

const routing = {
  root,
  publicOffer: `${root}/public-offer`,
  asp: `${root}/asp`,
  csp: `${root}/csp`,
};

export const getAboutRootRoute = () => routing.root;

export const getAboutCspRoute = () => routing.csp;

const AboutEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.publicOffer}
        component={AboutPublicOfferScreen}
      />
      <AppRoute
        exact
        protect
        path={routing.asp}
        component={AboutAspScreen}
        feature={EAppFeature.Asp}
      />
      <AppRoute
        exact
        protect
        path={routing.csp}
        component={AboutCspScreen}
        feature={EAppFeature.Csp}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default AboutEntry;
