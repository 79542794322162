import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { EBalanceType, EPaymentType } from 'domain/model';
import { AspCurrencyIcon, UnitsIcon } from 'presentation/media/icons';
import { FC } from 'react';

type Types = EPaymentType | EBalanceType;

export type PaymentIconProps = SvgIconProps & {
  readonly type: Types;
};

export const getPaymentIcon = (type: Types): Nullable<SvgIconComponent> => {
  switch (type) {
    case EPaymentType.AspRzdRub:
    case EBalanceType.ASP:
      return AspCurrencyIcon;

    case EBalanceType.CSP:
      return AspCurrencyIcon;

    case EPaymentType.BenefitRzdPoint:
    case EBalanceType.Bonuses:
      return UnitsIcon;

    case EPaymentType.Free:
    case EPaymentType.Rub:
      return null;
  }
};

export const PaymentIcon: FC<PaymentIconProps> = ({ type, ...others }) => {
  const Component = getPaymentIcon(type);

  return Component ? <Component {...others} /> : null;
};
