import { MPCheckbox } from 'theme/ui-kit/input';
import { CompensationLabel, Wrapper } from './controls';

type CompensationCheckboxProps = {
  readonly value: boolean;
  readonly onChange: (value: boolean) => void;
};

const CompensationCheckbox = ({ onChange, value }: CompensationCheckboxProps) => {
  return (
    <Wrapper>
      <MPCheckbox
        size='small'
        color='brand'
        value={value}
        checked={value}
        label={<CompensationLabel>Компенсация отдыха (КСП)</CompensationLabel>}
        onChange={() => onChange(!value)}
      />
    </Wrapper>
  );
};

export default CompensationCheckbox;
