import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { FC } from 'react';

type SummaryTextProps = {
  readonly endDate: string;
};

const SummaryText: FC<SummaryTextProps> = ({ endDate }) => {
  return (
    <Typography variant='body2'>
      Вам предоставлена возможность компенсировать часть расходов на&nbsp;оздоровление и&nbsp;отдых в&nbsp;
      {moment(endDate).year()}&nbsp;году:
    </Typography>
  );
};

export default SummaryText;
