import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';

export const Wrapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1.5)};
  `
);

export const Content = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  `
);

export const Description = styled(Typography)(
  () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const Card = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    padding: ${theme.spacing(3)};

    align-items: center;
    gap: ${theme.spacing(1.5)};

    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.secondary.A600};
    overflow: hidden;
  `
);

export const Title = styled(Typography)(
  ({ theme }) => css`
    width: 100%;
    display: flex;

    flex-grow: 1;
    gap: ${theme.spacing(2)};

    justify-content: space-between;
  `
);
