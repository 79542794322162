import { FormControlLabel } from '@mui/material';
import { StyledCheckbox } from './controls';

type ServiceCheckboxProps = {
  readonly checked: boolean;
  readonly label: string;
  readonly onChange: () => void;
  readonly disabled: boolean;
};

const ServiceCheckbox = ({ onChange, disabled, checked, label }: ServiceCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          size='small'
          color='brand'
          onChange={onChange}
          disabled={disabled}
          checked={checked}
        />
      }
      label={label}
    />
  );
};

export default ServiceCheckbox;
