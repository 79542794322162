import AuthEntry from 'features/auth/entry';
import CspEntry from 'features/csp/entry';
import CspOfferEntry from 'features/offer/csp/entry';
import SocialPackagesContainer from 'features/socialPackage/container';
import SocialPackageListenerContainer from 'features/socialPackage/listener/container';
import CurrentUserBalanceContainer from 'features/user/current/balance/container';
import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import CartEntry from 'presentation/features/cart/entry';
import CmsEntry from 'presentation/features/cms/entry';
import CookiesAcceptContainer from 'presentation/features/cookiesAccept/container';
import DataEntry from 'presentation/features/data/entry';
import LocationDetector from 'presentation/features/general/locationDetector/container';
import { AppRoute } from 'presentation/features/general/routing';
import LandingEntry from 'presentation/features/landing/entry';
import AppNotificationsReceiver from 'presentation/features/notification/receiver';
import AspOfferEntry from 'presentation/features/offer/asp/entry';
import BookingOfferEntry from 'presentation/features/offer/booking/entry';
import CorpOfferEntry from 'presentation/features/offer/corp/entry';
import ProductOfferEntry from 'presentation/features/offer/product/entry';
import TradeOfferEntry from 'presentation/features/offer/trade/entry';
import PartnerDeskEntry from 'presentation/features/partnerDesk/entry';
import UserEntry from 'presentation/features/user/entry';
import { EAppFeature } from 'presentation/types';
import { Redirect, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import NoopDecorator from '../../../components/common/renderDecoratorContainer/noopDecorator';
import useTechConfig from '../../../hooks/useTechConfig';
import HeaderContainer from '../../header/container';
import SearchEntry from '../../search/entry';
import SectionLinksContainer from '../../sectionLinks/container';
import { WsClientProvider } from '../../wsClient/wsClientProvider';
import { Content, Wrapper } from './controls';

const MainContainer = () => {
  const { user, defaultRoute, isCorpUser } = useAuthUser();
  const { hasFeature, isGuestModeDisabled } = useTechConfig();

  return (
    <WsClientProvider
      user={user}
      isCorpUser={isCorpUser}
    >
      <CurrentUserBalanceContainer />
      <SocialPackagesContainer />
      <SocialPackageListenerContainer />
      <AppNotificationsReceiver />
      <LocationDetector>
        <RenderDecoratorContainer
          decorators={[
            hasFeature(EAppFeature.Cookies) ? (
              <CookiesAcceptContainer key='cookiesAcceptContainer' />
            ) : (
              <NoopDecorator />
            ),
          ]}
        >
          <Wrapper>
            <HeaderContainer />
            <SectionLinksContainer />
            <Content>
              <Switch>
                <AppRoute
                  path={rootRouting.login}
                  component={AuthEntry}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  path={rootRouting.partner}
                  component={PartnerDeskEntry}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  feature={EAppFeature.Trade}
                  path={rootRouting.tradeOffer}
                  component={TradeOfferEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect
                  path={rootRouting.corpOffer}
                  component={CorpOfferEntry}
                />
                <AppRoute
                  protect
                  path={rootRouting.user}
                  component={UserEntry}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  feature={EAppFeature.Product}
                  path={rootRouting.product}
                  component={ProductOfferEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  feature={EAppFeature.Product}
                  path={rootRouting.cart}
                  component={CartEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  feature={EAppFeature.Booking}
                  path={rootRouting.bookingOffer}
                  component={BookingOfferEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect
                  feature={EAppFeature.Asp}
                  path={rootRouting.aspOffer}
                  component={AspOfferEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect
                  feature={EAppFeature.Csp}
                  path={rootRouting.cspOffer}
                  component={CspOfferEntry}
                />
                <AppRoute
                  protect
                  feature={EAppFeature.Csp}
                  path={rootRouting.csp}
                  component={CspEntry}
                  defaultRoute={defaultRoute}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  path={rootRouting.data}
                  component={DataEntry}
                />
                <AppRoute
                  protect
                  path={rootRouting.cms}
                  component={CmsEntry}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  path={rootRouting.search}
                  component={SearchEntry}
                />
                <AppRoute
                  protect={isGuestModeDisabled}
                  feature={EAppFeature.LandingWindow}
                  path={rootRouting.root}
                  component={LandingEntry}
                  defaultRoute={defaultRoute}
                />
                <Redirect to={defaultRoute} />
              </Switch>
            </Content>
          </Wrapper>
        </RenderDecoratorContainer>
      </LocationDetector>
    </WsClientProvider>
  );
};

export default MainContainer;
