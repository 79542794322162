import moment from 'moment-timezone';
import { FC, ReactNode } from 'react';
import { DeleteProfileResponse, UpdateEmailResponse } from '../../../../domain/model';
import { utcToLocalTimeZone } from '../../../utils/date';
import CodeConfirmationDialog from './dialog';
import { useCodeEdit } from './hooks/useCodeEdit';

type RequiredActionConfirmCodeContainerProps = {
  readonly email: string;
  readonly source: UpdateEmailResponse | DeleteProfileResponse;
  readonly back?: ReactNode;
  readonly onSuccess: () => void;
  readonly onGetNewCode: () => void;
  readonly onClose?: () => void;
};

export const RequiredActionConfirmCodeContainer: FC<RequiredActionConfirmCodeContainerProps> = ({
  email,
  source,
  back,
  onSuccess,
  onGetNewCode,
  onClose,
}) => {
  const { validation, codeTtl, codeRequestId, codeNextAttemptDate, isFetching, onSendCode } = useCodeEdit({
    source,
    onSuccess,
  });

  const codeNextAttemptSeconds = codeNextAttemptDate
    ? utcToLocalTimeZone(codeNextAttemptDate).diff(moment(), 'seconds')
    : 0;

  return (
    <CodeConfirmationDialog
      open
      email={email}
      codeTtl={codeTtl}
      codeRequestId={codeRequestId}
      codeNextAttemptSeconds={codeNextAttemptSeconds}
      validation={validation?.code}
      isFetching={isFetching}
      back={back}
      onGetNewCode={onGetNewCode}
      onClose={onClose}
      onSendCode={onSendCode}
    />
  );
};
