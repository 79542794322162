import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: ${theme.shape.borderRadius * 0.8}px;
    background: ${theme.palette.secondary.A400};

    padding: ${theme.spacing(1, 1.5)};
  `
);
