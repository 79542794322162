import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';

export const Wrapper = styled(MPGrid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
  `
);

export const StubWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    bottom: 0;

    display: flex;
  `
);
