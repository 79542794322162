import { Text } from '../../components/controls';

const AboutCspIsThereTaxInfo = () => {
  return (
    <>
      <Text>
        НДФЛ облагается в общеустановленном порядке каждая вторая и последующие путевки, за которые работник получает
        компенсацию.
      </Text>
    </>
  );
};

export default AboutCspIsThereTaxInfo;
