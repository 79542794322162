import { useGetUserBalanceQuery } from 'api/user';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import { getBonusConfig } from 'presentation/utils';
import { useCallback, useEffect } from 'react';

export const useBonusesBalance = () => {
  const { isCorpUser, user } = useAuthUser();

  const skipGetBonusBalanceRequest = !isCorpUser || !user?.pernr || !!getBonusConfig()?.unavailableReason;

  const {
    data: bonusesBalance = null,
    isFetching: bonusesBalanceIsFetching,
    error: bonusesFetchError,
    refetch: refreshBonusesBalanceInternal,
    isUninitialized: bonusesBalanceisUninitialized,
  } = useGetUserBalanceQuery(undefined, {
    pollingInterval: 0.5 * 60 * 1000,
    skip: skipGetBonusBalanceRequest,
  });

  const refreshBonusesBalance = useCallback(() => {
    if (!bonusesBalanceisUninitialized) {
      refreshBonusesBalanceInternal();
    }
  }, [bonusesBalanceisUninitialized, refreshBonusesBalanceInternal]);

  // ошибка получения баланса юзера
  useEffect(() => {
    if (bonusesFetchError) {
      const errorText = 'Error at request current user bonuses';
      console.error(errorText, bonusesFetchError);
    }
  }, [bonusesFetchError]);

  return {
    value: bonusesBalance?.balance ?? null,
    isFetching: bonusesBalanceIsFetching,
    refreshBalance: refreshBonusesBalance,
  };
};
