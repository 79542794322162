import { Typography } from '@mui/material';
import { StyledTab, StyledTabs } from './controls';
import { ReactNode } from 'react';

type AboutTabsItem = {
  readonly id: string;
  readonly name: ReactNode;
};

type AboutTabsComponentProps<T extends AboutTabsItem> = {
  readonly value: Nullable<T>;
  readonly tabs: T[];
  readonly onChange: (tab: T) => void;
  readonly title?: ReactNode;
};

const AboutTabsComponent = <T extends AboutTabsItem>({ value, tabs, onChange }: AboutTabsComponentProps<T>) => {
  return (
    <StyledTabs
      textColor='secondary'
      value={value?.id ?? tabs[0].id}
      orientation='vertical'
      indicatorColor='secondary'
    >
      {tabs.map(tab => (
        <StyledTab
          key={tab.id}
          label={<Typography variant={'subtitle2'}>{tab.name}</Typography>}
          value={tab.id}
          onClick={() => {
            onChange(tab);
          }}
        />
      ))}
    </StyledTabs>
  );
};

export default AboutTabsComponent;
