import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const InfoLink = styled(Typography)`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: ${p => p.theme.palette.text.primary};
  text-underline-offset: 4px;
  cursor: pointer;
`;
