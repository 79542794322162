import { useMemo } from 'react';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';
import { partnerDeskDetailsProductOffersSortSelector } from '../../store/selectors';
import { useProductOffersContextHandlers } from '../../hooks/useProductOffersContextHandlers';
import { EProductOfferSortType, productOfferSortType } from 'domain/model';
import OffersSortSelect from '../../../../offer/components/sortSelect';
import { useSelector } from 'react-redux';

const items = Object.values(productOfferSortType);

const PartnerDeskDetailsTabProductOffersToolbar = () => {
  const sort = useSelector(partnerDeskDetailsProductOffersSortSelector);

  const handlers = useProductOffersContextHandlers();

  return useMemo(
    () => (
      <PartnerDeskDetailsTabToolbarLayout
        title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.ProductOffers)}
        sort={
          <OffersSortSelect<EProductOfferSortType>
            value={sort}
            items={items}
            defaultValue={EProductOfferSortType.ByNovelty}
            renderValue={value => items.find(item => item.id === value)?.name}
            onChange={handlers.onChangeProductOffersSort}
          />
        }
      />
    ),
    [sort, handlers.onChangeProductOffersSort]
  );
};

export default PartnerDeskDetailsTabProductOffersToolbar;
