import AboutCspContainer from 'features/about/csp/container';
import ScreenLayout from 'presentation/layouts/screen';

const AboutCspScreen = () => {
  return (
    <ScreenLayout>
      <AboutCspContainer />
    </ScreenLayout>
  );
};

export default AboutCspScreen;
