import styled from '@emotion/styled';
import { MPGrid } from 'theme/ui-kit/grid';

export enum ESlotNameArea {
  title = 'title',
  promotionType = 'promotionType',
  leftPeriod = 'leftPeriod',
  activation = 'activation',
  promotion = 'promotion',
  newPromotion = 'newPromotion',
  warnMessages = 'warnMessages',
  infoMessages = 'infoMessages',
  bottomBar = 'bottomBar',
  subscriptions = 'subscriptions',
  price = 'price',
}

type SlotsGridAreaProps = {
  readonly area: ESlotNameArea;
};

export const SlotsGridArea = styled(MPGrid)<SlotsGridAreaProps>`
  width: 100%;
  grid-area: ${p => p.area};
`;

export const SlotsGrid = styled.div`
  width: 100%;
  z-index: 1;
  display: block;
  background-color: ${p => p.theme.palette.white.main};
  border-radius: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(3)};
  border: 1px solid ${p => p.theme.palette.secondary.A600};
  box-shadow: 0 20px 30px -20px hsla(220, 13%, 50%, 0.35);

  > [area=${ESlotNameArea.title}] > *:last-child {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ESlotNameArea.price}] > *:last-child {
    margin-top: ${p => p.theme.spacing(1.5)};
  }

  > [area=${ESlotNameArea.leftPeriod}] > *:last-child {
    margin-bottom: ${p => p.theme.spacing(1.5)};
  }

  > [area=${ESlotNameArea.activation}] > *:last-child, > [area=${ESlotNameArea.promotion}] > *:last-child {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ESlotNameArea.newPromotion}] > *:last-child {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ESlotNameArea.infoMessages}] > *:last-child {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(0)};
  }

  > [area=${ESlotNameArea.warnMessages}] > *:last-child {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  > [area=${ESlotNameArea.subscriptions}] > *:last-child {
    margin-top: ${p => p.theme.spacing(2)};
    margin-bottom: ${p => p.theme.spacing(2)};

    height: ${p => p.theme.spacing(9)};
  }

  > [area=${ESlotNameArea.bottomBar}] > *:last-child {
    margin-top: ${p => p.theme.spacing(2)};
  }
`;
