import { useAgreeSocialPackageMutation } from 'api/user';
import { SocialPackage } from 'domain/model';
import { useEffect } from 'react';

export const useSocialPackageToConfirmation = (socialPackage: SocialPackage) => {
  const [onAgree] = useAgreeSocialPackageMutation();

  useEffect(() => {
    onAgree({ socialPackageId: socialPackage.id });
  }, [onAgree, socialPackage.id]);
};
