import { Link, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { convertSecondsToHumanView, utcToLocalTimeZone } from 'presentationUtils/date';
import { FC, useEffect, useState } from 'react';

type ConfirmCodeEventTimerAdapterProps = {
  readonly codeTtl: number;
  readonly codeRequestId: string;
  readonly codeNextAttemptDate: Nullable<string>;
  readonly onGetNewCode: () => void;
};

export const ConfirmCodeEventTimerAdapter: FC<ConfirmCodeEventTimerAdapterProps> = ({
  codeTtl,
  codeNextAttemptDate,
  codeRequestId,
  onGetNewCode,
}) => {
  const codeNextAttemptSeconds = codeNextAttemptDate
    ? utcToLocalTimeZone(codeNextAttemptDate).diff(moment(), 'seconds')
    : 0;

  const [timer, setTimer] = useState(codeNextAttemptSeconds ?? 0);

  useEffect(() => {
    if (codeNextAttemptSeconds) {
      setTimer(codeNextAttemptSeconds);
    }
  }, [codeNextAttemptSeconds, codeRequestId]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [codeNextAttemptSeconds, codeRequestId]);

  return (
    <>
      {timer && timer && timer >= 0 ? (
        <>
          {codeTtl && (
            <Typography variant='body2'>Код действителен в течение {convertSecondsToHumanView(codeTtl)}</Typography>
          )}
          <Typography variant='body2'>Получить код повторно можно через {timer} секунд</Typography>
        </>
      ) : (
        <>
          {onGetNewCode && (
            <Link
              color='primary'
              component={Typography}
              underline='hover'
              variant='body2'
              onClick={onGetNewCode}
            >
              Получить код повторно
            </Link>
          )}
        </>
      )}
    </>
  );
};
