import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/confirmCity';
import ContentLoader from 'presentation/components/common/loader';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { Wrapper } from './controls';
import { locationDetectorCitySelector } from './store/selectors';
import { locationDetectorCityFetch, locationDetectorClear } from './store/slice';

type LocationDetectorProps = {
  readonly children: ReactNode;
};

const LocationDetector = ({ children }: LocationDetectorProps) => {
  const dispatch = useDispatch();
  const { settings, setCity } = useUserLocalSettings();
  const { publish } = useBusinessEventBus();

  const { city: localCity } = settings;

  const { data: city, isFetching } = useSelector(locationDetectorCitySelector);

  useEffect(() => {
    if (!localCity) {
      const promise = dispatch(locationDetectorCityFetch());
      return () => {
        dispatch(locationDetectorClear());
        promise?.abort();
      };
    }
  }, [dispatch, localCity]);

  useEffect(() => {
    if (city) {
      setCity(city.id, new AddressHelper(city).getLocalitySimpleName());
    }
  }, [city, setCity]);

  useEffect(() => {
    if (city) {
      publish(createEvent({ city: new AddressHelper(city).getLocalitySimpleName() }));
    }
  }, [city, publish]);

  return (
    <>
      {isFetching && (
        <Wrapper>
          <ContentLoader />
        </Wrapper>
      )}
      {settings.city && children}
    </>
  );
};

export default LocationDetector;
