import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';

type ActivationListItemExpireDateProps = {
  readonly expireDate: string;
};

const ActivationListItemExpireDate = ({ expireDate }: ActivationListItemExpireDateProps) => {
  return <Typography variant='body2'>Действует до {moment(expireDate).local().format(EDateFormat.Human)}</Typography>;
};

export default ActivationListItemExpireDate;
