import { buttonClasses, getButtonUtilityClass, svgIconClasses } from '@mui/material';
import { darken } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';
import { ComponentNameToClassKey, OverridesStyleRules } from '@mui/material/styles/overrides';
import { disabled, focused, selected } from './constants';
import { OverrideThemeFunc } from './types';

export const buttonCustomClasses = {
  colorBrand: getButtonUtilityClass('colorBrand'),
  colorWhite: getButtonUtilityClass('colorWhite'),
  colorBlack: getButtonUtilityClass('colorBlack'),
};

const buttons: OverrideThemeFunc<Components> = baseTheme => ({
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: baseTheme.shape.borderRadius * 1.2,

        fontSize: baseTheme.typography.button.fontSize,
        lineHeight: baseTheme.typography.button.lineHeight,
        fontWeight: baseTheme.typography.button.fontWeight,

        [`&:hover, & ${selected}, & ${focused}, &.${buttonClasses.focusVisible}`]: {
          boxShadow: 'none',
        },
      },
      sizeSmall: {
        fontSize: baseTheme.typography.body2.fontSize,
        lineHeight: baseTheme.typography.body2.lineHeight,
        fontWeight: baseTheme.typography.body2.fontWeight,

        padding: baseTheme.spacing(0.75, 1.5),

        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(0.75, 1.5),
        },
      },
      outlined: {
        borderWidth: '2px',
        borderColor: 'inherit',
        [`&:hover, & ${selected}, & ${focused}`]: {
          borderWidth: '2px',
          borderColor: 'inherit',
        },
        [`&${disabled}`]: {
          borderWidth: '2px',
          borderColor: 'transparent',
          backgroundColor: `${baseTheme.palette.action.disabled}`,
        },

        [`&.${buttonCustomClasses.colorWhite}`]: {
          color: baseTheme.palette.white.main,
        },

        [`&.${buttonCustomClasses.colorBlack}`]: {
          color: baseTheme.palette.black.main,
        },

        [`&.${buttonClasses.sizeSmall}`]: {
          padding: baseTheme.spacing(0.5, 1.25),

          [baseTheme.breakpoints.between('xs', 'xl')]: {
            padding: baseTheme.spacing(0.5, 1.25),
          },
        },
        [`&.${buttonClasses.sizeMedium}`]: {
          padding: baseTheme.spacing(1, 1.25),

          [baseTheme.breakpoints.between('xs', 'xl')]: {
            padding: baseTheme.spacing(1, 1.25),
          },
        },
        [`&.${buttonClasses.sizeLarge}`]: {
          padding: baseTheme.spacing(1.25, 3.25),

          [baseTheme.breakpoints.between('xs', 'xl')]: {
            padding: baseTheme.spacing(1.25, 3.25),
          },
        },
      },
      contained: {
        [`&.${buttonClasses.colorSecondary}`]: {
          background: baseTheme.palette.secondary.A600,
          color: baseTheme.palette.white.contrastText,

          [`&:hover, & ${selected}, & ${focused}`]: {
            background: darken(baseTheme.palette.secondary.A600, 0.1),
          },
        },
      },
      text: {
        [`&.${buttonCustomClasses.colorWhite}`]: {
          color: baseTheme.palette.white.main,
        },
        [`&.${buttonCustomClasses.colorBlack}`]: {
          color: baseTheme.palette.black.main,
        },
      },
      sizeMedium: {
        fontSize: baseTheme.typography.body2.fontSize,
        lineHeight: baseTheme.typography.body2.lineHeight,
        fontWeight: baseTheme.typography.body2.fontWeight,

        padding: baseTheme.spacing(1.25, 1.5),

        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1.25, 1.5),
        },
      },
      sizeLarge: {
        padding: baseTheme.spacing(1.5, 3.5),

        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1.5, 3.5),
        },
      },
    },
  },
  MuiFab: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => {
        const styles: Partial<
          OverridesStyleRules<'root', ComponentNameToClassKey['MuiFab'], typeof baseTheme>
        >['root'] = {
          overflow: 'hidden',
        };

        if (ownerState.disableElevation) {
          return {
            ...styles,
            boxShadow: 'none !important',
          };
        }

        return styles;
      },
      squared: {
        borderRadius: baseTheme.shape.borderRadius * 1.2,
      },
      primaryLight: {
        background: baseTheme.palette.primary.A400,

        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.primary.A400, 0.1),
        },

        [`& .${svgIconClasses.root}`]: {
          color: baseTheme.palette.primary.main,
        },
      },
      secondaryLight: {
        background: baseTheme.palette.secondary.A600,

        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.secondary.A600, 0.1),
        },
      },
      secondarySuperLight: {
        background: baseTheme.palette.secondary.A400,

        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.secondary.A400, 0.1),
        },
      },
      sizeMicro: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        minHeight: '32px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => {
        const styles: Partial<
          OverridesStyleRules<'root', ComponentNameToClassKey['MuiIconButton'], typeof baseTheme>
        >['root'] = {};

        if (ownerState.disablePadding) {
          return {
            ...styles,
            padding: 0,
          };
        }

        return styles;
      },
    },
  },
});

export default buttons;
