import { Breakpoint, Dialog, DialogProps, useMediaQuery } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import { FCC, ReactNode } from 'react';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { MPCommonDialogContext } from 'theme/ui-kit/dialog/common/context';
import { CloseButton } from './controls';
import { MPDialogBackButton } from './dialogBackButton';

export type MPDialogProps = Omit<DialogProps, 'title'> & {
  readonly title?: ReactNode;
  //замена крестика на кнопку Назад в полном экране
  readonly closeToBackButtonInFullScreen?: boolean;
  readonly backButtonLabel?: ReactNode;
  readonly closeButtonVisible?: boolean;
  readonly closeButton?: ReactNode;
  //брекпойнт, с которого включается полноэкранный режим
  readonly fullScreenBreakpoint?: Breakpoint;
  //враппер для всех внутренностей
  readonly wrapper?: FCC;
  //флаг о том, что билдер контента будет внешний и придёт сразу в children
  readonly externalBuilder?: boolean;
};

export const MPDialog = (props: MPDialogProps) => {
  const {
    children,
    fullWidth = true,
    maxWidth = 'md',
    closeToBackButtonInFullScreen = true,
    closeButtonVisible = true,
    closeButton,
    backButtonLabel,
    title,
    fullScreenBreakpoint,
    externalBuilder,
    ...others
  } = props;

  const fullScreenBreakpointDefault = others.fullScreen ? 10000 : 0;

  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const fullScreen = useMediaQuery(theme =>
    //делаем +1, чтобы захватить нижнюю границу брекпойнта
    theme.breakpoints.down(
      fullScreenBreakpoint ? theme.breakpoints.values[fullScreenBreakpoint] + 1 : fullScreenBreakpointDefault
    )
  );

  const isBackButtonVisible = closeButtonVisible && others.onClose && fullScreen && closeToBackButtonInFullScreen;
  const isCloseIconVisible = closeButtonVisible && others.onClose && (!fullScreen || !closeToBackButtonInFullScreen);

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll={downSm ? 'paper' : 'body'}
      PaperProps={{
        elevation: 0,
        variant: 'outlined',
      }}
      {...others}
      fullScreen={others.fullScreen || fullScreen}
    >
      {isBackButtonVisible && (
        <MPDialogBackButton
          label={backButtonLabel}
          onClick={event => others.onClose?.(event, 'escapeKeyDown')}
        />
      )}
      {isCloseIconVisible && (
        <>
          {closeButton ? (
            closeButton
          ) : (
            <CloseButton onClick={event => others.onClose?.(event, 'custom' as any)}>
              <CloseIcon fontSize='large' />
            </CloseButton>
          )}
        </>
      )}
      <MPCommonDialogContext.Provider
        value={{
          title,
          isBackButtonVisible,
          wrapper: others.wrapper,
          fullScreen,
          onClose: others.onClose,
        }}
      >
        {externalBuilder ? children : <MPDialogContentBuilder {...props} />}
      </MPCommonDialogContext.Provider>
    </Dialog>
  );
};
