import styled from '@emotion/styled';
import { css } from '@mui/material';
import React from 'react';

export const Wrapper = styled.div(
  p => css`
    flex: 1;
    display: grid;
    grid-template-columns: repeat(
      ${React.Children.count(p.children)},
      calc(100% / ${React.Children.count(p.children)})
    );
  `
);
