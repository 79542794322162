/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenBonusAccountBalance } from './data-contracts';
import { createRequest } from './http-client';

export const BonusesApi = {
  /**
   * No description
   * * @tags Bonuses
   * @name GetCurrentCustomerBonusBalance
   * @summary Баланс бонусного счета текущего пользователя
   * @request GET:/users/current/balance
   * @secure
   */
  getCurrentCustomerBonusBalance: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenBonusAccountBalance, void>({
      baseURL,
      path: `/users/current/balance`,
      method: 'GET',
      format: 'json',
      signal,
    }),
};
