import { PartnerDesk } from 'domain/model';
import AppImage from 'presentation/components/common/images/common';

type PartnerDeskLogoProps = {
  readonly partnerDesk: PartnerDesk;
};

const PartnerDeskLogo = ({ partnerDesk }: PartnerDeskLogoProps) => {
  const { image } = partnerDesk;

  if (!image?.path) {
    return null;
  }

  return (
    <AppImage
      src={image}
      alt=''
    />
  );
};
export default PartnerDeskLogo;
