import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Children } from 'react';
import { ActionsWrapper, ContentWrapper, SubtitleWrapper } from './controls';
import { UserProfileLayoutType } from './types';

const UserProfileLayout: UserProfileLayoutType = props => {
  const { title, subTitle, corpLinked, actions, loader, notifications, deleteProfile, children } = props;

  const childrenArray = Children.toArray(children);

  return (
    <Fade in>
      <DefaultContentWrapper>
        <ContentWrapper>
          <Typography variant='h1'>{title}</Typography>
          <Splitter size={3} />

          <SubtitleWrapper>
            <Typography variant='h2'>{subTitle}</Typography>
            {corpLinked}
          </SubtitleWrapper>

          {actions && <ActionsWrapper>{actions}</ActionsWrapper>}

          {childrenArray && childrenArray.length > 0 && (
            <MPGrid
              container
              spacing={1}
              direction='column'
            >
              {childrenArray?.map((child, index) => (
                <MPGrid
                  key={index}
                  item
                >
                  {child}
                </MPGrid>
              ))}
            </MPGrid>
          )}

          {notifications && (
            <>
              <Splitter size={3} />
              {notifications}
            </>
          )}

          {deleteProfile && (
            <>
              <Splitter size={5} />
              {deleteProfile}
            </>
          )}

          {loader}
        </ContentWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default UserProfileLayout;
