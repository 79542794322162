import styled from '@emotion/styled';
import { css, formControlLabelClasses, Typography } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    .${formControlLabelClasses.root} {
      margin-right: 0;
    }
  `
);

export const CompensationLabel = styled(Typography)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  `
);
