import { useMediaQuery } from '@mui/material';
import { Category } from 'domain/model';
import * as H from 'history';
import HorizontalScroller, { HorizontalScrollerItem } from 'presentation/components/common/horizontalScroll';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferSubCategoryChipItem from './chipItem';
import { SubCategoryLinkWrapper } from './controls';
import OfferSubCategoryLinkItem from './linkItem';

type OfferSubCategoriesProps = {
  readonly categories: Nullable<Category[]>;
  readonly linkFactory: (category: Category) => string | H.Location;
};

const OfferSubCategories = (props: OfferSubCategoriesProps) => {
  const { categories, linkFactory } = props;

  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  if (!categories) {
    return null;
  }

  return isMdDown ? (
    <HorizontalScroller gap={8}>
      {categories.map(category => (
        <HorizontalScrollerItem key={category.id}>
          <OfferSubCategoryChipItem
            label={category.name}
            to={linkFactory(category)}
          />
        </HorizontalScrollerItem>
      ))}
    </HorizontalScroller>
  ) : (
    <>
      <MPGrid
        container
        direction={isMdDown ? 'row' : 'column'}
      >
        {categories.map(category => (
          <SubCategoryLinkWrapper
            key={category.id}
            item
          >
            <OfferSubCategoryLinkItem to={linkFactory(category)}>{category.name}</OfferSubCategoryLinkItem>
          </SubCategoryLinkWrapper>
        ))}
      </MPGrid>
    </>
  );
};

export default OfferSubCategories;
