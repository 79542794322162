import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bookingOfferListIsFetchingSelector } from '../store/selectors';

const Loader = <ContentLoader position='fixed' />;

export const BookingOfferListLoaderAdapter = () => {
  const searchParams = useContextSearchParams();
  const isFetching = useSelector(state => bookingOfferListIsFetchingSelector(state, searchParams));

  return useMemo(() => (isFetching ? Loader : null), [isFetching]);
};
