import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { SocialPackageHowToUseEventProps } from '../types';

export const SocialPackageHowToUseActionsAdapter: FC<SocialPackageHowToUseEventProps> = ({ onNext }) => {
  return (
    <ConfirmGroupButtons>
      <MPButton
        color='brand'
        fullWidth
        size={'large'}
        onClick={onNext}
      >
        Закрыть
      </MPButton>
    </ConfirmGroupButtons>
  );
};
