import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import { createEvent as createEventAgreed } from '../agreed';
import { createEvent as createEventRefuse } from '../refuse';
import {
  SocialPackageToAgreementActionsAdapter,
  SocialPackageToAgreementContentAdapter,
  SocialPackageToAgreementTitleAdapter,
} from './adapters';
import { SocialPackageToAgreementEventProps } from './types';

/**
 * состояние КСП
 *
 * есть признак КСП у пользователя
 * еще не согласился
 * готов к активации {@link ESocialPackageStatus.Ready}
 */

export const SocialPackageToAgreementEventContainer: FC<SocialPackageToAgreementEventProps> = props => {
  const {
    event: {
      payload: { socialPackage },
    },
    onNext,
  } = props;

  const { publishTop } = useBusinessEventBus();

  const onRefuse = () => {
    publishTop(createEventRefuse({ socialPackage }));
    onNext();
  };

  const onAgreed = () => {
    publishTop(createEventAgreed({ socialPackage }));
    onNext();
  };

  return (
    <DialogueEventContentBuilderLayout
      title={<SocialPackageToAgreementTitleAdapter {...props} />}
      actions={
        <SocialPackageToAgreementActionsAdapter
          {...props}
          onRefuse={onRefuse}
          onAgreed={onAgreed}
        />
      }
      titleAsContent
    >
      <SocialPackageToAgreementContentAdapter {...props} />
    </DialogueEventContentBuilderLayout>
  );
};
