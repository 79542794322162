import { toCspRubCurrency } from 'presentationUtils/currency';
import { FC } from 'react';
import { SocialPackageToConfirmationEventProps } from '../types';

export const SocialPackageToConfirmationTitleAdapter: FC<SocialPackageToConfirmationEventProps> = ({
  event: { payload },
}) => {
  const {
    socialPackage: { maxLimit },
  } = payload;

  return `Компенсируем до\u00A0${toCspRubCurrency(maxLimit)} от\u00A0ваших затрат на\u00A0оздоровление и отдых`;
};
