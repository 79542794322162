import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';

const AboutCspWhatDocsInfo = () => {
  return (
    <>
      <Text>
        Копия одного из документов (бланк может быть изготовлен типографским способом или сформирован с использованием
        автоматизированных систем):
        <List>
          <ListItem>путевка;</ListItem>
          <ListItem>договор на приобретение путевки;</ListItem>
          <ListItem>обратный талон к путевке;</ListItem>
          <ListItem>отрывной талон к путевке.</ListItem>
        </List>
      </Text>
      <Text>
        Выписка из протокола Комиссии по распределению путевок на предоставление КСП на отдых и санаторно-курортное
        лечение.
      </Text>
      <Text>
        Документ об оплате (кассовый чек или бланк строгой отчетности с указанием обязательных реквизитов,
        предусмотренных законодательством Российской Федерации) или банковская квитанция об осуществлении платежа.
      </Text>
      <Text>
        Копии:
        <List>
          <ListItem>
            свидетельства о браке, если услуги оказаны супругу/супруге работника или если договор оформлен на имя
            супруга/супруги работника;
          </ListItem>
          <ListItem>свидетельства о рождении ребенка, если услуги оказаны ребенку работника.</ListItem>
        </List>
      </Text>
      <Text>
        Документы представляются ответственному за ведение работы по КСП в подразделении после получения услуги в
        течение 30 календарных дней после даты выезда с объекта оздоровления, но не позднее 10 декабря представление
        документов позднее установленных сроков (при их пропуске) возможно по уважительной причине (отпуск, болезнь,
        командировка, вахтовый метод работы, обучение и др.), подтвержденной соответствующими документами.
      </Text>
    </>
  );
};

export default AboutCspWhatDocsInfo;
