import styled from '@emotion/styled';
import { buttonClasses, css, tabClasses } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const Char = styled(MPButton)(
  ({ theme }) => css`
    min-width: auto !important;

    padding: ${theme.spacing(0.5, 1.5)} !important;

    white-space: nowrap;

    &.${buttonClasses.disabled} {
      color: ${theme.palette.secondary.main};
    }
  `
);

export const CountryWrapper = styled.div(
  ({ theme }) => css`
    display: flex;

    .${tabClasses.root} {
      min-width: auto !important;

      padding: ${theme.spacing(0.5, 1.5)} !important;
    }
  `
);

export const LettersWrapper = styled.div(
  () => css`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `
);

export const NumericItemWrapper = styled.div(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.down('md')} {
      text-align: end;
    }
  `
);
