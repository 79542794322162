import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import {
  SocialPackageToConfirmationActionsAdapter,
  SocialPackageToConfirmationContentAdapter,
  SocialPackageToConfirmationTitleAdapter,
} from './adapters';
import { SocialPackageToConfirmationEventProps } from './types';
import { useSocialPackageToConfirmation } from './useToConfirmation';

/**
 * состояние КСП
 *
 * есть признак КСП у пользователя
 * согласился за пределами системы
 * готов к активации или уже согласован {@link ESocialPackageStatus.Ready} {@link ESocialPackageStatus.Active} {@link ESocialPackageStatus.Upcoming}
 * при закрытии отправляется автоматическое согласие
 */

export const SocialPackageToConfirmationEventContainer: FC<SocialPackageToConfirmationEventProps> = props => {
  useSocialPackageToConfirmation(props.event.payload.socialPackage);

  return (
    <DialogueEventContentBuilderLayout
      title={<SocialPackageToConfirmationTitleAdapter {...props} />}
      actions={<SocialPackageToConfirmationActionsAdapter {...props} />}
      titleAsContent
    >
      <SocialPackageToConfirmationContentAdapter {...props} />
    </DialogueEventContentBuilderLayout>
  );
};
