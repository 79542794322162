import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  () => css`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    align-items: stretch;
    position: relative;
  `
);
