import StubCommon from 'components/common/stub/common';
import { commonStub } from 'media/images';
import { FC } from 'react';

export const SecurityFallbackCommon: FC = () => {
  return (
    <StubCommon
      title='Страница не доступна'
      text='Но всегда можно найти что-то интересное на главной странице или воспользоваться поиском'
      image={commonStub}
    />
  );
};
