import { CategoriesApi, CustomersApi } from '@privilege-frontend/api';
import { defaultCorpOfferActiveStatuses, defaultCorpOfferValidStatuses, getQueryErrorByAxiosError } from './utils';
import { Category, CategoryTree, CorpOfferShort, ECorpOfferSortType, EListResultTypes, Pageable } from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import { getBaseEndpoint, getPageableFromResponseHeaders } from 'openApi/utils';
import { api, ECorpOfferServicesTag, OfferListRequest, OffersCountRequest } from './index';

export type CorpOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type CorpOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type CorpOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetCorpOfferDetailsRequest = {
  readonly id: UUID;
};

const corpOfferCategoriesTransformer = (categories: Category[]): Category[] =>
  categories; /*.filter(item => !!item.parentId)*/

export const corpOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getCorpOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      queryFn: async ({ ids }, { signal }) => {
        try {
          const { data } = await CategoriesApi.getCorpOfferCategories(
            getBaseEndpoint(),
            {
              category: ids,
            },
            signal
          );
          return {
            data: corpOfferCategoriesTransformer(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getCorpOfferCategoriesUsed: builder.query<Category[], CorpOfferCategoriesUsedRequest>({
      queryFn: async ({ name, onlyLeafCategories }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerCorpOfferCategories(
            getBaseEndpoint(),
            {
              q: name,
              status: defaultCorpOfferValidStatuses,
              onlyLeafCategories,
            },
            signal
          );
          return {
            data: corpOfferCategoriesTransformer(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getCorpOfferCategoriesUsedTree: builder.query<CategoryTree, CorpOfferCategoriesUsedTreeRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerCorpOfferCategories(
            getBaseEndpoint(),
            {
              status: defaultCorpOfferValidStatuses,
              onlyLeafCategories: false,
            },
            signal
          );
          return {
            data: categoriesBuildTree(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getCorpOfferList: builder.query<Pageable<CorpOfferShort>, OfferListRequest>({
      queryFn: async ({ search, page, pageSize, sort = [] }, { signal }) => {
        const { name, categories, statuses = defaultCorpOfferActiveStatuses, partnerId } = search;
        try {
          const response = await CustomersApi.listCustomerCorpOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              q: name,
              status: statuses,
              category: categories,
              partnerId,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<CorpOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [ECorpOfferServicesTag.List],
    }),
    getCorpOffersCount: builder.query<number, OffersCountRequest>({
      queryFn: async ({ search }, { signal }) => {
        const { name, categories } = search;
        try {
          const { data } = await CustomersApi.listCustomerCorpOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.Count,
              page: 0,
              size: 1,
              q: name,
              status: defaultCorpOfferValidStatuses,
              category: categories,
            },
            signal
          );
          return {
            data: data?.[0]?.count ?? 0,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getCorpOfferSearch: builder.query<Pageable<CorpOfferShort>, CorpOfferSearchRequest>({
      keepUnusedDataFor: 0,
      queryFn: async ({ search, pageSize }, { signal }) => {
        const { name } = search;
        try {
          const response = await CustomersApi.listCustomerCorpOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: pageSize,
              sort: [ECorpOfferSortType.Default],
              q: name,
              status: defaultCorpOfferValidStatuses,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<CorpOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getCorpOfferDetails: builder.query<CorpOfferShort, GetCorpOfferDetailsRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerCorpOfferById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [
        { type: ECorpOfferServicesTag.Details, id: args.id },
        ECorpOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetCorpOfferListQuery,
  useGetCorpOffersCountQuery,
  useGetCorpOfferSearchQuery,
  useGetCorpOfferCategoriesQuery,
  useGetCorpOfferCategoriesUsedQuery,
  useGetCorpOfferCategoriesUsedTreeQuery,
  useGetCorpOfferDetailsQuery,
} = corpOfferApi;
