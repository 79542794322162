import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { SocialPackage } from 'domain/model';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { createEvent as createEventToAgreement } from '../../../../socialPackage/events/toAgreement';

type BookingOfferCspBannerAgreementAdapterProps = {
  readonly socialPackage: SocialPackage;
};

export const BookingOfferCspBannerAgreementAdapter = (props: BookingOfferCspBannerAgreementAdapterProps) => {
  const { socialPackage } = props;
  const { publish } = useBusinessEventBus();

  return (
    <SocialPackageBanner
      variant={'primary'}
      actionText={'Подробнее'}
      onClick={() => {
        publish(createEventToAgreement({ socialPackage }));
      }}
    >
      Компенсируем до {toCspRubCurrency(socialPackage.maxLimit)} от ваших затрат на оздоровление и отдых
    </SocialPackageBanner>
  );
};
