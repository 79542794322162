import styled from '@emotion/styled';
import { css, fabClasses } from '@mui/material';

export const MobileOptionsWrapper = styled.div(
  () => css`
    width: 100%;

    display: flex;
    justify-content: space-between;

    .${fabClasses.root} {
      background-color: transparent;
    }
  `
);
