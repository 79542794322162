import { Text } from '../../components/controls';

const AboutCspWhatIsItInfo = () => {
  return (
    <>
      <Text>
        Это компенсируемый социальный пакет, предоставляемый работникам на санаторно-курортное оздоровление и отдых по
        решению комиссии по распределению путевок на элемент КСП «Компенсация оплаты услуг организации отдыха и
        санаторно-курортного лечения для работника, его детей, супруга/супруги на сторонних объектах, определенных
        отдельными локальными нормативными актами ОАО «РЖД» по вопросам кадровой и социальной политики».
      </Text>
    </>
  );
};

export default AboutCspWhatIsItInfo;
