import React from 'react';
import { DialogueEventsFlow } from './';

export type DialogueEventsContextState<QueueType> = {
  readonly events: QueueType;
  readonly activeFlow: Nullable<DialogueEventsFlow>;
};

export type DialogueEventsContextValue<QueueType> = DialogueEventsContextState<QueueType> & {
  readonly onNext: (result?: any) => void;
  readonly onClose: () => void;
};

export const DialogueEventsContext = React.createContext<DialogueEventsContextValue<any>>(
  {} as DialogueEventsContextValue<any>
);
