/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenMainCategoryId, GenMainOfferCategory, GenMainProductCategory } from './data-contracts';
import { createRequest } from './http-client';

export const CategoriesApi = {
  /**
   * No description
   * * @tags Categories
   * @name GetCorpOfferCategories
   * @summary Получение списка категорий КП
   * @request GET:/corp-offer-categories
   * @secure
   */
  getCorpOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /**
       * Фильтр по родительской категории
       * @format uuid
       */
      parent?: string | null;
      /** только корневые категории */
      onlyRoot?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/corp-offer-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportCorpOfferCategories
   * @summary Получение списка категорий КП в xlsx формате
   * @request GET:/corp-offer-categories/export
   * @secure
   */
  exportCorpOfferCategories: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/corp-offer-categories/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetCorpOfferCategoryById
   * @summary Получение  категории КП
   * @request GET:/corp-offer-categories/{categoryId}
   * @secure
   */
  getCorpOfferCategoryById: (baseURL: string, categoryId: GenMainCategoryId, signal?: AbortSignal) =>
    createRequest<GenMainOfferCategory, void>({
      baseURL,
      path: `/corp-offer-categories/${categoryId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetTradeOfferCategories
   * @summary Получение списка категорий ТП
   * @request GET:/trade-offer-categories
   * @secure
   */
  getTradeOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /**
       * Фильтр по родительской категории
       * @format uuid
       */
      parent?: string | null;
      /** только корневые категории */
      onlyRoot?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/trade-offer-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportTradeOfferCategories
   * @summary Получение списка категорий ТП в xlsx формате
   * @request GET:/trade-offer-categories/export
   * @secure
   */
  exportTradeOfferCategories: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/trade-offer-categories/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetTradeOfferCategoryById
   * @summary Получение  категории ТП
   * @request GET:/trade-offer-categories/{categoryId}
   * @secure
   */
  getTradeOfferCategoryById: (baseURL: string, categoryId: GenMainCategoryId, signal?: AbortSignal) =>
    createRequest<GenMainOfferCategory, void>({
      baseURL,
      path: `/trade-offer-categories/${categoryId}`,
      method: 'GET',
      signal,
    }),

  /**
   * @description Сортировка: * по имени   - name (ASC|DESC)
   * * @tags Categories
   * @name GetProductCategories
   * @summary Получение списка категорий товаров
   * @request GET:/product-categories
   * @secure
   */
  getProductCategories: (
    baseURL: string,
    query: {
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /**
       * Фильтр по родительской категории
       * @format uuid
       */
      parent?: string | null;
      /** только корневые категории */
      onlyRoot?: boolean | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainProductCategory[], void>({
      baseURL,
      path: `/product-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportProductOfferCategories
   * @summary Получение списка категорий товаров в xlsx формате
   * @request GET:/product-categories/export
   * @secure
   */
  exportProductOfferCategories: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/product-categories/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetProductCategoryById
   * @summary Получение  категории товаров
   * @request GET:/product-categories/{categoryId}
   * @secure
   */
  getProductCategoryById: (baseURL: string, categoryId: GenMainCategoryId, signal?: AbortSignal) =>
    createRequest<GenMainProductCategory, void>({
      baseURL,
      path: `/product-categories/${categoryId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportBookingOfferPriceUnits
   * @summary Получение списка справочника единиц тарификаций услуг ДиО в xlsx формате
   * @request GET:/booking-services/price-units/export
   * @secure
   */
  exportBookingOfferPriceUnits: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/booking-services/price-units/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportBookingOfferServiceCategories
   * @summary Получение списка справочника категорий услуг ДиО в xlsx формате
   * @request GET:/booking-services/categories/export
   * @secure
   */
  exportBookingOfferServiceCategories: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/booking-services/categories/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetBookingOfferCategories
   * @summary Получение списка категорий ДиО
   * @request GET:/booking-offer-categories
   * @secure
   */
  getBookingOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /**
       * Фильтр по родительской категории
       * @format uuid
       */
      parent?: string | null;
      /** только корневые категории */
      onlyRoot?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/booking-offer-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name GetBookingOfferCategoryById
   * @summary Получение категории объектов ДиО
   * @request GET:/booking-offer-categories/{categoryId}
   * @secure
   */
  getBookingOfferCategoryById: (baseURL: string, categoryId: GenMainCategoryId, signal?: AbortSignal) =>
    createRequest<GenMainOfferCategory, void>({
      baseURL,
      path: `/booking-offer-categories/${categoryId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Categories
   * @name ExportBookingOfferCategories
   * @summary Получение списка справочника категорий ДиО в xlsx формате
   * @request GET:/booking-offer-categories/export
   * @secure
   */
  exportBookingOfferCategories: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/booking-offer-categories/export`,
      method: 'GET',
      signal,
    }),
};
