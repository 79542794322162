import { paginationSizeVariant } from 'domain/model';
import { EOfferType } from 'domain/model';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getOffersListQueryParams } from 'presentation/features/offer/utils';

export enum ECorpOfferUrlParam {
  Tab = 'tab',
  Category = ECatalogUrlParam.Category,
  PartnerId = 'partnerId',
}

export const corpOffersDefaultParams = getOffersListQueryParams(
  {
    name: null,
    categories: null,
    partnerId: null,
    page: 1,
    pageSize: paginationSizeVariant[0],
    sort: null,
  },
  EOfferType.CorpOffer
);
