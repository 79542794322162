import { Fade, Typography } from '@mui/material';
import useVisibleSectionLinks from 'features/sectionLinks/hooks/useVisibleSectionLinks';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { ContentWrapper, Wrapper } from './controls';
import { CspApplicationLayoutType } from './types';

const CspApplicationLayout: CspApplicationLayoutType = props => {
  const { title, actions, loader: Loader, children } = props;

  useVisibleSectionLinks();

  return (
    <Fade in>
      <DefaultContentWrapper>
        <Loader>
          <Wrapper elevation={0}>
            <Typography variant='h1'>{title}</Typography>
            <ContentWrapper>{children}</ContentWrapper>
            {actions}
          </Wrapper>
        </Loader>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default CspApplicationLayout;
