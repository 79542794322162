import { AxiosResponse } from 'axios';
import {
  useNsiBookingOrderStatusesQuery,
  useNsiCancelOrderCustomerTypesQuery,
  useNsiCancelOrderTypesQuery,
} from 'data/api/nsi';
import { useCancelOrderMutation, userApi } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import { EDateTimeFormat, EOfferType, EOrderStatus } from 'domain/model';
import ContentLoader from 'components/common/loader';
import Splitter from 'components/common/splitter';
import { getRequisites, getStatusName } from 'features/order/components/listItem/adapters/utils';
import { utcToLocalTimeZone } from 'presentation/utils/date';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancelOrderModal } from 'features/order/cancelOrderModal';
import CancelOption from '../../../components/optionItems/cancel';
import ContactsOption from '../../../components/optionItems/contacts';
import SupportOption from '../../../components/optionItems/support';
import { CommonOptionsWrapper, OptionsWrapper } from '../../../components/options/controls';
import StatusComment from '../../../components/statusComment';
import StatusText from '../../../components/statusText';
import { BookingOrderProps } from '../../types';
import { createEvent } from 'features/user/events/feedBack';
import { useUserEventBus } from '@privilege-frontend/eventBus';

const cancellableStatuses = [EOrderStatus.New, EOrderStatus.Confirmed];

const BookingOfferOrderOptionsAdapter: FC<BookingOrderProps> = ({ order }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);

  const { publishTop } = useUserEventBus();

  const onOpenFeedback = useCallback(() => publishTop(createEvent({})), [publishTop]);

  const {
    data: bookingOrderStatuses,
    isFetching: isBookingOrderStatusesFetching,
    error: bookingOrderStatusesError,
  } = useNsiBookingOrderStatusesQuery();
  const {
    data: cancelOrderTypes,
    isFetching: isCancelOrderTypesFetching,
    error: cancelOrderTypesError,
  } = useNsiCancelOrderTypesQuery();
  const {
    data: cancelCustomerTypes,
    isFetching: isCancelCustomerTypesFetching,
    error: cancelCustomerTypesError,
  } = useNsiCancelOrderCustomerTypesQuery();

  const isFetching = isBookingOrderStatusesFetching || isCancelOrderTypesFetching || isCancelCustomerTypesFetching;

  const [cancelOrder, { isLoading, error }] = useCancelOrderMutation();

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
    if (bookingOrderStatusesError) {
      ErrorHandler.handleHttpError(bookingOrderStatusesError);
    }
    if (cancelOrderTypesError) {
      ErrorHandler.handleHttpError(cancelOrderTypesError);
    }
    if (cancelCustomerTypesError) {
      ErrorHandler.handleHttpError(cancelCustomerTypesError);
    }
  }, [bookingOrderStatusesError, cancelCustomerTypesError, cancelOrderTypesError, error]);

  const handleCancelOrder = (id: UUID, comment: string) => {
    if (!order.id) {
      return;
    }

    cancelOrder({
      id: order.id,
      cancellationType: { id },
      comment,
    })
      .unwrap()
      .then(response => {
        if (order.id) {
          setModalVisible(false);
          dispatch(
            userApi.util.updateQueryData('getUserOrder', order.id, draft => {
              Object.assign(draft, response);
            })
          );
        }
      })
      .catch((response: AxiosResponse<ServerErrorResponse>) => ErrorHandler.handleHttpError(response));
  };

  const statusName = bookingOrderStatuses && getStatusName(bookingOrderStatuses, order.status);

  const date = utcToLocalTimeZone(order.createdAt).format(EDateTimeFormat.Human);

  const requisites = order.number && getRequisites(order.number, date);

  const isCancellable = cancellableStatuses.includes(order.status);

  if (isFetching || !cancelOrderTypes || !cancelCustomerTypes) {
    return <ContentLoader />;
  }

  return (
    <CommonOptionsWrapper>
      <StatusText
        status={statusName ?? ''}
        requisites={requisites}
      />
      <Splitter size={3} />
      {order.status === EOrderStatus.Cancelled && (
        <StatusComment
          order={order}
          reasons={[...cancelOrderTypes, ...cancelCustomerTypes]}
        />
      )}
      <Splitter size={3} />
      <OptionsWrapper>
        {order.offer?.id && <ContactsOption offerId={order.offer.id} />}
        {isCancellable && <CancelOption onClick={() => setModalVisible(true)} />}
        <SupportOption onOpen={onOpenFeedback} />
      </OptionsWrapper>
      <CancelOrderModal
        isOpen={isModalVisible}
        isFetching={isLoading}
        offerType={EOfferType.BookingOffer}
        onCancelOrder={handleCancelOrder}
        onClose={() => setModalVisible(false)}
      />
    </CommonOptionsWrapper>
  );
};

export default BookingOfferOrderOptionsAdapter;
