import styled from '@emotion/styled';
import { css, inputBaseClasses, outlinedInputClasses, selectClasses } from '@mui/material';
import { MPCheckbox, MPFormInput } from 'theme/ui-kit/input';

export const Select = styled(MPFormInput)(
  ({ theme: { spacing } }) => css`
    && .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input} {
      padding-left: ${spacing(2)};

      &[aria-expanded='true'] {
        background: unset;
      }
    }
  `
);

export const Checkbox = styled(MPCheckbox)(
  () => css`
    margin-left: 1px;
  `
);
