import { useMediaQuery } from '@mui/material';
import {
  ECorpOfferPromotionType,
  EOfferActivationStatus,
  EOfferStatus,
  ETradeOfferPromotionType,
  OfferActivation,
  OfferPromotionType,
} from 'domain/model';
import { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferListItemContainer from '../../offer/listItem/container';
import ActivationListItemCodeSidebar from '../components/sidebar/code';
import ActivationListItemFileSidebar from '../components/sidebar/file';
import ActivationListItemLinkSidebar from '../components/sidebar/link';
import ActivationListItemPendingSidebar from '../components/sidebar/pending';
import ActivationListItemRejectedSidebar from '../components/sidebar/rejected';
import ActivationListItemUpcomingSidebar from '../components/sidebar/upcoming';
import ActivationListItemWidgetSidebar from '../components/sidebar/widget';
import { OfferWrapper, Wrapper } from './controls';

export type ActivationListItemProps = {
  readonly activation: OfferActivation;
  readonly onOfferClick: () => void;
  readonly onOpenHelpDialog: (type: OfferPromotionType) => void;
  readonly getActivationCodeTypeName: (type: OfferPromotionType) => string;
  readonly onPartnerLinkClick: () => void;
  readonly onTakeActivation: () => void;
  readonly copied: boolean;
  readonly needCopy: boolean;
  readonly fileDownloading: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

const ActivationListItem = (props: ActivationListItemProps) => {
  const {
    activation,
    onOpenHelpDialog,
    onOfferClick,
    onPartnerLinkClick,
    getActivationCodeTypeName,
    onTakeActivation,
    fileDownloading,
    onNeedCopy,
    copied,
    needCopy,
  } = props;
  const { offer, promotion, status, lastStatusComment } = activation;
  const { type } = promotion;

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const direction: OfferListItemProps['direction'] = isSmDown ? 'row-reverse' : 'column';

  if (!offer) {
    return null;
  }

  let listItem;

  switch (activation.promotion.type) {
    case ECorpOfferPromotionType.CorpCertificate:
    case ECorpOfferPromotionType.ExternalCorpCertificate:
      listItem = (
        <OfferListItemContainer
          key={offer.id}
          offer={{
            ...offer,
            promotionType: activation.promotion.type,
            price: activation.price ?? 0,
            originalPrice: activation.originalPrice,
          }}
        />
      );
      break;
    default:
      listItem = (
        <OfferListItemContainer
          key={offer.id}
          offer={{ ...offer, promotionType: activation.promotion.type }}
          direction={direction}
        />
      );
  }

  return (
    <Wrapper>
      <MPGrid container>
        <MPGrid
          item
          zero={12}
          sm={isSmDown ? 12 : 6}
        >
          <OfferWrapper direction={direction}>{listItem}</OfferWrapper>
        </MPGrid>
        <MPGrid
          item
          zero={12}
          sm={6}
        >
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status === EOfferStatus.Upcoming && (
              <ActivationListItemUpcomingSidebar
                offerName={offer.name}
                offerCode={offer.code}
                startDate={offer.startDate}
                onOfferClick={onOfferClick}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.ReferralLink && (
              <ActivationListItemLinkSidebar
                partnerLink={promotion.publicPromotionValue}
                onPartnerLinkClick={onPartnerLinkClick}
                codeTypeName={getActivationCodeTypeName(type)}
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.Widget && (
              <ActivationListItemWidgetSidebar
                codeTypeName={getActivationCodeTypeName(type)}
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                activation={activation}
                srcDoc={promotion.publicPromotionValue}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.PublicPromocode && (
              <ActivationListItemCodeSidebar
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                partnerLink={offer.partnerLink}
                code={promotion.publicPromotionValue}
                codeTypeName={getActivationCodeTypeName(type)}
                onPartnerLinkClick={onPartnerLinkClick}
                onCopy={onTakeActivation}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
                copied={copied}
                needCopy={needCopy}
                onNeedCopy={onNeedCopy}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            (type === ETradeOfferPromotionType.AccessCode || type === ETradeOfferPromotionType.Promocode) && (
              <ActivationListItemCodeSidebar
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                partnerLink={offer.partnerLink}
                code={promotion.code}
                codeTypeName={getActivationCodeTypeName(type)}
                onPartnerLinkClick={onPartnerLinkClick}
                onCopy={onTakeActivation}
                copied={copied}
                needCopy={needCopy}
                onNeedCopy={onNeedCopy}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.Voucher && (
              <ActivationListItemFileSidebar
                downloadLabel={promotion.code}
                promotionType={ETradeOfferPromotionType.Voucher}
                partnerLink={offer.partnerLink}
                codeTypeName={getActivationCodeTypeName(type)}
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                isDownloading={fileDownloading}
                onPartnerLinkClick={onPartnerLinkClick}
                onDownload={onTakeActivation}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.Asp && (
              <ActivationListItemFileSidebar
                downloadLabel={promotion.code}
                promotionType={ETradeOfferPromotionType.Asp}
                codeTypeName={getActivationCodeTypeName(type)}
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                isDownloading={fileDownloading}
                onDownload={onTakeActivation}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            type === ETradeOfferPromotionType.Digift && (
              <ActivationListItemFileSidebar
                downloadLabel={promotion.code}
                promotionType={ETradeOfferPromotionType.Digift}
                codeTypeName={getActivationCodeTypeName(type)}
                appointmentDate={activation.appointmentDate}
                expireDate={activation.expireDate}
                isDownloading={fileDownloading}
                onDownload={onTakeActivation}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
              />
            )}
          {(status === EOfferActivationStatus.Approved || status === EOfferActivationStatus.Given) &&
            offer.status !== EOfferStatus.Upcoming &&
            (type === ECorpOfferPromotionType.ExternalCorpCertificate ||
              type === ECorpOfferPromotionType.CorpCertificate) && (
              <ActivationListItemFileSidebar
                downloadLabel={promotion.code}
                promotionType={type}
                partnerLink={null}
                codeTypeName={getActivationCodeTypeName(type)}
                expireDate={activation.expireDate}
                appointmentDate={activation.appointmentDate}
                onPartnerLinkClick={onPartnerLinkClick}
                isDownloading={fileDownloading}
                onDownload={onTakeActivation}
                onOpenHelpDialog={() => onOpenHelpDialog(type)}
              />
            )}
          {status === EOfferActivationStatus.Rejected && (
            <ActivationListItemRejectedSidebar
              offerName={offer.name}
              offerCode={offer.code}
              comment={lastStatusComment}
              onOfferClick={onOfferClick}
            />
          )}
          {status === EOfferActivationStatus.Pending && (
            <ActivationListItemPendingSidebar
              offerName={offer.name}
              offerCode={offer.code}
              comment={lastStatusComment}
              onOfferClick={onOfferClick}
            />
          )}
        </MPGrid>
      </MPGrid>
    </Wrapper>
  );
};

export default ActivationListItem;
