import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';

export const feedbackEventType = 'Feedback';

export type FeedbackEventPayload = {};

//факт удаления аккаунта в ответе
export type FeedbackEventResult = undefined;

export type FeedbackEvent = BusinessEvent<FeedbackEventPayload, FeedbackEventResult>;

export type FeedbackEventProps = DisplayEventComponentProps<FeedbackEventPayload, FeedbackEventResult>;
