import { Typography } from '@mui/material';
import { EPartnerRegDataDiscriminator, PartnerShort } from 'domain/model';
import { FC } from 'react';
import { AddressHelper } from 'utils/address';

type UserPersonalDataTransferToPartnerProps = {
  readonly partner: PartnerShort;
};

const Item: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <div>
      <Typography
        variant='subtitle2'
        component='span'
      >
        {label}:
      </Typography>{' '}
      {value}
    </div>
  );
};

const UserPersonalDataTransferPartnerInfo = (props: UserPersonalDataTransferToPartnerProps) => {
  const { partner } = props;

  return (
    <Typography variant='body2'>
      {partner.regInfo?.discriminator === EPartnerRegDataDiscriminator.PartnerIndividualData && (
        <>
          {partner.name && (
            <Item
              label={'Партнёр'}
              value={`ИП ${partner.name}`}
            />
          )}
          {partner.regInfo.ogrnip && (
            <Item
              label={'ОГРНИП'}
              value={partner.regInfo.ogrnip}
            />
          )}
        </>
      )}
      {partner.regInfo?.discriminator === EPartnerRegDataDiscriminator.PartnerCompanyData && (
        <>
          {partner.regInfo.companyName && (
            <Item
              label={'Партнёр'}
              value={partner.regInfo.companyName}
            />
          )}
          {partner.inn && (
            <Item
              label={'ИНН'}
              value={partner.inn}
            />
          )}
        </>
      )}
      {partner.address && (
        <Item
          label={'Юридический адрес'}
          value={new AddressHelper(partner.address).getFullPathWithPostalCode()}
        />
      )}
    </Typography>
  );
};

export default UserPersonalDataTransferPartnerInfo;
