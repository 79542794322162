import { EOfferStatus } from 'domain/model';
import OfferClaimPriceInfo from '../../../../components/claim/priceInfo';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';

export const AspOfferClaimPriceAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }

  const isVisible =
    (aspOffer.status === EOfferStatus.Active || aspOffer.status === EOfferStatus.Upcoming) &&
    !activation.isLastActivationPending;

  if (!isVisible || !aspOffer.paymentType) {
    return null;
  }

  return (
    <OfferClaimPriceInfo
      paymentType={aspOffer.paymentType}
      price={aspOffer.price}
    />
  );
};
