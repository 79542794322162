import { ETradeOfferPromotionType } from 'domain/model';
import { ReactNode } from 'react';

type DialogContent = {
  readonly title: ReactNode;
  readonly text: ReactNode;
};

export const dialogContent: Record<ETradeOfferPromotionType, DialogContent> = {
  [ETradeOfferPromotionType.Asp]: {
    title: 'Как использовать сертификат',
    text: '',
  },
  [ETradeOfferPromotionType.Digift]: {
    title: 'Как использовать сертификат',
    text: '',
  },
  [ETradeOfferPromotionType.Voucher]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.Widget]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.PublicPromocode]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.ReferralLink]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.Promocode]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.AccessCode]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.CorpCertificate]: {
    title: '',
    text: '',
  },
  [ETradeOfferPromotionType.ExternalCorpCertificate]: {
    title: '',
    text: '',
  },
};
