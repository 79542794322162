import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { EOfferActivationStatus, OfferActivation } from 'domain/model';
import { ApiCancellable, ApiRequestPageable } from './types';
import { createCancelToken, getBaseEndpoint, getUserQueryParams } from './utils';

type AllProps = ApiRequestPageable & ApiCancellable;

type ByOfferIdProps = ApiRequestPageable &
  ApiCancellable & {
    readonly offerId: UUID;
  };

type CountProps = ApiCancellable;

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CreateProps = {
  readonly offerId: UUID;
};

type CertificateDownloadUrlProps = {
  readonly activationId: UUID;
};

type ActivationApi = {
  readonly all: (props: AllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferActivation[]>>;
  readonly byOfferId: (props: ByOfferIdProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferActivation[]>>;
  readonly count: (props: CountProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferActivation[]>>;
  readonly one: (props: OneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferActivation>>;
  readonly create: (props: CreateProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferActivation>>;
  readonly certificateDownloadUrl: (props: CertificateDownloadUrlProps) => string;
};

export const activationStatuses: EOfferActivationStatus[] = [
  EOfferActivationStatus.Pending,
  EOfferActivationStatus.Rejected,
  EOfferActivationStatus.Approved,
  EOfferActivationStatus.Given,
];

/**
 * АПИ по работе с активациями предложений
 */
const activation: ActivationApi = {
  all: props => {
    const { signal, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    activationStatuses.forEach(status => params.append('status', status));

    return {
      url: `/customers/current/activations`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  byOfferId: props => {
    const { signal, offerId, ...queryParams } = props;
    const params = getUserQueryParams(queryParams);

    params.append('offerIds', offerId);
    activationStatuses.forEach(status => params.append('status', status));

    return {
      url: `/customers/current/activations`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  count: props => {
    return activation.all({ ...props, page: 1, pageSize: 1 });
  },
  one: props => {
    const { id, signal } = props;
    return {
      url: `/customers/current/activations/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  create: props => {
    const { offerId } = props;

    return {
      url: `/customers/current/activations`,
      method: 'POST',
      data: { offerId },
    };
  },
  certificateDownloadUrl: ({ activationId }) => {
    return `${getBaseEndpoint()}/customers/current/activations/${activationId}/cert`;
  },
};

export default activation;
