import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';
import { alertClasses } from '@mui/material';

const alert: OverrideThemeFunc<Components> = baseTheme => ({
  MuiAlert: {
    styleOverrides: {
      root: {
        padding: baseTheme.spacing(1, 1.5),
        borderRadius: `${baseTheme.shape.borderRadius}px`,
      },
      filled: {
        [`&.${alertClasses.colorInfo}`]: {
          color: baseTheme.palette.text.primary,
          backgroundColor: baseTheme.palette.secondary.A700,
        },
      },
      message: {
        padding: 0,
      },
    },
  },
});

export default alert;
