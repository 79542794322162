import { Typography } from '@mui/material';
import { ProductOffer } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { ReactNode } from 'react';
import { GroupedByProductIdCategoryAttribute } from '../utils/prepareModelData';
import { ChildrenWrapper, Wrapper } from './controls';

type AttributeGroupProps = {
  readonly group: GroupedByProductIdCategoryAttribute;
  readonly selectedVariant: ProductOffer;
  readonly children: (g: GroupedByProductIdCategoryAttribute) => ReactNode;
};

const AttributeGroup = ({ group, children }: AttributeGroupProps) => {
  return (
    <Wrapper>
      <Typography fontWeight='bold'>{group.attribute.name}:</Typography>
      <Splitter size={1} />
      <ChildrenWrapper>{children(group)}</ChildrenWrapper>
    </Wrapper>
  );
};

export default AttributeGroup;
