import { Link } from '@mui/material';
import { MPButton } from '../../../theme/ui-kit/button';
import CorpLinkProxy from '../links/proxy';

type PartnerLinkProps = {
  readonly link: Nullable<string>;
  readonly onClick: () => void;
};

const PartnerLink = (props: PartnerLinkProps) => {
  const { link, onClick } = props;

  return (
    <CorpLinkProxy link={link ?? ''}>
      {linkByProxy => (
        <MPButton
          href={linkByProxy}
          component={Link}
          target='_blank'
          color='brand'
          fullWidth
          onClick={onClick}
        >
          Перейти на сайт партнёра
        </MPButton>
      )}
    </CorpLinkProxy>
  );
};

export default PartnerLink;
