import { AxiosResponse, AxiosStatic, CancelToken } from 'axios';
import { AppConfigurator } from 'system/appConfigurator';
import apiDefinition from './index';
import { ApiResponsePageable } from './types';

export const getBaseEndpoint = () => AppConfigurator.getInstance().getApiBase();

export const getWsServicesEndpoint = () => AppConfigurator.getInstance().getApiWsUrl();

export const getFilesEndpoint = () => AppConfigurator.getInstance().getApiFilesPath();

export const getBonusesEndpoint = (): string => AppConfigurator.getInstance().getApiBonusesPath();

export const getAddressEndpoint = (): string => AppConfigurator.getInstance().getApiAddressPath();

export const getBenefitTransactionEndpoint = (): string => AppConfigurator.getInstance().getApiBenefitTransactionPath();

export const getFileDownloadEndpoint = (props: { id: string; private?: boolean }) =>
  apiDefinition.files.getImageUrl(props.id, props.private);

export const createCancelToken = (axios: AxiosStatic, signal: AbortSignal): CancelToken => {
  const source = axios.CancelToken.source();

  signal.addEventListener('abort', () => {
    source.cancel();
  });

  return source.token;
};

export const getPageableFromResponseHeaders = (response: AxiosResponse): ApiResponsePageable => {
  const { headers } = response;
  return {
    pageCount: parseInt(headers['x-paging-page-count'], 10),
    totalCount: parseInt(headers['x-paging-total-count'], 10),
    page: parseInt(headers['x-paging-page'], 10),
    pageSize: parseInt(headers['x-paging-page-size'], 10),
  };
};

/**
 * Возвращает типовые данные для запросов
 * @param query поиск q
 * @param sort сортировка sort
 * @param page страница
 * @param pageSize размер страницы
 * @param favorite флаг избранного
 * @returns объект URLSearchParams
 *
 * @deprecated to remove
 */
export const getUserQueryParams = ({
  query,
  sort,
  page,
  pageSize,
  favorite,
}: {
  query?: Nullable<string>;
  sort?: Nullable<string[]>;
  page?: number;
  pageSize?: number;
  favorite?: Nullable<boolean>;
}): URLSearchParams => {
  const params = new URLSearchParams();

  if (sort) {
    sort.forEach(item => params.append('sort', item));
  }

  if (query) {
    params.append('q', query);
  }

  if (favorite) {
    params.append('favorite', favorite.toString());
  }

  if (typeof page !== 'undefined') {
    params.append('page', (page - 1).toString(10));
  }

  if (typeof pageSize !== 'undefined') {
    params.append('size', pageSize.toString(10));
  }

  return params;
};
