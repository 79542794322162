import { Link, LinkProps, Typography } from '@mui/material';
import { api, EUserServicesTag } from 'data/api';
import ErrorHandler from 'data/network/errorHandler';
import { UpdateEmailResponse } from 'domain/model';
import { RequiredActionConfirmCodeContainer } from 'features/requiredAction/confirmCode/container';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useEmailEditQuery } from 'presentation/hooks/useEmailEditQuery';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

type ConfirmAdapterProps = {
  readonly onClose?: () => void;
  readonly onSuccess: () => void;
  readonly onChangeEmail: () => void;
};

const ChangeEmail: FC<LinkProps> = props => (
  <Link
    color='primary'
    component={Typography}
    underline='hover'
    {...props}
  >
    Изменить почту
  </Link>
);

export const ConfirmAdapter: FC<ConfirmAdapterProps> = ({ onClose, onSuccess, onChangeEmail }) => {
  const dispatch = useDispatch();
  const { tokenParsed, refreshToken } = useAuthToken();

  const [source, setSource] = useState<Nullable<UpdateEmailResponse>>(null);

  const [updateCurrentUserEmail, { isLoading: isFetching, error }] = useEmailEditQuery();

  const email = tokenParsed?.email ?? '';

  const onGetCode = useCallback(
    () => updateCurrentUserEmail({ email }).unwrap().then(setSource).catch(console.error),
    [email, updateCurrentUserEmail]
  );

  const onConfirmed = async () => {
    // обновление токена (для подхвата нового признака о подтвержденной почте)
    await refreshToken();
    dispatch(api.util.invalidateTags([EUserServicesTag.Current]));
    onSuccess();
  };

  useEffect(() => {
    onGetCode();
  }, [onGetCode]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
      setSource(null);
      onClose?.();
    }
  }, [error, onClose]);

  return (
    <>
      {source && (
        <RequiredActionConfirmCodeContainer
          email={email}
          source={source}
          onSuccess={onConfirmed}
          onGetNewCode={onGetCode}
          back={<ChangeEmail onClick={onChangeEmail} />}
          onClose={onClose}
        />
      )}
      {isFetching && <ContentLoader />}
    </>
  );
};
