import { Fade } from '@mui/material';
import { Category } from 'domain/model';
import { ArrowLeftIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OffersTextHeader from '../textHeader';

type OffersCategoryHeaderProps = {
  readonly category: Category;
  readonly onChangeCategory: (id: Nullable<UUID>) => void;
};

const OffersCategoryHeader = (props: OffersCategoryHeaderProps) => {
  const { category, onChangeCategory } = props;

  return (
    <Fade in>
      <MPGrid
        container
        spacing={1}
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <MPGrid item>
          <MPFab
            variant={'squared'}
            size={'micro'}
            color={'secondaryLight'}
            onClick={() => onChangeCategory(category.parentId)}
          >
            <ArrowLeftIcon fontSize='medium' />
          </MPFab>
        </MPGrid>
        <MPGrid
          item
          zero
        >
          <OffersTextHeader>{category.name}</OffersTextHeader>
        </MPGrid>
      </MPGrid>
    </Fade>
  );
};

export default OffersCategoryHeader;
