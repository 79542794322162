import { Typography, useMediaQuery } from '@mui/material';
import CodeTitle from 'presentation/components/common/codeTitle/title';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Bold } from '../../../../user/details/activations/controls';
import { SidebarWrapper } from '../controls';

type ActivationListItemRejectedSidebarProps = {
  readonly offerName: Nullable<string>;
  readonly offerCode: Nullable<string>;
  readonly comment: Nullable<string>;
  readonly onOfferClick: () => void;
};

const ActivationListItemRejectedSidebar = (props: ActivationListItemRejectedSidebarProps) => {
  const { offerName, offerCode, comment, onOfferClick } = props;

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { x2 } = useGridSpacing();

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={x2}
        direction='column'
      >
        {isSmDown && (
          <MPGrid item>
            <Bold onClick={onOfferClick}>{offerName}</Bold>
          </MPGrid>
        )}
        <MPGrid item>
          <CodeTitle code={offerCode} />
        </MPGrid>
        <MPGrid item>
          <Typography
            color='primary'
            gutterBottom
          >
            Отказ
          </Typography>
        </MPGrid>
        <MPGrid item>
          <Typography variant='body2'>{comment ?? 'Причина не установлена'}</Typography>
        </MPGrid>
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemRejectedSidebar;
