import { ReactNode } from 'react';
import BackButton from '../../../features/offer/components/back';
import { SmartPhoneFixedBarWrapper } from './controls';

type SmartPhoneFixedBarProps = {
  readonly onBack: () => void;
  readonly text: Nullable<string>;
  readonly favorite?: ReactNode;
};

const SmartPhoneFixedBar = (props: SmartPhoneFixedBarProps) => {
  const { text, favorite, onBack } = props;

  return (
    <SmartPhoneFixedBarWrapper>
      <BackButton
        label={text}
        onClick={onBack}
      />

      {favorite}
    </SmartPhoneFixedBarWrapper>
  );
};

export default SmartPhoneFixedBar;
