import { RootState } from 'data/store/store';
import { EUserRole } from 'domain/model';

export const authProviderIsLoadingSelector = (store: RootState): boolean => store.auth.provider.isLoading;

export const authProviderIsAuthenticatedSelector = (store: RootState): boolean => store.auth.provider.isAuthenticated;

export const authProviderTokenSelector = (store: RootState) => store.auth.provider.token;

export const authProviderTokenParsedSelector = (store: RootState) => store.auth.provider.tokenParsed;

export const authProviderTokenParsedAccessSelector = (store: RootState) =>
  store.auth.provider.tokenParsed?.realm_access?.roles;

export const authProviderIsCorpUserSelector = (store: RootState): Nullable<boolean> =>
  store.auth.provider.tokenParsed?.realm_access?.roles?.some(r => r === EUserRole.Corp) ?? null;
