import { AnyDisplayEvent } from '@privilege-frontend/eventBus';
import { QueueOwn } from '../../types';

export class ArrayQueue implements QueueOwn {
  private value: AnyDisplayEvent[] = [];

  private updateSize() {
    this.size = this.value.length;
  }

  add(event: AnyDisplayEvent) {
    this.value.push(event);
    this.updateSize();
  }

  poll() {
    const event = this.value?.[0];
    if (event) {
      this.value = this.value.slice(1);
      this.updateSize();
    }
    return event;
  }

  peek() {
    return this.value?.[0];
  }

  forEach = this.value.forEach;

  removeMany(callback: (a: AnyDisplayEvent) => boolean) {
    this.value = this.value.filter(v => !callback(v));
    this.updateSize();
  }

  clone() {
    const copy = new ArrayQueue() as this;
    copy.value = this.value;
    copy.updateSize();
    return copy;
  }

  size = this.value.length;
}
