import { Number } from '../../components/listNumber/controls';

type ListNumberProps = {
  readonly number: number;
};

const ListNumber = ({ number }: ListNumberProps) => {
  return <Number variant='subtitle1'>{number}</Number>;
};

export default ListNumber;
