import { EOfferActivateError, EOfferActivationStatus, EOfferStatus } from 'domain/model';
import { useMemo } from 'react';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { CorpOfferClaimBalanceNotEnoughWarnMessage } from './balanceNotEnough';
import { CorpOfferClaimBusinessErrorMessage } from './businessError';
import { CorpOfferClaimCodesEndsMessage } from './codesEnds';
import { CurrentActivateErrrorPausedOfferWarnMessage } from './currentActivateErrrorPausedOffer';
import { CorpOfferClaimPendingWarnMessage } from './pendingWarn';
import { CorpOfferClaimRejectedWarnMessage } from './rejectedWarn';

export const CorpOfferClaimWarnMessagesAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferClaimContext();

  const isCodesEnded: boolean = (corpOffer?.notUsedOfferCount ?? 0) === 0;

  const { isUserBalanceNotEnough } = activation;

  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      return (
        <>
          {activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && (
            <CorpOfferClaimBusinessErrorMessage error={activation.activationError} />
          )}

          {isCodesEnded && isActivationReceivedNow && corpOffer.promotionType && (
            <CorpOfferClaimCodesEndsMessage promotionType={corpOffer.promotionType} />
          )}

          {activation.lastActivation?.status === EOfferActivationStatus.Pending && <CorpOfferClaimPendingWarnMessage />}

          {(corpOffer?.status === EOfferStatus.Active || isActivationReceivedNow) &&
            isUserBalanceNotEnough &&
            corpOffer.paymentType &&
            !isCodesEnded && <CorpOfferClaimBalanceNotEnoughWarnMessage paymentType={corpOffer.paymentType} />}

          {!isActivationReceivedNow &&
            corpOffer?.status !== EOfferStatus.Archived &&
            activation?.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CorpOfferClaimRejectedWarnMessage />
            )}

          {isActivationReceivedNow &&
            corpOffer?.status === EOfferStatus.Paused &&
            activation?.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CurrentActivateErrrorPausedOfferWarnMessage />
            )}
        </>
      );
    }
  }, [
    corpOffer,
    activation.activationError,
    activation.lastActivation?.status,
    isCodesEnded,
    isActivationReceivedNow,
    isUserBalanceNotEnough,
  ]);
};
