import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent as createEventChangeEmail } from 'features/user/events/changeEmail';
import { FCC } from 'react';
import { ConfirmAdapter } from './adapters';
import { ConfirmEmailEventProps } from './types';

export const ConfirmEmailContainer: FCC<ConfirmEmailEventProps> = ({
  event: {
    payload: { withSuccessStep },
    flowId,
    onSuccess,
  },
  onNext,
  onClose,
}) => {
  const { publishTop } = useBusinessEventBus();

  const onChangeEmail = () => {
    publishTop(createEventChangeEmail({ withSuccessStep }, { flowId, onSuccess }));
    onNext();
  };

  const onComplete = () => {
    onNext(true);
  };

  return (
    <ConfirmAdapter
      onClose={onClose}
      onSuccess={onComplete}
      onChangeEmail={onChangeEmail}
    />
  );
};
