import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';

export const InfoText = styled(Typography)(
  ({ theme }) => css`
    background: ${theme.palette.secondary.A400};
    padding: ${theme.spacing(1, 1.5)};
    border-radius: ${theme.shape.borderRadius}px;

    word-wrap: break-word;
  `
);
