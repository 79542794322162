import { getCspOffersListRoute } from 'features/offer/csp/routes';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { SocialPackageToConfirmationEventProps } from '../types';

export const SocialPackageToConfirmationActionsAdapter: FC<SocialPackageToConfirmationEventProps> = props => {
  const { onNext } = props;

  const history = useHistory();

  const onAction = () => {
    history.push(getCspOffersListRoute());
    onNext();
  };

  return (
    <ConfirmGroupButtons>
      <MPButton
        color='brand'
        fullWidth
        size={'large'}
        onClick={onAction}
      >
        Показать места отдыха
      </MPButton>
    </ConfirmGroupButtons>
  );
};
