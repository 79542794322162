import { Price, PriceWrapper } from './controls';
import { toRubCurrency } from 'presentationUtils/currency';
import { Ellipsis } from 'components/common/ellipsis';
import { BookingPriceItem } from 'domain/model';

type BookingListItemPriceProps = {
  readonly priceItem: BookingPriceItem;
};

const BookingListItemPrice = (props: BookingListItemPriceProps) => {
  const { priceItem } = props;

  return (
    <PriceWrapper>
      от
      <Price>{toRubCurrency(priceItem.price)}</Price>
      <Ellipsis as='span'>{priceItem.unit?.name?.toLocaleLowerCase() ?? ''}</Ellipsis>
    </PriceWrapper>
  );
};

export default BookingListItemPrice;
