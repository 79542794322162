import { Text } from '../../components/controls';
import { LinkCSPObjects } from './link';

const AboutCspWhenSubmitInfo = () => {
  return (
    <Text>
      Заявление на выплату компенсации за самостоятельно приобретенные путевки в{' '}
      <LinkCSPObjects>сторонние учреждения</LinkCSPObjects> на следующий год можно подать, написав заявление в комиссию
      по распределению путевок в августе текущего года.
    </Text>
  );
};

export default AboutCspWhenSubmitInfo;
