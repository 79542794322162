import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type WrapperState = {
  readonly guid: UUID;
  readonly containers: UUID[];
};

type Reducer<T> = CaseReducer<WrapperState[], PayloadAction<T>>;

type LazyWrapperPayload = {
  readonly containerId: UUID;
  readonly guid: UUID;
};

type Reducers = SliceCaseReducers<WrapperState[]> & {
  pushLazyLoadedItem: Reducer<LazyWrapperPayload>;
};

const slice = createSlice<WrapperState[], Reducers, 'lazyWrapper'>({
  name: 'lazyWrapper',
  initialState: [],
  reducers: {
    pushLazyLoadedItem: (state, { payload }) => {
      const { guid, containerId } = payload;

      const currentPage = state.find(page => page.guid === guid);

      if (!currentPage) {
        state.push({
          guid,
          containers: [containerId],
        });
      } else {
        if (!currentPage.containers.find(id => id === containerId)) currentPage.containers.push(containerId);
      }
    },
  },
});

export const { pushLazyLoadedItem } = slice.actions;

export default slice.reducer;
