import { CategoriesApi, CustomersApi } from '@privilege-frontend/api';
import { getQueryErrorByAxiosError } from 'api/utils';
import {
  Category,
  CategoryTree,
  EListResultTypes,
  EOfferStatus,
  EProductOfferSortType,
  Pageable,
  PriceRangeFilter,
  ProductOffer,
  ProductOfferShort,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import { getBaseEndpoint, getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EProductOfferServicesTag, OfferDetailsRequest, OfferListRequest, OffersCountRequest } from './index';

export type ProductOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type ProductOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly city?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
  readonly partnerId?: Nullable<UUID>;
  readonly collectionId?: Nullable<UUID>;
};

export type ProductOfferCategoriesUsedTreeRequest = {
  readonly city: string;
};

export type ProductOfferVariationsRequest = {
  readonly productDeskId: UUID;
};

const defaultProductOfferListStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.OutOfStock];

export type ProductOfferListRequest = OfferListRequest & Partial<PriceRangeFilter>;

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductOfferCategories: builder.query<Category[], void>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CategoriesApi.getProductCategories(
            getBaseEndpoint(),
            { page: 0, size: 100000 },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferCategory: builder.query<Category, UUID>({
      queryFn: async (id, { signal }) => {
        try {
          const { data } = await CategoriesApi.getProductCategoryById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferCategoriesUsed: builder.query<Category[], ProductOfferCategoriesUsedRequest>({
      queryFn: async ({ name, onlyLeafCategories, partnerId }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerProductOfferCategories(
            getBaseEndpoint(),
            {
              q: name,
              status: defaultProductOfferListStatuses,
              onlyLeafCategories,
              partnerId,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferCategoriesUsedTree: builder.query<CategoryTree, ProductOfferCategoriesUsedTreeRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              status: defaultProductOfferListStatuses,
              onlyLeafCategories: false,
            },
            signal
          );
          return {
            data: categoriesBuildTree(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferSearch: builder.query<Pageable<ProductOfferShort>, ProductOfferSearchRequest>({
      keepUnusedDataFor: 0,
      queryFn: async ({ search, pageSize }, { signal }) => {
        const { name } = search;
        try {
          const response = await CustomersApi.listCustomerProductOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: pageSize,
              sort: [EProductOfferSortType.ByNovelty],
              q: name,
              status: defaultProductOfferListStatuses,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<ProductOfferShort> = {
            data: response.data as unknown as ProductOfferShort[],
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferList: builder.query<Pageable<ProductOfferShort>, ProductOfferListRequest>({
      queryFn: async ({ search, page, pageSize, sort = [], minPrice, maxPrice }, { signal }) => {
        const { name, categories, statuses = defaultProductOfferListStatuses, partnerId } = search;
        try {
          const response = await CustomersApi.listCustomerProductOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              q: name,
              status: statuses,
              category: categories,
              partnerId,
              minPrice,
              maxPrice,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<ProductOfferShort> = {
            data: response.data as unknown as ProductOfferShort[],
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EProductOfferServicesTag.List],
    }),
    getProductOffersCount: builder.query<number, OffersCountRequest>({
      queryFn: async ({ search }, { signal }) => {
        const { name, categories } = search;
        try {
          const { data } = await CustomersApi.listCustomerProductOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.Count,
              page: 0,
              size: 1,
              q: name,
              status: defaultProductOfferListStatuses,
              category: categories,
            },
            signal
          );
          return {
            data: data?.[0]?.count ?? 0,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getProductOfferDetails: builder.query<ProductOffer, OfferDetailsRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerProductOfferById(getBaseEndpoint(), id, signal);
          return {
            data: data as ProductOffer,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [
        { type: EProductOfferServicesTag.Details, id: args.id },
        EProductOfferServicesTag.Details,
      ],
    }),
    getProductOfferVariations: builder.query<ProductOfferShort[], ProductOfferVariationsRequest>({
      queryFn: async ({ productDeskId }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerProductOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: 100000,
              productDeskId,
            },
            signal
          );
          return {
            data: data as unknown as ProductOfferShort[],
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
  }),
});

export const {
  useGetProductOfferListQuery,
  useGetProductOffersCountQuery,
  useGetProductOfferCategoriesQuery,
  useGetProductOfferCategoryQuery,
  useGetProductOfferCategoriesUsedQuery,
  useGetProductOfferDetailsQuery,
  useGetProductOfferCategoriesUsedTreeQuery,
  useGetProductOfferSearchQuery,
  useGetProductOfferVariationsQuery,
} = productApi;
