import { EOfferListType } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersTextHeader from '../../../components/textHeader';
import TradeOfferListDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOfferListGuidSelector, tradeOfferListUpcomingArgsSelector } from '../store/selectors';

type TradeOfferListDataUpcomingAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Upcoming;

export const TradeOfferListDataUpcomingAdapter = ({ guid }: TradeOfferListDataUpcomingAdapterProps) => {
  const currentGuid = useSelector(tradeOfferListGuidSelector);
  const args = useSelector(tradeOfferListUpcomingArgsSelector);

  const handlers = useContextHandlers();

  const header = useMemo(
    () => (
      <OffersTextHeader variant='h2'>
        Скоро будут доступны
        <Splitter size={3} />
      </OffersTextHeader>
    ),
    []
  );

  return useMemo(
    () =>
      guid !== currentGuid || !args ? null : (
        <TradeOfferListDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          header={header}
          onChangePage={handlers.onChangePage}
          onShowCard={handlers.onShowCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangePage, handlers.onShowCard, header]
  );
};
