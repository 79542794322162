import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';
import { EOfferType } from 'domain/model';

export const getUserFavoritesTradeOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesTradeOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesCorpOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesCorpOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesProductOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesProductOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesBookingsOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesBookingsOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesAllOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesOffersIsFetchingSelector = (guid: UUID) =>
  createSelector(
    getUserFavoritesTradeOffersIsFetchingSelector(guid),
    getUserFavoritesCorpOffersIsFetchingSelector(guid),
    getUserFavoritesProductOffersIsFetchingSelector(guid),
    getUserFavoritesBookingsOffersIsFetchingSelector(guid),
    getUserFavoritesAllOffersIsFetchingSelector(guid),
    (
      tradeOffersIsFetching,
      corpOffersIsFetching,
      productOffersIsFetching,
      bookingOffersIsFetching,
      allOffersIsFetching
    ) => {
      return (
        tradeOffersIsFetching ||
        corpOffersIsFetching ||
        productOffersIsFetching ||
        bookingOffersIsFetching ||
        allOffersIsFetching
      );
    }
  );

/** @deprecated не оптимален */
export const getUserFavoritesSelector = () => (store: RootState) => {
  const request = userApi.endpoints.getUserFavorites.select()(store);
  return request.data;
};

// все избранные офферы
export const userFavoritesSelector = (store: RootState) => {
  const request = userApi.endpoints.getUserFavorites.select()(store);

  return request.data;
};

export const createUserIsOfferFavoriteSelector = createSelector(
  userFavoritesSelector,
  (store: RootState, offerId: UUID) => offerId,
  (store: RootState, offerId: UUID, offerType: EOfferType) => offerType,
  (favorites, offerId, offerType) => {
    return !!favorites?.[offerType]?.find(f => f === offerId);
  }
);

export const userIsOfferFavoriteSelector = (offerId: UUID, offerType: EOfferType) => (store: RootState) =>
  createUserIsOfferFavoriteSelector(store, offerId, offerType);
