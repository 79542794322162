import styled from '@emotion/styled';
import { buttonClasses, css, svgIconClasses, Typography, typographyClasses } from '@mui/material';
import { DownloadIcon } from '../../../../media/icons';
import { MPButton } from '../../../../theme/ui-kit/button';

export const StyledDottedButton = styled(MPButton)(
  ({ theme, disabled }) => css`
    &.${buttonClasses.root} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      border: 1px solid ${theme.palette.primary.A600};
      background-color: ${theme.palette.primary.A500};

      padding: 0.75rem 1.25rem;

      ${theme.breakpoints.down('sm')} {
        padding: 0.75rem 1.25rem;
      }
    }

    .${typographyClasses.root} {
      color: ${disabled && theme.palette.action.disabled};
    }
  `
);

export const StyledDownloadIcon = styled(DownloadIcon)(
  () => css`
    &.${svgIconClasses.root} {
      margin-right: 0.25rem;
      margin-left: 0 !important;
    }
  `
);

export const TextLabel = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    font-weight: ${theme.typography.fontWeightMedium};
    align-items: center;
  `
);

export const DownloadLabel = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);
