import styled from '@emotion/styled';
import { css } from '@mui/material';

export const WithIconWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const WithIconWrapperContent = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-left: ${theme.spacing(0.5)};
  `
);

export const InfoWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.palette.warning.light};
    border-radius: ${theme.spacing(1.5)};
    padding: ${theme.spacing(1.5, 2)};
  `
);
