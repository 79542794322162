import activation from './activation';
import address from './address';
import booking from './booking';
import cms from './cms';
import files from './files';
import notification from './notification';
import user from './user';
import userEvent from './userEvent';

const apiDefinition = {
  files,
  user,
  notification,
  userEvent,
  activation,
  cms,
  address,
  booking,
};

export default apiDefinition;
