import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { CorpOfferReceivedActivationEventContainer } from './container';
import {
  CorpOfferReceivedActivationEvent,
  CorpOfferReceivedActivationEventPayload,
  corpOfferReceivedActivationEventType,
} from './types';

export * from './types';

export const createEvent = (payload: CorpOfferReceivedActivationEventPayload): CorpOfferReceivedActivationEvent =>
  EventCreatorFactory.business()
    .getCustom(corpOfferReceivedActivationEventType, CorpOfferReceivedActivationEventContainer)
    .withPayload(payload)
    .withOutMerge()
    .get();
