import { Typography } from '@mui/material';
import moment from 'moment';
import { MPButton } from 'theme/ui-kit/button';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { EDateFormat } from 'domain/model';
import { pluralize } from 'presentation/utils';

type CorpOfferGetNewCertProps = {
  readonly open: boolean;
  readonly price: Nullable<number>;
  readonly offerEndDate: Nullable<string>;
  readonly activationExpiryDays: number;
  readonly onGetCertificate: () => void;
  readonly onClose: () => void;
};

const CorpOfferGetNewCertDialog = ({
  open,
  price,
  offerEndDate,
  activationExpiryDays,
  onGetCertificate,
  onClose,
}: CorpOfferGetNewCertProps) => {
  const activationExpiryDate = moment(offerEndDate).endOf('day').add(activationExpiryDays, 'day');

  return (
    <MPConfirmDialog
      open={open}
      maxWidth='xs'
      title='Получить ещё один сертификат?'
      onClose={onClose}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            onClick={onGetCertificate}
          >
            Да, получить еще один
          </MPButton>
          <MPButton
            color='brand'
            size='large'
            fullWidth
            variant='text'
            onClick={onClose}
          >
            Нет
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <Typography>
        Сертификат этого предложения уже есть в разделе Мои привилегии. Хотите получить новый за {price}{' '}
        {price !== null && pluralize(price, ['балл', 'балла', 'баллов'])}?
      </Typography>
      <Typography fontWeight='bold'>Внимание!</Typography>
      <Typography>Сертификат действителен до {moment(activationExpiryDate).format(EDateFormat.Human)} года.</Typography>
    </MPConfirmDialog>
  );
};

export default CorpOfferGetNewCertDialog;
