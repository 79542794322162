import { useSelector } from 'react-redux';
import { cspApplicationHasApplicationSelector } from '../store/selectors';
import { CspApplicationDescription } from '../../components/applicationDescription';

export const CspApplicationContentAdapter = () => {
  const hasApplication = useSelector(cspApplicationHasApplicationSelector);

  if (hasApplication) {
    return null;
  }

  return <CspApplicationDescription />;
};
