import { FC } from 'react';
import { DialogueEventAsFeatureType, useDialogueEvents } from '../';

type DialogueEventsFeatureFlowAdapterProps = {
  readonly event: Nullable<DialogueEventAsFeatureType>;
};

export const DialogueEventsFeatureFlowAdapter: FC<DialogueEventsFeatureFlowAdapterProps> = ({ event }) => {
  const { onNext, onClose } = useDialogueEvents();

  if (!event) {
    return null;
  }

  const Feature = event.feature;

  return (
    <Feature
      event={event}
      onNext={onNext}
      onClose={onClose}
    />
  );
};
