import { Category } from 'domain/model';
import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { useContextHandlers } from '../hooks/useContextHandlers';

type UseProductOffersCatalog = {
  readonly city: string;
  readonly onChangeCategory: (category: Category) => void;
};

const useProductOffersCatalog = (): UseProductOffersCatalog => {
  const { city } = useUserLocalSettings().settings;
  const handlers = useContextHandlers();

  return {
    city: city?.name ?? '',
    onChangeCategory: handlers.onSelectProductOffersCategory,
  };
};

export default useProductOffersCatalog;
