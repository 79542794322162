import { BookingOffersRequestProps } from 'openApi/user/bookingOffer';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { BookingOffersSearchListLocationState } from '../../types';
import { getBookingOffersListArgs } from '../cacheKeyArgs';
import BookingOfferListData from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextSearchParams } from '../hooks/useContextSearchParams';
import { bookingOfferListArgsSelector, bookingOfferListGuidSelector } from '../store/selectors';

type BookingOfferListDataAdapterProps = Pick<BookingOffersSearchListLocationState, 'guid'>;

export const BookingOfferListDataAdapter = memo(({ guid }: BookingOfferListDataAdapterProps) => {
  const searchParams = useContextSearchParams();
  const currentGuid = useSelector(bookingOfferListGuidSelector);
  const storeArgs = useSelector(bookingOfferListArgsSelector);

  const offersListArgs: BookingOffersRequestProps = getBookingOffersListArgs(searchParams, storeArgs);

  const handlers = useContextHandlers();

  return guid !== currentGuid || !offersListArgs ? null : (
    <BookingOfferListData
      guid={currentGuid}
      args={offersListArgs}
      onShowCard={handlers.onShowCard}
    />
  );
});

BookingOfferListDataAdapter.displayName = 'BookingOfferListDataAdapter';
