import styled from '@emotion/styled';
import { css } from '@mui/material';

type WrapperProps = {
  readonly stretch?: boolean;
};

export const ControlsWrapper = styled.div<WrapperProps>(
  ({ theme, stretch }) => css`
    height: 100%;

    position: relative;

    display: flex;
    gap: ${theme.spacing()};

    > * {
      flex: ${stretch ? 1 : 0};
    }
  `
);

export const Wrapper = styled.div``;
