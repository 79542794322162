import { corpOfferSortType, ECorpOfferSortType } from 'domain/model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../../offer/components/sortSelect';
import { useCorpOffersContextHandlers } from '../../hooks/useCorpOffersContextHandlers';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';
import { partnerDeskDetailsCorpOffersSortSelector } from '../../store/selectors';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';

const items = Object.values(corpOfferSortType);

const PartnerDeskDetailsTabCorpOffersToolbar = () => {
  const sort = useSelector(partnerDeskDetailsCorpOffersSortSelector);

  const handlers = useCorpOffersContextHandlers();

  return useMemo(
    () => (
      <PartnerDeskDetailsTabToolbarLayout
        title={getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.CorpOffers)}
        sort={
          <OffersSortSelect<ECorpOfferSortType>
            value={sort}
            items={items}
            defaultValue={ECorpOfferSortType.Default}
            onChange={handlers.onChangeCorpOffersSort}
          />
        }
      />
    ),
    [sort, handlers.onChangeCorpOffersSort]
  );
};

export default PartnerDeskDetailsTabCorpOffersToolbar;
