import { Link, Typography } from '@mui/material';
import { Wrapper } from './controls';
import { Link as RouterLink } from 'react-router-dom';
import { getCspOffersListRoute } from 'features/offer/csp/routes';
import { SocialPackageApplicationShort } from 'domain/model';
import { FC } from 'react';
import moment from 'moment-timezone';

type CspApplicationCreatedInfoProps = {
  readonly application: SocialPackageApplicationShort;
};

const cspOffersLink = (
  <Link
    component={RouterLink}
    to={getCspOffersListRoute({})}
  >
    объекты с&nbsp;признаком «КСП:&nbsp;Отдых»
  </Link>
);

export const CspApplicationCreatedInfo: FC<CspApplicationCreatedInfoProps> = ({ application }) => {
  return (
    <Wrapper elevation={0}>
      <Typography variant='subtitle1'>Спасибо!</Typography>

      <Typography variant='body2'>
        Ваше заявление на частичную компенсацию расходов&nbsp; на&nbsp;путевки в&nbsp;{cspOffersLink}&nbsp; успешно
        принято.
      </Typography>

      <Typography variant='body2'>
        По результатам рассмотрения заявления Комиссия по&nbsp;распределению путевок в санаторно-курортные
        и&nbsp;оздоровительные учреждения сообщит Вам &nbsp;о&nbsp;принятом решении.
      </Typography>

      <Typography variant='subtitle1'>Заявка №{application.code}</Typography>

      <Typography variant='body2'>
        Выбран месяц заезда — {moment.months()[application.month - 1]} {application.year}
      </Typography>

      {application.familyMembersCount && (
        <Typography variant='body2'>Количество членов семьи — {application.familyMembersCount}</Typography>
      )}
    </Wrapper>
  );
};
