import { Content } from './controls';
import { FCC, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps, Typography, useMediaQuery } from '@mui/material';
import { ExpandableHTML } from 'features/offer/booking/components/serviceSelector/controls';
import { ExpandableText } from '../../../expandableText';

type ContentLimiterTooltipLayoutProps = {
  readonly rows?: number;
  readonly tooltipProps?: Partial<TooltipProps>;
};

export const ContentLimiterTooltipLayout: FCC<ContentLimiterTooltipLayoutProps> = props => {
  const { children, rows = 1, tooltipProps } = props;
  const refContent = useRef<Nullable<HTMLDivElement>>(null);
  const [expandable, setExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.clientHeight < refContent.current.scrollHeight) {
        setExpandable(false);
      } else {
        setExpandable(true);
      }
    }
  }, [refContent, children]);

  if (isMobile) {
    return (
      <ExpandableText
        as='div'
        lines={1}
        withOutButton
        expanded={isExpanded}
        onExpand={() => setIsExpanded(!isExpanded)}
      >
        <ExpandableHTML>
          <Typography
            variant={'body2'}
            color={'textSecondary'}
          >
            {children}
          </Typography>
        </ExpandableHTML>
      </ExpandableText>
    );
  }

  return (
    <Tooltip
      arrow
      title={children}
      placement={'bottom'}
      disableFocusListener={expandable}
      disableHoverListener={expandable}
      {...tooltipProps}
    >
      <Content
        rows={rows}
        ref={refContent}
        variant={'body2'}
        color={'textSecondary'}
      >
        {children}
      </Content>
    </Tooltip>
  );
};
