import { PriceRangeFilter } from 'domain/model';
import ProductFilterActionsAdapter from './adapter/actions';
import ProductFilterBehaviorAdapter from './adapter/behavior';
import ProductFilterPriceAdapter from './adapter/filterPrice';
import { ProductOfferFilterContext } from './context';
import { useProductOfferFilter } from './hooks/useProductOfferFilter';
import ProductFilterLayout from './layout';

type ProductFilterContainerProps = {
  readonly onChangeFilter: (filter: PriceRangeFilter) => void;
};

const Layout = ProductFilterLayout;

const ProductFilterContainer = (props: ProductFilterContainerProps) => {
  const { onChangeFilter } = props;

  const handlers = useProductOfferFilter();

  return (
    <ProductOfferFilterContext.Provider value={handlers}>
      <ProductFilterBehaviorAdapter onChangeFilter={onChangeFilter} />
      <Layout actions={<ProductFilterActionsAdapter />}>
        <ProductFilterPriceAdapter />
      </Layout>
    </ProductOfferFilterContext.Provider>
  );
};

export default ProductFilterContainer;
