import { FC } from 'react';
import { BusinessEvent, DisplayEventComponentProps } from '../types';
import { OwnEventBuilder } from './ownEvent';

type EventType<Payload, Result> = BusinessEvent<Payload, Result>;
type UiType<Payload, Result> = FC<DisplayEventComponentProps<Payload, Result>>;
type PayloadType<Payload, Result> = EventType<Payload, Result>['payload'];
type SuccessType<Payload, Result> = EventType<Payload, Result>['onSuccess'];

export class BusinessEventCreator {
  /**
   * простой ивент
   * @param uniqueKey ключ, см. {@link EventOwn.uniqueKey}
   * @param ui представление, см. {@link EventOwn.feature} и {@link EventOwn.component}, для использования feature пользоваться {@link BusinessEventCreator.getCustom}
   * @param payload нагрузка, см. {@link EventOwn.payload}
   */
  getSimple<Payload extends object, Result>(
    uniqueKey: string,
    ui: UiType<Payload, Result>,
    payload: Payload
  ): EventType<Payload, Result> {
    return new BusinessEventBuilder<Payload, Result>(uniqueKey, ui).withPayload(payload).get();
  }

  /**
   * ивент с функцией обратного вызова
   * @param uniqueKey ключ, см. {@link EventOwn.uniqueKey}
   * @param ui представление, см. {@link EventOwn.feature} и {@link EventOwn.component}, для использования feature пользоваться {@link BusinessEventCreator.getCustom}
   * @param payload нагрузка, см. {@link EventOwn.payload}
   * @param onSuccess функция возврата результата по окончанию, см. {@link EventOwn.onSuccess}
   */
  getWithCallback<Payload extends object, Result>(
    uniqueKey: string,
    ui: UiType<Payload, Result>,
    payload: Payload,
    onSuccess: SuccessType<Payload, Result>
  ): EventType<Payload, Result> {
    return new BusinessEventBuilder<Payload, Result>(uniqueKey, ui, onSuccess).withPayload(payload).get();
  }

  /**
   * ручная конфигурация ивента
   * @param uniqueKey ключ, см. {@link EventOwn.uniqueKey}
   * @param ui представление, см. {@link EventOwn.feature} и {@link EventOwn.component}
   */
  getCustom<Payload extends object, Result>(
    uniqueKey: string,
    ui: UiType<Payload, Result>
  ): BusinessEventBuilder<Payload, Result> {
    return new BusinessEventBuilder<Payload, Result>(uniqueKey, ui);
  }

  /**
   * конвертация в единый flow
   * @param flowId ключ флоу, см. {@link EventOwn.flowId}
   * @param events ивенты
   */
  asFlow(flowId: string, events: EventType<any, any>[]): EventType<any, any>[] {
    return [...events].map(e => ({ ...e, flowId }));
  }
}

class BusinessEventBuilder<Payload extends object, Result> extends OwnEventBuilder<EventType<Payload, Result>> {
  private readonly ui: UiType<Payload, Result>;

  private asFeature: boolean = false;
  private mergeWithNext: boolean = true;
  private mergeWithPrev: boolean = true;

  /**
   * @param uniqueKey ключ, см. {@link EventOwn.uniqueKey}
   * @param ui представление, см. {@link EventOwn.feature} и {@link EventOwn.component}
   * @param onSuccess калбэк, см. {@link EventOwn.onSuccess}
   */
  constructor(uniqueKey: string, ui: UiType<Payload, Result>, onSuccess?: SuccessType<Payload, Result>) {
    super(uniqueKey, onSuccess);
    this.ui = ui;
  }

  /**
   * {@link OwnEventBuilder.withCallback}
   */
  withCallback(onSuccess: SuccessType<Payload, Result>): BusinessEventBuilder<Payload, Result> {
    super.withCallback(onSuccess);
    return this;
  }

  /**
   * {@link OwnEventBuilder.withPayload}
   */
  withPayload(payload: PayloadType<Payload, Result>): BusinessEventBuilder<Payload, Result> {
    super.withPayload(payload);
    return this;
  }

  /**
   * {@link OwnEventBuilder.withFlowId}
   */
  withFlowId(flowId?: string): BusinessEventBuilder<Payload, Result> {
    super.withFlowId(flowId);
    return this;
  }

  /**
   * {@link OwnEventBuilder.withTopPriority}
   */
  withTopPriority(): BusinessEventBuilder<Payload, Result> {
    super.withTopPriority();
    return this;
  }

  /**
   * @description отключить объединение со следующим ивентом, см. {@link EventOwn.mergeWithNext}
   * @description по умолчанию включено
   */
  withOutMergeNext(): BusinessEventBuilder<Payload, Result> {
    this.mergeWithNext = false;
    return this;
  }

  /**
   * @description отключить объединение с предыдущим ивентом, см. {@link EventOwn.mergeWithPrev}
   * @description по умолчанию включено
   */
  withOutMergePrev(): BusinessEventBuilder<Payload, Result> {
    this.mergeWithPrev = false;
    return this;
  }

  /**
   * @description отключить объединение с предыдущим и следующим ивентом ивентом, см. {@link withOutMergeNext} and {@link withOutMergePrev}
   */
  withOutMerge(): BusinessEventBuilder<Payload, Result> {
    this.withOutMergeNext();
    this.withOutMergePrev();
    return this;
  }

  /**
   * @description включить режим самостоятельной фичи {@link DisplayEventOwn.feature}
   * @description по умолчанию отключено
   */
  makeAsFeature(): BusinessEventBuilder<Payload, Result> {
    this.asFeature = true;
    return this;
  }

  /** получить итоговый ивент */
  get(): EventType<Payload, Result> {
    const own = super.get();
    if (this.asFeature) {
      return {
        ...own,
        mergeWithPrev: this.mergeWithPrev,
        mergeWithNext: this.mergeWithNext,
        feature: this.ui,
      };
    } else {
      return {
        ...own,
        mergeWithPrev: this.mergeWithPrev,
        mergeWithNext: this.mergeWithNext,
        component: this.ui,
      };
    }
  }
}
