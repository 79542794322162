import { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { EEventPriority, EventOwn } from './types';
import { eventBusDomLabel } from './utils';

const debug = (...args: any[]) => console.debug('EventBus [core]', ...args);

export type UseEventBus = {
  /**
   * публикация события любого строкового типа с любой нагрузкой (коллекция)
   */
  publish: <EventName extends string, Payload extends EventOwn>(eventName: EventName, ...payloads: Payload[]) => void;
  /**
   * publish + приоритет {@link EEventPriority.Top}
   */
  publishTop: <EventName extends string, Payload extends EventOwn>(
    eventName: EventName,
    ...payloads: Payload[]
  ) => void;

  /**
   * публикация нескольких событий как единого flow
   * событиям под капотом назначается flowId
   */
  publishFlow: <EventName extends string, Payload extends EventOwn>(
    eventName: EventName,
    ...payloads: Payload[]
  ) => void;

  /**
   * подписка на получение события любого строкового типа с любой нагрузкой (коллекция)
   */
  subscribe: <EventName extends string, Payload extends object>(
    eventName: EventName,
    callback: Dispatch<Payload>
  ) => void;

  /**
   * отписка от мониторинга событий любого строкового типа
   */
  unsubscribe: <EventName extends string>(eventName: EventName) => void;
};

/**
 * @description шина событий
 */
export const useEventBus = (): UseEventBus => {
  const listeners: Record<string, EventListenerOrEventListenerObject> = useMemo(() => ({}), []);
  const element = useRef<Comment>();

  useEffect(() => {
    const nodeIterator = document.createNodeIterator(document.body, NodeFilter.SHOW_COMMENT);
    const comment = nodeIterator.nextNode() as Comment | null;

    if (comment && 'data' in comment && comment.data === eventBusDomLabel) {
      element.current = comment as Comment;
    }
  }, []);

  const error = useCallback(() => {
    throw new Error('EventBus not found');
  }, []);

  const publish = useCallback<UseEventBus['publish']>(
    (eventName, ...payloads) => {
      if (element.current) {
        payloads.forEach(payload => {
          const event = new CustomEvent(eventName, { detail: payload });
          debug('publish', event);
          element.current!.dispatchEvent(event);
        });
      } else {
        error();
      }
    },
    [error]
  );

  const publishTop = useCallback<UseEventBus['publishTop']>(
    (eventName, ...payloads) => {
      const priority = EEventPriority.Top;
      publish(eventName, ...payloads.map(payload => ({ ...payload, priority })));
    },
    [publish]
  );

  const publishFlow = useCallback<UseEventBus['publish']>(
    (eventName, ...payloads) => {
      const flowId = uuid();
      publish(eventName, ...payloads.map(payload => ({ ...payload, flowId })));
    },
    [publish]
  );

  const subscribe = useCallback<UseEventBus['subscribe']>(
    (eventName, callback) => {
      if (element.current) {
        let callbackEvent: EventListenerOrEventListenerObject;

        if (eventName in listeners) {
          callbackEvent = listeners[eventName]!;
        } else {
          callbackEvent = (event: Event) => {
            callback((event as CustomEvent).detail);
          };
          listeners[eventName] = callbackEvent;
        }

        element.current.addEventListener(eventName, callbackEvent);
      } else {
        error();
      }
    },
    [error, listeners]
  );

  const unsubscribe = useCallback<UseEventBus['unsubscribe']>(
    eventName => {
      element.current?.removeEventListener(eventName, listeners[eventName] || null);
    },
    [listeners]
  );

  return {
    publish,
    publishTop,
    publishFlow,
    subscribe,
    unsubscribe,
  };
};
