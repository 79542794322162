import { EOfferStatus } from 'domain/model';
import { useMemo } from 'react';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { AspOfferClaimArchivedMessage } from './archived';
import { AspOfferClaimPausedMessage } from './paused';
import { AspOfferClaimUnavailableMessage } from './unavailable';
import { AspOfferClaimUpcomingMessage } from './upcoming';

export const AspOfferClaimStateMessagesAdapter = () => {
  const { aspOffer, isActivationReceivedNow, isActivationAvailable } = useAspOfferClaimContext();

  return useMemo(() => {
    if (!aspOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return <AspOfferClaimUnavailableMessage />;
      } else {
        return (
          <>
            {aspOffer.status === EOfferStatus.Upcoming && (
              <AspOfferClaimUpcomingMessage startDate={aspOffer.startDate} />
            )}

            {aspOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && <AspOfferClaimPausedMessage />}

            {aspOffer.status === EOfferStatus.Archived && <AspOfferClaimArchivedMessage />}
          </>
        );
      }
    }
  }, [aspOffer, isActivationAvailable, isActivationReceivedNow]);
};
