import { PartnerShort } from 'domain/model';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { ReactNode } from 'react';
import UserPersonalDataTransferLayout from './layout';
import UserPersonalDataTransferPartnerInfo from './partnerInfo';

type UserPersonalDataTransferToPartnerProps = {
  readonly partner: PartnerShort;
  readonly text: ReactNode;
  readonly onClose: () => void;
};

const Layout = UserPersonalDataTransferLayout;

export const UserPersonalDataTransferToPartner = (props: UserPersonalDataTransferToPartnerProps) => {
  const { partner, text, onClose } = props;

  return (
    <Layout
      text={text}
      partnerInfo={<UserPersonalDataTransferPartnerInfo partner={partner} />}
      actions={
        <MPButton
          fullWidth
          size='large'
          color='brand'
          onClick={onClose}
        >
          Понятно
        </MPButton>
      }
      onClose={onClose}
    />
  );
};
