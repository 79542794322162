import { FC } from 'react';
import { Divider, Typography } from '@mui/material';
import { BookingOrderItem as Item } from 'domain/model/order';
import { EOrderByDateType, EOrderItemStatus } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { toRubCurrency } from 'presentation/utils/currency';
import OrderField from '../../../components/orderField';
import BookingOrderItemSlotRange from './slotRange';
import BookingOrderItemSlotMultiple from './slotMultiple';
import { Wrapper } from './controls';
import { BookingOrderOffer } from 'domain/model';

const BookingOrderItem: FC<Item & { bookingOffer: BookingOrderOffer }> = ({
  bookingOffer,
  priceItem,
  status,
  slots,
  totalCost,
  qty,
}) => {
  if (!priceItem?.service) {
    return null;
  }

  const isCancelled = status === EOrderItemStatus.Cancelled;
  const cost = toRubCurrency(totalCost);

  return (
    <Wrapper isCancelled={isCancelled}>
      {isCancelled && (
        <Typography
          variant='body1'
          mb={1}
        >
          Отменена
        </Typography>
      )}
      <Splitter size={2} />

      <OrderField
        label='Услуга'
        text={priceItem.name ?? ''}
      />
      <Splitter size={2} />

      <OrderField
        label='Тариф'
        text={`${priceItem.service.name ?? ''} — ${toRubCurrency(priceItem.price)} ${priceItem.unit?.name ?? ''}`}
      />
      <Splitter size={2} />

      {priceItem.service.orderByDateType === EOrderByDateType.Period && slots?.length && (
        <BookingOrderItemSlotRange
          bookingOffer={bookingOffer}
          slots={slots}
        />
      )}

      {priceItem.service.orderByDateType === EOrderByDateType.Days && slots?.length && (
        <>
          <BookingOrderItemSlotMultiple
            bookingOffer={bookingOffer}
            slots={slots}
          />
        </>
      )}

      {priceItem.service.orderByDateType === EOrderByDateType.None && typeof qty === 'number' && (
        <OrderField
          label='Количество'
          text={String(qty)}
        />
      )}

      <Splitter size={1.75} />

      {cost && (
        <OrderField
          label='Стоимость'
          text={cost}
        />
      )}

      <Splitter size={3} />
      <Divider />
      <Splitter size={1} />
    </Wrapper>
  );
};

export default BookingOrderItem;
