import { FC } from 'react';
import { InvalidStepAdapter } from './';

type InvalidFlowAdapterProps = {
  readonly phone: string;
  readonly isFetching: boolean;
  readonly onSubmit: (phone: string) => void;
};

export const InvalidFlowAdapter: FC<InvalidFlowAdapterProps> = ({ phone, isFetching, onSubmit }) => {
  return (
    <InvalidStepAdapter
      phone={phone}
      isFetching={isFetching}
      onSubmit={onSubmit}
    />
  );
};
