import { EEventPriority, EventOwn } from '../types';

type SuccessType<EventType extends EventOwn> = EventType['onSuccess'];
type PayloadType<EventType extends EventOwn> = EventType['payload'];

export class OwnEventBuilder<EventType extends EventOwn> {
  protected readonly uniqueKey: string;

  protected flowId?: string;
  protected priority: EEventPriority = EEventPriority.Default;
  protected payload = {} as PayloadType<EventType>;
  protected onSuccess: SuccessType<EventType>;

  /**
   * @param uniqueKey ключ, см. {@link EventOwn.uniqueKey}
   * @param onSuccess калбэк, см. {@link EventOwn.onSuccess}
   */
  constructor(uniqueKey: string, onSuccess?: SuccessType<EventType>) {
    this.uniqueKey = uniqueKey;
    this.onSuccess = onSuccess;
  }

  /** добавить функцию обратного вызова, см. {@link EventOwn.onSuccess} */
  withCallback(onSuccess: SuccessType<EventType>): OwnEventBuilder<EventType> {
    this.onSuccess = onSuccess;
    return this;
  }

  /** добавить нагрузку, см. {@link EventOwn.payload} */
  withPayload(payload: PayloadType<EventType>): OwnEventBuilder<EventType> {
    this.payload = payload;
    return this;
  }

  /** добавить flowId, см. {@link EventOwn.flowId} */
  withFlowId(flowId?: string): OwnEventBuilder<EventType> {
    this.flowId = flowId;
    return this;
  }

  /**
   * @description установить приоритет {@link EEventPriority.Top}
   * @description по умолчанию {@link EEventPriority.Default}
   */
  withTopPriority(): OwnEventBuilder<EventType> {
    this.priority = EEventPriority.Top;
    return this;
  }

  /** получить итоговый ивент */
  get(): EventOwn {
    return {
      uniqueKey: this.uniqueKey,
      flowId: this.flowId,
      payload: this.payload,
      priority: this.priority,
      onSuccess: this.onSuccess,
    };
  }
}
