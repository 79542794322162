import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing(2)};
  `
);

export const TextWrapper = styled.div(
  () => css`
    flex: 1;
  `
);
