import { Link } from '@mui/material';
import { EOfferStatus, ETradeOfferPromotionType } from 'domain/model';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';

const TradeOfferClaimPartnerLink = ({ link, onLinkClick }: { link: string; onLinkClick: () => void }) => {
  return (
    <CorpLinkProxy link={link}>
      {linkByProxy => (
        <MPButton
          component={Link}
          size='large'
          href={linkByProxy}
          fullWidth
          color='brand'
          target='_blank'
          onClick={onLinkClick}
        >
          Перейти на сайт партнёра
        </MPButton>
      )}
    </CorpLinkProxy>
  );
};

export const TradeOfferClaimPartnerLinkAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, onPartnerButtonClick } = useTradeOfferClaimContext();

  return useMemo(() => {
    const displayPartnerLink =
      (tradeOffer?.status === EOfferStatus.Active || isActivationReceivedNow) &&
      tradeOffer?.promotionType !== ETradeOfferPromotionType.ReferralLink;

    return tradeOffer?.partnerLink && displayPartnerLink ? (
      <TradeOfferClaimPartnerLink
        link={tradeOffer.partnerLink}
        onLinkClick={onPartnerButtonClick}
      />
    ) : null;
  }, [
    tradeOffer?.partnerLink,
    tradeOffer?.status,
    tradeOffer?.promotionType,
    isActivationReceivedNow,
    onPartnerButtonClick,
  ]);
};
