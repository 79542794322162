import { Components } from '@mui/material/styles/components';
import { getTypographyUtilityClass } from '@mui/material/Typography/typographyClasses';
import { OverrideThemeFunc } from './types';

const typographyIconClass = getTypographyUtilityClass('icon');

const link: OverrideThemeFunc<Components> = () => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        [`&.${typographyIconClass}`]: {
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
    },
  },
});

export default link;
