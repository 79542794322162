import { Typography } from '@mui/material';
import { EBalanceType } from 'domain/model';
import { FC } from 'react';
import { BalanceSlotTitle } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotTitleAdapter: FC<UserFinanceBalanceSlotAdapterProps> = ({
  balance,
  disabled = false,
}) => {
  return (
    <BalanceSlotTitle>
      <Typography
        variant='body2'
        color={disabled ? 'secondary' : 'inherit'}
      >
        {balance.type === EBalanceType.Bonuses && 'Бонусный пакет'}
        {balance.type === EBalanceType.ASP && 'Авансируемый социальный пакет'}
        {balance.type === EBalanceType.CSP && 'Компенсируемый социальный пакет'}
      </Typography>
    </BalanceSlotTitle>
  );
};
