import { PriceRangeFilter } from 'domain/model';
import { useContextHandlers } from 'features/offer/booking/list/hooks/useContextHandlers';
import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import {
  bookingOfferAllServicesSelector,
  bookingOfferAvailableServicesSelector,
  bookingOfferExtremumPriceRangeSelector,
  makeBookingOfferUnionIsFetchingSelector,
} from 'features/offer/booking/list/store/selectors';
import { BookingOfferListFilterCount } from 'features/offer/booking/list/types';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import BookingOfferListFilter from 'features/offer/booking/filter';

type BookingOfferListFilterAdapterProps = BookingOfferListFilterCount;

const BookingOfferListFilterAdapter: FC<BookingOfferListFilterAdapterProps> = ({ filterCount }) => {
  const searchParams = useContextSearchParams();
  const { services, rzdSocialPackage, minPrice, maxPrice } = searchParams;

  const isFilterFetching = useSelector(state => makeBookingOfferUnionIsFetchingSelector(searchParams)(state));
  const allServices = useSelector(state => bookingOfferAllServicesSelector(state, searchParams));
  const extremumPriceRange = useSelector(state => bookingOfferExtremumPriceRangeSelector(state, searchParams));
  const availableServices = useSelector(state => bookingOfferAvailableServicesSelector(state, searchParams));

  const priceFilter: PriceRangeFilter = useMemo(() => {
    return {
      maxPrice: maxPrice ?? null,
      minPrice: minPrice ?? null,
    };
  }, [maxPrice, minPrice]);

  const { onChangePriceRange, onSelectService, onResetFilters, onChangeCompensation } = useContextHandlers();

  return (
    <BookingOfferListFilter
      isFilterFetching={isFilterFetching}
      allServices={allServices}
      rzdSocialPackage={rzdSocialPackage ?? null}
      services={services ?? null}
      availableServices={availableServices}
      extremumPriceRange={extremumPriceRange}
      priceFilter={priceFilter}
      filterCount={filterCount}
      onChangePriceRange={onChangePriceRange}
      onSelectService={onSelectService}
      onResetFilters={onResetFilters}
      onChangeCompensation={onChangeCompensation}
    />
  );
};

export default BookingOfferListFilterAdapter;
