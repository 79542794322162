import { SwipeStepper } from 'components/common/swipeStepper';
import { FC, useState } from 'react';
import { ChangeStepAdapter, ConfirmStepAdapter } from './';

enum EMode {
  Confirm = 'confirm',
  Change = 'change',
}

type ConfirmFlowAdapterProps = {
  readonly phone: string;
  readonly isFetching: boolean;
  readonly onSubmit: (phone: string) => void;
  readonly onCancel: () => void;
};

export const ConfirmFlowAdapter: FC<ConfirmFlowAdapterProps> = ({ phone, isFetching, onSubmit, onCancel }) => {
  const [mode, setMode] = useState<EMode>(() => EMode.Confirm);

  const activeIndex = mode === EMode.Confirm ? 0 : 1;

  const onChange = () => setMode(EMode.Change);

  return (
    <SwipeStepper
      activeIndex={activeIndex}
      steps={[
        <ConfirmStepAdapter
          key={'confirm'}
          phone={phone}
          onSubmit={onSubmit}
          onChange={onChange}
          onCancel={onCancel}
        />,
        <ChangeStepAdapter
          key={'change'}
          isFetching={isFetching}
          onSubmit={onSubmit}
        />,
      ]}
    />
  );
};
