import { FC, Fragment } from 'react';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model';
import { BookingDateSlot } from 'domain/model/order';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';
import { getBookingSlotLabel } from 'features/offer/booking/components/modal/constants';
import { BookingOrderOffer } from 'domain/model';

type BookingOrderItemSlotRangeProps = {
  readonly bookingOffer: BookingOrderOffer;
  readonly slots: BookingDateSlot[];
};

const BookingOrderItemSlotRange: FC<BookingOrderItemSlotRangeProps> = ({ bookingOffer, slots }) => {
  return slots.map(slot => (
    <Fragment key={`${slot.startDate}-${slot.endDate}`}>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem title='Прибытие'>{moment(slot.startDate).format(EDateFormat.Human)}</BookingSlotItem>
        <BookingSlotItem title='Выезд'>{moment(slot.endDate).format(EDateFormat.Human)}</BookingSlotItem>
      </MPGrid>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem title={getBookingSlotLabel(bookingOffer)}>{slot.qty}</BookingSlotItem>
      </MPGrid>
    </Fragment>
  ));
};

export default BookingOrderItemSlotRange;
