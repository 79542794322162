import styled from '@emotion/styled';
import {
  css,
  DialogContent,
  DialogContentProps,
  Paper,
  PaperProps,
  typographyClasses,
  paperClasses,
  dialogClasses,
} from '@mui/material';
import { PRODUCT_OFFER_SIDEBAR_WIDTH as WIDTH } from 'presentation/features/offer/product/list/constants';
import { forwardRef } from 'react';

export const DialogWrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)(
  () => css`
    &.${paperClasses.root}.${dialogClasses.paper} {
      max-width: 100%;
      width: 100%;
      border-radius: 0;
      border: 0;
      height: 100vh;
      padding: 0;
      margin: 0;
    }
  `
);

export const DialogTitleWrapper = styled.div(
  ({ theme }) => css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      justify-content: center;
    }

    .${typographyClasses.root} {
      font-weight: 700;
      width: ${WIDTH}px;
      font-size: 19px;
      line-height: 24px;

      ${theme.breakpoints.up('sm')} {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
      }
    }
  `
);

export const StyledDialogContent = styled(
  forwardRef<any, DialogContentProps>((props, ref) => (
    <DialogContent
      {...props}
      ref={ref}
    />
  ))
)(
  () => css`
    display: flex;
    justify-content: center;
  `
);

export const FilterWrapper = styled.div(
  () => css`
    width: ${WIDTH}px;
  `
);
