import { EBalanceType } from 'domain/model';
import { UserFinanceHistoryBonusesAdapter } from './historyBonuses';
import useCurrentUserBalances from '../../../current/hooks/useBalances';

type UserFinanceHistoryAdapterProps = {
  readonly guid: UUID;
  readonly tab: EBalanceType;
};

export const UserFinanceHistoryAdapter = (props: UserFinanceHistoryAdapterProps) => {
  const { tab, guid } = props;
  const balancesData = useCurrentUserBalances();

  const isBalanceBonuses = balancesData?.find(balance => {
    return balance.type === EBalanceType.Bonuses;
  });

  switch (tab) {
    case EBalanceType.Bonuses:
      return isBalanceBonuses ? <UserFinanceHistoryBonusesAdapter guid={guid} /> : null;
    case EBalanceType.ASP:
    case EBalanceType.CSP:
      return null;
  }
};
