import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import Splitter from '../../splitter';
import CarouselDefault from '../carouselDefault';
import useGetDefaultCarouselNavigation from '../defaultNavigation/useGetDefaultCarouselNavigation';
import CarouselThumbs from '../thumbs';
import { CarouselCommonWrapper, DefaultCarouselWrapper, ThumbCarouselWrapper } from './controls';
import { useMediaQuery } from '@mui/material';

export type CarouselGalleryProps = {
  readonly children?: any[];
  readonly initSlide?: number;
  readonly mainSwiperContainerHeight?: number;
  readonly thumbSwiperContainerHeight?: number;
  readonly thumbScrollMode?: boolean;
  readonly mainSwiperProps?: SwiperProps;
  readonly thumbSwiperProps?: SwiperProps;
  readonly thumbSlidesPerView?: number;
  readonly showThumbs?: boolean;
  readonly swipersGap?: number;
  readonly defaultCarouselOverlay?: ReactNode;
  readonly thumbChildren?: JSX.Element[];
  readonly aspectRatio?: number;
  readonly onActiveIndexChange?: (activeIndex: number) => void;
};

const CarouselGallery = ({
  children,
  initSlide,
  showThumbs = true,
  // TODO(@Protopopov Ruslan): глючит при изменении, нужна реинициализация swiper
  thumbScrollMode = false,
  mainSwiperContainerHeight,
  thumbSwiperContainerHeight,
  swipersGap,
  thumbChildren,
  aspectRatio,
  defaultCarouselOverlay,
  onActiveIndexChange,
  mainSwiperProps = {
    spaceBetween: 10,
    navigation: false,
    pagination: false,
    loop: true,
  },
  thumbSwiperProps = {
    spaceBetween: 10,
    loop: true,
    slidesPerView: 4,
  },
}: CarouselGalleryProps) => {
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [swiper, setSwiper] = useState<Nullable<SwiperClass>>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<Nullable<SwiperClass>>(null);

  const childrenCount = React.Children.count(children);
  const detailSlides = children?.map((child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>) ?? [];
  const finalThumbChildren = thumbChildren ?? children;
  const thumbSlides = finalThumbChildren?.map((child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>);

  const finalShowThumbs = showThumbs && !!(thumbSlides?.length && thumbSlides.length > 1);

  const handlerChangeActiveIndex = (swiper: SwiperClass) => {
    onActiveIndexChange?.(swiper.realIndex);
  };

  const controlsElement = useGetDefaultCarouselNavigation({
    navigationEnabled: !!mainSwiperProps?.navigation,
    childrenCount,
    swiper,
  });

  useEffect(() => {
    if (initSlide) {
      swiper?.slideTo(initSlide, 0);
      thumbsSwiper?.slideTo(initSlide, 0);
    }
  }, [initSlide, swiper, thumbsSwiper]);

  const internalLoop = useMemo(() => {
    if (typeof thumbSwiperProps.slidesPerView === 'string') {
      return true;
    }

    if (!thumbSwiperProps?.slidesPerView || !thumbSlides?.length) {
      return false;
    }

    return thumbSlides.length >= thumbSwiperProps.slidesPerView;
  }, [thumbSlides?.length, thumbSwiperProps?.slidesPerView]);

  const internalThumbSwiperProps: SwiperProps = {
    ...thumbSwiperProps,
    loop: internalLoop,
  };

  return (
    <CarouselCommonWrapper>
      <DefaultCarouselWrapper height={mainSwiperContainerHeight}>
        <CarouselDefault
          controlsElement={controlsElement}
          swiperProps={mainSwiperProps}
          onSwiper={setSwiper}
          handlerChangeActiveIndex={handlerChangeActiveIndex}
          thumbsSwiper={thumbsSwiper}
          aspectRatio={aspectRatio}
        >
          {detailSlides}
        </CarouselDefault>
        {defaultCarouselOverlay}
      </DefaultCarouselWrapper>

      {finalShowThumbs && isSmUp && (
        <>
          <Splitter size={`${swipersGap}px`} />
          <ThumbCarouselWrapper height={thumbSwiperContainerHeight}>
            <CarouselThumbs
              onSwiper={setThumbsSwiper}
              thumbScrollMode={thumbScrollMode}
              swiperProps={internalThumbSwiperProps}
            >
              {thumbSlides}
            </CarouselThumbs>
          </ThumbCarouselWrapper>
        </>
      )}
    </CarouselCommonWrapper>
  );
};

export default CarouselGallery;
