import { Typography } from '@mui/material';
import { CheckIcon, InfoEmptyIcon } from 'media/icons';
import { FC } from 'react';
import { BookingCspPeriodRestrictions } from '../../types';
import { Wrapper } from './controls';

type BookingCspPeriodRestrictionProps = {
  readonly restriction: Nullable<BookingCspPeriodRestrictions>;
};

export const BookingCspPeriodRestriction: FC<BookingCspPeriodRestrictionProps> = ({ restriction }) => {
  if (!restriction || !restriction.text) {
    return null;
  }

  const icon = restriction.allowed ? (
    <CheckIcon
      fontSize='medium'
      color='success'
    />
  ) : (
    <InfoEmptyIcon
      fontSize='medium'
      color='warning'
    />
  );

  return (
    <Wrapper>
      <Typography variant='body2'>{restriction.text}</Typography>
      {icon}
    </Wrapper>
  );
};
