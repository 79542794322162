import ScreenLayout from 'presentation/layouts/screen';
import AboutAspContainer from '../../features/about/asp/container';

const AboutAspScreen = () => {
  return (
    <ScreenLayout>
      <AboutAspContainer />
    </ScreenLayout>
  );
};

export default AboutAspScreen;
