import styled from '@emotion/styled';
import { css } from '@mui/material';
import { MPGrid } from 'theme/ui-kit/grid';
import { EInnerGridAreas, EParentGridAreas } from './types';

type ParentLayoutGridAreaProps = {
  readonly area: EParentGridAreas;
};

const sidebarUpLgMarginLeft = 16;

const sidebarWidth = {
  default: 288,
  upLg: 348 + sidebarUpLgMarginLeft, //плюсуем sidebarUpLgMarginLeft чтобы отступ по макету выдержать, он задан ниже в виде margin-left для upLg
};

export const FitContainer = styled.div`
  display: flex;
  flex: 1;

  [area=${EParentGridAreas.Inner}] {
    margin: ${p => p.theme.spacing(1, -1.5, 0 - 1.5)};

    ${p => p.theme.breakpoints.up('sm')} {
      margin: ${p => p.theme.spacing(1, -2, 0 - 2)};
    }

    ${p => p.theme.breakpoints.up('md')} {
      margin: 0;
    }
  }
`;

export const ParentLayoutGridArea = styled(MPGrid)<ParentLayoutGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const ParentLayoutGrid = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: grid;
    grid-column-gap: ${theme.spacing()};
    grid-row-gap: ${theme.spacing()};
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas: '${`${EParentGridAreas.Inner} `.repeat(12)}';

    ${theme.breakpoints.up('md')} {
      grid-row-gap: ${theme.spacing(1)};
      grid-template-areas:
        '${`${EParentGridAreas.Bar} `.repeat(12)}'
        '${`${EParentGridAreas.Inner} `.repeat(12)}';
    }

    [area=${EParentGridAreas.Bar}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        display: flex;
      }
    }
  `
);

type InnerLayoutGridAreaProps = {
  readonly area: EInnerGridAreas;
};

export const InnerLayoutGridArea = styled(MPGrid)<InnerLayoutGridAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const InnerLayoutGrid = styled.div(
  ({ theme }) => css`
    width: 100%;

    padding-top: ${theme.spacing(3.5)};
    padding-bottom: ${theme.spacing(3)};

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;

    grid-template-areas:
      '${`${EInnerGridAreas.Image} `.repeat(12)}'
      '${`${EInnerGridAreas.Breadcrumbs} `.repeat(12)}'
      '${`${EInnerGridAreas.Title} `.repeat(12)}'
      '${`${EInnerGridAreas.Description} `.repeat(12)}'
      '${`${EInnerGridAreas.Sidebar} `.repeat(12)}'
      '${`${EInnerGridAreas.Tabs} `.repeat(12)}';

    ${theme.breakpoints.up('md')} {
      padding-top: 0;
      border-radius: ${theme.spacing(3)};

      grid-column-gap: ${theme.spacing(2)};
      grid-template-columns: minmax(300px, 100%) ${sidebarWidth.default}px;
      grid-template-areas:
        '${EInnerGridAreas.Breadcrumbs} ${EInnerGridAreas.Breadcrumbs}'
        '${EInnerGridAreas.Title} ${EInnerGridAreas.Title}'
        '${EInnerGridAreas.Favorite} ${EInnerGridAreas.Favorite}'
        '${EInnerGridAreas.Image} ${EInnerGridAreas.Sidebar}'
        '${EInnerGridAreas.Description} ${EInnerGridAreas.Sidebar}'
        '... ${EInnerGridAreas.Sidebar}'
        '${EInnerGridAreas.Tabs} ${EInnerGridAreas.Tabs}';

      [area=${EInnerGridAreas.SmartphoneFixedBar}] {
        display: none;
      }
    }

    ${theme.breakpoints.up('lg')} {
      grid-column-gap: ${theme.spacing(3)};
      grid-template-columns: minmax(300px, 100%) ${sidebarWidth.upLg}px;
    }

    ${theme.breakpoints.up(1100)} {
      grid-template-columns: minmax(300px, 480px) minmax(150px, 300px) ${sidebarWidth.upLg}px;

      grid-template-rows: auto auto auto auto auto 0; //нужно, чтобы не было пустой грид строки после сайдбара, так как "..." рядом с tabs даёт пустоту
      grid-template-areas:
        '${EInnerGridAreas.Breadcrumbs} ${EInnerGridAreas.Breadcrumbs} ${EInnerGridAreas.Breadcrumbs}'
        '${EInnerGridAreas.Title} ${EInnerGridAreas.Title} ${EInnerGridAreas.Title}'
        '${EInnerGridAreas.Favorite} ${EInnerGridAreas.Favorite} ${EInnerGridAreas.Favorite}'
        '${EInnerGridAreas.Image} ${EInnerGridAreas.Description} ${EInnerGridAreas.Sidebar}'
        '${EInnerGridAreas.Tabs} ${EInnerGridAreas.Tabs} ${EInnerGridAreas.Sidebar}'
        '${EInnerGridAreas.Tabs} ${EInnerGridAreas.Tabs} ...';
    }

    [area=${EInnerGridAreas.Breadcrumbs}] {
      margin-top: ${theme.spacing(2)};

      ${theme.breakpoints.up('md')} {
        margin-top: 0;
      }
    }

    [area=${EInnerGridAreas.Image}] {
      margin: 0 -${theme.spacing(1.5)};

      ${theme.breakpoints.up('sm')} {
        margin: 0 -${theme.spacing(2)};
      }

      ${theme.breakpoints.up('md')} {
        margin: 0;
        margin-top: ${theme.spacing(1.5)};
      }
    }

    [area=${EInnerGridAreas.Title}] {
      margin-top: ${theme.spacing(1.5)};

      ${theme.breakpoints.up('sm')} {
        margin-top: ${theme.spacing(2)};
      }

      ${theme.breakpoints.up('md')} {
        padding-right: ${theme.spacing(6)};
      }
    }

    [area=${EInnerGridAreas.Favorite}] {
      display: none;

      ${theme.breakpoints.up('md')} {
        display: block;
      }

      ${theme.breakpoints.up('md')} {
        > :first-of-type {
          margin-top: ${theme.spacing(1.5)};
        }
      }
    }

    [area=${EInnerGridAreas.Description}] {
      margin-top: ${theme.spacing(1.5)};

      ${theme.breakpoints.up('sm')} {
        margin-top: ${theme.spacing(2)};
      }

      ${theme.breakpoints.up('md')} {
        margin-top: ${theme.spacing(3)};
      }

      ${theme.breakpoints.up('lg')} {
        margin-top: ${theme.spacing(4)};
      }
    }

    [area=${EInnerGridAreas.Sidebar}] {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(3)};

      margin-top: ${theme.spacing(3)};

      ${theme.breakpoints.up('sm')} {
        margin-top: ${theme.spacing(4)};
      }

      ${theme.breakpoints.up('md')} {
        margin-top: ${theme.spacing(1.5)};
      }

      ${theme.breakpoints.up('lg')} {
        margin-left: ${sidebarUpLgMarginLeft}px;
      }
    }

    [area=${EInnerGridAreas.Tabs}] {
      margin-top: ${theme.spacing(3)};

      ${theme.breakpoints.up('sm')} {
        margin-top: ${theme.spacing(5)};
      }
    }
  `
);

export const RelatedWrapper = styled.div(
  ({ theme }) => css`
    :not(:empty) {
      padding: ${theme.spacing(2)};
      margin-top: ${theme.spacing(3)};

      ${theme.breakpoints.up('md')} {
        padding: 0;
      }
    }
  `
);
