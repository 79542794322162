import { BusinessEvent, useBusinessEventBus } from '@privilege-frontend/eventBus';
import { AxiosResponse } from 'axios';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import store from 'data/store/store';
import { BookingPriceUnit, BookingSlotPeriod, EOrderByDateType, ServicePriceUnit } from 'domain/model';
import { BookingDateSlot } from 'domain/model/order';
import { useAuth } from 'features/auth/provider/useAuth';
import {
  currentUserHasRzdSocialPackages,
  currentUserIsEmailVerifiedSelector,
  currentUserIsStatusEnabledSelector,
} from 'features/user/current/store/selectors';
import { createEvent as confirmEmailEvent } from 'features/user/events/confirmEmail';
import { createEvent as confirmPhoneEvent } from 'features/user/events/confirmPhone';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { createEvent } from 'features/user/events/needLogin';
import { useWebAnalytics } from 'features/webAnalytics';
import moment from 'moment-timezone';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { ERenderSource } from 'presentation/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isDefined } from 'utils/array';
import { getBookingOffersDetailsOrderRoute, getBookingOffersListRoute } from '../../routes';
import { BookingCspPeriodRestrictions } from '../../types';
import { bookingOfferDetailsCartSelector } from '../store/selectors';
import {
  bookingOfferDetailsAddToCart,
  bookingOfferDetailsClearCart,
  bookingOfferDetailsSetModal,
} from '../store/slice';
import { BookingCartItem, Cart, UseBookingOfferDetails } from '../types';
import { sortByDate } from '../utils';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';

type MergeServices = (
  props: Pick<BookingCartItem, 'priceUnit' | 'orderItem'> &
    Cart & {
      serviceTariffInCart?: Nullable<BookingCartItem>;
    }
) => BookingCartItem[];

type MergePeriodServices = (props: Required<Pick<BookingCartItem, 'orderItem' | 'index'>> & Cart) => BookingCartItem[];

type EditCart = (props: Required<BookingCartItem> & Cart) => BookingCartItem[];

const useBookingOfferDetails = (id: UUID, from: ERenderSource): UseBookingOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { publishFlow } = useBusinessEventBus();

  const history = useHistory();

  const { isAuthenticated, login } = useAuth();
  const { publish } = useBusinessEventBus();
  const isUserEmailVerified = useSelector(currentUserIsEmailVerifiedSelector);
  const isUserStatusEnabled = useSelector(currentUserIsStatusEnabledSelector);
  const isUserHasRzdSocialPackages = useSelector(currentUserHasRzdSocialPackages);

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const { createOrder, isOrderCreating, errorCreateOrder, bookingOffer } = useBookingOfferDetailsData(id);

  const [customerComment, setCustomerComment] = useState('');

  const changeCustomerComment = (value: string) => {
    setCustomerComment(value);
  };

  const cart = useSelector(bookingOfferDetailsCartSelector);

  const getCart = useCallback(() => {
    const cart = bookingOfferDetailsCartSelector(store.getState());
    return cart?.[id] ?? null;
  }, [id]);

  const onBack = useCallback(() => {
    gotoPrevIndependentLocation(getBookingOffersListRoute());
  }, [gotoPrevIndependentLocation]);

  // Объединение услуг и тарифов
  const mergeServices: MergeServices = useCallback(
    ({ priceUnit, orderItem, cart, serviceTariffInCart }) => {
      if (!bookingOffer) {
        return [];
      }

      const updatedCart = [...cart];

      const serviceTariffIndex = serviceTariffInCart && cart.indexOf(serviceTariffInCart);
      const unit = { bookingOffer, priceUnit, orderItem };
      const isTariffExist = typeof serviceTariffIndex === 'number';

      // тариф услуги с датами существует в корзине, одинаковые суммируются
      if (priceUnit?.orderByDateType !== EOrderByDateType.None && serviceTariffInCart) {
        const cartSlots = [...serviceTariffInCart.orderItem.slots];
        orderItem.slots.forEach(item => {
          const existingItem = serviceTariffInCart.orderItem.slots.find(cartItem => {
            const sameStartDates = cartItem.startDate === item.startDate;

            if (priceUnit?.orderByDateType === EOrderByDateType.Days) {
              return sameStartDates;
            } else {
              return sameStartDates && cartItem.endDate === item.endDate;
            }
          });

          if (existingItem) {
            const existedData = cartSlots[cartSlots.indexOf(existingItem)];
            cartSlots[cartSlots.indexOf(existingItem)] = {
              ...existedData,
              qty: existedData.qty + item.qty,
            };
          } else {
            cartSlots.push(item);
          }
        });

        if (isTariffExist && updatedCart[serviceTariffIndex]) {
          unit.orderItem.slots = cartSlots;
          updatedCart[serviceTariffIndex] = unit;
          updatedCart[serviceTariffIndex].orderItem.slots.sort(sortByDate);
        }
      }

      // аналогично для бездат
      if (priceUnit?.orderByDateType === EOrderByDateType.None && isTariffExist) {
        const currentData = updatedCart[serviceTariffIndex];
        updatedCart[serviceTariffIndex] = {
          ...currentData,
          orderItem: {
            ...currentData.orderItem,
            qty: (currentData.orderItem.qty ?? 0) + (orderItem.qty ?? 0),
          },
        };
      }

      return updatedCart;
    },
    [bookingOffer]
  );

  const mergePeriodServicesOnEdit: MergePeriodServices = useCallback(({ index, cart, orderItem }) => {
    const updatedCart = [...cart];
    const changedSlot = orderItem.slots[index[1]];

    const hasSameValue =
      changedSlot &&
      updatedCart[index[0]]?.orderItem.slots.find((cartItem, cartIndex) => {
        return (
          cartIndex !== index[1] &&
          cartItem.startDate === changedSlot.startDate &&
          cartItem.endDate === changedSlot.endDate
        );
      });

    if (hasSameValue) {
      updatedCart[index[0]].orderItem.slots = updatedCart[index[0]].orderItem.slots
        .map((cartItem, cartIndex) => {
          if (cartIndex === index[1]) {
            return null;
          }

          return cartItem.startDate === changedSlot.startDate && cartItem.endDate === changedSlot.endDate
            ? {
                ...cartItem,
                qty: cartItem.qty + changedSlot.qty,
              }
            : cartItem;
        })
        .filter(isDefined);
    }

    return updatedCart;
  }, []);

  const editCart: EditCart = useCallback(
    ({ index, priceUnit, orderItem, cart }) => {
      let updatedCart: BookingCartItem[] = [];

      updatedCart = cart
        .map((item, mapIndex) => {
          if (mapIndex === index[0]) {
            const isNoDates = priceUnit.orderByDateType === EOrderByDateType.None;
            // проверка на удаление
            const isNotEmpty = isNoDates ? (orderItem.qty ?? 0) > 0 : orderItem.slots.length > 0;
            return isNotEmpty
              ? {
                  bookingOffer: item.bookingOffer,
                  priceUnit,
                  orderItem: isNoDates ? orderItem : { ...orderItem, slots: orderItem.slots.sort(sortByDate) },
                }
              : null;
          }
          return item;
        })
        .filter(isDefined);

      // Мерж при совпадении дат после обновления сервисов типа Range
      if (priceUnit.orderByDateType === EOrderByDateType.Period) {
        updatedCart = mergePeriodServicesOnEdit({ index, orderItem, cart: updatedCart });
      }

      return updatedCart;
    },
    [mergePeriodServicesOnEdit]
  );

  const updateCart = useCallback(
    (props: BookingCartItem) => {
      if (!bookingOffer) {
        return;
      }

      const { priceUnit, orderItem } = props;
      const cart = getCart();
      const serviceInCart = cart?.find(({ priceUnit: cartUnit }) => cartUnit.id === priceUnit.id);
      const serviceTariffInCart = cart?.find(
        ({
          priceUnit: cartUnit,
          orderItem: {
            priceItem: { id: cartPriceItemId },
          },
        }) => cartUnit.id === priceUnit.id && cartPriceItemId === orderItem.priceItem.id
      );
      let updatedCart: BookingCartItem[] = [];

      // Редактирование
      if (typeof props.index === 'object' && cart) {
        updatedCart = editCart({ ...(props as Required<BookingCartItem>), cart });
      } else if (cart && serviceInCart) {
        // Добавление нового тарифа в существующую услугу
        if (!serviceTariffInCart) {
          updatedCart = [...cart];
          updatedCart.push({ bookingOffer, priceUnit, orderItem });
          // Мерж тарифов услуги
        } else {
          updatedCart = mergeServices({ cart, serviceTariffInCart, priceUnit, orderItem });
        }
        // Добавление новой услуги с тарифом
      } else {
        updatedCart = [...(cart ?? []), { bookingOffer, priceUnit, orderItem }];
      }

      dispatch(bookingOfferDetailsAddToCart({ offerId: id, items: updatedCart }));
    },
    [bookingOffer, dispatch, editCart, getCart, id, mergeServices]
  );

  const onClearOfferCart: UseBookingOfferDetails['onClearOfferCart'] = useCallback(() => {
    dispatch(bookingOfferDetailsClearCart(id));
  }, [dispatch, id]);

  const onChangeServicesSelection: UseBookingOfferDetails['onChangeServicesSelection'] = ({
    priceItemId,
    priceUnit,
    isIncrement,
  }) => {
    if (!isAuthenticated) {
      login();
      return;
    }

    if (!bookingOffer) {
      return;
    }

    const cart = getCart();
    const byUnit = cart?.filter(item => item.priceUnit?.id === priceUnit.id);

    // Модалки с датами
    if (priceUnit.orderByDateType !== EOrderByDateType.None) {
      dispatch(
        bookingOfferDetailsSetModal({
          bookingOffer,
          priceUnit,
          orderItem: { priceItem: { id: priceItemId }, qty: null, slots: [] },
        })
      );
      return;
    }

    // Без дат
    dispatch(
      bookingOfferDetailsSetModal({
        bookingOffer,
        priceUnit,
        orderItem: {
          priceItem: { id: priceItemId },
          qty: isIncrement || !byUnit || !byUnit.length ? 1 : byUnit[0].orderItem.qty,
          slots: [],
        },
      })
    );
  };

  const onDeleteService: UseBookingOfferDetails['onDeleteService'] = index => {
    const cart = getCart();
    const selection = cart?.[index[0]];

    if (!selection) {
      return;
    }

    let slots = [] as BookingSlotPeriod[];

    if (typeof index[1] === 'number') {
      slots = [...selection.orderItem.slots];
      slots.splice(index[1], 1);
    }

    updateCart({
      ...selection,
      index,
      orderItem: {
        priceItem: selection.orderItem.priceItem,
        qty: 0,
        slots,
      },
    });
  };

  const onApplyModalChanges: UseBookingOfferDetails['onApplyModalChanges'] = props => {
    const cart = getCart();
    const { priceUnit, orderItem } = props || {};

    if (props && priceUnit && orderItem && bookingOffer) {
      const byItem = cart?.filter(item => item.orderItem.priceItem.id === orderItem.priceItem.id);

      if (priceUnit?.orderByDateType === EOrderByDateType.None && byItem?.length && cart) {
        updateCart({
          bookingOffer,
          priceUnit,
          orderItem,
          index:
            priceUnit.orderByDateType === EOrderByDateType.None && !props.index ? undefined : [cart.indexOf(byItem[0])],
        });
      } else {
        updateCart(props);
      }
    }

    dispatch(bookingOfferDetailsSetModal(null));
  };

  const onUpdateModal: UseBookingOfferDetails['onUpdateModal'] = index => {
    const cart = getCart();
    const selection = cart?.[index[0]];

    if (!selection) {
      return;
    }

    dispatch(bookingOfferDetailsSetModal({ ...selection, index }));
  };

  const handleOrder = async (userPhone: string) => {
    if (!cart || !Array.isArray(cart[id])) {
      return;
    }

    // оформляем заказ
    try {
      const order = await createOrder({
        customerComment,
        customerPhone: userPhone,
        items:
          cart[id]?.map(({ orderItem, priceUnit }) => {
            const slots: BookingDateSlot[] = orderItem.slots?.map(({ startDate, endDate, qty }) => ({
              startDate: typeof startDate === 'string' ? startDate : '',
              endDate: typeof endDate === 'string' ? endDate : '',
              qty,
            }));
            return {
              priceItem: orderItem.priceItem,
              ...(priceUnit.orderByDateType === EOrderByDateType.None
                ? { qty: orderItem.qty, slots: null }
                : { qty: null, slots }),
            };
          }) ?? [],
      }).unwrap();

      history.push(getBookingOffersDetailsOrderRoute({ id: order.id }));
    } catch (error) {
      ErrorHandler.handleHttpError(error as AxiosResponse<ServerErrorResponse>);
      return;
    }

    onClearOfferCart();
  };

  const tryHandleOrder = () => {
    const events: BusinessEvent[] = [];

    if (isUserEmailVerified === false) {
      events.push(confirmEmailEvent({}));
    }

    if (isUserStatusEnabled === false) {
      events.push(createEventNeedFillProfile({}));
    } else {
      //передаём калбэк для автоматического создания заказа после ивента ввода телефона
      events.push(confirmPhoneEvent({}, handleOrder));
    }

    publishFlow(events);
  };

  const onCalculateCspPeriodRestrictions = (
    service: BookingPriceUnit,
    priceUnit: ServicePriceUnit,
    slots: BookingSlotPeriod[]
  ): Nullable<BookingCspPeriodRestrictions> => {
    if (!isUserHasRzdSocialPackages || !service.rzdSocialPackage) {
      return null;
    }

    //Для КСП надо не менее 7 выбранных дней календаря
    const minDaysCount = 7;

    //интересующие нас единицы измерения, для остальных - не нужны ограничения
    const destUnitNames = ['сутки', 'ночь', 'день'];
    const destUnitName = priceUnit?.name ? !!destUnitNames.find(u => u === priceUnit.name.trim().toLowerCase()) : false;

    if (!destUnitName) {
      return null;
    }

    /**
     * считаем выбранные дни календаря
     * дату начала берём начало дня
     * дату окончания берём конец дня и добавляем секунду чтобы перескочить на следующий день
     * это нужно для правильной работы diff, иначе он всегда будет 0 возвращать, так как считает полные дни
     */
    const selectedDaysCount = slots.reduce<number>((prev, next) => {
      const startDate = moment(next.startDate).startOf('day');
      const endDate = moment(next.endDate).endOf('day').add(1, 'second');
      const daysDiff = endDate.diff(startDate, 'days');
      return prev + daysDiff;
    }, 0);

    if (selectedDaysCount >= minDaysCount) {
      return {
        text: 'Можно компенсировать по КСП',
        allowed: true,
      };
    } else {
      return {
        text: `Компенсация КСП — от ${minDaysCount} дней`,
        allowed: false,
      };
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      publish(createEvent({}));
    }
  }, [isAuthenticated, publish]);

  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);

  return {
    onBack,
    onClearOfferCart,
    onChangeServicesSelection,
    onDeleteService,
    onApplyModalChanges,
    onUpdateModal,
    handleOrder,
    tryHandleOrder,
    isOrderCreating,
    errorCreateOrder,
    customerComment,
    changeCustomerComment,
    onCalculateCspPeriodRestrictions,
  };
};

export default useBookingOfferDetails;
