import React from 'react';
import { BookingOfferListSearchParams, UseBookingOfferList } from '../types';

export type BookingOfferListContextValue = UseBookingOfferList;

export const BookingOfferListContext = React.createContext<BookingOfferListContextValue>(
  {} as BookingOfferListContextValue
);

export const BookingOfferListSearchParamsContext = React.createContext<BookingOfferListSearchParams>(
  {} as BookingOfferListSearchParams
);
