import { TextFieldProps } from '@mui/material';
import AppSvgIcon from 'presentation/components/common/icon';
import { forwardRef, KeyboardEventHandler } from 'react';

import { SearchIcon } from '../../../../media/icons';
import { AdornmentButton, StyledSearchInput } from './controls';

export type SearchInputProps = Pick<TextFieldProps, 'placeholder' | 'disabled' | 'value' | 'onChange' | 'onClick'> & {
  readonly showIcon?: boolean;
  readonly onSubmit?: () => void;
};

const SearchInput = forwardRef<any, SearchInputProps>((props, ref) => {
  const { placeholder, value, disabled, showIcon, onChange, onClick, onSubmit } = props;

  const onKeyUp: KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      onSubmit?.();
    }
  };

  return (
    <StyledSearchInput
      inputRef={ref}
      type='search'
      size='small'
      id={'main-search-input'}
      value={value}
      showIcon={!!showIcon}
      disabled={disabled}
      placeholder={placeholder || 'Поиск'}
      slotProps={{
        input: {
          endAdornment: showIcon ? (
            <AdornmentButton
              size='large'
              color='brand'
              onClick={onSubmit}
            >
              <AppSvgIcon
                fontSize='medium'
                icon={SearchIcon}
              />
            </AdornmentButton>
          ) : null,
          onKeyUp,
        },
      }}
      onClick={onClick}
      onChange={onChange}
    />
  );
});

export default SearchInput;
