import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.spacing()};
    background: ${theme.palette.secondary.A700};
    padding: ${theme.spacing(1.5, 2)};
  `
);
