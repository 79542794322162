import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { Cart, CartItem } from 'domain/model';
import { ApiCancellable } from '../types';
import { createCancelToken } from '../utils';

type GetCartProps = ApiCancellable;

type GetCartItemProps = ApiCancellable & {
  readonly id: UUID;
};

type DeleteCartItemProps = ApiCancellable & {
  readonly id: UUID;
};

type AddToCartProps = ApiCancellable & {
  readonly productId: UUID;
  readonly quantity: number;
};

type UpdateCartItemProps = ApiCancellable & {
  readonly cartItemId: UUID;
  readonly productId: UUID;
  readonly quantity: number;
};

export type CartApi = {
  readonly getCart: (props: GetCartProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Cart>>;
  readonly getCartItem: (props: GetCartItemProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CartItem>>;
  readonly deleteCartItem: (props: DeleteCartItemProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, void>>;
  readonly updateCartItem: (props: UpdateCartItemProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CartItem>>;
  readonly addToCart: (props: AddToCartProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CartItem>>;
};

const cart: CartApi = {
  getCart: ({ signal }) => {
    return {
      url: `/customers/current/basket`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  getCartItem: ({ signal, id }) => {
    return {
      url: `/customers/current/basket/items/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  deleteCartItem: ({ signal, id }) => {
    return {
      url: `/customers/current/basket/items/${id}`,
      method: 'DELETE',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  updateCartItem: ({ signal, cartItemId, productId, quantity }) => {
    return {
      url: `/customers/current/basket/items/${cartItemId}`,
      method: 'PUT',
      data: {
        offer: productId,
        quantity,
      },
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  addToCart: ({ signal, quantity, productId }) => {
    return {
      url: `/customers/current/basket/items`,
      method: 'POST',
      data: {
        quantity,
        offer: productId,
      },
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default cart;
