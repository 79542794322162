import { BonusAccountBalance } from 'domain/model';
import { FC } from 'react';
import UserBalanceExpire from '../../../../components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

type UserFinanceBalanceSlotExpireAdapterProps = UserFinanceBalanceSlotAdapterProps<BonusAccountBalance>;

export const UserFinanceBalanceSlotExpireAdapter: FC<UserFinanceBalanceSlotExpireAdapterProps> = ({
  balance,
  disabled = false,
}) => {
  const info = balance.source.pointsExpirationInfo;

  return (
    info?.dateExpiration &&
    info.sum !== null &&
    info.sum !== 0 && (
      <BalanceSlotExpire>
        <UserBalanceExpire
          value={info.sum}
          disabled={disabled}
          expireDate={info.dateExpiration}
          balance={balance}
        />
      </BalanceSlotExpire>
    )
  );
};
