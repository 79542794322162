import {
  useGetAllBookingServicesQuery,
  useGetAvailableBookingServicesQuery,
  useGetBookingPriceRangeQuery,
} from 'api/bookingOffer';
import { makeSearchParams } from 'features/offer/booking/list/utils';
import { bookingOfferListGuidSelector } from 'presentation/features/offer/booking/list/store/selectors';
import { bookingOffersStartSession } from 'presentation/features/offer/booking/list/store/slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingOffersSearchListLocationState, ELastChangedFilterAttribute } from '../../types';
import { getAllBookingServicesArgs, getAvailableBookingServicesArgs, getBookingPriceRangeArgs } from '../cacheKeyArgs';
import useApplyFiltersToSearchParams from './useApplyFiltersToSearchParams';

const useBookingFilter = (props: BookingOffersSearchListLocationState) => {
  const { guid } = props;
  const searchParams = makeSearchParams(props);
  const { categoryId, minPrice, maxPrice, services, lastChangedFilterAttribute } = searchParams;
  const dispatch = useDispatch();
  const currentGuid = useSelector(bookingOfferListGuidSelector);

  const applyFiltersToSearchParams = useApplyFiltersToSearchParams(props);

  const bookingPriceRangeArgs = getBookingPriceRangeArgs(searchParams);

  // экстремумы цены
  const { data: extremumPriceRangeData, isFetching: isFetchingExtremumPriceRangeData } = useGetBookingPriceRangeQuery(
    bookingPriceRangeArgs,
    {
      skip: !categoryId,
      refetchOnMountOrArgChange: true,
    }
  );

  const availableBookingServicesArgs = getAvailableBookingServicesArgs(searchParams);

  useGetAvailableBookingServicesQuery(availableBookingServicesArgs, {
    skip:
      (lastChangedFilterAttribute === ELastChangedFilterAttribute.Services && !!services?.length) ||
      !availableBookingServicesArgs.categoryId,
  });

  const allBookingServicesArgs = getAllBookingServicesArgs(searchParams);
  useGetAllBookingServicesQuery(allBookingServicesArgs, { skip: !allBookingServicesArgs.categoryId });

  // сессия
  useEffect(() => {
    if (guid && currentGuid !== guid) {
      dispatch(bookingOffersStartSession({ guid }));
    }
  }, [currentGuid, dispatch, guid]);

  // при перезагрузке страницы очищаем lastChangedFilterAttribute
  useEffect(() => {
    applyFiltersToSearchParams({ lastChangedFilterAttribute: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // установка цены в 0 и 0 после обновления Compensation
  useEffect(() => {
    const isLastChangedAttributeCompensation = [ELastChangedFilterAttribute.Compensation].some(
      item => item === lastChangedFilterAttribute
    );

    const pass = isLastChangedAttributeCompensation;

    if (pass && !isFetchingExtremumPriceRangeData) {
      const { minPrice: extremumMinPrice, maxPrice: extremumMaxPrice } = extremumPriceRangeData ?? {};
      const searchParamsPriceRange = {
        minPrice: extremumMinPrice,
        maxPrice: extremumMaxPrice,
      };

      applyFiltersToSearchParams({ ...searchParamsPriceRange });
    }
  }, [
    applyFiltersToSearchParams,
    extremumPriceRangeData,
    isFetchingExtremumPriceRangeData,
    lastChangedFilterAttribute,
  ]);

  // установка цены по экстремумам цены при обновлении сервисов или компенсации
  useEffect(() => {
    const hasSelectedServices = services?.length;
    const isLastChangedAttributeServices = [ELastChangedFilterAttribute.Services].some(
      item => item === lastChangedFilterAttribute
    );
    const pass = isLastChangedAttributeServices && hasSelectedServices;

    if (pass && !isFetchingExtremumPriceRangeData) {
      const { minPrice: extremumMinPrice, maxPrice: extremumMaxPrice } = extremumPriceRangeData ?? {};

      if (extremumMinPrice == null || extremumMaxPrice == null || minPrice == null || maxPrice == null) {
        return;
      }

      const searchParamMinPrice = minPrice > extremumMinPrice ? minPrice : extremumMinPrice;
      const searchParamMaxPrice = maxPrice < extremumMaxPrice ? maxPrice : extremumMaxPrice;

      const searchParamsPriceRange = {
        minPrice: searchParamMinPrice < searchParamMaxPrice ? searchParamMinPrice : extremumMinPrice,
        maxPrice: searchParamMaxPrice > searchParamMinPrice ? searchParamMaxPrice : extremumMaxPrice,
      };

      applyFiltersToSearchParams({ ...searchParamsPriceRange });
    }
  }, [
    applyFiltersToSearchParams,
    extremumPriceRangeData,
    isFetchingExtremumPriceRangeData,
    lastChangedFilterAttribute,
    maxPrice,
    minPrice,
    services?.length,
  ]);

  // установка цены по экстремумам цены при выключении всех сервисов
  useEffect(() => {
    const hasNotSelectedServices = !services?.length;
    const isLastChangedAttributeServices = [ELastChangedFilterAttribute.Services].some(
      item => item === lastChangedFilterAttribute
    );

    const pass = isLastChangedAttributeServices && hasNotSelectedServices;

    if (pass && !isFetchingExtremumPriceRangeData) {
      const { minPrice: extremumMinPrice, maxPrice: extremumMaxPrice } = extremumPriceRangeData ?? {};

      const searchParamsPriceRange = {
        minPrice: extremumMinPrice,
        maxPrice: extremumMaxPrice,
      };
      applyFiltersToSearchParams({ ...searchParamsPriceRange });
    }
  }, [
    applyFiltersToSearchParams,
    extremumPriceRangeData,
    isFetchingExtremumPriceRangeData,
    lastChangedFilterAttribute,
    services?.length,
  ]);

  // установка независимых от цены сервисов при первичной загрузке (при ценах равных их экстремумам)
  useEffect(() => {
    const hasSelectedServices = services?.length;

    const isLastChangedAttributeServices = [null, undefined].some(item => item === lastChangedFilterAttribute);

    const pass = isLastChangedAttributeServices && hasSelectedServices;

    if (pass && !isFetchingExtremumPriceRangeData) {
      const { minPrice: extremumMinPrice, maxPrice: extremumMaxPrice } = extremumPriceRangeData ?? {};

      if (extremumMinPrice == null || extremumMaxPrice == null || minPrice == null || maxPrice == null) {
        return;
      }

      const minPricesEqual = minPrice === extremumMinPrice;
      const maxPricesEqual = maxPrice === extremumMaxPrice;

      if (minPricesEqual && maxPricesEqual) {
        applyFiltersToSearchParams({ lastChangedFilterAttribute: ELastChangedFilterAttribute.System });
      }
    }
  }, [
    applyFiltersToSearchParams,
    extremumPriceRangeData,
    isFetchingExtremumPriceRangeData,
    lastChangedFilterAttribute,
    maxPrice,
    minPrice,
    services?.length,
  ]);
};

export default useBookingFilter;
