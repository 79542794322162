import { CspAccountBalance, ESocialPackageStatus } from 'domain/model';
import { FC } from 'react';
import UserBalanceExpire from '../../../../components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

type UserFinanceBalanceSlotExpireCspAdapterProps = UserFinanceBalanceSlotAdapterProps<CspAccountBalance>;

export const UserFinanceBalanceSlotExpireCspAdapter: FC<UserFinanceBalanceSlotExpireCspAdapterProps> = ({
  balance,
  disabled = false,
}) => {
  const expireDate =
    balance.source.status !== ESocialPackageStatus.Upcoming ? balance.source.endDate : balance.source.startDate;

  return expireDate ? (
    <BalanceSlotExpire>
      <UserBalanceExpire
        value={balance.value ?? 0}
        disabled={disabled}
        expireDate={expireDate}
        balance={balance}
      />
    </BalanceSlotExpire>
  ) : null;
};
