import styled from '@emotion/styled';

type DummyProps = {
  readonly elementSize: 20 | 25 | 33 | 50 | 100;
  readonly isContentLoaded: boolean;
};

export const Wrapper = styled.div<DummyProps>`
  ${p => (!p.isContentLoaded ? `min-height: ${p.elementSize}vh` : '')}
`;
