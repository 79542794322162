import * as Sentry from '@sentry/react';
import { Primitive } from '@sentry/types';
import { ScopeContext } from '@sentry/types/types/scope';
import { CapturedUserAttributes } from 'domain/model';
import { MPAuthenticationClient } from 'features/auth/types';
import { SentryType } from 'system/appConfigurator';

export default class SentryClient {
  private static instance: SentryClient;

  public static getInstance(): SentryClient {
    if (!SentryClient.instance) {
      SentryClient.instance = new SentryClient();
    }

    return SentryClient.instance;
  }

  private constructor() {}

  options: Nullable<SentryType> = null;

  private authService: Nullable<MPAuthenticationClient> = null;

  public init(options: Nullable<SentryType>) {
    this.options = options;

    if (!options) {
      console.info(`Sentry client not started, options is empty`);
      return;
    }

    if (options?.enabled && options?.project) {
      const release = options?.project + (options?.release ? `@${options?.release}` : '');
      console.log('options.xhrTracking', options.xhrTracking);
      Sentry.init({
        ...options,
        release,
        integrations: [
          Sentry.browserTracingIntegration({
            instrumentPageLoad: false,
            instrumentNavigation: false,
          }),
          Sentry.breadcrumbsIntegration({
            dom: false,
            history: false,
            console: false,
            fetch: false,
            xhr: true,
            sentry: false,
          }),
        ],
        beforeSend: event => {
          const user = this.getUserForCapture();
          if (user) {
            event.user = user;
          }
          return event;
        },
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr') {
            //исключаем запроса для облачных метрик
            if (breadcrumb.data?.url?.indexOf('.yandex.') !== -1) {
              return null;
            }
            if (
              breadcrumb.data?.status_code &&
              //исключаем 200-е ответы, чтобы не были огромные логи
              breadcrumb.data?.status_code >= 200 &&
              breadcrumb.data?.status_code <= 299
            ) {
              return null;
            }
            //добавляем message из ответа и data из запроса
            const newBreadcrumb: Sentry.Breadcrumb = {
              ...breadcrumb,
              data: {
                ...breadcrumb.data,
                request: hint?.input?.toString()?.slice(0, 1024),
                response: hint?.xhr?.responseText?.toString()?.slice(0, 300),
              },
            };
            return newBreadcrumb;
          }
          return breadcrumb;
        },
        tracesSampleRate: 1.0,
      });

      if (options?.tags) {
        Sentry.setTags(
          options.tags as {
            [key: string]: Primitive;
          }
        );
      }

      console.info(`Sentry client init with release '${release}'`);
    } else {
      console.info(`Sentry client is not active. Check the env variables: sentryEnabled, sentryProject`);
    }
  }

  public isActive(): boolean {
    return this.options?.enabled ?? false;
  }

  public setAuthService(authService: MPAuthenticationClient) {
    this.authService = authService;
  }

  public getCaptureUserAttributes(): Nullable<CapturedUserAttributes> {
    return this.options?.captureUserAttributes ?? null;
  }

  public getUserForCapture(): Nullable<Sentry.User> {
    if (this.options?.enabled) {
      const captureUserAttributes = this.getCaptureUserAttributes();
      const user = this.authService?.tokenParsed;
      if (captureUserAttributes?.length) {
        if (user) {
          const context: Sentry.User = captureUserAttributes.reduce<any>((next, prev) => {
            const key = prev as keyof typeof user;
            if (key === 'id') {
              next[prev] = user?.sid;
            }
            if (key === 'email') {
              next[prev] = user?.email;
            }
            return next;
          }, {});
          return context;
        } else {
          return { id: 'anonymous' };
        }
      }
    }
    return null;
  }

  public captureException(exception: string | Error, context?: Partial<ScopeContext>) {
    if (this.options?.enabled) {
      if (typeof exception === 'string') {
        Sentry.captureException(new Error(exception), context);
      } else {
        Sentry.captureException(exception, context);
      }
    } else {
      console.warn('Sentry client is not active. Check env params');
    }
  }
}
