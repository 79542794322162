import { CorpOfferShort } from 'domain/model';
import { UnitsIcon } from 'media/icons';
import { toCurrency } from 'presentationUtils/currency';
import { OriginalPrice, Price, PriceWrapper } from './controls';

type CorpOfferListItemPriceProps = Pick<CorpOfferShort, 'price' | 'originalPrice'>;

const CorpOfferListItemPrice = (props: CorpOfferListItemPriceProps) => {
  const { price, originalPrice } = props;

  const isOriginalPriceVisible = originalPrice !== null && price !== null && originalPrice > price;

  return (
    <PriceWrapper>
      <UnitsIcon />
      <Price
        variant='h2'
        component='div'
      >
        {toCurrency(price)}
      </Price>
      {isOriginalPriceVisible && (
        <OriginalPrice
          variant='body2'
          color='textSecondary'
        >
          {toCurrency(originalPrice)}
        </OriginalPrice>
      )}
    </PriceWrapper>
  );
};

export default CorpOfferListItemPrice;
