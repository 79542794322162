import { Alert, Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { FC, ReactNode } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';

type UserPersonalDataTransferLayoutProps = {
  readonly text?: ReactNode;
  readonly actions: ReactNode;
  readonly partnerInfo?: ReactNode;
  readonly onClose: () => void;
};

const UserPersonalDataTransferLayout: FC<UserPersonalDataTransferLayoutProps> = props => {
  const { actions, text, partnerInfo, onClose } = props;

  return (
    <MPConfirmDialog
      open={true}
      title={`Согласие на\u000Aпередачу персональных данных`}
      maxWidth='xs'
      buttons={<ConfirmGroupButtons>{actions}</ConfirmGroupButtons>}
      onClose={onClose}
    >
      {text && (
        <>
          <Typography variant='body2'>{text}</Typography>
          <Splitter size={2} />
        </>
      )}

      {partnerInfo && (
        <>
          <Alert
            variant='filled'
            severity='info'
            icon={false}
          >
            {partnerInfo}
          </Alert>
          <Splitter size={2} />
        </>
      )}

      <Typography variant='body2'>
        Согласие дается до достижения цели и может быть отозвано мной в любой момент.
      </Typography>
    </MPConfirmDialog>
  );
};

export default UserPersonalDataTransferLayout;
