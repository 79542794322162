import ContentLoader from 'presentation/components/common/loader';
import React, { useRef } from 'react';
import OfferClaimLayout from '../../../../layouts/offerClaimLayout';
import { AspOfferClaimActivationAdapter } from './adapters/activation';
import ActivityEndDate from './adapters/activityEndDate';
import HelpAdapter from './adapters/help';
import { AspOfferClaimStateMessagesAdapter } from './adapters/messages/stateMessages';
import { AspOfferClaimCommonMessagesAdapter } from './adapters/messages/commonMessages';
import { AspOfferPromotionTypeAdapter } from './adapters/promotionType';
import OfferCodeAdapter from './adapters/offerCode';
import ClaimContentLayout from './layout/content';
import ClaimInfoLayout from './layout/info';
import {
  AspOfferClaimActivationSlot,
  AspOfferClaimInfoMessagesSlot,
  AspOfferClaimPriceSlot,
  AspOfferClaimPromotionTypeSlot,
  AspOfferClaimWarnMessagesSlot,
} from './slots';
import useAspOfferClaimContext from './provider/useAspOfferClaimContext';
import { AspOfferClaimPriceAdapter } from './adapters/price';
import NdflAdapter from './adapters/ndfl';
import AspOfferClaimLinkToActivationSlot from './slots/linkToActivations';
import AspOfferClaimLinkToActivationsAdapter from './adapters/linkToActivations';

const AspOfferClaim = React.forwardRef(() => {
  const { aspOffer, isFetching } = useAspOfferClaimContext();
  const ref = useRef<any>();

  return aspOffer ? (
    <OfferClaimLayout
      ref={ref}
      content={
        <ClaimContentLayout>
          <AspOfferClaimPromotionTypeSlot>
            <AspOfferPromotionTypeAdapter />
          </AspOfferClaimPromotionTypeSlot>
          <AspOfferClaimPriceSlot>
            <AspOfferClaimPriceAdapter />
          </AspOfferClaimPriceSlot>
          <AspOfferClaimActivationSlot>
            {isFetching ? <ContentLoader /> : <AspOfferClaimActivationAdapter />}
          </AspOfferClaimActivationSlot>
          <AspOfferClaimWarnMessagesSlot>
            <AspOfferClaimCommonMessagesAdapter />
          </AspOfferClaimWarnMessagesSlot>
          <AspOfferClaimInfoMessagesSlot>
            <AspOfferClaimStateMessagesAdapter />
          </AspOfferClaimInfoMessagesSlot>
          <AspOfferClaimLinkToActivationSlot>
            <AspOfferClaimLinkToActivationsAdapter />
          </AspOfferClaimLinkToActivationSlot>
        </ClaimContentLayout>
        // <SlotsGridArea
        //   area={ESlotNameArea.subscriptions}
        //   key={ESlotNameArea.subscriptions}
        // >
        //   <AspOfferSidebarSubscriptionAdapter />
        // </SlotsGridArea>,
      }
      skirt={
        <ClaimInfoLayout
          help={
            <>
              <NdflAdapter key='ndfl' />
              <HelpAdapter key='help' />
            </>
          }
        >
          <OfferCodeAdapter key='code' />
          <ActivityEndDate key='end' />
        </ClaimInfoLayout>
      }
    ></OfferClaimLayout>
  ) : null;
});

export default AspOfferClaim;
