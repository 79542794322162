import { EOfferActivateError, EOfferStatus, ETradeOfferPromotionType } from 'domain/model';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { TradeOfferClaimCodesEndsMessage } from './codesEnds';
import { TradeOfferClaimErrorMessage } from './error';
import { TradeOfferClaimReactivationMessage } from './reactivation';

export const TradeOfferClaimWarnMessagesAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, activation, isTimerActive, stopTimer } = useTradeOfferClaimContext();

  const isCodesEnded: boolean = (tradeOffer?.notUsedOfferCount ?? 0) === 0;

  const isTradeOfferPersonalPromotionType =
    tradeOffer?.promotionType !== ETradeOfferPromotionType.Widget &&
    tradeOffer?.promotionType !== ETradeOfferPromotionType.PublicPromocode &&
    tradeOffer?.promotionType !== ETradeOfferPromotionType.ReferralLink &&
    tradeOffer?.promotionType !== ETradeOfferPromotionType.Asp &&
    tradeOffer?.promotionType !== ETradeOfferPromotionType.Digift;

  return useMemo(() => {
    if (!tradeOffer) {
      return null;
    } else {
      return (
        <>
          {activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && (
            <TradeOfferClaimErrorMessage error={activation.activationError} />
          )}

          {isCodesEnded && isActivationReceivedNow && isTradeOfferPersonalPromotionType && tradeOffer.promotionType && (
            <TradeOfferClaimCodesEndsMessage promotionType={tradeOffer.promotionType} />
          )}

          {tradeOffer.status === EOfferStatus.Active &&
            activation.lastActivation?.promotion &&
            !isCodesEnded &&
            isTimerActive &&
            activation.isReactivationSupported &&
            activation.lastActivation?.appointmentDate && (
              <TradeOfferClaimReactivationMessage
                date={activation.lastActivation?.appointmentDate}
                stop={stopTimer}
              />
            )}
        </>
      );
    }
  }, [
    tradeOffer,
    activation.activationError,
    activation.lastActivation?.promotion,
    activation.lastActivation?.appointmentDate,
    activation.isReactivationSupported,
    isCodesEnded,
    isActivationReceivedNow,
    isTradeOfferPersonalPromotionType,
    isTimerActive,
    stopTimer,
  ]);
};
