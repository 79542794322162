/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainAddressObjectLink,
  GenMainMutableUserData,
  GenMainUser,
  GenMainUserAgreement,
  GenMainUserAgreementRequest,
  GenMainUserId,
  GenMainUserRevokeAgreementRequest,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const UsersApi = {
  /**
   * @description Who am I по  токену
   * * @tags Users
   * @name GetLoggedUser
   * @summary Получение профиля залогиненного пользователя
   * @request GET:/users/current
   * @secure
   */
  getLoggedUser: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainUser, void>({
      baseURL,
      path: `/users/current`,
      method: 'GET',
      signal,
    }),

  /**
   * @description Who am I по  токену или регистрация
   * * @tags Users
   * @name OnLoginUser
   * @summary Получение профиля или регистрация аутентифицированного в kc пользователя
   * @request GET:/users/current/userinfo
   * @secure
   */
  onLoginUser: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainUser, void>({
      baseURL,
      path: `/users/current/userinfo`,
      method: 'GET',
      signal,
    }),

  /**
   * @description Привязка внешнего пользователя к корп учетке по токену
   * * @tags Users
   * @name LinkCorpUser
   * @summary Привязка внешнего пользователя к корп учетке
   * @request PATCH:/users/current/corpLink
   * @secure
   */
  linkCorpUser: (baseURL: string, signal?: AbortSignal) =>
    createRequest<void, void>({
      baseURL,
      path: `/users/current/corpLink`,
      method: 'PATCH',
      signal,
    }),

  /**
   * @description Список всех документов с которыми пользователь должен ознакомиться, актуальный для текущего пользователя Если agreementDate не заполнено, значит данный пользователь не согласился с последней версией данного документа. Этот метод доступен даже если пользователь не согласился с обязательными документами.
   * * @tags Users
   * @name GetCurrentUserAgreements
   * @summary Список всех документов для согласия текущего пользователя
   * @request GET:/users/current/agreements
   * @secure
   */
  getCurrentUserAgreements: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainUserAgreement[], void>({
      baseURL,
      path: `/users/current/agreements`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * @description Подписать неквалифицированной цифровой подписью юр.доков Этот метод доступен даже если пользователь не согласился с обязательными документами.
   * * @tags Users
   * @name UserAgree
   * @summary Согласиться с документами
   * @request POST:/users/current/agreements
   * @secure
   */
  userAgree: (baseURL: string, data: GenMainUserAgreementRequest, signal?: AbortSignal) =>
    createRequest<void, GenMainUserAgreementRequest>({
      baseURL,
      path: `/users/current/agreements`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * @description Отозвать свои согласия с документами Этот метод доступен даже если пользователь не согласился с обязательными документами.
   * * @tags Users
   * @name UserRevokeAgree
   * @summary Отозвать свои согласия с документами
   * @request DELETE:/users/current/agreements
   * @secure
   */
  userRevokeAgree: (baseURL: string, data: GenMainUserRevokeAgreementRequest, signal?: AbortSignal) =>
    createRequest<void, GenMainUserRevokeAgreementRequest>({
      baseURL,
      path: `/users/current/agreements`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Users
   * @name UpdateUser
   * @summary Редактирование данных пользователя
   * @request PUT:/users/{userId}
   * @secure
   */
  updateUser: (baseURL: string, userId: GenMainUserId, data: GenMainMutableUserData, signal?: AbortSignal) =>
    createRequest<GenMainUser, GenMainMutableUserData>({
      baseURL,
      path: `/users/${userId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * @description Пользователь сам меняет свой email или это делает админ МП
   * * @tags Users
   * @name ChangeEmail
   * @summary Изменениe email пользоветеля
   * @request PATCH:/users/{userId}/email
   * @deprecated
   * @secure
   */
  changeEmail: (
    baseURL: string,
    {
      userId,
      ...query
    }: {
      /**
       * URL, на который происходит редирект после подтверждения почты; при отстуствии - не происходит
       * Актуален только когда админ меняет чужую почту
       */
      redirect_uri?: string | null;
      /** id пользователя */
      userId: GenMainUserId;
    },
    data: {
      /**
       * новый email пользователя
       * @format email
       */
      email: string;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      void,
      {
        /**
         * новый email пользователя
         * @format email
         */
        email: string;
      }
    >({
      baseURL,
      path: `/users/${userId}/email`,
      method: 'PATCH',
      query: query,
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Users
   * @name ChangeUserLocation
   * @summary Зафиксировать местоположение пользователя
   * @request PATCH:/users/{userId}/location
   * @secure
   */
  changeUserLocation: (
    baseURL: string,
    userId: GenMainUserId,
    data: {
      /** Ссылка на объект адреса */
      location: GenMainAddressObjectLink;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      void,
      {
        /** Ссылка на объект адреса */
        location: GenMainAddressObjectLink;
      }
    >({
      baseURL,
      path: `/users/${userId}/location`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * @description Пользователь сам сбрасывает свой пароль или это делает админ МП
   * * @tags Users
   * @name UpdatePassword
   * @summary Сброс пароля пользователя
   * @request PATCH:/users/{userId}/password
   * @secure
   */
  updatePassword: (baseURL: string, userId: GenMainUserId, signal?: AbortSignal) =>
    createRequest<void, void>({
      baseURL,
      path: `/users/${userId}/password`,
      method: 'PATCH',
      signal,
    }),
};
