import { useRefuseSocialPackageMutation } from 'api/user';
import ContentLoader from 'components/common/loader';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { SocialPackageRefuseEventProps } from '../types';

export const SocialPackageRefuseActionsAdapter: FC<SocialPackageRefuseEventProps> = props => {
  const {
    event: {
      payload: {
        socialPackage: { id },
      },
    },
    onNext,
  } = props;

  const [onRefuse, { isLoading }] = useRefuseSocialPackageMutation();

  const onRefuseInternal = async () => {
    await onRefuse({ socialPackageId: id });
    onNext();
  };

  return (
    <ConfirmGroupButtons>
      <MPButton
        color='brand'
        fullWidth
        size={'large'}
        onClick={onNext}
        disabled={isLoading}
      >
        Ещё подумаю
      </MPButton>
      <MPButton
        color='brand'
        variant='text'
        size={'large'}
        fullWidth
        disabled={isLoading}
        onClick={onRefuseInternal}
      >
        Отказаться от льготы
        {isLoading && <ContentLoader />}
      </MPButton>
    </ConfirmGroupButtons>
  );
};
