import {
  BenefitTransactionsApi,
  BonusesApi,
  CmsContainersApi,
  CommonsApi,
  CustomersApi,
  NotificationsApi,
  OtpApi,
  UsersApi,
} from '@privilege-frontend/api';
import { aspOfferApi } from 'data/api/aspOffer';
import {
  AddressLink,
  Banner,
  BonusBalanceResponse,
  BookingOfferShort,
  CartItem,
  CorpOfferShort,
  DeleteProfileResponse,
  EBalanceType,
  EBannerPlace,
  EClientOfferType,
  ECreateOrderDiscriminator,
  EListResultTypes,
  EOfferActivationSortType,
  EOfferActivationStatus,
  EOfferShortDiscriminator,
  EOfferType,
  EOrderCommandDiscriminator,
  EOrderSortType,
  EOrderStatus,
  ESocialPackageEmployeeAgreement,
  ESocialPackagesResultType,
  EUserRole,
  LandingWindow,
  NotificationOption,
  NotificationSetting,
  OfferActivation,
  OfferFavorite,
  OfferUnavailabilityReason,
  Pageable,
  ProductOffer,
  SocialPackage,
  SocialPackageApplicationRequest,
  SocialPackageApplicationShort,
  TradeOfferShort,
  UpdateEmailResponse,
  User,
  UserBTTransaction,
  UserData,
  UserFeedBackForm,
} from 'domain/model';
import { OrderFull, OrderShort } from 'domain/model/order';
import { UpdateProps } from 'openApi/notification';
import {
  getBaseEndpoint,
  getBenefitTransactionEndpoint,
  getBonusesEndpoint,
  getPageableFromResponseHeaders,
} from 'openApi/utils';
import { pageableCacheClearByTag } from 'presentation/features/general/pageable/cacheStorage/redux/store/slice';
import { RootState } from '../store/store';
import { corpOfferApi } from './corpOffer';
import {
  api,
  EAspOfferServicesTag,
  EBookingOfferServicesTag,
  ECacheServicesTag,
  ECartServicesTag,
  ECorpOfferServicesTag,
  EOrderServicesTag,
  EProductOfferServicesTag,
  ETradeOfferServicesTag,
  EUserServicesTag,
  OfferFavoriteActionRequest,
} from './index';
import {
  createOfferFavoritePatches,
  createUserNewActivationPatches,
  createUserNewActivationTagsToInvalidate,
} from './patches';
import { productApi } from './productOffer';
import { tradeOfferApi } from './tradeOffer';
import { getQueryErrorByAxiosError } from './utils';

/**
 * Модифицированный ответ запроса избранного
 * ! При добавлении новых типов отредактировать также favoritesCountMap
 */
export type UserFavoritesResponse = {
  [EClientOfferType.TradeOffer]: UUID[];
  tradeOffersCount: number;
  [EClientOfferType.AspOffer]: UUID[];
  aspOffersCount: number;
  [EClientOfferType.CorpOffer]: UUID[];
  corpOffersCount: number;
  [EClientOfferType.ProductOffer]: UUID[];
  productOffersCount: number;
  [EClientOfferType.BookingOffer]: UUID[];
  bookingOffersCount: number;
  totalCount: number;
};

// маппинг для патчей при операциях с избранным с карточек
export const favoritesCountMap = {
  [EClientOfferType.TradeOffer]: 'tradeOffersCount',
  [EClientOfferType.AspOffer]: 'aspOffersCount',
  [EClientOfferType.CorpOffer]: 'corpOffersCount',
  [EClientOfferType.ProductOffer]: 'productOffersCount',
  [EClientOfferType.BookingOffer]: 'bookingOffersCount',
} as Record<EClientOfferType, keyof UserFavoritesResponse>;

type UserFavoritesOffersRequest = {
  readonly guid: UUID;
};

export type UserBannersRequest = {
  readonly guid: UUID;
  readonly place: EBannerPlace;
  readonly cityId: Nullable<UUID>;
};

export type CancelOrderRequest = {
  readonly id: UUID;
  readonly cancellationType: { id: UUID };
  readonly comment: string;
};

export type UserOrdersRequest = {
  readonly guid: UUID;
  readonly page: number;
  readonly pageSize: number;
  readonly statuses: EOrderStatus[];
};

export type UserActivationRequest = {
  readonly id: UUID;
};

export type UserActivationsRequest = {
  readonly guid: UUID;
  readonly page: number;
  readonly pageSize: number;
};

type GetUserOfferActivationsRequest = {
  readonly id: UUID;
};

export type ActivateUserOfferRequest = {
  readonly id: UUID;
};

export type UserOfferAvailabilityRequest = {
  readonly offerId: UUID;
  readonly reasons?: Nullable<OfferUnavailabilityReason[]>;
};

export type UserOfferSubscriptionRequest = {
  readonly offerId: UUID;
  readonly offerType: EOfferType | EClientOfferType;
  readonly subscribe: boolean;
};

export type UserBTTransactionsRequest = {
  readonly page: number;
  readonly pageSize: number;
  readonly sort?: Nullable<string[]>;
};

export type UserSocialPackageAgreeRequest = {
  readonly socialPackageId: UUID;
};

export type NotificationsRequest = {
  readonly roles: EUserRole[];
};

const activationStatuses: EOfferActivationStatus[] = [
  EOfferActivationStatus.Pending,
  EOfferActivationStatus.Rejected,
  EOfferActivationStatus.Approved,
  EOfferActivationStatus.Given,
];

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<User, {}>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await UsersApi.onLoginUser(getBaseEndpoint(), signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Current],
    }),
    deleteCurrentUserProfile: builder.mutation<DeleteProfileResponse, void>({
      queryFn: async () => {
        try {
          const { data } = await OtpApi.deleteCurrentUserProfile(getBaseEndpoint());
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getUserBalance: builder.query<BonusBalanceResponse, void>({
      queryFn: async (_, { signal }) => {
        try {
          const response = await BonusesApi.getCurrentCustomerBonusBalance(getBonusesEndpoint(), signal);
          const data: BonusBalanceResponse = {
            ...response.data,
            type: EBalanceType.Bonuses /*todo asp пока нет АСП - захардкожено*/,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Current],
    }),
    getUserBanners: builder.query<Banner[], UserBannersRequest>({
      queryFn: async ({ place }, { signal }) => {
        try {
          const { data } = await CustomersApi.listBannersForCurrentUser(
            getBaseEndpoint(),
            {
              bannerPlaceCodes: [place],
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getUserFavoritesOffers: builder.query<OfferFavorite[], UserFavoritesOffersRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
            },
            signal
          );
          return {
            data: data as OfferFavorite[],
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Favorites],
    }),
    getUserFavorites: builder.query<UserFavoritesResponse, void>({
      keepUnusedDataFor: 3600,
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
            },
            signal
          );

          const transformedData = {
            [EClientOfferType.TradeOffer]: [] as UUID[],
            [EClientOfferType.AspOffer]: [] as UUID[],
            [EClientOfferType.CorpOffer]: [] as UUID[],
            [EClientOfferType.ProductOffer]: [] as UUID[],
            [EClientOfferType.BookingOffer]: [] as UUID[],
          };

          (response.data as OfferFavorite[]).forEach(({ discriminator, id }) => {
            // Всё избранное без корректного type будет проигнорировано
            switch (discriminator) {
              case EOfferShortDiscriminator.Trade:
                transformedData[EOfferType.TradeOffer].push(id);
                break;
              case EOfferShortDiscriminator.Corp:
                transformedData[EOfferType.CorpOffer].push(id);
                break;
              case EOfferShortDiscriminator.Booking:
                transformedData[EOfferType.BookingOffer].push(id);
                break;
              case EOfferShortDiscriminator.Product:
                break;
            }
          });

          const totalCount = Object.values(transformedData).reduce((count, value) => count + value.length, 0);

          const data: UserFavoritesResponse = {
            ...transformedData,
            tradeOffersCount:
              transformedData[EClientOfferType.TradeOffer].length + transformedData[EClientOfferType.AspOffer].length,
            aspOffersCount: transformedData[EClientOfferType.AspOffer].length,
            corpOffersCount: transformedData[EClientOfferType.CorpOffer].length,
            productOffersCount: transformedData[EClientOfferType.ProductOffer].length,
            bookingOffersCount: transformedData[EClientOfferType.BookingOffer].length,
            totalCount,
          };

          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.FavoritesCount],
    }),
    getUserFavoritesTradeOffers: builder.query<Pageable<TradeOfferShort>, UserFavoritesOffersRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              offerType: [EOfferType.TradeOffer],
              resultType: EListResultTypes.List,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<TradeOfferShort> = {
            data: response.data.filter(item => item.discriminator === EOfferShortDiscriminator.Trade),
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [ETradeOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesCorpOffers: builder.query<Pageable<CorpOfferShort>, UserFavoritesOffersRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              offerType: [EOfferType.CorpOffer],
              resultType: EListResultTypes.List,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<CorpOfferShort> = {
            data: response.data.filter(item => item.discriminator === EOfferShortDiscriminator.Corp),
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [ECorpOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesProductOffers: builder.query<Pageable<ProductOffer>, UserFavoritesOffersRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              offerType: [EOfferType.ProductOffer],
              resultType: EListResultTypes.List,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<ProductOffer> = {
            data: response.data.filter(
              item => item.discriminator === EOfferShortDiscriminator.Product
            ) as unknown as ProductOffer[],
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EProductOfferServicesTag.FavoriteList],
    }),
    getUserFavoritesBookingsOffers: builder.query<Pageable<BookingOfferShort>, UserFavoritesOffersRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCurrentCustomerFavoriteOffersTyped(
            getBaseEndpoint(),
            {
              offerType: [EOfferType.BookingOffer],
              resultType: EListResultTypes.List,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<BookingOfferShort> = {
            data: response.data.filter(item => item.discriminator === EOfferShortDiscriminator.Booking),
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EBookingOfferServicesTag.FavoriteList],
    }),
    getUserOrder: builder.query<OrderFull, UUID>({
      queryFn: async (id, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerOrderById(getBaseEndpoint(), id, signal);
          return {
            data: data as OrderFull,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getUserOrders: builder.query<Pageable<OrderShort>, UserOrdersRequest>({
      queryFn: async ({ statuses, page, pageSize }, { signal }) => {
        try {
          const response = await CustomersApi.listCustomerOrders(
            getBaseEndpoint(),
            {
              status: statuses,
              page: page - 1,
              size: pageSize,
              sort: [EOrderSortType.ByCreatedAt],
            },
            signal
          );

          const pageable = getPageableFromResponseHeaders(response);

          const data: Pageable<OrderShort> = {
            data: response.data as OrderShort[],
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };

          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EOrderServicesTag.Orders],
    }),
    getUserOrdersCount: builder.query<number, void>({
      keepUnusedDataFor: 3600,
      queryFn: async (_, { signal }) => {
        try {
          const response = await CustomersApi.listCustomerOrders(
            getBaseEndpoint(),
            {
              page: 0,
              size: 1,
            },
            signal
          );

          const pageable = getPageableFromResponseHeaders(response);

          return {
            data: pageable.totalCount,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.OrdersCount],
    }),
    createUserOrders: builder.mutation<OrderFull[], CartItem[]>({
      queryFn: async (items, { signal }) => {
        try {
          const { data } = await CustomersApi.createCustomerOrders(
            getBaseEndpoint(),
            {
              discriminator: ECreateOrderDiscriminator.Product,
              items,
            },
            signal
          );
          return {
            data: data as OrderFull[],
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [ECartServicesTag.Cart, EOrderServicesTag.Orders, EUserServicesTag.OrdersCount],
    }),
    cancelOrder: builder.mutation<OrderFull, CancelOrderRequest>({
      queryFn: async ({ id, cancellationType, comment }, { signal }) => {
        try {
          const { data } = await CustomersApi.postCustomerCommandForOrder(
            getBaseEndpoint(),
            id,
            {
              discriminator: EOrderCommandDiscriminator.Cancel,
              cancellationType,
              comment,
            },
            signal
          );
          return {
            data: data as OrderFull,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: (result, error, args) => [
        EOrderServicesTag.Orders,
        EUserServicesTag.OrdersCount,
        {
          type: ECacheServicesTag.Common,
          id: args.id,
        },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        //очищаем кэш в redux хранилище для pageable
        dispatch(pageableCacheClearByTag({ tag: EOrderServicesTag.Orders }));
      },
    }),
    getUserNewActivation: builder.query<OfferActivation, UserActivationRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getPersonalPromotionActivationById(getBaseEndpoint(), id, signal);
          return {
            data: data as OfferActivation,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted(args, { getState, dispatch, queryFulfilled }) {
        const { data: newActivation } = await queryFulfilled;
        //инвалидируем нужные тэги
        const tagsToInvalidate = createUserNewActivationTagsToInvalidate({ newActivation });
        await dispatch(userApi.util.invalidateTags(tagsToInvalidate));

        //патчим данные в кэшах
        await createUserNewActivationPatches({
          newActivation,
          state: getState() as RootState,
          dispatch,
        });
      },
    }),
    getUserActivations: builder.query<Pageable<OfferActivation>, UserActivationsRequest>({
      queryFn: async ({ page, pageSize }, { signal }) => {
        try {
          const response = await CustomersApi.getPersonalPromotionActivationsTyped(
            getBaseEndpoint(),
            {
              page: page - 1,
              size: pageSize,
              sort: [EOfferActivationSortType.ByCreatedAt],
            },
            signal
          );

          const pageable = getPageableFromResponseHeaders(response);

          const data: Pageable<OfferActivation> = {
            data: response.data as OfferActivation[],
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };

          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Activations],
    }),
    getUserActivationsCount: builder.query<number, {}>({
      keepUnusedDataFor: 3600,
      queryFn: async (_, { signal }) => {
        try {
          const {
            data: [{ count }],
          } = await CustomersApi.getPersonalPromotionActivationsTyped(
            getBaseEndpoint(),
            {
              page: 0,
              size: 1,
              resultType: EListResultTypes.Count,
            },
            signal
          );
          return {
            data: count,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Activations, EUserServicesTag.ActivationsCount],
    }),
    userFeedback: builder.mutation<number, UserFeedBackForm>({
      queryFn: async data => {
        try {
          const { status } = await CommonsApi.userFeedback(getBaseEndpoint(), data);
          return {
            data: status,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    addUserOfferToFavorites: builder.mutation<number, OfferFavoriteActionRequest>({
      queryFn: async ({ id }) => {
        try {
          const { status: data } = await CustomersApi.likeOffer(getBaseEndpoint(), {
            offerId: id,
          });
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ id, offerType, ...payload }, { getState, dispatch, queryFulfilled }) {
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: true,
          state: getState() as RootState,
          dispatch,
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      },
    }),
    removeUserOfferFromFavorites: builder.mutation<number, OfferFavoriteActionRequest>({
      queryFn: async ({ id }) => {
        try {
          const { status: data } = await CustomersApi.dislikeOffer(getBaseEndpoint(), id);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ id, offerType, ...payload }, { getState, dispatch, queryFulfilled }) {
        //патчим данные в кэшах
        const patches = await createOfferFavoritePatches({
          id,
          type: offerType,
          ...payload,
          favorite: false,
          state: getState() as RootState,
          dispatch,
        });
        try {
          await queryFulfilled;
        } catch {
          patches.forEach(patch => patch.undo());
        }
      },
    }),
    updateCurrentUser: builder.mutation<number, { id: UUID; data: UserData }>({
      queryFn: async ({ id, data }) => {
        try {
          const { status } = await UsersApi.updateUser(getBaseEndpoint(), id, data);
          return {
            data: status,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.Current],
    }),
    updateCurrentUserEmail: builder.mutation<UpdateEmailResponse, { email: string }>({
      queryFn: async ({ email }) => {
        try {
          const { data } = await OtpApi.userChangeOrConfirmEmail(getBaseEndpoint(), { email });
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    confirmCode: builder.mutation<number, { otpId: UUID; code: string }>({
      queryFn: async ({ otpId, code }) => {
        try {
          const { status: data } = await OtpApi.confirmOtp(getBaseEndpoint(), otpId, { code });
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    bindCurrentUserCorpRole: builder.mutation<number, void>({
      queryFn: async () => {
        try {
          const { status: data } = await UsersApi.linkCorpUser(getBaseEndpoint());
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    changeUserLocation: builder.mutation<number, { userId: UUID; location: AddressLink }>({
      queryFn: async ({ userId, location }) => {
        try {
          const { status: data } = await UsersApi.changeUserLocation(getBaseEndpoint(), userId, { location });
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [
        EUserServicesTag.Current,
        EUserServicesTag.Location,
        ETradeOfferServicesTag.List,
        ETradeOfferServicesTag.ByPartnerList,
        ETradeOfferServicesTag.Details,
        EAspOfferServicesTag.List,
        EAspOfferServicesTag.ByPartnerList,
        EAspOfferServicesTag.Details,
        ECorpOfferServicesTag.List,
        ECorpOfferServicesTag.ByPartnerList,
        ECorpOfferServicesTag.Details,
        EProductOfferServicesTag.List,
        EProductOfferServicesTag.ByPartnerList,
        EProductOfferServicesTag.Details,
        EBookingOfferServicesTag.List,
        EBookingOfferServicesTag.Details,
      ],
    }),
    resetCurrentUserPassword: builder.mutation<number, { id: UUID }>({
      queryFn: async ({ id }) => {
        try {
          const { status: data } = await UsersApi.updatePassword(getBaseEndpoint(), id);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.Current],
    }),
    getUserOfferActivations: builder.query<OfferActivation[], GetUserOfferActivationsRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getPersonalPromotionActivationsTyped(
            getBaseEndpoint(),
            {
              page: 0,
              size: 100000,
              sort: [EOfferActivationSortType.ByCreatedAt],
              offerIds: [id],
              status: activationStatuses,
              resultType: EListResultTypes.List,
            },
            signal
          );
          return {
            data: data as OfferActivation[],
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Activations],
    }),
    activateUserOffer: builder.mutation<OfferActivation, ActivateUserOfferRequest>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await CustomersApi.activatePersonalPromotion(getBaseEndpoint(), { offerId: id });
          return {
            data: data as OfferActivation,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.Activations],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          //очищаем кэш в redux хранилище для pageable
          dispatch(pageableCacheClearByTag({ tag: EUserServicesTag.Activations }));
        } catch (e: any) {
          console.error('Activate offer error', e);
        }
      },
    }),
    getUserOfferAvailability: builder.query<OfferUnavailabilityReason[], UserOfferAvailabilityRequest>({
      queryFn: async ({ offerId, reasons }, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerOfferActivationAvailability(
            getBaseEndpoint(),
            {
              offerId,
              reasons,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Current, EUserServicesTag.Activations],
    }),
    subscribeToUserOffer: builder.mutation<number, UserOfferSubscriptionRequest>({
      queryFn: async ({ offerId, subscribe }) => {
        try {
          const { status: data } = subscribe
            ? await CustomersApi.subscribeToOffer(getBaseEndpoint(), { offerId })
            : await CustomersApi.unsubscribeFromOffer(getBaseEndpoint(), offerId);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ offerId: id, offerType, subscribe }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        switch (offerType) {
          case EClientOfferType.CorpOffer:
          case EOfferType.CorpOffer: {
            dispatch(
              corpOfferApi.util.updateQueryData('getCorpOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EClientOfferType.TradeOffer:
          case EOfferType.TradeOffer: {
            dispatch(
              tradeOfferApi.util.updateQueryData('getTradeOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EClientOfferType.AspOffer: {
            dispatch(
              aspOfferApi.util.updateQueryData('getAspOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EClientOfferType.ProductOffer:
          case EOfferType.ProductOffer: {
            dispatch(
              productApi.util.updateQueryData('getProductOfferDetails', { id }, draft => {
                Object.assign(draft, { ...draft, subscribe });
              })
            );
            break;
          }
          case EClientOfferType.BookingOffer:
          case EOfferType.BookingOffer: {
            // TODO booking
            break;
          }
        }
      },
    }),
    getLandingPageTest: builder.query<LandingWindow, void>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CmsContainersApi.listCustomerContainers(
            getBaseEndpoint(),
            {
              page: 0,
              size: 100000,
            },
            signal
          );
          return {
            data: data as LandingWindow,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAllNotifications: builder.query<NotificationOption[], NotificationsRequest>({
      queryFn: async ({ roles }, { signal }) => {
        try {
          const { data } = await NotificationsApi.getUserNotificationOptions(
            getBaseEndpoint(),
            {
              role: roles,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    updateNotification: builder.mutation<NotificationSetting, UpdateProps>({
      queryFn: async ({ id, option, enabled }) => {
        try {
          const { data } = await NotificationsApi.updateUserNotificationSettings(getBaseEndpoint(), id, {
            optionId: option.id,
            enabled,
          });
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ option, enabled }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getCurrentUser', {}, draft => {
            const notification = draft.notificationSettings?.find(item => item.option?.id === option.id);

            if (notification) Object.assign(notification, { enabled });
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getTransactions: builder.query<Pageable<UserBTTransaction>, UserBTTransactionsRequest>({
      queryFn: async (params, api) => {
        try {
          const response = await BenefitTransactionsApi.getUserBenefitTransactions(
            getBenefitTransactionEndpoint(),
            {
              page: params.page - 1,
              size: params.pageSize,
              sort: params.sort,
            },
            api.signal
          );
          const pageable = getPageableFromResponseHeaders(response);

          return {
            data: {
              data: response.data,
              totalCount: pageable.totalCount,
              pageCount: pageable.pageCount,
              page: pageable.page,
            },
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.BTTransactions],
    }),
    getSocialPackagesList: builder.query<SocialPackage[], void>({
      queryFn: async (params, api) => {
        try {
          const result = await CustomersApi.listCustomerSocialPackagesTyped<ESocialPackagesResultType.List>(
            getBaseEndpoint(),
            {
              resultType: ESocialPackagesResultType.List,
            },
            api.signal
          );

          return {
            data: result.data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.SocialPackages],
    }),
    agreeSocialPackage: builder.mutation<SocialPackage, UserSocialPackageAgreeRequest>({
      queryFn: async ({ socialPackageId }) => {
        try {
          const result = await CustomersApi.handleCustomerSocialPackageCommand(getBaseEndpoint(), socialPackageId, {
            discriminator: 'AgreementCommand',
            agreementStatus: ESocialPackageEmployeeAgreement.Agreed,
          });

          return {
            data: result.data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.SocialPackages],
    }),
    refuseSocialPackage: builder.mutation<SocialPackage, UserSocialPackageAgreeRequest>({
      queryFn: async ({ socialPackageId }) => {
        try {
          const result = await CustomersApi.handleCustomerSocialPackageCommand(getBaseEndpoint(), socialPackageId, {
            discriminator: 'AgreementCommand',
            agreementStatus: ESocialPackageEmployeeAgreement.Refused,
          });

          return {
            data: result.data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.SocialPackages],
    }),
    createSocialPackageApplication: builder.mutation<SocialPackageApplicationShort, SocialPackageApplicationRequest>({
      queryFn: async request => {
        try {
          const result = await CustomersApi.createRzdSocialPackageApplication(getBaseEndpoint(), request);

          return {
            data: result.data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.SocialPackageApplications],
    }),
    getSocialPackageApplicationsList: builder.query<SocialPackageApplicationShort[], void>({
      queryFn: async (_, api) => {
        try {
          const result = await CustomersApi.listCurrentRzdSocialPackageApplications(getBaseEndpoint(), api.signal);

          return {
            data: result.data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.SocialPackageApplications],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useDeleteCurrentUserProfileMutation,
  useGetUserBalanceQuery,
  useGetUserFavoritesOffersQuery,
  useGetUserFavoritesTradeOffersQuery,
  useGetUserFavoritesCorpOffersQuery,
  useGetUserFavoritesProductOffersQuery,
  useGetUserFavoritesBookingsOffersQuery,
  useGetUserFavoritesQuery,
  useGetUserOrdersQuery,
  useGetUserOrdersCountQuery,
  useGetUserOrderQuery,
  useCreateUserOrdersMutation,
  useGetUserActivationsQuery,
  useGetUserActivationsCountQuery,
  useAddUserOfferToFavoritesMutation,
  useRemoveUserOfferFromFavoritesMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentUserEmailMutation,
  useBindCurrentUserCorpRoleMutation,
  useGetUserBannersQuery,
  useActivateUserOfferMutation,
  useGetUserOfferActivationsQuery,
  useGetUserOfferAvailabilityQuery,
  useChangeUserLocationMutation,
  useResetCurrentUserPasswordMutation,
  useSubscribeToUserOfferMutation,
  useUserFeedbackMutation,
  useGetTransactionsQuery,
  useGetLandingPageTestQuery,
  useCancelOrderMutation,
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
  useConfirmCodeMutation,
  useGetSocialPackagesListQuery,
  useAgreeSocialPackageMutation,
  useRefuseSocialPackageMutation,
  useCreateSocialPackageApplicationMutation,
  useGetSocialPackageApplicationsListQuery,
} = userApi;
