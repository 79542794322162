import { Typography } from '@mui/material';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { phoneFormatView } from 'presentationUtils/phone';
import { FC } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { InfoText } from './controls';

type ConfirmStepAdapterProps = {
  readonly phone: string;
  readonly onSubmit: (phone: string) => void;
  readonly onChange: () => void;
  readonly onCancel: () => void;
};

export const ConfirmStepAdapter: FC<ConfirmStepAdapterProps> = ({ phone, onSubmit, onChange }) => {
  return (
    <DialogueEventContentBuilderLayout
      title='Телефон'
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => onSubmit(phone)}
          >
            Да, это мой номер
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onChange}
          >
            Указать номер телефона
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <InfoText variant='body2'>
        Номер{' '}
        <Typography
          variant='subtitle2'
          component='span'
        >
          {phoneFormatView(phone)}
        </Typography>{' '}
        ваш? Менеджер перезвонит на него для уточнения деталей по заказу.
      </InfoText>
    </DialogueEventContentBuilderLayout>
  );
};
