import styled from '@emotion/styled';
import { MPGrid } from 'theme/ui-kit/grid';
import AppSvgIcon from '../../../../components/common/icon';

export const Wrapper = styled(MPGrid)``;

export const Icon = styled(AppSvgIcon)`
  width: 3rem;
  height: 3rem;
`;
