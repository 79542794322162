import { useMediaQuery } from '@mui/material';
import { Category, CategoryTreeItem } from 'domain/model';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import CategorySelectDoubleLayout from 'presentation/features/category/select/double/layout';
import { useCallback, useEffect, useRef } from 'react';
import CategorySelectDouble from '../../components/selectDouble';
import { categoryArrayToColumns } from '../../utils';
import { ColumnWrapper } from './controls';

type CategorySelectDoubleContainerProps = {
  readonly categories: CategoryTreeItem[];
  readonly expanded: Nullable<CategoryTreeItem>;
  readonly selectedCategory: Nullable<CategoryTreeItem>;
  readonly isSelectedOrExpanded: (category: CategoryTreeItem) => boolean;
  readonly onSelect: (category: Category) => void;
};

const Layout = CategorySelectDoubleLayout;

//вывод категорий дуплексом - родитель и под ним все дети, причем с возможностью свернуть/развернуть детей
const CategorySelectDoubleContainer = (props: CategorySelectDoubleContainerProps) => {
  const { categories, selectedCategory, expanded, isSelectedOrExpanded, onSelect } = props;

  const overlayScrollbarRef = useRef<Nullable<OverlayScrollbarsComponent>>();

  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const scrollToCategory = useCallback(
    (el: Nullable<HTMLElement>) => {
      const overlayInstance = overlayScrollbarRef.current?.osInstance();
      if (el) {
        overlayInstance?.scroll({ y: el.offsetTop - 50 }, 100);
      } else {
        overlayInstance?.scroll({ y: 0 }, 100);
      }
    },
    [overlayScrollbarRef]
  );

  //сброс прокрутки при переходе в другую категорию
  useEffect(() => {
    scrollToCategory(null);
  }, [expanded, scrollToCategory]);

  let columnsCount = 4;
  if (isLgDown) {
    columnsCount = 3;
  }
  if (isMdDown) {
    columnsCount = 2;
  }
  if (isSmDown) {
    columnsCount = 1;
  }
  const categoriesByColumns = categories ? categoryArrayToColumns(categories, columnsCount) : null;

  return (
    <Layout>
      {categoriesByColumns?.map((children, index) => (
        <ColumnWrapper key={index}>
          {children.map(child => (
            <CategorySelectDouble
              key={child.id}
              category={child}
              selected={selectedCategory}
              isInSelectedPath={isSelectedOrExpanded}
              initialVisibleCount={4}
              onClick={onSelect}
              scrollToCategory={scrollToCategory}
            />
          ))}
        </ColumnWrapper>
      ))}
    </Layout>
  );
};

export default CategorySelectDoubleContainer;
