/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenMainPartnerDesk, GenMainPartnerId, GenMainPredicate } from './data-contracts';
import { createRequest } from './http-client';

export const PartnerDesksApi = {
  /**
   * No description
   * * @tags PartnerDesks
   * @name GetPartnerDesks
   * @summary Получение списка c фильтром постранично
   * @request GET:/partner-desks
   * @secure
   */
  getPartnerDesks: (
    baseURL: string,
    query: {
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainPartnerDesk[], void>({
      baseURL,
      path: `/partner-desks`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags PartnerDesks
   * @name GetPartnerDeskById
   * @summary Получение карточки партнёра по id
   * @request GET:/partner-desks/{partnerId}
   * @secure
   */
  getPartnerDeskById: (baseURL: string, partnerId: GenMainPartnerId, signal?: AbortSignal) =>
    createRequest<GenMainPartnerDesk, void>({
      baseURL,
      path: `/partner-desks/${partnerId}`,
      method: 'GET',
      signal,
    }),
};
