import { CmsFeatureContext } from 'domain/model';
import CmsPreviewLandingContainer from './landing';
import CmsPreviewPartnerContainer from './partner';

type CmsContainerContainerProps = {
  readonly context: CmsFeatureContext;
  readonly guid: UUID;
};

const CmsPreviewContainer = ({ context, guid }: CmsContainerContainerProps) => {
  return context.partner ? (
    <CmsPreviewPartnerContainer
      guid={guid}
      partner={context.partner}
    />
  ) : (
    <CmsPreviewLandingContainer guid={guid} />
  );
};

export default CmsPreviewContainer;
