import { BrowserOptions } from '@sentry/browser/types/client';
import { CapturedUserAttributes } from 'domain/model';
import { ExternalTheme } from 'presentation/theme';
import { parseEnv } from 'utils/env';
import { EAppFeature } from '../presentation/types';

export type SentryType = BrowserOptions & {
  readonly tags: Nullable<object>;
  readonly project: Nullable<string>;
  readonly captureUserAttributes: Nullable<CapturedUserAttributes>;
  readonly xhrTracking: boolean;
};

type ApiAuthServiceType = {
  readonly clientId: string;
  readonly realm: string;
  readonly idpHint: string;
  readonly idpRzhdPortal: string;
  readonly url: string;
};

export type SupportInfoType = {
  readonly phones: string;
  readonly email: string;
};

export type PulseInfoType = {
  readonly caption: Nullable<string>;
  readonly url: Nullable<string>;
  readonly supportEmail: Nullable<string>;
};

export type BonusInfoType = {
  readonly unavailableReason: Nullable<string>;
};

export type MapsInfoType = {
  readonly unavailableReason: Nullable<string>;
};

export type ExternalThemes = Record<string, ExternalTheme>;

type AppConfiguratorOptions = {
  readonly apiBase: {
    readonly url: string;
    readonly path: string;
    readonly filesPath: string;
    readonly addressPath: string;
    readonly bonusesPath: string;
    readonly benefitTransactionPath: string;
  };
  readonly apiWs: {
    readonly url: string;
    readonly path: string;
    readonly sockjsPath: string;
    readonly enabled: boolean;
  };
  readonly apiAuthService: ApiAuthServiceType;
  readonly sentry: SentryType;
  readonly supportInfo: SupportInfoType;
  readonly debug?: boolean;
  readonly features?: string[];
  readonly corpEarningsSystemUrl?: string;
  readonly partnerPersonalAccountUrl?: string;
  readonly corpLinkProxyUrl?: string;
  readonly corpCspOffersUrl?: string;
  readonly siteCaption?: string;
  readonly pulse: PulseInfoType;
  readonly bonus: BonusInfoType;
  readonly maps: MapsInfoType;
  readonly externalThemes: Nullable<ExternalThemes>;
};

type AppEnvStatic = {
  readonly apiBase: string;
  readonly apiPath: string;
  readonly apiFilesPath: string;
  readonly apiAddressPath: string;
  readonly apiBonusesPath: string;
  readonly apiBenefitTransactionPath: string;

  readonly apiWs: string;
  readonly apiWsPath: string;
  readonly apiWsSockjsPath: string;
  readonly wsEnabled: string;

  readonly authServiceUrl: string;
  readonly authServiceRealm: string;
  readonly authServiceClientId: string;
  readonly authServiceIdpHint: string;
  readonly authServiceIdpRzhdPortal: string;

  readonly supportPhones: string;
  readonly supportEmail: string;

  readonly debug: string;

  readonly features: string;

  readonly corpEarningsSystemUrl: string;
  readonly partnerPersonalAccountUrl: string;
  readonly corpLinkProxyUrl: string;
  readonly corpCspOffersUrl: string;

  readonly sentryEnabled: string;
  readonly sentryDsn: string;
  readonly sentryTags: string;
  readonly sentryProject: string;
  readonly sentryRelease: string;
  readonly sentryCaptureUserAttributes: string;
  readonly sentryAutoSessionTracking: string;
  readonly sentryEnvironment: string;
  readonly sentryDebug: string;
  readonly sentryXhrTracking: string;
  readonly siteCaption?: string;

  readonly pulseUrl?: string;
  readonly pulseCaption?: string;
  readonly pulseSupportEmail?: string;

  readonly bonusUnavailableReason?: string;
  readonly mapsUnavailableReason?: string;
};

type AppEnvRuntime = {
  readonly appVersion: string;
  readonly externalThemes: Nullable<ExternalThemes>;
};

export type AppEnv = AppEnvStatic & AppEnvRuntime;

export class AppConfigurator {
  private static instance: AppConfigurator;

  public static getInstance(): AppConfigurator {
    if (!AppConfigurator.instance) {
      AppConfigurator.instance = new AppConfigurator();
    }

    return AppConfigurator.instance;
  }

  private constructor() {}

  private options: AppConfiguratorOptions = AppConfigurator.init();

  private static loadEnv(): AppEnv {
    if (process.env.NODE_ENV === 'test') {
      return {
        appVersion: '',

        apiBase: '',
        apiPath: '',
        apiFilesPath: '',
        apiBonusesPath: '',
        apiAddressPath: '',
        apiBenefitTransactionPath: '',
        apiWs: '',
        apiWsPath: '',
        apiWsSockjsPath: '',
        wsEnabled: '',

        authServiceIdpHint: '',
        authServiceIdpRzhdPortal: '',
        authServiceClientId: '',
        authServiceRealm: '',
        authServiceUrl: '',

        supportPhones: '',
        supportEmail: '',
        debug: '',

        features: '',
        corpEarningsSystemUrl: '',
        partnerPersonalAccountUrl: '',
        corpLinkProxyUrl: '',
        corpCspOffersUrl: '',
        siteCaption: '',

        pulseUrl: '',
        pulseCaption: '',
        pulseSupportEmail: '',

        sentryEnabled: '',
        sentryDsn: '',
        sentryTags: '',
        sentryProject: '',
        sentryRelease: '',
        sentryEnvironment: '',
        sentryCaptureUserAttributes: '',
        sentryAutoSessionTracking: '',
        sentryXhrTracking: '',
        sentryDebug: '',
        externalThemes: null,
      };
    } else {
      const request = new XMLHttpRequest();

      const staticConfigUrl = '/config/app.env';
      const versionFileUrl = '/version.txt';

      let staticOptions: Nullable<AppEnvStatic> = null;
      request.open('GET', staticConfigUrl, false);
      request.send();
      if (request.status === 200) {
        staticOptions = parseEnv<AppEnvStatic>(request.responseText);
        console.log('app static options', staticOptions);
      }
      if (!staticOptions || !Object.keys(staticOptions).length) {
        throw Error(`Not found application static config '${staticConfigUrl}'`);
      }

      let runtimeOptions: AppEnvRuntime = {
        appVersion: '',
        externalThemes: null,
      };
      request.open('GET', versionFileUrl, false);
      request.send();
      if (request.status === 200) {
        const version = request.responseText.trim();
        runtimeOptions = { ...runtimeOptions, appVersion: version };
        console.log('app version', version);
      }

      /*
      по факту не используется, комментарий ниже к getExternalTheme
      const themeFileUrl = '/themes/theme.json';
      request.open('GET', themeFileUrl, false);
      request.send();
      if (request.status === 200) {
        try {
          const externalThemes: ExternalThemes = JSON.parse(request.responseText.trim().replace(/[\n\r]/g, ''));
          runtimeOptions = { ...runtimeOptions, externalThemes };
        } catch (e) {
          console.error(e);
        }
      }*/

      return {
        ...staticOptions,
        ...runtimeOptions,
      };
    }
  }

  setOptions(options: AppConfiguratorOptions) {
    this.options = options;
  }

  private static init(): AppConfiguratorOptions {
    const env = AppConfigurator.loadEnv();

    let sentryTags: Nullable<object> = null;
    if (env.sentryTags) {
      try {
        const parsedSentryTags = JSON.parse(env.sentryTags);
        if (typeof parsedSentryTags === 'object') {
          sentryTags = parsedSentryTags;
        } else {
          console.warn('Sentry tags is not object');
        }
      } catch (e) {
        console.warn('Sentry tags is not json format');
      }
    }

    let sentryCaptureUserAttributes: Nullable<string[]> = null;
    if (env.sentryCaptureUserAttributes) {
      try {
        sentryCaptureUserAttributes = env.sentryCaptureUserAttributes.split(',').map(item => item.trim());
      } catch (e) {
        console.warn('Sentry capture user attribute parse error', sentryCaptureUserAttributes);
      }
    }

    return {
      apiBase: {
        url: env.apiBase ?? '',
        path: env.apiPath ?? '',
        filesPath: env.apiFilesPath ?? '',
        bonusesPath: env.apiBonusesPath ?? '',
        addressPath: env.apiAddressPath ?? '',
        benefitTransactionPath: env.apiBenefitTransactionPath ?? '',
      },
      apiWs: {
        url: env.apiWs ?? '',
        path: env.apiWsPath ?? '',
        sockjsPath: env.apiWsSockjsPath ?? '',
        enabled: (env.wsEnabled || 'true') === 'true',
      },
      apiAuthService: {
        url: env.authServiceUrl,
        realm: env.authServiceRealm,
        clientId: env.authServiceClientId,
        idpHint: env.authServiceIdpHint ?? '',
        idpRzhdPortal: env.authServiceIdpRzhdPortal ?? '',
      },
      supportInfo: {
        phones: env.supportPhones,
        email: env.supportEmail ?? '',
      },
      debug: env.debug === 'true' || process.env.NODE_ENV === 'development',
      features: env.features?.split(','),
      corpEarningsSystemUrl: env.corpEarningsSystemUrl ?? '',
      partnerPersonalAccountUrl: env.partnerPersonalAccountUrl ?? '',
      corpLinkProxyUrl: env.corpLinkProxyUrl ?? '',
      corpCspOffersUrl: env.corpCspOffersUrl ?? '',
      sentry: {
        enabled: env.sentryEnabled === 'true',
        dsn: env.sentryDsn ?? '',
        debug: env.sentryDebug === 'true',
        tags: sentryTags,
        project: env.sentryProject ?? '',
        release: env.sentryRelease ?? '',
        captureUserAttributes: sentryCaptureUserAttributes
          ? (sentryCaptureUserAttributes as CapturedUserAttributes)
          : null,
        autoSessionTracking: env.sentryAutoSessionTracking === 'true',
        xhrTracking: env.sentryXhrTracking === 'true',
        environment: env.sentryEnvironment ?? 'production',
      },
      siteCaption: env.siteCaption,
      pulse: {
        url: env.pulseUrl ?? null,
        caption: env.pulseCaption ?? null,
        supportEmail: env.pulseSupportEmail ?? null,
      },
      bonus: {
        unavailableReason: env.bonusUnavailableReason ?? null,
      },
      maps: {
        unavailableReason: env.mapsUnavailableReason ?? null,
      },
      externalThemes: env.externalThemes,
    };
  }

  getOptions(): AppConfiguratorOptions {
    return this.options;
  }

  getApiAuthService(): ApiAuthServiceType {
    return this.options.apiAuthService;
  }

  getApiBase() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.path}`;
  }

  getApiFilesPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.filesPath}`;
  }

  getApiBonusesPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.bonusesPath}`;
  }

  getApiAddressPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.addressPath}`;
  }

  getApiBenefitTransactionPath() {
    return `${this.options?.apiBase?.url}${this.options?.apiBase?.benefitTransactionPath}`;
  }

  getApiWs() {
    return this.options.apiWs;
  }

  getApiWsUrl() {
    return `${this.options?.apiWs?.url}${this.options?.apiBase?.path}${this.options?.apiWs?.path}`;
  }

  getApiWsEnabled() {
    return this.options.apiWs.enabled;
  }

  getSupportInfo() {
    return this.options?.supportInfo;
  }

  getSiteCaption() {
    return this.options?.siteCaption;
  }

  getPulseConfig() {
    return this.options?.pulse;
  }

  getBonusConfig() {
    return this.options?.bonus;
  }

  isDebug() {
    return this.options?.debug ?? false;
  }

  getFeatures() {
    return this.options?.features;
  }

  getCorpEarningsSystemUrl() {
    return this.options?.corpEarningsSystemUrl ?? '';
  }

  getPartnerPersonalAccountUrl() {
    return this.options?.partnerPersonalAccountUrl ?? '';
  }

  getCorpLinkProxyUrl() {
    return this.options?.corpLinkProxyUrl ?? '';
  }

  getCorpCspOffersUrl() {
    return this.options?.corpCspOffersUrl ?? '';
  }

  getMapsConfig() {
    return this.options?.maps ?? ({} as MapsInfoType);
  }

  /**
   * @deprecated
   * по факту не используется, это был первый вариант реализации
   * второй вариант - получение из user.org
   * но данный не удаляется, потому что есть сомнения в последнем
   */
  getExternalTheme(names: string[]): Nullable<ExternalTheme> {
    for (let i = 0; i < names.length; i++) {
      const theme = this.options?.externalThemes?.[names[i]];
      if (theme) {
        return theme;
      }
    }
    return this.options?.externalThemes?.['default'] ?? null;
  }

  hasFeature(feature: EAppFeature) {
    return this.options.features?.includes(feature.toString()) ?? false;
  }
}
