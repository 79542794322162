import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'data/store/store';
import { EOfferType } from 'domain/model';
import { CollectionStateStruct } from './slice';

const collectionsSelector = (store: RootState): CollectionStateStruct[] => store.collection.collections;
const sessionSelector = (store: RootState, guid: UUID) => guid;
const offerSelector = (store: RootState, guid: UUID, id: UUID) => id;

export const createTradeOffersCollectionSelector = createSelector(
  collectionsSelector,
  sessionSelector,
  offerSelector,
  (collections, guid, id) =>
    collections.find(collection => collection.guid === guid)?.[EOfferType.TradeOffer].find(offer => offer.id === id)
);
export const createCorpOffersCollectionSelector = createSelector(
  collectionsSelector,
  sessionSelector,
  offerSelector,
  (collections, guid, id) =>
    collections.find(collection => collection.guid === guid)?.[EOfferType.CorpOffer].find(offer => offer.id === id)
);

export const tradeOffersCollectionSelector = (guid: UUID, id: UUID) => (store: RootState) =>
  createTradeOffersCollectionSelector(store, guid, id);
export const corpOffersCollectionSelector = (guid: UUID, id: UUID) => (store: RootState) =>
  createCorpOffersCollectionSelector(store, guid, id);
