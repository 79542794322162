import styled from '@emotion/styled';
import {
  css,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemText as MuiListItemText,
  listItemButtonClasses,
} from '@mui/material';

export const Wrapper = styled(MuiListItemButton)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    border-radius: ${theme.shape.borderRadius * 1.2}px;

    &.${listItemButtonClasses.root} {
      padding: ${theme.spacing(1.5, 0.75, 1.5, 2)};
    }
  `
);

export const ListItemText = styled(MuiListItemText)(
  () => css`
    margin: 0;
    flex: 1;
  `
);

export const ActionWrapper = styled(MuiListItemSecondaryAction)(
  ({ theme }) => css`
    position: relative;
    display: flex;

    pointer-events: none;
    right: ${theme.spacing(1.5)};
    transform: none;
    right: 0;
  `
);

export const IconWrapper = styled(MuiListItemIcon)(
  ({ theme }) => css`
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    min-width: auto;

    margin-right: ${theme.spacing(2)};

    img {
      max-width: 100%;
      max-height: 100%;

      object-fit: contain;
      object-position: center center;
    }
  `
);
