import { BusinessEventCreator } from './businessEvent';
import { UserEventCreator } from './userEvent';

export class EventCreatorFactory {
  static business() {
    return new BusinessEventCreator();
  }
  static user() {
    return new UserEventCreator();
  }
}
