import { CmsContainer, CmsFeatureContext, EBaseOfferType, ECmsContainerType } from 'domain/model';
import { useMemo } from 'react';
import { EAppCategories } from '../../../types';
import CmsBannersContainer from '../banners/container';
import { CmsOfferCategories } from '../categories/container';
import CmsCollectionContainer from '../collection/container';
import CmsContainerHeader from '../components/containerHeader';
import CmsContainerSplitter from '../components/containerSplitter';
import CmsBannersLayout from '../layouts/banner';
import CmsOfferCategoriesLayout from '../layouts/categories';
import { ECmsCollectionPlace } from '../types';

type CmsContainerContainerProps = {
  readonly container: CmsContainer;
  readonly guid: UUID;
  readonly context?: CmsFeatureContext;
  readonly onContainerDataLoaded: () => void;
};

const CmsContainerContainer = (props: CmsContainerContainerProps) => {
  const { container, guid, onContainerDataLoaded, context } = props;
  const { showTitle, title, filterSettings, showIcon } = container;

  const header = useMemo(() => {
    const titleText = showTitle ? title : null;

    if (!showTitle) return null;

    let infoText;

    switch (filterSettings?.offerType) {
      case EBaseOfferType.CorpOffer:
        infoText = EAppCategories.CorpOffers;
        break;
      case EBaseOfferType.TradeOffer:
        infoText = EAppCategories.TradeOffers;
        break;
      case EBaseOfferType.ProductOffer:
      case EBaseOfferType.BookingOffer:
      case null:
      case undefined:
        infoText = '';
        break;
    }

    return (
      <CmsContainerHeader
        text={titleText}
        infoText={infoText}
      />
    );
  }, [showTitle, title, filterSettings]);

  const bannersList = [
    ECmsContainerType.Banner1,
    ECmsContainerType.Banner1Resizable,
    ECmsContainerType.Banner1LinkResizable,
    ECmsContainerType.Banner2,
    ECmsContainerType.Banner2MobileWide,
    ECmsContainerType.Banner3,
    ECmsContainerType.Banner3Carousel,
    ECmsContainerType.Banner3Resizable,
    ECmsContainerType.Banner4,
    ECmsContainerType.Banner4High,
  ];

  const collectionId = container.components?.[0].linkObjectId;

  return (
    <>
      {bannersList.includes(container.type.code) && (
        <CmsBannersContainer
          guid={guid}
          context={context}
          containerType={container.type.code}
          layout={CmsBannersLayout}
          header={header}
          footer={CmsContainerSplitter}
          offerType={container.filterSettings?.offerType ?? null}
          showIcon={showIcon}
          components={container.components}
          onDataLoaded={onContainerDataLoaded}
        />
      )}
      {container.type.code === ECmsContainerType.Category4Offer && (
        <CmsOfferCategories
          guid={guid}
          context={context}
          layout={CmsOfferCategoriesLayout}
          header={header}
          footer={CmsContainerSplitter}
          offerType={container.filterSettings?.offerType ?? null}
          components={container.components}
          containerType={container.type.code}
          onDataLoaded={onContainerDataLoaded}
        />
      )}
      {collectionId &&
        [ECmsContainerType.CompilationOffer, ECmsContainerType.CompilationPartner].includes(container.type.code) && (
          <CmsCollectionContainer
            header={header}
            footer={CmsContainerSplitter}
            collectionId={collectionId}
            guid={guid}
            place={ECmsCollectionPlace.Self}
            pageSize={container.pageSize}
            onDataLoaded={onContainerDataLoaded}
          />
        )}
    </>
  );
};

export default CmsContainerContainer;
