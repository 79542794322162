import ErrorHandler from 'data/network/errorHandler';
import { PartnerShort } from 'domain/model';
import { UserPersonalDataTransferToPartner } from 'features/user/components/personalDataTransfer';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BookingSidebar from '../../components/sidebar';
import UserPrompt from '../../components/userPrompt';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferDetailsCartSelector } from '../store/selectors';

type BookingOfferSidebarAdapterProps = {
  readonly id: UUID;
};

const BookingOfferSidebarAdapter: FC<BookingOfferSidebarAdapterProps> = ({ id }) => {
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const [confirmDeleteId, setConfirmDeleteId] = useState([-1]);
  const [partnerForPersonalDataTransfer, setPartnerForPersonalDataTransfer] = useState<Nullable<PartnerShort>>(null);

  const cart = useSelector(bookingOfferDetailsCartSelector);

  const {
    onUpdateModal,
    onDeleteService,
    isOrderCreating,
    errorCreateOrder,
    customerComment,
    tryHandleOrder,
    changeCustomerComment,
  } = useContextHandlers();

  useEffect(() => {
    if (errorCreateOrder) {
      ErrorHandler.handleHttpError(errorCreateOrder);
    }
  }, [errorCreateOrder]);

  if (!bookingOffer) {
    return null;
  }

  const handleDeleteItem = (index: number[]) => {
    setConfirmDeleteId([-1]);
    onDeleteService(index);
  };

  return (
    <>
      <BookingSidebar
        bookingOffer={bookingOffer}
        isLoading={isOrderCreating}
        offerId={bookingOffer.code}
        cart={cart?.[id] ?? null}
        customerComment={customerComment}
        onSelectItem={onUpdateModal}
        onDeleteItem={setConfirmDeleteId}
        onOrder={tryHandleOrder}
        onShowPersonalDataTransferInfo={() => setPartnerForPersonalDataTransfer(bookingOffer.partner)}
        onChangeCustomerComment={changeCustomerComment}
      />

      <UserPrompt
        isVisible={confirmDeleteId[0] > -1}
        title='Удалить услугу?'
        text='Услуга будет удалена из корзины'
        onProceed={() => handleDeleteItem(confirmDeleteId)}
        onCancel={() => setConfirmDeleteId([-1])}
      />

      {partnerForPersonalDataTransfer && (
        <UserPersonalDataTransferToPartner
          partner={partnerForPersonalDataTransfer}
          text={`С целью обработки заказа даю согласие ОАО\u000A«РЖД» на\u000Aпередачу моих персональных данных партнёру Витрины в следующем составе: ФИО, номер телефона, адрес электронной почты и других персональных данных, указанных в заказе.`}
          onClose={() => setPartnerForPersonalDataTransfer(null)}
        />
      )}
    </>
  );
};

export default BookingOfferSidebarAdapter;
