import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { GreenOKIcon } from 'presentation/media/icons';

const BookingOfferOrderHeader = (
  <>
    <Typography variant='h1'>
      Заказ создан &nbsp;
      <GreenOKIcon />
    </Typography>
    <Splitter size={2} />
    <Typography variant='body2'>
      Дождитесь звонка от представителя объекта для согласования деталей бронирования и окончательной стоимости.
    </Typography>
  </>
);

export default BookingOfferOrderHeader;
