import { EOfferType } from 'domain/model';
import { useAuth } from 'features/auth/provider/useAuth';
import { OfferFavoriteLinkContainer } from '../../../favorite';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';

type AspOfferDetailsFavoriteAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

export const AspOfferDetailsFavoriteAdapter = ({ guid, id }: AspOfferDetailsFavoriteAdapterProps) => {
  const { isAuthenticated } = useAuth();
  const { aspOffer } = useAspOfferDetailsData(id);

  if (!aspOffer || !isAuthenticated) {
    return null;
  }

  return (
    <OfferFavoriteLinkContainer
      guid={guid}
      offerId={id}
      offerType={EOfferType.TradeOffer}
    />
  );
};
