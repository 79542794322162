import styled from '@emotion/styled';
import { css } from '@mui/material';

type StyledSplitterCommonProps = {
  readonly size: number | string;
  readonly relative?: boolean;
};

type StyledSplitterProps = StyledSplitterCommonProps & {
  readonly variant: 'vertical' | 'horizontal';
};

const VerticalSplitter = styled(({ size, relative, ...others }: StyledSplitterCommonProps) => <div {...others} />)(
  ({ theme, size, relative }) => css`
    width: ${typeof size === 'string' ? size : relative ? `${size}rem` : theme.spacing(size)};
    height: 100%;
  `
);

const HorizontalSplitter = styled(({ size, relative, ...others }: StyledSplitterCommonProps) => <div {...others} />)(
  ({ size, relative, theme }) => css`
    width: 100%;
    height: ${typeof size === 'string' ? size : relative ? `${size}rem` : theme.spacing(size)};
  `
);

export const StyledSplitter = styled((props: StyledSplitterProps) =>
  props.variant === 'vertical' ? (
    <VerticalSplitter
      size={props.size}
      relative={props.relative}
    />
  ) : (
    <HorizontalSplitter
      size={props.size}
      relative={props.relative}
    />
  )
)``;
