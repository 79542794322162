import styled from '@emotion/styled';
import { css } from '@mui/material';
import { forwardRef } from 'react';

const ROW_HEIGHT = 1.5;
const ROW_COUNT = 4;
const UNIT_KIND = 'rem';

export const Wrapper = styled.div`
  position: relative;
`;

type ContainerLimiterProps = {
  readonly rowCount: number;
  readonly transparentSmooth?: boolean;
  readonly isOverflowed?: boolean;
};

export const ContainerLimiter = styled(
  forwardRef(
    (
      { rowCount, transparentSmooth, isOverflowed, ...others }: ContainerLimiterProps & { [key: string]: any },
      ref: any
    ) => (
      <div
        ref={ref}
        {...others}
      />
    )
  )
)(
  ({ theme, rowCount = ROW_COUNT, isOverflowed, transparentSmooth }) => css`
    line-height: ${ROW_HEIGHT}${UNIT_KIND};
    max-height: ${rowCount * ROW_HEIGHT}${UNIT_KIND};
    overflow: hidden;
    position: relative;

    ${isOverflowed &&
    transparentSmooth &&
    css`
      &:after {
        height: 30px;

        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 10%) 0%, rgba(255, 255, 255, 1) 100%);
      }
    `}
    ${theme.breakpoints.up('xl')} {
      max-height: 30rem;
    }
  `
);

type DangerouslyHtmlWrapperInnerProps = {
  readonly rowCount: number;
};

export const DangerouslyHtmlWrapperInner = styled.div<DangerouslyHtmlWrapperInnerProps>(
  ({ rowCount }) => css`
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: ${rowCount};
    -webkit-box-orient: vertical;
  `
);
