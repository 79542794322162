/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainDeleteCurrentUserProfileResponse,
  GenMainSubmitOtpCodeCommand,
  GenMainUserChangeOrConfirmEmailCommand,
  GenMainUserChangeOrConfirmEmailResponse,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const OtpApi = {
  /**
 * No description
* * @tags Otp
 * @name DeleteCurrentUserProfile
 * @summary Удаление профиля текущего пользователя
Коды некоторых ошибок:
   * USER_PROFILE_DELETE_REQUEST_MAX_ATTEMPTS - Использовано максимальное количество попыток удалить профиль
   * USER_PROFILE_DELETE_REQUEST_TOO_FREQUENT - Слишком частые попытки изменить почту
 * @request DELETE:/users/current
 * @secure
*/
  deleteCurrentUserProfile: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainDeleteCurrentUserProfileResponse, void>({
      baseURL,
      path: `/users/current`,
      method: 'DELETE',
      format: 'json',
      signal,
    }),

  /**
   * @description Для подтверждения уже установленной почты нужно передать ту же почту в запрос. Пользователь может поменять свой email через подтверждение кодом Если нужно отправить код заново - метод вызывается снова с тем же email Коды некоторых ошибок: * EMAIL_CHANGE_REQUEST_MAX_ATTEMPTS - использовано максимальное количество попыток изменить почту, попробуйте позже(см поле nextAttempt) * EMAIL_CHANGE_IS_TAKEN - почта занята другим пользователем * EMAIL_CHANGE_IS_CURRENT_USER_EMAIL - почта уже установлена данному пользователю * EMAIL_CHANGE_REQUEST_TOO_FREQUENT - Слишком частые попытки изменить почту, попробуйте позже
   * * @tags Otp
   * @name UserChangeOrConfirmEmail
   * @summary Изменениe или подтверждение своего email пользователем
   * @request POST:/users/current/email
   * @secure
   */
  userChangeOrConfirmEmail: (baseURL: string, data: GenMainUserChangeOrConfirmEmailCommand, signal?: AbortSignal) =>
    createRequest<GenMainUserChangeOrConfirmEmailResponse, GenMainUserChangeOrConfirmEmailCommand>({
      baseURL,
      path: `/users/current/email`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),

  /**
 * No description
* * @tags Otp
 * @name ConfirmOtp
 * @summary Подтверждение одноразового кода
Коды некоторых ошибок:
   * CONFIRM_CODE_INVALID_USER - Подтверждение кода не тем пользователем который его запрашивал
   * CONFIRM_CODE_INVALID - Код недействителен (просрочен, либо уже запрошен новый)
   * CONFIRM_CODE_MAX_ATTEMPTS - Превышено максимальное кол-во попыток подтверждения кода
   * CONFIRM_CODE_FAILED_ATTEMPT - Неверный код
 * @request POST:/users/current/otp/{otpId}
 * @secure
*/
  confirmOtp: (baseURL: string, otpId: string, data: GenMainSubmitOtpCodeCommand, signal?: AbortSignal) =>
    createRequest<void, GenMainSubmitOtpCodeCommand>({
      baseURL,
      path: `/users/current/otp/${otpId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),
};
