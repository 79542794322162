import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { css, Paper, PaperProps } from '@mui/material';

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ClaimWrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)(
  ({ theme }) => css`
    width: 100%;

    display: inline-flex;
    position: relative;
    box-shadow: none;

    ${theme.breakpoints.up('md')} {
      min-height: 10rem;
    }
  `
);

export const SkirtWrapper = styled.div(
  ({ theme: { spacing, palette } }) => css`
    z-index: 0;
    width: 100%;

    padding: ${spacing(6, 3, 2, 3)};
    margin-top: -${spacing(4)};

    display: flex;
    flex-direction: column;

    background-color: ${palette.secondary.A700};
    border-radius: ${spacing(2)};
  `
);
