import { PartnerOfferListRequest } from 'data/api/partner';
import { paginationSizeVariant } from 'domain/model';
import { EOfferStatus, OfferSortTypes } from 'domain/model';
import { FCC } from 'react';
import { EAppCategories } from '../../../../types';

export enum EPartnerDeskDetailsTab {
  PartnerWindow = 'partner-window',
  TradeOffers = 'trade-offers',
  AspOffers = 'asp-offers',
  ProductOffers = 'product-offers',
  CorpOffers = 'corp-offers',
  BookingOffers = 'booking-offers',
  AboutCompany = 'about-company',
  Contacts = 'contacts',
}

export type PartnerDeskDetailsTabInfo = {
  readonly label: string;
  readonly count?: number;
  readonly value: EPartnerDeskDetailsTab;
  readonly toolbar?: FCC;
};

type GetPartnerOffersListQueryParamsProps<S extends OfferSortTypes> = {
  readonly partnerId: UUID;
  readonly categoryId?: Nullable<UUID>;
  readonly statuses?: EOfferStatus[];
  readonly pageSize: number;
  readonly page: number;
  readonly sort: Nullable<S[]>;
};

// todo добавить автотесты
export const getPartnerOffersListQueryParams = <S extends OfferSortTypes>(
  props: GetPartnerOffersListQueryParamsProps<S>
): PartnerOfferListRequest<S> => {
  const { partnerId, categoryId, pageSize, page, sort, statuses } = props;

  return {
    partnerId,
    categoryId,
    sort,
    statuses,
    page: 1,
    pageSize: page * pageSize,
  };
};

export const partnerDeskOffersListEmptyParams = getPartnerOffersListQueryParams<any>({
  partnerId: '',
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null,
});

export const getPartnerDeskDetailsFirstActiveTab = (
  tab: Nullable<EPartnerDeskDetailsTab>,
  tabList: PartnerDeskDetailsTabInfo[]
) => {
  return tabList.find(item => item.value === tab) ?? tabList?.[0] ?? null;
};

export const getPartnerDeskDetailsTabName = (tab: EPartnerDeskDetailsTab): string => {
  switch (tab) {
    case EPartnerDeskDetailsTab.PartnerWindow:
      return 'Главная';
    case EPartnerDeskDetailsTab.ProductOffers:
      return EAppCategories.ProductOffers;
    case EPartnerDeskDetailsTab.TradeOffers:
      return EAppCategories.TradeOffers;
    case EPartnerDeskDetailsTab.AspOffers:
      return EAppCategories.AspOffers;
    case EPartnerDeskDetailsTab.CorpOffers:
      return EAppCategories.CorpOffers;
    case EPartnerDeskDetailsTab.BookingOffers:
      return EAppCategories.BookingOffers;
    case EPartnerDeskDetailsTab.AboutCompany:
      return 'О компании';
    case EPartnerDeskDetailsTab.Contacts:
      return 'Контакты';
  }
};
