import styled from '@emotion/styled';
import { css, DialogTitle as MuiDialogTitle } from '@mui/material';

export const DialogTitle = styled(MuiDialogTitle)(
  ({ theme: { spacing } }) => css`
    padding-left: 0;
    padding-right: 0;
    padding-top: ${spacing(3)};
  `
);
