import styled from '@emotion/styled';
import { css } from '@mui/material';

type SlideContentWrapperProps = {
  readonly active?: boolean;
};

export const SlideContentWrapper = styled.div<SlideContentWrapperProps>(
  ({ active }) => css`
    display: flex;
    //сделано для того, чтобы autoHeight для swiper-а работал совместно с прокруткой внутри диалога
    height: ${active ? '100px' : 'inherit'};
  `
);
