import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { useAuth } from 'features/auth/provider/useAuth';
import { createEvent } from 'features/user/events/needLogin';
import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { ERenderSource } from 'presentation/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addSearchParamToLocation } from 'routing';
import { getTradeOffersListRoute } from '../../routes';
import { tradeOfferDetailsStartSession } from '../store/slice';

export type UseTradeOfferDetails = {
  readonly onBack: () => void;
  readonly onChangeTab: (newTab: ETradeOfferDetailsTab) => void;
};

export type UseTradeOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
  readonly from: ERenderSource;
};

const useTradeOfferDetails = ({ id, guid, from }: UseTradeOfferDetailsProps): UseTradeOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const { isAuthenticated } = useAuth();
  const { publish } = useBusinessEventBus();
  const dispatch = useDispatch();
  const history = useHistory();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const onBack = () => {
    gotoPrevIndependentLocation(getTradeOffersListRoute());
  };

  const onChangeTab = (newTab: ETradeOfferDetailsTab) => {
    history.replace(
      addSearchParamToLocation({
        location: history.location,
        param: ETradeOfferUrlParam.Tab,
        value: newTab,
      })
    );
  };

  useEffect(() => {
    if (!isAuthenticated) {
      publish(createEvent({}));
    }
  }, [isAuthenticated, publish]);

  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);

  useEffect(() => {
    dispatch(tradeOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  return {
    onBack,
    onChangeTab,
  };
};

export default useTradeOfferDetails;
