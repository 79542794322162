import styled from '@emotion/styled';
import { css } from '@mui/material';

export const CarouselCommonWrapper = styled.div(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;

    ${theme.breakpoints.up('md')} {
      max-height: initial;
    }
  `
);

type WrapperProps = {
  readonly height?: number;
};

export const DefaultCarouselWrapper = styled.div<WrapperProps>(
  ({ height }) => css`
    width: 100%;
    ${height ? `height: ${height}px` : 'height: auto'};
    position: relative;
  `
);

export const ThumbCarouselWrapper = styled.div<WrapperProps>(
  ({ height }) => css`
    width: 100%;
    ${height ? `height: ${height}px` : 'height: auto'};
  `
);
