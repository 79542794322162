import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';
import { SocialPackage } from 'domain/model';

export const socialPackageRefuseEventType = 'SocialPackageRefuse';

export type SocialPackageRefuseEventPayload = {
  readonly socialPackage: SocialPackage;
};

export type SocialPackageRefuseEvent = BusinessEvent<SocialPackageRefuseEventPayload>;

export type SocialPackageRefuseEventProps = DisplayEventComponentProps<SocialPackageRefuseEventPayload>;
