import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';

const AboutCspWhatSizeCostInfo = () => {
  return (
    <>
      <Text>
        <List>
          <ListItem>Если Ваша должность категории «рабочие» — 48 300 руб.</ListItem>
          <ListItem>Если Ваша должность категории «другие служащие» — 45 540 руб</ListItem>
          <ListItem>Если Ваша должность категории «специалисты» — 41 400 руб.</ListItem>
          <ListItem>Если Ваша должность категории «руководители» — 34 500 руб.</ListItem>
          <ListItem>Стоимость компенсации на 1 члена семьи составляет — 28 960 руб.</ListItem>
        </List>
        Суммы компенсации обновляются каждый год.
      </Text>
    </>
  );
};

export default AboutCspWhatSizeCostInfo;
