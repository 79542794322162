import { Link, LinkProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { forwardRef } from 'react';
import AppSvgIcon from '../../../components/common/icon';
import Splitter from '../../../components/common/splitter';

type MPLinkProps = LinkProps & {
  readonly startIcon?: typeof SvgIcon;
  readonly iconSize?: 'micro' | 'small' | 'medium' | 'large';
};

export const MPLink = forwardRef(({ children, startIcon, iconSize = 'micro', ...props }: MPLinkProps, ref: any) => {
  return (
    <Link
      {...props}
      variant={startIcon ? 'icon' : props.variant}
      ref={ref}
    >
      {startIcon && (
        <>
          <AppSvgIcon
            fontSize={iconSize}
            icon={startIcon}
          />
          <Splitter
            size={1}
            variant='vertical'
          />
        </>
      )}
      {children}
    </Link>
  );
});
