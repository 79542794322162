import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    grid-gap: ${theme.spacing(3)};

    ${theme.breakpoints.down('sm')} {
      grid-gap: ${theme.spacing(2)};
    }
  `
);
