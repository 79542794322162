import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import Notifier from '../../system/notifier';
import ErrorHandler from 'data/network/errorHandler';

export const downloadURL = (data: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = data;
  a.download = fileName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  a.remove();
};

const axiosInstance = axios;

type UseFileDownloadProps = {
  readonly src: string;
  readonly accept?: string;
  readonly fileName?: string;
  readonly onDownload?: (success: boolean) => void;
};

type CreateDownloadRequestOptions = {
  readonly src: string;
  readonly fileName?: string;
};

export const createDownloadRequest = (
  options: CreateDownloadRequestOptions,
  cancelCallback?: CancelTokenSource,
  accept?: string
) => {
  const { fileName, src } = options;

  const config: AxiosRequestConfig = {
    responseType: 'blob',
    headers: {
      accept: accept ?? '*',
    },
    cancelToken: cancelCallback?.token,
  };

  return axiosInstance.get(src, config).then(response => {
    const { headers, data } = response;

    if (accept) {
      const fileUrl = URL.createObjectURL(new Blob([data], { type: accept }));
      downloadURL(fileUrl, fileName || 'file');
    } else {
      const fName: string = decodeURI(headers['content-disposition'].split('filename=')[1])
        .replaceAll('"', '')
        .replaceAll("'", '');
      const type: string = decodeURI(headers['content-type']);
      const fileUrl = URL.createObjectURL(new Blob([data], { type }));
      downloadURL(fileUrl, fileName || fName);
    }
  });
};

const useFileDownload = (props: UseFileDownloadProps) => {
  const { fileName, src, accept, onDownload } = props;

  const [start, setStart] = useState<boolean>(false);

  useEffect(() => {
    if (!start) return;

    const cancelCallback: CancelTokenSource = axiosInstance.CancelToken.source();

    createDownloadRequest({ src, fileName }, cancelCallback, accept)
      .then(() => {
        onDownload?.(true);
      })
      .catch(error => {
        if (!axiosInstance.isCancel(error)) {
          console.error('Ошибка при загрузке файла', error);
          onDownload?.(false);
          Notifier.getInstance().addError(`Ошибка при загрузке файла`);
          ErrorHandler.captureException(error);
        }
      })
      .finally(() => {
        setStart(false);
      });

    return () => cancelCallback.cancel();
  }, [start, src, accept, fileName, onDownload]);

  return {
    start,
    setStart,
  };
};

export default useFileDownload;
