import styled from '@emotion/styled';

export const AttributesGroupSection = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: ${p => p.theme.spacing(2)};
  }

  && > * + * {
    margin-left: ${p => p.theme.spacing()};
  }
`;

export const Wrapper = styled.div``;

export const ChildrenWrapper = styled.div``;
