import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { Wrapper } from './controls';

type StatusTextProps = {
  readonly status: string;
  readonly requisites: Nullable<string>;
};

const StatusText = ({ status, requisites }: StatusTextProps) => {
  return (
    <Wrapper>
      <Typography>{status}</Typography>
      <Splitter
        variant='vertical'
        size={0.5}
      />
      {requisites && (
        <Typography
          textAlign='center'
          color='textSecondary'
        >
          {requisites}
        </Typography>
      )}
    </Wrapper>
  );
};

export default StatusText;
