import { CategoryTree, CategoryTreeItem } from 'domain/model';

//перевод коллекции в несколько коллекций для вывода колонок
export const categoryArrayToColumns = (source: CategoryTreeItem[], columnsCount: number): CategoryTreeItem[][] => {
  const result = [
    ...source
      .reduce((a, b, i) => a.set(i % columnsCount, (a.get(i % columnsCount) || []).concat(b)), new Map())
      .values(),
  ];
  //добиваем пустыми колонками для корректного отображения
  for (let i = 1; i <= columnsCount - result.length; i++) {
    result.push([]);
  }
  return result;
};

//получение всех родителей относительно категории
export const getCategoryPathById = (tree: CategoryTree, id: UUID): Nullable<CategoryTreeItem[]> => {
  const findPath = (items: CategoryTreeItem[], id: UUID, path: CategoryTreeItem[]): Nullable<CategoryTreeItem[]> => {
    const existed = items?.find(item => item.id === id);
    if (existed) return [...path, existed];

    for (const item of items) {
      if (item.children?.length) {
        const existedChild = findPath(item.children, id, [...path, item]);
        if (existedChild?.[existedChild?.length - 1]?.id === id) return existedChild;
      }
    }

    return null;
  };
  return findPath(tree, id, []);
};
