import { ChipProps, useMediaQuery } from '@mui/material';
import { EOfferClientTagType, EOfferServerTagType, OfferTag as OfferTagType, OfferTagsType } from 'domain/model';
import { AspCurrencyIcon } from 'media/icons';
import { ReactElement } from 'react';
import { MPChip } from 'theme/ui-kit/chip';

type OfferTagProps = {
  readonly offerTag: OfferTagType;
  readonly size?: 'small' | 'medium' | 'micro';
};

const getOfferTagIcon = (offerTagType: OfferTagsType): ReactElement => {
  switch (offerTagType) {
    case EOfferServerTagType.Hit:
    case EOfferServerTagType.Sale:
    case EOfferServerTagType.New:
    case EOfferClientTagType.CSP:
      return <></>;
    case EOfferClientTagType.ASP:
      return <AspCurrencyIcon />;
  }
};

const getOfferTagColor = (offerTagType: OfferTagsType): ChipProps['color'] => {
  switch (offerTagType) {
    case EOfferServerTagType.Hit:
      return 'warning';
    case EOfferServerTagType.Sale:
      return 'error';
    case EOfferServerTagType.New:
      return 'success';
    case EOfferClientTagType.CSP:
      return 'csp';
    case EOfferClientTagType.ASP:
      return 'white';
  }
};

const OfferTag = (props: OfferTagProps) => {
  const { offerTag, size = 'small' } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    offerTag.type && (
      <MPChip
        size={isMdUp ? size : 'micro'}
        icon={getOfferTagIcon(offerTag.type)}
        color={getOfferTagColor(offerTag.type)}
        variant='filled'
        label={offerTag.name}
      />
    )
  );
};

export default OfferTag;
