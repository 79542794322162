import UserFavoritesStubAdapter from 'presentation/features/user/details/favorites/adapters/stub';
import useEntityScrollPositions from 'presentation/features/user/details/favorites/hooks/useEntityScrollPositions';
import useMountCandidates from 'presentation/features/user/details/favorites/hooks/useMountCandidates';
import UserDetailsLayout from 'presentation/layouts/userDetails';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { getUserFavoritesRoute } from '../../entry';
import UserFavoritesLoaderAdapter from './adapters/loader';
import { useUserFavoritesTabs } from './hooks/useTabs';
import {
  UserFavoritesBookingsOffersContainer,
  UserFavoritesCorpOffersContainer,
  UserFavoritesProductOffersContainer,
  UserFavoritesTabs,
  UserFavoritesTradeOffersContainer,
} from './tabs';
import { EUserFavoritesTab } from './utils';

type UserFavoritesContainerProps = {
  readonly guid: UUID;
  readonly tab: EUserFavoritesTab;
  readonly isCorpUser: boolean;
};

const Layout = UserDetailsLayout;

const UserFavoritesContainer = (props: UserFavoritesContainerProps) => {
  const { guid, tab, isCorpUser } = props;
  const history = useHistory();

  const { scrollToPosition, updateScrollPosition, scrollPositions } = useEntityScrollPositions();
  const { excludeCandidate, hasCandidate } = useMountCandidates();

  const { tabList, tabToDisplay } = useUserFavoritesTabs({
    guid,
    tab,
    isCorpUser,
  });

  const onChangeTab = useCallback(
    (newTab: EUserFavoritesTab) => {
      history.replace(getUserFavoritesRoute({ guid, tab: newTab }));
      updateScrollPosition(tab, window.scrollY);
    },
    [guid, history, tab, updateScrollPosition]
  );

  const tabsComponent = useMemo(
    () => (
      <UserFavoritesTabs
        tab={tabToDisplay}
        tabs={tabList}
        onChangeTab={onChangeTab}
      />
    ),
    [tabToDisplay, tabList, onChangeTab]
  );

  const onMountTabContainer = (offerTypeTabContainer: EUserFavoritesTab) => {
    excludeCandidate(offerTypeTabContainer);
    if (typeof scrollPositions[offerTypeTabContainer] === 'number') {
      scrollToPosition(scrollPositions[offerTypeTabContainer]);
    }
  };

  return (
    <Layout
      header='Избранное'
      toolbar={tabsComponent}
      loader={<UserFavoritesLoaderAdapter guid={guid} />}
    >
      {tabToDisplay?.value === EUserFavoritesTab.ProductOffers && (
        <UserFavoritesProductOffersContainer
          guid={guid}
          updateOnMount={hasCandidate(EUserFavoritesTab.ProductOffers)}
          onMount={() => onMountTabContainer(EUserFavoritesTab.ProductOffers)}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.TradeOffers && (
        <UserFavoritesTradeOffersContainer
          guid={guid}
          updateOnMount={hasCandidate(EUserFavoritesTab.TradeOffers)}
          onMount={() => onMountTabContainer(EUserFavoritesTab.TradeOffers)}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.CorpOffers && (
        <UserFavoritesCorpOffersContainer
          guid={guid}
          updateOnMount={hasCandidate(EUserFavoritesTab.CorpOffers)}
          onMount={() => onMountTabContainer(EUserFavoritesTab.CorpOffers)}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.BookingOffers && (
        <UserFavoritesBookingsOffersContainer
          guid={guid}
          updateOnMount={hasCandidate(EUserFavoritesTab.BookingOffers)}
          onMount={() => onMountTabContainer(EUserFavoritesTab.BookingOffers)}
        />
      )}
      <UserFavoritesStubAdapter
        countGuid={guid}
        tabsCount={tabList.length}
      />
    </Layout>
  );
};

export default UserFavoritesContainer;
