import { OfferActivation } from 'domain/model/activation';
import { ECorpOfferPromotionType, EOfferActivationStatus, ETradeOfferPromotionType } from 'domain/model';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useState } from 'react';
import useCopied from '../../../hooks/useCopied';
import { createDownloadRequest } from '../../../hooks/useFileDownload';
import { getOfferPromotionDownloadUrl } from '../utils';

export type OfferActivationState = {
  readonly isTaking: boolean;
  readonly needCopy: boolean;
  readonly isTaked: boolean;
  readonly onTakeActivation: (activation: OfferActivation) => void;
  readonly onNeedCopy: (value: boolean) => void;
  readonly onCopy: () => void;
};

const useTakeOfferActivations = (): OfferActivationState => {
  const { webAnalytics } = useWebAnalytics();

  const [fileDownloading, setFileDownloading] = useState(false);

  const { onCopy, onNeedCopy, needCopy, copied, copy } = useCopied();

  const isTaked = copied;
  const isTaking = fileDownloading;

  const takeOfferActivation = useCallback(
    (activation: OfferActivation) => {
      switch (activation.promotion.type) {
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
        case ETradeOfferPromotionType.PublicPromocode:
          if (activation.offer) {
            webAnalytics.tradeOfferActivateByCopy(activation.offer.id);
            webAnalytics.offerTakeActivation(activation.offer.id);
          }
          break;
        case ETradeOfferPromotionType.Widget:
        case ETradeOfferPromotionType.ReferralLink:
          break;
        case ETradeOfferPromotionType.Voucher:
        case ECorpOfferPromotionType.CorpCertificate:
        case ECorpOfferPromotionType.ExternalCorpCertificate:
        case ETradeOfferPromotionType.Asp:
        case ETradeOfferPromotionType.Digift:
          if (activation.offer) {
            webAnalytics.offerTakeActivation(activation.offer.id);
          }
          break;
      }
    },
    [webAnalytics]
  );

  const onTakeActivation = useCallback<OfferActivationState['onTakeActivation']>(
    async activation => {
      switch (activation.promotion.type) {
        case ETradeOfferPromotionType.Promocode:
        case ETradeOfferPromotionType.AccessCode:
          await copy(activation.promotion.code);
          onCopy();
          break;
        case ETradeOfferPromotionType.PublicPromocode:
          await copy(activation.promotion.publicPromotionValue);
          onCopy();
          break;
        case ETradeOfferPromotionType.Voucher:
        case ETradeOfferPromotionType.Asp:
        case ETradeOfferPromotionType.Digift:
        case ECorpOfferPromotionType.CorpCertificate:
        case ECorpOfferPromotionType.ExternalCorpCertificate: {
          if (
            activation.status !== EOfferActivationStatus.Approved &&
            activation.status !== EOfferActivationStatus.Given
          ) {
            return;
          }

          const src = getOfferPromotionDownloadUrl(activation);
          if (!src) {
            return;
          }
          setFileDownloading(true);
          createDownloadRequest({ src }).finally(() => setFileDownloading(false));
          break;
        }
        case ETradeOfferPromotionType.Widget:
        case ETradeOfferPromotionType.ReferralLink:
          break;
      }

      takeOfferActivation(activation);
    },
    [copy, onCopy, takeOfferActivation]
  );

  return {
    onTakeActivation,
    isTaking,
    onNeedCopy,
    needCopy,
    isTaked,
    onCopy,
  };
};

export default useTakeOfferActivations;
