import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { WelcomeBenefitsEventContainer } from './container';
import { WelcomeBenefitsEvent, WelcomeBenefitsEventPayload, welcomeBenefitsEventType } from './types';

export type { WelcomeBenefitsEventPayload } from './types';

export const createEvent = (payload: WelcomeBenefitsEventPayload): WelcomeBenefitsEvent =>
  EventCreatorFactory.business()
    .getCustom(welcomeBenefitsEventType, WelcomeBenefitsEventContainer)
    .withPayload(payload)
    .withOutMergeNext()
    .get();
