import CorpLinkProxy from 'components/common/links/proxy';
import { getCorpOffersListRoute } from 'features/offer/corp/routes';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { AppConfigurator } from 'system/appConfigurator';
import { MPButton } from 'theme/ui-kit/button';
import { BalanceSlotActions } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotActionsAdapter: FC<UserFinanceBalanceSlotAdapterProps> = () => {
  const history = useHistory();
  const corpEarningUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl();

  const onBy = () => history.push(getCorpOffersListRoute({}));

  return (
    <BalanceSlotActions>
      <MPButton
        variant='contained'
        color='brand'
        fullWidth
        onClick={onBy}
      >
        Потратить
      </MPButton>
      {corpEarningUrl && (
        <CorpLinkProxy link={corpEarningUrl}>
          {linkByProxy => (
            <MPButton
              variant='outlined'
              color='brand'
              fullWidth
              href={linkByProxy}
              target='_blank'
            >
              Получить
            </MPButton>
          )}
        </CorpLinkProxy>
      )}
    </BalanceSlotActions>
  );
};
