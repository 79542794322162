import { Typography } from '@mui/material';
import { CategoryTreeItem } from 'domain/model';
import AppImage from 'presentation/components/common/images/common';
import { isValidElement } from 'react';
import { ArrowRightIcon } from '../../../../media/icons';
import { ActionWrapper, IconWrapper, ListItemText, Wrapper } from './controls';

type CategorySelectBranchProps = {
  readonly category: CategoryTreeItem;
  readonly selected: boolean;
  readonly onClick: () => void;
  readonly onMouseEnter?: Nullable<() => void> | false;
};

const CategorySelectBranch = ({ category, selected, onClick, onMouseEnter }: CategorySelectBranchProps) => {
  const image = category.image;

  return (
    <Wrapper
      key={category.id}
      selected={selected}
      onClick={onClick}
      onMouseEnter={onMouseEnter || undefined}
    >
      {image && <IconWrapper>{isValidElement(image) ? <>{image}</> : <AppImage src={image.path} />}</IconWrapper>}

      <ListItemText primary={<Typography color='textPrimary'>{category.name}</Typography>} />

      {category.children?.length > 0 && (
        <ActionWrapper>
          <ArrowRightIcon color='secondary' />
        </ActionWrapper>
      )}
    </Wrapper>
  );
};

export default CategorySelectBranch;
