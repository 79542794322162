import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { SocialPackage } from 'domain/model';
import { createEvent as createEventHowToUse } from '../../../../socialPackage/events/howToUse';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';

type BookingOfferCspBannerHowUseAdapterProps = {
  readonly socialPackage: SocialPackage;
};

export const BookingOfferCspBannerHowUseAdapter = (props: BookingOfferCspBannerHowUseAdapterProps) => {
  const { socialPackage } = props;
  const { publish } = useBusinessEventBus();

  return (
    <SocialPackageBanner
      variant={'tertiary'}
      actionText={'Как получить компенсацию'}
      onClick={() => {
        publish(createEventHowToUse({ socialPackage }));
      }}
    >
      Компенсируйте часть своих затрат на оздоровление и отдых
    </SocialPackageBanner>
  );
};
