import { ELocaleShort } from '../../../../types';
import { AlphabetData } from './types';

export const filterAlhpabetByLocale = (locale: ELocaleShort) => (letter: AlphabetData) => {
  if (locale === ELocaleShort.En) {
    return new RegExp('[A-z]').test(letter);
  }

  return RegExp('[А-я]').test(letter);
};

export const specialCharactersAndDigitsRegEx = /^[0-9@~`!#$%^&*()_=+№\\';:"/?>.<,-]/;

export const filterDigitsAndSpecials = (letter: AlphabetData) => {
  return specialCharactersAndDigitsRegEx.test(letter);
};

export const charIsAllowed = (char: string, allowedAlphabet: AlphabetData[]) => {
  return allowedAlphabet.includes(char);
};
