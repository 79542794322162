import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@mui/material';
import { css } from '@emotion/react';
import { ellipsisCss } from 'presentation/components/common/ellipsis';

export const TypographyEllipsis = styled(Typography)<TypographyProps>(
  () => css`
    ${ellipsisCss};
  `
);
