import { EventBusContainer } from '@privilege-frontend/eventBus';
import DialogueEventsContainer from 'features/eventsFlow/dialogue';
import HistoryExtensionContainer from 'features/general/historyExtension/container';
import Notifier from 'features/general/notifier/container';
import { GuestTargetContainer } from 'features/user/guestTarget/container';
import { WebAnalyticsInitializer } from 'features/webAnalytics';
import { GlobalPreviewModeContainer } from 'screen/cms/globalPreviewMode/container';

const AppInfrastructure = () => {
  return (
    <>
      <EventBusContainer />
      <WebAnalyticsInitializer />
      <GlobalPreviewModeContainer />
      <GuestTargetContainer />
      <Notifier />
      <HistoryExtensionContainer />
      <DialogueEventsContainer />
    </>
  );
};

export default AppInfrastructure;
