import { FC } from 'react';
import { BalanceSlotValue } from '../../layout/controls';
import { UserFinanceBalanceValueAdapter } from '../balanceValue';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotValueAdapter: FC<UserFinanceBalanceSlotAdapterProps> = ({ balance, disabled }) => {
  return (
    <BalanceSlotValue>
      <UserFinanceBalanceValueAdapter
        balance={balance}
        disabled={disabled}
      />
    </BalanceSlotValue>
  );
};
