import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { CartItem } from 'domain/model';

export type CartDetailsState = {
  readonly guid: Nullable<UUID>;
  readonly selected: CartItem[];
};

type Reducer<T> = CaseReducer<CartDetailsState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<CartDetailsState> & {
  cartDetailsStartSession: Reducer<{ guid: UUID; items: CartItem[] }>;
  cartDetailsToggleSelected: Reducer<CartItem[]>;
  cartDetailsSelect: Reducer<CartItem[]>;
};

const slice = createSlice<CartDetailsState, Reducers, 'provide'>({
  name: 'provide',
  initialState: {
    guid: null,
    selected: [],
  },
  reducers: {
    cartDetailsStartSession: (state, { payload }) => {
      const { guid, items } = payload;
      const sessionGuid = state.guid;

      if (sessionGuid !== guid) {
        state.guid = guid;
        state.selected = [];
      }

      //удаляем из выбранных те которых уже нет в корзине
      state.selected = state.selected.filter(s => items.some(item => item.id === s.id));
    },
    cartDetailsToggleSelected: (state, { payload }) => {
      state.selected = payload.reduce<CartItem[]>(
        (prev, next) => {
          const existedIndex = prev.findIndex(p => p.id === next.id);
          if (existedIndex === -1) {
            return [...prev, next];
          } else {
            const result = [...prev];
            result.splice(existedIndex, 1);
            return result;
          }
        },
        [...state.selected]
      );
    },
    cartDetailsSelect: (state, { payload }) => {
      state.selected = payload;
    },
  },
});

export const { cartDetailsStartSession, cartDetailsToggleSelected, cartDetailsSelect } = slice.actions;

export default slice.reducer;
