import { getAboutCspRoute } from 'features/about/entry';
import { DocIcon2 } from 'media/icons';
import { MPLink } from 'theme/ui-kit/link';

const CompensationRulesDoc = () => {
  return (
    <MPLink
      startIcon={DocIcon2}
      href={getAboutCspRoute()}
      target='_blank'
    >
      Подробные правила программы
    </MPLink>
  );
};

export default CompensationRulesDoc;
