import HttpClient from 'data/network/http';
import useHistoryExtensions from 'hooks/useHistoryExtensions';
import ContentLoader from 'presentation/components/common/loader';
import { LoginLayoutType } from 'presentation/features/auth/login/layout/types';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getHomepageRoute } from 'routing';
import { AppConfigurator } from 'system/appConfigurator';
import LoginLayout from './layout';

const Layout: LoginLayoutType = LoginLayout;

const LoginRzdContainer = () => {
  const history = useHistory();
  const { prevIndependentLocation } = useHistoryExtensions();
  const authService = HttpClient.getInstance().getAuthService();

  useEffect(() => {
    if (!authService?.authenticated) {
      authService?.login({
        redirectUri: prevIndependentLocation
          ? `${prevIndependentLocation.pathname}${prevIndependentLocation.search}`
          : window.origin,
        idpHint: AppConfigurator.getInstance().getApiAuthService().idpRzhdPortal,
      });
    } else {
      history.replace(getHomepageRoute());
    }
  }, [authService, history, prevIndependentLocation]);

  return (
    <Layout>
      <ContentLoader />
    </Layout>
  );
};
export default LoginRzdContainer;
