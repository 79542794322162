import { OfferTagsWrapper } from './controls';
import { OfferTags as OfferTagsType } from 'domain/model';
import OfferTag from '../tag';

type OfferTagsProps = {
  readonly tags: OfferTagsType;
};

export const OfferTags = (props: OfferTagsProps) => {
  const { tags } = props;

  return (
    <OfferTagsWrapper>
      {tags.map(t => (
        <OfferTag
          key={t.name}
          offerTag={t}
        />
      ))}
    </OfferTagsWrapper>
  );
};

export default OfferTags;
