import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import AppImage from '../../../../../../components/common/images/common';
import { css } from '@mui/material';

export const StyledAppImage = styled(AppImage)(
  ({ theme }) => css`
    width: 160px;
    height: 104px;

    object-fit: cover;
    border-radius: ${theme.shape.borderRadius * 1.5}px;
  `
);

export const StyledLink = styled(Link)`
  align-self: flex-start;
`;
