import { AccountBalance } from 'domain/model';
import {
  currentUserBalancesSelector,
  currentUserCspActiveBalanceSelector,
} from 'features/user/current/store/selectors';
import { useSelector } from 'react-redux';

const useCurrentUserHeaderBalances = (): Nullable<AccountBalance[]> => {
  const balances = useSelector(currentUserBalancesSelector);
  const cspBalances = useSelector(currentUserCspActiveBalanceSelector);

  const finalBalances: AccountBalance[] = [];

  if (balances) {
    finalBalances.push(balances);
  }

  if (cspBalances) {
    finalBalances.push(cspBalances);
  }

  return finalBalances.length ? finalBalances : null;
};

export default useCurrentUserHeaderBalances;
