import usePageableArgsInRedux from '../../../../general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import { useGetTransactionsQuery, UserBTTransactionsRequest } from 'api/user';
import usePageableList from '../../../../general/pageable/usePageableList';
import { EPaginationBehaviour } from '../../../../general/pageable/types';
import { useCallback } from 'react';
import UserFinancesBonusesContainer from '../bonuses';
import useRtkQueryCachedPageableData from '../../../../general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { UserBTTransaction } from 'domain/model';
import { EUserServicesTag } from 'data/api';

export type UserFinanceHistoryBonusesAdapterProps = {
  readonly guid: UUID;
};

const pageSize: number = 10 as const;

export const UserFinanceHistoryBonusesAdapter = (props: UserFinanceHistoryBonusesAdapterProps) => {
  const { guid } = props;

  const argsStorage = usePageableArgsInRedux<UserBTTransactionsRequest>({
    guid,
    defaultState: {
      page: 1,
      pageSize,
    },
  });

  const { data, loadMore, isEmpty, isFetching, totalCount } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    useQuery: useGetTransactionsQuery,
    argsStorage,
    queryOptions: {
      refetchOnMountOrArgChange: true,
    },
    cacheProvider: useRtkQueryCachedPageableData<UserBTTransaction>({
      guid,
      tag: EUserServicesTag.BTTransactions,
      payload: argsStorage.currentState,
    }),
  });

  const onLoadMore = useCallback(() => {
    loadMore?.();
  }, [loadMore]);

  return (
    <UserFinancesBonusesContainer
      data={data}
      isEmpty={isEmpty}
      totalCount={totalCount}
      isFetching={isFetching}
      onLoadMore={onLoadMore}
    />
  );
};
