import styled from '@emotion/styled';
import { css, typographyClasses } from '@mui/material';
import { MPFab } from 'presentation/theme/ui-kit/button';

export const Wrapper = styled.div(
  ({ theme }) => css`
    height: auto;

    display: flex;
    align-items: center;
    flex: none;

    margin-bottom: ${theme.spacing(2)};

    .${typographyClasses.root} {
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `
);

export const IconWrapper = styled(MPFab)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    min-width: auto;
    min-height: auto;

    margin-right: ${theme.spacing()};
    color: ${theme.palette.text.primary};
  `
);
