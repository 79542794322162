import { useHistory } from 'react-router';
import { UserMenuItemFinances } from '../../components/menuItems';
import useCurrentUserHeaderBalances from '../../current/hooks/useHeaderBalances';
import { getUserFinancesRoute } from '../../entry';

export const UserMenuFinancesAdapter = () => {
  const history = useHistory();
  const balances = useCurrentUserHeaderBalances();

  const onClick = () => {
    history.push(getUserFinancesRoute({}));
  };

  return (
    balances &&
    balances.length > 0 && (
      <UserMenuItemFinances
        balances={balances}
        onClick={onClick}
      />
    )
  );
};
