import { api, EUserServicesTag } from 'data/api';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useDispatch } from 'react-redux';
import { UpdateEmailResponse } from '../../../../../domain/model';
import { useEmailEditQuery } from '../../../../hooks/useEmailEditQuery';
import { useUserProfileEmailEdit } from './useEmailEdit';

type UseCodeConfirm = {
  readonly email: string;
  readonly source?: UpdateEmailResponse;
  readonly onGetNewCode: () => void;
  readonly onConfirmed: () => Promise<boolean>;
};

export const useCodeConfirm = (): UseCodeConfirm => {
  const dispatch = useDispatch();
  const { refreshToken } = useAuthToken();

  const [, { data: emailEditData }] = useEmailEditQuery();
  const email = emailEditData?.newEmail ?? '';

  const { onUpdate } = useUserProfileEmailEdit();

  const onGetNewCode = () => onUpdate(email);

  const onRefreshToken = async () => {
    // обновление токена (для смены почты)
    await refreshToken();
    dispatch(api.util.invalidateTags([EUserServicesTag.Current]));
  };

  const onConfirmed = async () => {
    await onRefreshToken();
    return true;
  };

  return {
    email,
    source: emailEditData,
    onConfirmed,
    onGetNewCode,
  };
};
