import { EventCreatorFactory } from '@privilege-frontend/eventBus';
import { ConfirmPhoneEventContainer } from './container';
import { ConfirmPhoneEvent, ConfirmPhoneEventPayload, ConfirmPhoneEventResult, confirmPhoneEventType } from './types';

export type { ConfirmPhoneEventPayload } from './types';

export const createEvent = (
  payload: ConfirmPhoneEventPayload,
  onSuccess?: (result: ConfirmPhoneEventResult) => void
): ConfirmPhoneEvent =>
  EventCreatorFactory.business()
    .getCustom(confirmPhoneEventType, ConfirmPhoneEventContainer)
    .withPayload(payload)
    .withCallback(onSuccess)
    .withOutMergeNext()
    .get();
