import { BusinessEvent, DisplayEventComponentProps } from '@privilege-frontend/eventBus';
import { SocialPackage } from 'domain/model';

export const socialPackageAgreedEventType = 'SocialPackageAgreed';

export type SocialPackageAgreedEventPayload = {
  readonly socialPackage: SocialPackage;
};

export type SocialPackageAgreedEvent = BusinessEvent<SocialPackageAgreedEventPayload>;

export type SocialPackageAgreedEventProps = DisplayEventComponentProps<SocialPackageAgreedEventPayload>;
