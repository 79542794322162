import {
  GenMainCorpOfferPersonalPromotionType as ECorpOfferPromotionType,
  GenMainOfferTagType as EOfferServerTagType,
  GenMainOfferType as EOfferType,
  GenMainOfferUnavailabilityReason,
  GenMainPromotionType as ETradeOfferPromotionType,
  GenMainUserRole,
} from '@privilege-frontend/api';
import { AppOptionTyped } from './index';

export {
  GenMainUserStatus as EUserStatus,
  GenMainGender as EUserGender,
  GenMainOfferStatus as EOfferStatus,
  GenMainPartnerStatus as EPartnerStatus,
  GenMainPartnerType as EPartnerType,
  GenMainOfferTagType as EOfferServerTagType,
  GenCmsCollectionLinkObjectType as ECmsCollectionLinkObjectType,
  GenCmsBannerLinkObjectType as ECmsBannerLinkObjectType,
  GenMainOfferUnavailabilityReason as EOfferActivateError,
  GenMainOfferActivationStatus as EOfferActivationStatus,
  GenMainTargetType as ETargetType,
  GenMainDeliveryType as EDeliveryType,
  GenCmsOfferType as EBaseOfferType,
  GenBTTransactionType as EBTTransactionType,
  GenMainMultiSelectorValueType as EMultiSelectorValueType,
  GenMainAddressLevelId as EAddressLevel,
  GenMainOrderItemStatus as EOrderItemStatus,
  GenMainOrderStatus as EOrderStatus,
  GenMainServiceOrderByDateType as EOrderByDateType,
  GenMainCorpOfferPersonalPromotionType as ECorpOfferPromotionType,
  GenMainPromotionType as ETradeOfferPromotionType,
  GenMainPaymentType as EPaymentType,
  GenMainServiceCategoryStatus as EServiceCategoryStatus,
  GenMainResultType as ESocialPackagesResultType,
  GenMainSocialPackageEmployeeAgreement as ESocialPackageEmployeeAgreement,
  GenMainSocialPackageStatus as ESocialPackageStatus,
  GenMainProductOrderPaymentType as EProductOrderPaymentType,
  GenMainOfferType as EOfferType,
} from '@privilege-frontend/api';

export enum ENoticeStatus {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum EUserRole {
  Customer = GenMainUserRole.Customer,
  AdminMp = GenMainUserRole.AdminMp,
  AdminPartner = GenMainUserRole.AdminPartner,
  Corp = 'corp_user',
}

export enum ETradeOfferSortType {
  Default = 'rating,desc',
  ByNovelty = 'startDate,desc',
  ByEndDate = 'endDate',
}

export enum ECorpOfferSortType {
  Default = 'rating,desc',
  ByPriceAsc = 'price,asc',
  ByPriceDesc = 'price,desc',
  ByNovelty = 'startDate,desc',
  ByEndDate = 'endDate',
}

export enum EBookingOfferSortType {
  Default = 'rating,desc',
  ByMinPrice = 'minPrice',
  ByMaxPrice = 'minPrice,desc',
  ByNovelty = 'new,desc',
}

export type OfferSortTypes = ETradeOfferSortType | ECorpOfferSortType | EProductOfferSortType | EBookingOfferSortType;

export enum EProductOfferSortType {
  ByPriceAsc = 'price,asc',
  ByPriceDesc = 'price,desc',
  ByDiscount = 'salePercent,desc',
  ByNovelty = 'createdAt,desc',
}

export enum EOfferActivationSortType {
  ByCreatedAt = 'createdAt,desc',
}

export enum EOrderSortType {
  ByCreatedAt = 'createdAt,desc',
}

export type TradeOfferPromotionType = Exclude<
  ETradeOfferPromotionType,
  ETradeOfferPromotionType.CorpCertificate | ETradeOfferPromotionType.ExternalCorpCertificate
>;

export type AspOfferPromotionType = ETradeOfferPromotionType.Asp | ETradeOfferPromotionType.Digift;

export type TradePersonalPromotionType =
  | ETradeOfferPromotionType.Promocode
  | ETradeOfferPromotionType.Voucher
  | ETradeOfferPromotionType.AccessCode;

export type OfferPromotionType = ETradeOfferPromotionType | ECorpOfferPromotionType;

export type TradePublicPromotionType =
  | ETradeOfferPromotionType.PublicPromocode
  | ETradeOfferPromotionType.Widget
  | ETradeOfferPromotionType.ReferralLink;

export type PersonalPromotionType = TradePersonalPromotionType | ECorpOfferPromotionType | AspOfferPromotionType;

export enum EOrderCreateError {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Unknown = 'UNKNOWN',
}

export type OfferUnavailabilityReason = GenMainOfferUnavailabilityReason;

export enum EBannerPartition {
  TradeOffers = 'trade-offers',
  CorpOffers = 'corp-offers',
  ProductOffers = 'product-offers',
}

export enum EBannerPlace {
  TradeOffersListPageTop = 'TRADE_OFFERS_LIST_PAGE_TOP',
  CorpOffersListPageTop = 'CORP_OFFERS_LIST_PAGE_TOP',
  ProductOffersListPageTop = 'PRODUCT_OFFERS_LIST_PAGE_TOP',
}

export enum EClientOfferType {
  TradeOffer = EOfferType.TradeOffer,
  CorpOffer = EOfferType.CorpOffer,
  ProductOffer = EOfferType.ProductOffer,
  BookingOffer = EOfferType.BookingOffer,
  AspOffer = 'ASP_OFFER',
}

export const tradeOfferSortType: { [name in ETradeOfferSortType]: AppOptionTyped<ETradeOfferSortType> } = {
  [ETradeOfferSortType.Default]: {
    id: ETradeOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [ETradeOfferSortType.ByNovelty]: {
    id: ETradeOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
  [ETradeOfferSortType.ByEndDate]: {
    id: ETradeOfferSortType.ByEndDate,
    name: 'Скоро закончатся',
  },
};

export const corpOfferSortType: { [name in ECorpOfferSortType]: AppOptionTyped<ECorpOfferSortType> } = {
  [ECorpOfferSortType.Default]: {
    id: ECorpOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [ECorpOfferSortType.ByPriceAsc]: {
    id: ECorpOfferSortType.ByPriceAsc,
    name: 'Сначала дешевле',
  },
  [ECorpOfferSortType.ByPriceDesc]: {
    id: ECorpOfferSortType.ByPriceDesc,
    name: 'Сначала дороже',
  },
  [ECorpOfferSortType.ByNovelty]: {
    id: ECorpOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
  [ECorpOfferSortType.ByEndDate]: {
    id: ECorpOfferSortType.ByEndDate,
    name: 'Скоро закончатся',
  },
};

export const bookingOfferSortType: { [name in EBookingOfferSortType]: AppOptionTyped<EBookingOfferSortType> } = {
  [EBookingOfferSortType.Default]: {
    id: EBookingOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [EBookingOfferSortType.ByMinPrice]: {
    id: EBookingOfferSortType.ByMinPrice,
    name: 'Сначала дешевле',
  },
  [EBookingOfferSortType.ByMaxPrice]: {
    id: EBookingOfferSortType.ByMaxPrice,
    name: 'Сначала дороже',
  },
  [EBookingOfferSortType.ByNovelty]: {
    id: EBookingOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
};

export const productOfferSortType: { [name in EProductOfferSortType]: AppOptionTyped<EProductOfferSortType> } = {
  [EProductOfferSortType.ByPriceAsc]: {
    id: EProductOfferSortType.ByPriceAsc,
    name: 'Сначала дешевле',
  },
  [EProductOfferSortType.ByPriceDesc]: {
    id: EProductOfferSortType.ByPriceDesc,
    name: 'Сначала дороже',
  },
  [EProductOfferSortType.ByDiscount]: {
    id: EProductOfferSortType.ByDiscount,
    name: 'По скидке',
  },
  [EProductOfferSortType.ByNovelty]: {
    id: EProductOfferSortType.ByNovelty,
    name: 'По новизне',
  },
};

export enum ECmsContainerType {
  Banner1 = 'BANNER_1',
  Banner1Resizable = 'BANNER_1_RESIZABLE',
  Banner1LinkResizable = 'BANNER_1_LINK_RESIZABLE',
  Banner2 = 'BANNER_2',
  Banner2MobileWide = 'BANNER_2_MOBILE_WIDE',
  Banner3 = 'BANNER_3',
  Banner3Carousel = 'BANNER_3_CAROUSEL',
  Banner3Resizable = 'BANNER_3_RESIZABLE',
  Banner4 = 'BANNER_4',
  Banner4High = 'BANNER_4_HIGH',
  CompilationOffer = 'COMPILATION_OFFER',
  CompilationPartner = 'COMPILATION_PARTNER',
  Category4Offer = 'CATEGORY_4_OFFER',
}

export enum EFavoriteProcessAction {
  Add = 'add',
  Remove = 'remove',
}

export enum EOfferListType {
  Common = 'common',
  Upcoming = 'upcoming',
}

// Адресные опции для отображения
export enum EAddressOption {
  // Почтовый индекс
  PostalCode,
}

/** Дискриминатор для типов офферов */
export enum EOfferShortDiscriminator {
  Trade = 'CustomerTradeOfferShort',
  Corp = 'CustomerCorpOfferShort',
  Booking = 'CustomerBookingOfferShort',
  Product = 'CustomerProductOfferShort',
}

export enum ECreateOrderDiscriminator {
  Product = 'ProductOrderRequest',
  Booking = 'BookingOrderRequest',
}

export enum EOrderCommandDiscriminator {
  Cancel = 'CustomerCancelOrderCommand',
}

export enum EOrderShortDiscriminator {
  ProductOrderShort = 'ProductOrderShort',
  BookingOrderShort = 'BookingOrderShort',
}

export enum EOrderRequestDiscriminator {
  BookingOrderRequest = 'BookingOrderRequest',
}

export enum EOrderDiscriminator {
  ProductOrder = 'ProductOrder',
  BookingOrder = 'BookingOrder',
}

export enum EPartnerRegDataDiscriminator {
  PartnerCompanyData = 'PartnerCompanyData',
  PartnerIndividualData = 'PartnerIndividualData',
}

export enum EReasonInitiator {
  Customer = 'customer',
  Partner = 'partner',
}

export enum EOfferClientTagType {
  ASP = 'ASP',
  CSP = 'CSP',
}

export enum EBalanceType {
  Bonuses = 'BONUSES',
  ASP = 'ASP',
  CSP = 'CSP',
}

export type OfferTagsType = EOfferServerTagType | EOfferClientTagType;

export enum EDeleteProfileError {
  UserProfileDeleteForbidden = 'USER_PROFILE_DELETE_FORBIDDEN',
}
