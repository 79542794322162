import { EOfferListType } from 'domain/model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAspOffersContextHandlers } from '../../../hooks/useAspOffersContextHandlers';
import {
  partnerDeskDetailsAspOffersCommonArgsSelector,
  partnerDeskDetailsGuidSelector,
} from '../../../store/selectors';
import PartnerDeskAspOffersDataContainer from '../dataContainer';

type PartnerDeskAspOffersCommonAdapterProps = {
  readonly guid: UUID;
};

const type = EOfferListType.Common;

const PartnerDeskAspOffersCommonAdapter = ({ guid }: PartnerDeskAspOffersCommonAdapterProps) => {
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsAspOffersCommonArgsSelector);

  const handlers = useAspOffersContextHandlers();

  return useMemo(
    () =>
      !currentGuid || guid !== currentGuid || !args ? null : (
        <PartnerDeskAspOffersDataContainer
          type={type}
          guid={currentGuid}
          args={args}
          onChangePage={handlers.onChangeAspOffersPage}
          onShowCard={handlers.onShowAspOffersCard}
        />
      ),
    [args, currentGuid, guid, handlers.onChangeAspOffersPage, handlers.onShowAspOffersCard]
  );
};

export default PartnerDeskAspOffersCommonAdapter;
