import { AspOffer } from 'domain/model';
import OfferRelatedListLayout from 'presentation/layouts/offerRelatedList';
import { FC } from 'react';
import { AspOfferRelatedListDataAdapter, AspOffersRelatedHeaderAdapter } from './adapters';
import { AspOfferRelatedListContext } from './context';
import useAspOfferRelatedListHandlers from './hooks/useAspRelatedOfferList';

const Layout = OfferRelatedListLayout;
const Header = <AspOffersRelatedHeaderAdapter />;
const Loader = null;

type AspOfferRelatedListContainerProps = {
  readonly source: AspOffer;
};

const AspOfferRelatedListContainer: FC<AspOfferRelatedListContainerProps> = ({ source }) => {
  const handlers = useAspOfferRelatedListHandlers({ source });

  return (
    <AspOfferRelatedListContext.Provider value={handlers}>
      <Layout
        header={Header}
        loader={Loader}
      >
        <AspOfferRelatedListDataAdapter />
      </Layout>
    </AspOfferRelatedListContext.Provider>
  );
};

export default AspOfferRelatedListContainer;
