import { getCspOffersListRoute } from '../../../offer/csp/routes';
import { Link } from './controls';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode } from 'react';

type LinkCSPObjectsProps = {
  readonly children: ReactNode;
};

export const LinkCSPObjects = ({ children }: LinkCSPObjectsProps) => {
  return (
    <RouterLink
      target='_blank'
      to={getCspOffersListRoute()}
    >
      <Link
        underline={'none'}
        color='primary'
      >
        {children}
      </Link>
    </RouterLink>
  );
};
