import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { FC } from 'react';
import {
  SocialPackageHowToUseActionsAdapter,
  SocialPackageHowToUseContentAdapter,
  SocialPackageHowToUseTitleAdapter,
} from './adapters';
import { SocialPackageHowToUseEventProps } from './types';

/**
 * успешное согласие с КСП
 */

export const SocialPackageHowToUseEventContainer: FC<SocialPackageHowToUseEventProps> = props => {
  return (
    <DialogueEventContentBuilderLayout
      title={<SocialPackageHowToUseTitleAdapter />}
      actions={<SocialPackageHowToUseActionsAdapter {...props} />}
      titleAsContent
    >
      <SocialPackageHowToUseContentAdapter {...props} />
    </DialogueEventContentBuilderLayout>
  );
};
