import { useGetProductOfferCategoriesUsedQuery, useGetProductOfferCategoryQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { Category } from 'domain/model';
import { getBreadcrumbChains } from 'presentation/components/common/breadcrumb/expanded/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useUserLocalSettings from '../../../../../hooks/useUserLocalSettings';
import { productOfferListCategoryIdSelector, productOfferListNameSelector } from '../store/selectors';

type UseProductOfferListCategoriesData = {
  //текущая выбранная категория
  readonly selectedCategory: Nullable<Category>;
  //подкатегории текущей выбранной категории
  readonly selectedSubcategories: Category[];
  //категории для хлебных крошек
  readonly categoriesToBreadcrumbs: Category[][];
  //категории для отображения на экране в виде дополнительного фильтра
  readonly categoriesToFilter: Nullable<Category[]>;
  //факт того что текущая категория является листом
  readonly isCurrentCategoryLeaf: boolean;
  //факт процесса загрузки данных по категориям
  readonly isFetching: boolean;
}

// TODO(@Protopopov Ruslan): этот хук сейчас используется в нескольких местах и возвращает кэш по ключам из редакса - city, name, categoryId
// TODO протестить - будут ли ошибки будут пушиться пропорционально количеству хуков
export const useProductOfferListCategoriesData = (): UseProductOfferListCategoriesData => {
  const {
    settings: { city },
  } = useUserLocalSettings();

  const name = useSelector(productOfferListNameSelector);
  const categoryId = useSelector(productOfferListCategoryIdSelector);

  //запрос категории по id (если она есть в параметрах)
  const {
    data: currentCategoryData,
    isFetching: currentCategoryIsFetching,
    error: currentCategoryFetchError,
  } = useGetProductOfferCategoryQuery(categoryId ?? '', { skip: !categoryId });

  //запрос всех категорий по текущему фильтру и поиску (если он есть в параметрах)
  const { data: categoriesUsedData, error: categoriesUsedFetchError } = useGetProductOfferCategoriesUsedQuery(
    {
      name,
      city: city?.name ?? null,
    },
    { skip: !name }
  );

  //запрос всех категорий по текущему фильтру
  const {
    data: productCategories,
    isFetching: isProductCategoriesFetching,
    error: productCategoriesError,
  } = useGetProductOfferCategoriesUsedQuery({
    city: city?.name ?? null,
    onlyLeafCategories: false,
  });

  useEffect(() => {
    if (currentCategoryFetchError) {
      ErrorHandler.handleHttpError(currentCategoryFetchError);
    }
    if (categoriesUsedFetchError) {
      ErrorHandler.handleHttpError(categoriesUsedFetchError);
    }
    if (productCategoriesError) {
      ErrorHandler.handleHttpError(productCategoriesError);
    }
  }, [categoriesUsedFetchError, currentCategoryFetchError, productCategoriesError]);

  const categoriesToBreadcrumbs =
    currentCategoryData && productCategories ? getBreadcrumbChains([currentCategoryData], productCategories) : [];

  const categoriesToFilter = categoriesUsedData?.length
    ? categoriesUsedData
    : currentCategoryData
    ? [currentCategoryData]
    : [];

  const selectedCategory = categoriesToFilter?.find(categoryToFilter => categoryToFilter.id === categoryId) ?? null;
  const selectedSubcategories = productCategories?.filter(pc => pc.parentId === selectedCategory?.id) ?? [];
  const hasCurrentCategoryChildren = selectedSubcategories.length === 0;

  const isCurrentCategoryLeaf = !hasCurrentCategoryChildren;
  const isFetching = currentCategoryIsFetching || isProductCategoriesFetching;

  return {
    selectedCategory,
    categoriesToBreadcrumbs,
    categoriesToFilter,
    selectedSubcategories,
    isCurrentCategoryLeaf,
    isFetching,
  };
};
