import styled from '@emotion/styled';
import { css, Paper } from '@mui/material';

export const Wrapper = styled(Paper)(
  ({ theme }) => css`
    max-width: 600px;

    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};

    margin: auto;
    padding: ${theme.spacing(4)};

    border-radius: ${theme.shape.borderRadius * 2.4}px;

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1.5)};
      margin-left: -${theme.spacing(1.5)};
      margin-right: -${theme.spacing(1.5)};

      border-radius: 0;
    }

    > :empty {
      display: none;
    }
  `
);

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
  `
);
