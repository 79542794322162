import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 268px;

    margin-right: ${theme.spacing(4)};

    ${theme.breakpoints.down('lg')} {
      width: 220px;
      margin-right: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('md')} {
      width: 100%;

      margin-right: 0;
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const PriceWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin-bottom: ${spacing(2)};
  `
);

export const CompensationWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin-bottom: ${spacing(3)};
  `
);

export const ServicesWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing(1.5)};
    margin-bottom: ${spacing(3)};
    padding-left: ${spacing(1.25)};
  `
);
