import { Typography } from '@mui/material';
import { bannerCspIcon, bannerCspIconAlt } from 'media/images';
import { ReactNode } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import { Actions, Content, IconWrapper, Wrapper } from './controls';

export type SocialPackageBannerVariants = 'primary' | 'secondary' | 'tertiary';

export type SocialPackageBannerProps = {
  readonly actionText?: ReactNode;
  readonly onClick?: () => void;
  readonly children?: any;
  readonly variant?: SocialPackageBannerVariants;
};

const getIconByVariant = (variant: SocialPackageBannerVariants) => {
  switch (variant) {
    case 'primary':
      return bannerCspIcon;
    case 'secondary':
      return bannerCspIconAlt;
    case 'tertiary':
      return bannerCspIconAlt;
  }
};

const getColorButtonByVariant = (variant: SocialPackageBannerVariants) => {
  switch (variant) {
    case 'primary':
      return 'white';
    case 'secondary':
      return 'primary';
    case 'tertiary':
      return 'primary';
  }
};

const getColorTextByVariant = (variant: SocialPackageBannerVariants) => {
  switch (variant) {
    case 'primary':
      return 'white';
    case 'secondary':
      return 'textPrimary';
    case 'tertiary':
      return 'textPrimary';
  }
};

export const SocialPackageBanner = (props: SocialPackageBannerProps) => {
  const { onClick, variant = 'primary', children, actionText } = props;

  return (
    <Wrapper variant={variant}>
      <Content>
        <IconWrapper>
          <img
            src={getIconByVariant(variant)}
            alt={''}
          />
        </IconWrapper>
        <Typography
          color={getColorTextByVariant(variant)}
          variant={'subtitle1'}
        >
          {children}
        </Typography>
      </Content>

      {onClick && actionText && (
        <Actions>
          <MPButton
            variant={'outlined'}
            color={getColorButtonByVariant(variant)}
            fullWidth
            onClick={onClick}
          >
            {actionText}
          </MPButton>
        </Actions>
      )}
    </Wrapper>
  );
};
