import { BookingOfferShort, Category, EBookingOfferSortType, PriceRangeFilter } from 'domain/model';
import { PaginationSize } from '../../../types';

export enum ELastChangedFilterAttribute {
  Services = 'services',
  Compensation = 'compensation',
  Price = 'price',
  System = 'system',
}

export type BookingOffersSearchListLocationState = Partial<PriceRangeFilter> & {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: UUID;
  readonly services?: UUID[];
  readonly sort?: EBookingOfferSortType[];
  readonly rzdSocialPackage?: boolean;
  readonly lastChangedFilterAttribute?: Nullable<ELastChangedFilterAttribute>;
};

export type BookingOffersListLocationState = {
  readonly guid: UUID;
};

export enum EBookingUrlParam {
  MinPrice = 'minPrice',
  MaxPrice = 'maxPrice',
  Sort = 'sort',
  Services = 'services',
  Tab = 'tab',
  Compensation = 'rzdSocialPackage',
  LastChangedFilterAttribute = 'lastChangedFilterAttribute',
}

export type UseBookingOfferList = {
  readonly onChangeSort: (sort: EBookingOfferSortType[]) => void;
  readonly onChangePage: (newPage: number) => void;
  readonly onChangePageSize: (newPageSize: PaginationSize) => void;
  readonly onChangePriceRange: (priceRange: PriceRangeFilter) => void;
  readonly onChangeCompensation: (value: boolean) => void;
  readonly onSelectService: (id: UUID) => void;
  readonly onClearSelectedServices: VoidFunction;
  readonly onChangeCategory: (category: Category) => void;
  readonly onReturnToTopCategory: (id: Nullable<UUID>) => void;
  readonly onResetFilters: VoidFunction;
  readonly onShowCard: (bookingOffer: BookingOfferShort) => void;
};

export type BookingOfferListSearchParams = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly services: UUID[];
  readonly rzdSocialPackage: Nullable<boolean>;
  readonly minPrice: Nullable<number>;
  readonly maxPrice: Nullable<number>;
  readonly sort: Nullable<EBookingOfferSortType[]>;
  readonly lastChangedFilterAttribute?: Nullable<ELastChangedFilterAttribute>;
};

export type BookingCspPeriodRestrictions = {
  readonly text?: Nullable<string>;
  readonly allowed: boolean;
};
