import { FC } from 'react';

export enum EAssistantScenario {
  Onboarding = 'onboarding',
  SelectLocality = 'select-locality',
  AspAccept = 'asp-accept',
}

export type AssistantScenarioConfig = {
  readonly type: EAssistantScenario;
  readonly required: boolean;
  readonly interval?: number;
};

export type AssistantScenario = {
  readonly type: EAssistantScenario;
  readonly required: boolean;
  readonly interval?: number;
  readonly manager: FC;
  readonly component: FC;
};

export enum EAssistantScenarioStatus {
  Pending = 'pending',
  Ready = 'ready',
}
