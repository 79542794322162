import { Typography } from '@mui/material';
import { BookingOffer, BookingSlotPeriod, EDateFormat } from 'domain/model';
import { BookingCspPeriodRestriction } from '../cspPeriodRestriction';
import moment from 'moment-timezone';
import CountInput from 'presentation/components/common/countInput';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { DatesRange, MPRangeCalendarPicker } from 'presentation/theme/ui-kit/picker';
import { Dispatch, FC, SetStateAction } from 'react';
import { countWidth, TableHeader } from './constants';
import { PeriodRestrictionWrapper } from './controls';
import { BookingCspPeriodRestrictions } from 'features/offer/booking/types';

type BookingOfferModalPeriodProps = {
  readonly cspRestriction: Nullable<BookingCspPeriodRestrictions>;
  readonly bookingOffer: BookingOffer;
  readonly slots: BookingSlotPeriod[];
  readonly index: number;
  readonly setSlots: Dispatch<SetStateAction<BookingSlotPeriod[]>>;
};

const BookingOfferModalPeriod: FC<BookingOfferModalPeriodProps> = ({
  bookingOffer,
  cspRestriction,
  slots,
  index,
  setSlots,
}) => {
  const { qty = 1, startDate, endDate } = slots[index] ?? {};

  const handleChangeRange = (dates: Nullable<DatesRange>) => {
    setSlots(state => {
      const updatedState = [...state];

      updatedState[index] = {
        ...(state[index] ?? { qty: 1 }),
        startDate: dates?.startDate ?? null,
        endDate: dates?.endDate ?? null,
      };

      return updatedState;
    });
  };

  const handleChangeCount = (qty: number) => {
    setSlots(state => {
      const updatedState = [...state];

      if (!qty) {
        updatedState.splice(index, 1);
      } else {
        updatedState[index] = {
          ...updatedState[index],
          qty,
        };
      }

      return updatedState;
    });
  };

  const rangeValue = startDate || endDate ? ([startDate, endDate].filter(Boolean) as Date[]) : [];

  return (
    <>
      <MPRangeCalendarPicker
        limitPastSelection
        value={rangeValue}
        onChange={handleChangeRange}
      />
      {startDate && endDate && (
        <>
          {cspRestriction && (
            <PeriodRestrictionWrapper>
              <BookingCspPeriodRestriction restriction={cspRestriction} />
            </PeriodRestrictionWrapper>
          )}

          {<TableHeader bookingOffer={bookingOffer} />}
          <MPGrid
            container
            justifyContent='space-between'
            alignItems='center'
          >
            <MPGrid item>
              <Typography
                variant='body1'
                component='div'
              >
                с {moment(startDate).format(EDateFormat.Human)}
                <Splitter size={0.25} />
                по {moment(endDate).format(EDateFormat.Human)}
              </Typography>
            </MPGrid>
            <MPGrid
              item
              width={countWidth}
            >
              <CountInput
                isInputDisabled
                min={0}
                size='micro'
                value={qty}
                onIncrement={handleChangeCount}
                onDecrement={handleChangeCount}
              />
            </MPGrid>
          </MPGrid>
        </>
      )}
    </>
  );
};

export default BookingOfferModalPeriod;
