import { EOfferActivateError, EOfferStatus } from 'domain/model';
import { useMemo } from 'react';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { AspOfferClaimBalanceNotEnoughWarnMessage } from './balanceNotEnough';
import { AspOfferClaimErrorMessage } from './error';
import { AspOfferClaimPendingInfoMessage } from './pendingInfo';
import { AspOfferClaimPendingWaitingMessage } from './pendingWaiting';

export const AspOfferClaimCommonMessagesAdapter = () => {
  const { aspOffer, isActivationReceivedNow, activation } = useAspOfferClaimContext();

  const { isUserBalanceNotEnough, isLastActivationPending } = activation;

  return useMemo(() => {
    if (!aspOffer) {
      return null;
    } else {
      return (
        <>
          {activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && (
            <AspOfferClaimErrorMessage error={activation.activationError} />
          )}

          {(aspOffer?.status === EOfferStatus.Active || isActivationReceivedNow) &&
            isUserBalanceNotEnough &&
            !isLastActivationPending &&
            aspOffer.paymentType && <AspOfferClaimBalanceNotEnoughWarnMessage paymentType={aspOffer.paymentType} />}

          {isLastActivationPending && (
            <>
              <AspOfferClaimPendingWaitingMessage />
              <AspOfferClaimPendingInfoMessage />
            </>
          )}
        </>
      );
    }
  }, [aspOffer, activation.activationError, isActivationReceivedNow, isUserBalanceNotEnough, isLastActivationPending]);
};
