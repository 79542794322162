import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPPhoneInput } from '../../../../theme/ui-kit/input';
import { UserProfileEditAttribute } from './attribute';
import { UserProfileEditAttributeProps } from './types';

type UserProfileEditAttributePhoneProps = UserProfileEditAttributeProps<'phone'>;

export const UserProfileEditAttributePhone = (props: UserProfileEditAttributePhoneProps) => {
  const { value, validation, isFetching, onChange } = props;

  return (
    <UserProfileEditAttribute {...props}>
      <Typography>Введите новый номер телефона</Typography>
      <Splitter size={2} />
      <MPPhoneInput
        value={value}
        label={'Новый номер телефона'}
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={onChange}
      />
    </UserProfileEditAttribute>
  );
};
