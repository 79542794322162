import React, { FCC } from 'react';
import useGridSpacing from '../../../hooks/useGridSpacing';
import { MPGrid } from '../../grid';

type Variants = 'vertical' | 'horizontal';

type ConfirmGroupButtonsProps = {
  readonly variant?: Variants;
};

const flexDirectionMap: Record<Variants, 'column' | 'row'> = {
  vertical: 'column',
  horizontal: 'row',
};

const ConfirmGroupButtons: FCC<ConfirmGroupButtonsProps> = props => {
  const { children, variant = 'vertical' } = props;
  const { x2 } = useGridSpacing();

  const childrenToRender = React.Children.toArray(children);

  const flexDirection = flexDirectionMap[variant];

  return (
    <MPGrid
      container
      spacing={x2}
      flexDirection={flexDirection}
    >
      {childrenToRender.map((c, i) => (
        <MPGrid
          item
          key={i}
          zero
        >
          {c}
        </MPGrid>
      ))}
    </MPGrid>
  );
};

export default ConfirmGroupButtons;
