import { Person } from 'domain/model';
import { AppConfigurator } from 'system/appConfigurator';
import { locale } from '../../locale';

export enum EUserNameRule {
  Normal,
  LastnameF,
}

export const pluralize = (num: number, labels: string[]) => {
  num = Math.abs(num);
  const cases = [2, 0, 1, 1, 1, 2];

  return labels[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]];
};

export const normalizedLink = (link: string, secure = false) => {
  const http = 'http';
  let finalLink = link.indexOf(http) === -1 ? `${http}://${link}` : link;
  if (secure) {
    finalLink = finalLink.replace(new RegExp(`^${http}`), `${http}s`);
  }
  return finalLink;
};

export const getPersonFullName = (user: Person, separator = ' ') => {
  return [user.lastName, user.firstName, user.middleName].filter(item => !!item).join(separator);
};

export const getPersonShortName = (
  user: Person,
  separator = ' ',
  rule: EUserNameRule = EUserNameRule.Normal
): string => {
  try {
    switch (rule) {
      case EUserNameRule.Normal:
        return [user.lastName, user.firstName].filter(item => !!item).join(separator);
      case EUserNameRule.LastnameF:
        return [user.lastName, user.firstName]
          .filter(item => !!item)
          .map((item, index) => (index === 0 ? item : `${item ? item[0].toUpperCase() : ''}.`))
          .join(separator);
    }
  } catch (e: any) {
    console.error(e);
    return 'Не определено';
  }
};

export const formatNumber = (num: Nullable<number>): Nullable<string> => {
  if (num === null) return null;

  return num.toLocaleString(locale.full);
};

export const getInternetSiteDomain = () => {
  const hostname = document.domain;
  const www = 'www';
  if (process.env.NODE_ENV !== 'production') {
    return hostname;
  }

  return normalizedLink(www + '.' + hostname, true);
};

export const getSiteCaption = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getSiteCaption() || getInternetSiteDomain();
};

export const getPulseConfig = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getPulseConfig();
};

export const getBonusConfig = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getBonusConfig();
};

export const getSupportInfo = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getSupportInfo();
};

export const getSupportEmail = () => getSupportInfo()?.email;

export const getPartnerPersonalAccountUrl = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getPartnerPersonalAccountUrl();
};

export const getMapsConfig = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getMapsConfig();
};
