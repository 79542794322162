import styled from '@emotion/styled';
import { CarouselCommonWrapper } from 'presentation/components/common/carousels/gallery/controls';

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${CarouselCommonWrapper} {
    height: 100%;
    max-height: 100%;
  }
`;
