import styled from '@emotion/styled';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 0 ${theme.spacing(4)};
    flex-wrap: wrap;
    justify-content: center;
  `
);

export const LinkMore = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
);
