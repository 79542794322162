import * as H from 'history';
import { Location } from 'history';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { SearchResultsLocationState } from 'presentation/features/search/entry';
import { ERenderSource, ESearchUrlParam } from 'presentation/types';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
import { GetSearchResultsRouteProps } from '../types';
import { ETradeOfferDetailsTab } from './details/utils';
import { ETradeOfferUrlParam } from './utils';

const root = rootRouting.tradeOffer;
export const routing = {
  list: root,
  details: `${root}/:id`,
  search: `${root}/search`,
};

type GetTradeOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: ETradeOfferDetailsTab;
  readonly from?: ERenderSource;
};

export type TradeOffersListLocationState = {
  readonly guid: UUID;
};

export type TradeOfferDetailsLocationState = {
  readonly guid: UUID;
  readonly from?: ERenderSource;
};

type GetTradeOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
  readonly partnerId?: Nullable<UUID>;
};

type GetTradeOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getTradeOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getTradeOfferDetailsRoute = ({
  id,
  tab,
  guid,
  from,
}: GetTradeOfferDetailsRouteProps): H.Location<TradeOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(ETradeOfferUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
      from,
    },
    hash: '',
  };
};

export const getTradeOffersSearchRoute = (
  props: GetTradeOffersSearchRouteProps
): H.Location<TradeOffersListLocationState> => {
  const { name, categoryId, guid, partnerId } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ETradeOfferUrlParam.Category, categoryId);
  }

  if (partnerId) {
    params.append(ETradeOfferUrlParam.PartnerId, partnerId);
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getTradeOffersListRoute = (
  props?: GetTradeOffersListRouteProps
): Location<TradeOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};
