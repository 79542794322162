import * as H from 'history';
import { Location } from 'history';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { SearchResultsLocationState } from 'presentation/features/search/entry';
import { ERenderSource, ESearchUrlParam } from 'presentation/types';
import { v4 as uuidv4 } from 'uuid';
import { GetSearchResultsRouteProps } from '../types';
import { EAspOfferDetailsTab } from './details/utils';
import { routing } from './entry';
import { EAspOfferUrlParam } from './utils';

type GetAspOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

type GetAspOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
  readonly partnerId?: Nullable<UUID>;
};

type GetAspOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: EAspOfferDetailsTab;
  readonly from?: ERenderSource;
};

export type AspOfferDetailsLocationState = {
  readonly guid: UUID;
  readonly from?: ERenderSource;
};

export type AspOffersListLocationState = {
  readonly guid: UUID;
};

export const getAspOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getAspOffersListRoute = (props?: GetAspOffersListRouteProps): Location<AspOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getAspOffersSearchRoute = (
  props: GetAspOffersSearchRouteProps
): H.Location<AspOffersListLocationState> => {
  const { name, categoryId, guid, partnerId } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(EAspOfferUrlParam.Category, categoryId);
  }

  if (partnerId) {
    params.append(EAspOfferUrlParam.PartnerId, partnerId);
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getAspOfferDetailsRoute = ({
  id,
  tab,
  guid,
  from,
}: GetAspOfferDetailsRouteProps): H.Location<AspOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(EAspOfferUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
      from,
    },
    hash: '',
  };
};
