import styled from '@emotion/styled';
import { breadcrumbsClasses, Collapse, css } from '@mui/material';
import { MPBreadcrumbs } from 'presentation/theme/ui-kit/breadcrumbs';

export const StyledBreadcrumbs = styled(MPBreadcrumbs)`
  & .${breadcrumbsClasses.ol} {
    align-items: baseline;
    flex-wrap: nowrap;
  }
`;

type BreadcrumbItemWrapper = {
  readonly hidden?: boolean;
};

export const BreadcrumbItemWrapper = styled.div<BreadcrumbItemWrapper>(
  ({ hidden }) => css`
    display: flex;
    ${hidden && `visibility: hidden;`}
  `
);

export const BreadcrumbCollapse = styled(Collapse)(
  ({ theme }) => css`
    > :first-of-type {
      padding-top: ${theme.spacing()};
      padding-left: ${theme.spacing()};
    }
  `
);
