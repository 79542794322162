import { FileLink } from 'domain/model';
import AppImage from 'presentation/components/common/images/common';
import { NoImageIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../components/common/icon';
import { Card, CardTitle, Stub, WrapperImage } from './controls';

type PartnerDeskCardProps = {
  readonly title: string;
  readonly image?: Nullable<FileLink>;
  readonly onClick: () => void;
};

const StubImage = () => (
  <Stub>
    <AppSvgIcon
      viewBox='0 0 25 25'
      sx={{ fontSize: 70 }}
      icon={NoImageIcon}
    />
  </Stub>
);

const PartnerDeskCard = ({ title, image, onClick }: PartnerDeskCardProps) => {
  return (
    <Card
      elevation={0}
      onClick={onClick}
    >
      <WrapperImage>{image ? <AppImage src={image} /> : <StubImage />}</WrapperImage>
      <CardTitle variant='body2'>{title}</CardTitle>
    </Card>
  );
};

export default PartnerDeskCard;
