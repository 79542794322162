import { useGetSocialPackageApplicationsListQuery } from 'api/user';
import { CspApplicationCreatedInfo } from '../../components/applicationCreatedInfo';

export const CspApplicationLastApplicationAdapter = () => {
  const { data } = useGetSocialPackageApplicationsListQuery();

  const application = data?.[0];

  if (!application) {
    return null;
  }

  return <CspApplicationCreatedInfo application={application} />;
};
