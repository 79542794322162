import apiDefinition from 'data/openApi';
import { FileLink } from 'domain/model';
import ContentLoader from 'presentation/components/common/loader';
import { MPAvatarEditor } from 'presentation/theme/ui-kit/avatarEditor';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Notifier from 'system/notifier';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { Wrapper } from './controls';

type UserAvatarEditorContainerProps = {
  readonly source: Nullable<File | string>;
  readonly onClose: () => void;
  readonly onChange: (file: FileLink) => void;
};

const width = 150;
const height = 150;
const quality = 0.8;

const UserAvatarEditorContainer = (props: UserAvatarEditorContainerProps) => {
  const { source, onChange, onClose } = props;

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const editorRef = useRef<AvatarEditor>(null);

  const onSaveInternal = () => {
    const editor = editorRef.current;
    if (editor) {
      setIsFetching(() => true);

      editor.getImage().toBlob(
        blob => {
          if (blob) {
            const file = new File([blob], 'image.png');
            apiDefinition.files
              .upload(file)
              .then(({ data }) => onChange(data))
              .catch(e => {
                Notifier.getInstance().addError(`Произошла ошибка при загрузке файла: ${e.message}`);
              })
              .finally(() => {
                setIsFetching(() => false);
              });
          } else {
            Notifier.getInstance().addError('Не удалось обработать изображение');
            setIsFetching(() => false);
          }
        },
        'image/jpeg',
        quality
      );
    } else {
      Notifier.getInstance().addError('Не удалось обратиться к редактору изображения');
    }
  };

  return (
    <MPConfirmDialog
      open={!!source}
      maxWidth='xs'
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            disabled={isFetching}
            onClick={onSaveInternal}
          >
            Сохранить
          </MPButton>
          <MPButton
            variant='text'
            color='brand'
            size='large'
            fullWidth
            disabled={isFetching}
            onClick={onClose}
          >
            Отменить
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <Wrapper>
        {source && (
          <MPAvatarEditor
            editorRef={editorRef}
            image={source}
            width={width}
            height={height}
          />
        )}
        {isFetching && <ContentLoader />}
      </Wrapper>
    </MPConfirmDialog>
  );
};

export default UserAvatarEditorContainer;
