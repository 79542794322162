import { useMediaQuery } from '@mui/material';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FirstLevelWrapper, List, RootWrapper } from './controls';
import { CategorySelectLayoutType } from './types';

const CategorySelectLayout: CategorySelectLayoutType = props => {
  const { backButton, firstLevel, loader, scrollbarRef, hasContainerDivider = true, children } = props;

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <>
      {isSmDown && backButton}

      <RootWrapper hasBorder={hasContainerDivider}>
        <OverlayScrollbarsComponent
          options={{ scrollbars: { autoHide: 'leave' }, overflowBehavior: { x: 'hidden', y: 'scroll' } }}
        >
          <List>{children}</List>
        </OverlayScrollbarsComponent>
      </RootWrapper>

      {firstLevel && (
        <FirstLevelWrapper>
          <OverlayScrollbarsComponent
            ref={scrollbarRef as any}
            options={{ scrollbars: { autoHide: 'leave' } }}
          >
            {firstLevel}
          </OverlayScrollbarsComponent>
        </FirstLevelWrapper>
      )}

      {loader}
    </>
  );
};

export default CategorySelectLayout;
