import ScreenLayout from 'presentation/layouts/screen';
import { EBalanceType } from 'domain/model';
import RequiredGuidInLocationContainer from 'features/general/locationGuid/container';
import UserFinancesEntryContainer from 'features/user/details/finances/container';

const UserBonusesFinancesScreen = () => {
  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <UserFinancesEntryContainer
            guid={guid}
            tab={EBalanceType.Bonuses}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserBonusesFinancesScreen;
