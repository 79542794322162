import { breadcrumbsClasses, chipClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { ComponentNameToClassKey, OverridesStyleRules } from '@mui/material/styles/overrides';
import { chipCustomClasses } from './chip';
import { OverrideThemeFunc } from './types';

const breadcrumbs: OverrideThemeFunc<Components> = baseTheme => ({
  MuiBreadcrumbs: {
    defaultProps: {
      gap: 'default',
    },
    styleOverrides: {
      root: ({ ownerState }) => {
        let styles: Partial<
          OverridesStyleRules<'root', ComponentNameToClassKey['MuiBreadcrumbs'], typeof baseTheme>
        >['root'] = {};
        if (ownerState.gap === 'dense') {
          styles = {
            ...styles,
            [`& .${breadcrumbsClasses.separator}`]: {
              margin: baseTheme.spacing(0, 0.25),
            },
          };
        }
        return styles;
      },
      li: {
        [`& .${chipCustomClasses.colorDefault}`]: {
          ':hover': {
            background: baseTheme.palette.secondary.A900,
          },
        },
        [`& .${chipClasses.sizeSmall}>.${chipClasses.label}`]: {
          padding: baseTheme.spacing(0.75, 1.5),

          fontSize: baseTheme.typography.caption.fontSize,
          lineHeight: baseTheme.typography.caption.lineHeight,
          fontWeight: baseTheme.typography.caption.fontWeight,
        },
      },
    },
  },
});

export default breadcrumbs;
