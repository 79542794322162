import { EValidationType, validationRegexp, ValidationResult, ValidationRules } from 'presentation/utils/validation';
import { useState } from 'react';
import validator from 'validator';
import { useEmailEditQuery } from '../../../../hooks/useEmailEditQuery';
import validateObject from '../../../../hooks/validation/utils';

type EmailValue = { email: string };

type UseUserProfileEmailEdit = {
  readonly isFetching: boolean;
  readonly validation: Nullable<ValidationResult<EmailValue>>;
  readonly onUpdate: (email: string) => Promise<boolean>;
  readonly setValidation: (result: Nullable<ValidationResult<EmailValue>>) => void;
};

export const emailValidation: ValidationRules<{ email: string }> = {
  email: {
    required: true,
    requiredMessage: 'Необходимо указать корректный адрес электронной почты',
    validator: (object, value: Nullable<string>) => {
      if (!value) return null;

      const isEmail = validator.isEmail(value);
      if (!isEmail || !validationRegexp.email.test(value)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректный адрес электронной почты',
        };
      } else {
        return null;
      }
    },
  },
};

const rules = { email: emailValidation.email };

export const useUserProfileEmailEdit = (): UseUserProfileEmailEdit => {
  const [validation, setValidation] = useState<Nullable<ValidationResult<EmailValue>>>(null);

  const [updateCurrentUserEmail, { isLoading: isFetching }] = useEmailEditQuery();

  const onUpdate = (email: string) => {
    const validation = validateObject<EmailValue>({ email }, rules);
    const isValid = validation.isValid;
    setValidation(validation.results);
    if (isValid) {
      return updateCurrentUserEmail({ email })
        .unwrap()
        .then(result => !!result)
        .catch(error => {
          setValidation({
            email: {
              hasError: true,
              type: EValidationType.Error,
              message: error.data?.message || 'Произошла неизвестная ошибка',
            },
          });
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  };

  return {
    isFetching,
    validation,
    onUpdate,
    setValidation,
  };
};
